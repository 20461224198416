//CookieConsent
import React from 'react';
import { Component, Fragment } from 'react';
import "../Styles/CookieConsent.css"

const cookieStorage = {
  getItem: (key) => {

    const cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce((acc, [key, value]) => ({...acc, [key.trim()]: value}), {})

    return cookies[key];


  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value}`
  },
}
export const storageType = localStorage; //sessionStorage // cookieStorage
export const consentPropertyName = 'trg_consent'

export const shouldShowPopup = () => !storageType.getitem(consentPropertyName);
export const saveToStorage = () => storageType.setItem(consentPropertyName, true );

export class CookiePopUp extends Component{

  render()
  {
    return(
      <fragment>
        <div id = "consent-popup" class = "cookie__popup hidden">
          <p>
            By using this site you agree to our Terms and conditions
            Please <a id = "accept" href="#">Accept</a> these before using our site.
          </p>
        </div>
      </fragment>
    )}
}
