import {
         MEMBERS_EXPERIENCES_ANDEVENTS_LIST_LOADED,
         EVENT_REVIEW_LOADED

        } from '../Actions/Types.js'


const initialState = {
  membersExperiencesAndEventsList: null,
  membersExperiencesAndEventsListLoaded: false,
  membersEventReview: null,
  memberEventReviewLoaded: false
}

export default function (state =initialState, action) {
  switch(action.type) {



    case MEMBERS_EXPERIENCES_ANDEVENTS_LIST_LOADED:
    return {
      ...state,
      membersExperiencesAndEventsList: action.payload,
      membersExperiencesAndEventsListLoaded: true,
    };


    case EVENT_REVIEW_LOADED:
    return {
      ...state,
      membersEventReview: action.payload,
      memberEventReviewLoaded: true,
    };



    default:
      return state;
  }

}
