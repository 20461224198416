import React from 'react';
import axios from 'axios'
import {returnErrors} from './Messages.js'
import store from '../Store.js'

import {
         MEMBERS_EXPERIENCES_ANDEVENTS_LIST_LOADED,
         EVENT_REVIEW_LOADED
        } from '../Actions/Types.js'


export const getEventAndExperienceData= (query )  => (dispatch, getState) =>
  new Promise(function(resolve, reject)
  {


    /*
    this.setState({
      isLoadingOrderData: true,
    })*/

    var url = "https://www.therealgrape.com/api/auth/member-profile/my-events-and-experiences"
    //var url = "http://127.0.0.1:8000/api/auth/member-profile/my-events-and-experiences"

    // GET token from state
    const token = getState().auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((result) => {


        dispatch({
          type: MEMBERS_EXPERIENCES_ANDEVENTS_LIST_LOADED,
          payload: result.data
        })

        resolve(result)


      })
      .catch((error)   => console.log(error));


    })

export const getEventReview= (query )  => (dispatch, getState) => {

  var url = "https://www.therealgrape.com/api/auth/member-profile/get-event-review/"  + query
  //var url = "http://127.0.0.1:8000/api/auth/member-profile/get-event-review/"  + query



  // GET token from state
  const token = getState().auth.token;

  // send some headers with our request
  // 'Content-type': 'application/json',
  //'X-CSRFToken': csrftoken
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // IF token, add to headers config
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // Now let us make our request to load the user
  // which returns a promise
  axios.get(url, config)
    .then(result => {
      dispatch({
        type: EVENT_REVIEW_LOADED,
        payload: result.data
      });

      //console.log("returned reviews:" result.data)


    }).catch(err => {
      /*dispatch(returnErrors(err.response.data, err.response.status));*/
      /*dispatch({

      })*/
      console.log("Event review fail error: ", err)
    });



}
