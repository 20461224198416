import React, {useState, useEffect, useLayoutEffect} from 'react';
import ReactGA from 'react-ga'
import {createBrowserHistory} from 'history'

import  { Component } from 'react';
import '../App.css';
import '../Styles/Cart.css'
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import "../Actions/Auth.js"

import axios from 'axios'

import {loadUser, logout, getCart} from '../Actions/Auth.js'
import store from '../Store.js'

import {
       genderList,
       countryList,
       internationalCallingCodeList,
       findElementInCountryListArray,
       findElementInCountryCodeListArray,
       currencyCodes,
       currencyCodesReduced,
       findElementInCCyListArray,
       removeZeroFromPhoneNumber,
       titleList,
       PlaceHolderImage} from "../Forms/FormComponents.js"

import {
  withRouter, // used in the app export default - expore using with Journal
  BrowserRouter as Router,

  HashRouter, // try using the HashRouter
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from 'react-router-dom'

import {dateElementFromDate, dateFromText, dateFormatterFromString} from "../Components/DashboardComponents.js"
import getSymbolFromCurrency from 'currency-symbol-map'

/* Date and time inouts*/
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
//import TimePicker from 'react-time-picker'
import TimeKeeper from 'react-timekeeper';
import TimezoneSelect from 'react-timezone-select'



//needs to be imported after react-dates styles: _datepicker.css
import '../Styles/MyDashboard_datepicker.css'


//import DatePicker from "react-datepicker";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// google maps api
import { Loader } from "@googlemaps/js-api-loader"

// stripe elements
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer, CardElement, CardNumberElement, CardExpiryElement,CardCvcElement } from '@stripe/react-stripe-js';

import CreditCardLogos from '../img/logo/CARDS.svg'


var FormData = require('form-data');
//import DateTimePicker from 'react-datetime-picker';

// load stripe elements

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly"
});
/*const google = window.google*/
declare var google;

// stripe promose
const stripePromiseA = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);


class CartClass extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,

  };

  constructor(props) {
    super(props);

    this.state = {
      orderData: {},
      orderItems: [],
      isLoadingOrderData: true,

    }

    this.getOrderData      = this.getOrderData.bind(this)
    this.updateCart        = this.updateCart.bind(this)
    this.productType       = this.productType.bind(this)
    this.updateUserOrder   = this.updateUserOrder.bind(this)
  }

  componentDidMount() {
      this.getOrderData()
  }

  componentDidUpdate(){
/*    this.setState({
      isLoadingOrderData: true,
    })
    this.getOrderData()*/
  }

  getOrderData(){


    /*
    this.setState({
      isLoadingOrderData: true,
    })*/

    var url = "https://www.therealgrape.com/api/cart/my-orders"
    //var url = "http://127.0.0.1:8000/api/cart/my-orders"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        orderData: response.data,
        isLoadingOrderData: false,
        })

        console.log(response.data)


      })
      .catch((error)   => console.log(error));


  }

  /*onIncrement(e) {

    var inc = e.target.value
    setState({

    })

  }*/

  updateCart(e) {
    e.preventDefault()
    const { isAuthenticated, user } = this.props.auth;
    //var updateBtns = document.getElementsByClassName('update-cart')
    const elementId    =  e.currentTarget.id;
    var clickedElement = document.getElementById(elementId)




    var productId    = clickedElement.dataset.product
    var action       = clickedElement.dataset.action
    var productGroup = clickedElement.dataset.productgroup
    var quantity     = 1


    console.log('productGroup: ', productGroup,'productId: ', productId, 'Action: ', action,'Quantity: ', quantity)


    if (isAuthenticated) {

      this.updateUserOrder(productGroup, productId, action, quantity)
    } else {

    }
}

updateUserOrder(productGroup, productId, action, quantity, e){


  var url = "https://www.therealgrape.com/api/cart/update-item-to-cart"
  // var url = "https://www.therealgrape.com/api/cart/update-item-to-\cart"\
  //var url = "http://127.0.0.1:8000/api/cart/update-item-to-cart"

  // GET token from state
  const token = this.props.auth.token;

  // set up the header for the get calls
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // // Provide the authorisation token
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // now we need to stringfy the field that we want to provide
  // Request body

  const body = JSON.stringify({'productGroup':productGroup , 'productId':productId, 'action':action, 'quantity': quantity});

  // now post the Data
  axios.post(url, body, config)
  .then((response) => {
    /*dispatch */
    store.dispatch(getCart());
  })
  .then((data) => {
    /*location.reload()*/
    /*document.location.reload()*/
    /*this.forceUpdate(e)*/
    this.getOrderData()
  })

}


  productType(orderType, product) {

    if (orderType === "Events") {
        return product.event__id
    }
    else if (orderType === "Experiences") {
        return product.experiences__id
    }
    else {
      return "None"
    }
  }


  render()
  {
      const { isAuthenticated, user } = this.props.auth;
      var isLoadingOrderData              = this.state.isLoadingOrderData;
      var self = this

      return(
        !isAuthenticated ? [

          <div>
            <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
              <div class = "app-content-full container">
                <div class = "someSpacing">
                </div>
                <br/>
                <div class = "row">
                  <div class = "col-sm-12">
                    <div class = "stack">
                      <div class = "stack-element">
                        <div class = "cart__page__strapline" style  = {{textAlign:"left"}}>Your cart (0 items)</div>
                      </div>
                    </div>
                  </div>
                  <div class = "col-sm-12">
                    <hr class="spacing__large"/>
                    <div class = "cart__page__intro-copy"> You do not have any bookings in your cart </div>
                  </div>

                  <div class = "col-sm-12">
                    <div class = "shopping-links-wrapper" >
                      <div class = "shopping-links" >
                        <a class = "cart-link"  href="/login"  >login </a>
                        <div class = "shopping-links-separator" style = {{display: "inline-block"}}/>
                        <a class = "cart-link" href = '/Events'> Shop Events</a>
                        <div class = "shopping-links-separator" style = {{display: "inline-block"}}/>
                        <a class = "cart-link" href = '/Experiences'>Shop Experiences </a>
                      </div>
                    </div>
                  </div>
                  <div class = "col-sm-12">
                    <hr class="spacing__large"/>
                  </div>
                </div> {/*end row*/}

              </div>
            </div>
          </div>

        ] :
        [ isLoadingOrderData ? [
          <div>
            <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
              <div class = "app-content-full container">
                <div class = "someSpacing">
                </div>
                <br/>
                <div class = "row">
                  <div class = "col-sm-12">
                    <div class = "stack">
                      <div class = "stack-element">
                        <div class = "cart__page__strapline" style  = {{textAlign:"left"}}>Loading cart ...</div>
                      </div>
                    </div>
                  </div>
                </div> {/*end row*/}

              </div>
            </div>
          </div>
        ] : [

          <div>
            <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
              <div class = "app-content-full container">
                <div class = "someSpacing">
                </div>
                <br/>


                <div class = "row">

                  <div class = "col-sm-12 col-md-12 col-lg-8" > {/*Cart */}
                    <br class="hide-for-small-screens show-for-large-formats"/>
                    <div class = "cart-page cart-page-elevation">
                      <div class = "stack">
                        <div class = "cart-stack-element-title">
                          <div>
                            <div class = "cart-title-container" >
                              <hr class = "title__ruler" style={{marginRight:"3px"}}/>
                              <div class = "cart__element__strapline" style = {{textTransform: "uppercase"}} >Your cart ({this.state.orderData.numberOfItemsInCart} items)</div>
                              <hr class = "title__ruler" style={{marginLeft:"3px"}}/>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class = "stack">
                        <div class = "stack-element-main">
                          {/*<div>*/}
                            <div class = "row"> {/* render bought products*/}
                              <div class = "col-sm-12">
                                {/*render each bought element*/}
                                <div class = "stack">
                                  <div class = "stack-element-inner">
                                    <ul class = "my-cart-cards" >
                                      {self.state.orderData.cart.map((product, index) =>(
                                        <li key = {`product-${index}`}>
                                          <div class = "cart-entry" style = {{marginTop: "10px"}} > {/*start cart entry*/}
                                            <div class = "row">
                                              <div class = "col-sm-4 col-md-3">
                                                {/*<a class = "product-image" href = "https://real-grape-site-s3-bucket.s3.amazonaws.com/avignon_bridge_rhone_1600x1600_CqtB787.jpg" title ="">*/}
                                                  {/*<div class = "image-map-wrapper">*/}
                                                  <div class = "image-map-wrapper">
                                                    <img alt="" class="cart_Img_Centered"  src = {product.mainExperienceImage}/>
                                                  </div>
                                                  {/*</div>*/}
                                                {/*</a>*/}
                                              </div> {/*end image col*/}
                                              <div class = "col-sm-8 col-md-9">
                                                <div class = "row">
                                                  <div class = "col-sm-11 col-md-11">
                                                    <div class = "cart_Event-title"> {product.orderType === "Events" ? product.event__eventTitle : product.experiences__experienceTitle}</div>
                                                    <hr/>
                                                    <div class = "cart__copy__description"> {product.orderType === "Events" ? product.event__eventType : product.experiences__experienceType} </div>
                                                  </div>
                                                  <div class = "col-sm-1 col-md-1 text-right">
                                                    <div class = "row">
                                                      <div class = "col-sm-12">
                                                        <div class = "remove-icon-wrapper">
                                                          <div class = "stack">
                                                            {/*class = "cart__buttons"*/}
                                                            <button aria-hidden="true"  class = "cart__buttons" onClick={(e) => this.updateCart(e)} id = {`update-cart-remove-${index}`} data-productGroup = {product.orderType} data-product={product.event__id}  data-product={self.productType(product.orderType, product) } data-action="remove">
                                                              {/*<i aria-hidden="true"  class="fas fa-times button_cursor"></i>*/}
                                                              x
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class = "row">
                                                  <div class = "col-sm-12">
                                                    <div class = "row">
                                                      <div class = "col-sm-12 col-md-4" style = {{alignItems:"center"}}>
                                                          {/*increment decrement button*/}
                                                        <div class = "quantity__controls__container" >
                                                          <div class = "quantity__controls" >

                                                            {/*<button  aria-hidden="true" class= "cart__buttons inc_dec_buttons" style={{marginRight:"3px"}} onClick={(e) => this.updateCart(e)} id = {`update-cart-decrease-${index}`} data-productGroup = {product.orderType} data-product={self.productType(product.orderType, product) } data-action="decrease" >
                                                              <i  class="far fa-minus-square quantity-button inc_dec_buttons"></i>
                                                            </button>*/}

                                                            <button  aria-hidden="true" class= "cart__buttons inc_dec_buttons" style={{marginRight:"0px"}} onClick={(e) => this.updateCart(e)} id = {`update-cart-decrease-${index}`} data-productGroup = {product.orderType} data-product={self.productType(product.orderType, product) } data-action="decrease" >
                                                              -
                                                            </button>

                                                            <div class = "quantity__control_display">{product.quantity}</div>

                                                            <button aria-hidden="true" class= " inc_dec_buttons" style={{marginLeft:"0px"}} onClick={(e) => this.updateCart(e)} id = {`update-cart-increase-${index}`}  data-productGroup = {product.orderType} data-product={self.productType(product.orderType, product) } data-action="increase" >
                                                              +
                                                            </button>

                                                            {/*<button aria-hidden="true" class= "cart__buttons inc_dec_buttons" style={{marginLeft:"3px"}} onClick={(e) => this.updateCart(e)} id = {`update-cart-increase-${index}`}  data-productGroup = {product.orderType} data-product={self.productType(product.orderType, product) } data-action="increase" >
                                                              <i   class="far fa-plus-square quantity-button inc_dec_buttons"></i>
                                                            </button>*/}

                                                          </div>
                                                        </div>

                                                      </div>
                                                      <div class = "col-sm-0 col-md-4">
                                                        <div style = {{textAlign:"center", marginBottom: "5px"}}>
                                                          <span style={{ marginRight: '5px', fontWeight:'500', color: '#000' }}> {getSymbolFromCurrency(product.currency)}{product.price.toFixed(2) }</span>
                                                          <span style={{ marginRight: '5px', fontWeight:'500', fontSize:'12px', color: '#8d8478' }}> + {product.clientTransactionFee.toFixed(2)} Fee </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                              <hr/>
                                            </div> {/*end cart row*/}
                                          </div> {/*end cart entry*/}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div> {/* end render bought products*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div> {/*End Cart */}

                  <div class = "col-sm-12 col-md-12 col-lg-4" >  {/*Cart Summary*/}
                    <br class="hide-for-small-screens show-for-large-formats"/>
                    <div class = "sticky-wrapper">
                      <div class = "sticky-summary">
                        <div>
                          <div class = "cart-page cart-page-elevation">
                            <div class = "cart-summary-title-container cart-summary-title-container-maxWidthLg">
                              <div class = "cart-title-container">

                                <hr class = "title__ruler" style={{marginRight:"3px"}}/>
                                <div class = "cart__element__strapline" style = {{textTransform: "uppercase"}}> summary</div>
                                <hr class = "title__ruler" style={{marginLeft:"3px"}}/>
                              </div>
                              <div class = "cart-summary-element-container">
                                <div class = "row cart-summary-content">

                                  <div class = "col-sm-12 col-md-12">
                                    <div class = "cart__element__strapline" style= {{fontWeight:"300", textTransform:"uppercase", textAlign:"center"}}>Voucher Code</div>
                                  </div>
                                  <div class = "col-sm-12 col-md-12">
                                    <div class = "row " style = {{ margin:"2px 2px 20px 2px"}}>
                                      <div class = "col-sm-12 col-md-12">
                                        <div class = "input-group mb-3" style = {{display:"flex", justifyContent: "center", marginLeft:"2px", marginRight:"2px"}}>
                                          {/*<input onChange={this.onChange} value={username} type="text"  name="username" className="form-control"   />*/}
                                          <input  type = "text" name="voucher" placeholder = "Enter your voucher" class = "form-control"/>
                                          <div style = {{display:"flex"}}>
                                            <button class = "bui-button"
                                                    style = {{textTransform:"uppercase"}}
                                                    onClick={(e) => this.updateCart(e)} id = {`update-cart-voucher`} data-productGroup = "" data-product=""  data-product="" data-action="voucher"
                                                    >apply </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class = "col-sm-12 col-md-12">
                                      <div class = "row order-summary-item-container" style = {{ margin:"2px 2px 20px 2px"}}>
                                        <div class = "col-xs-9 col-sm-9 col-md-9">
                                          Subtotal
                                        </div>
                                        <div class = "col-xs-3 col-sm-3 col-md-3">
                                          {self.state.orderData.subtotal.toFixed(2)}
                                        </div>
                                        <div class = "col-xs-9 col-sm-9 col-md-9">
                                          Booking Fee
                                        </div>
                                        <div class = "col-xs-3 col-sm-3 col-md-3">
                                          {self.state.orderData.bookingFees.toFixed(2)}
                                        </div>

                                        <div class = "col-xs-9 col-sm-9 col-md-9">
                                          VAT
                                        </div>
                                        <div class = "col-xs-3 col-sm-3 col-md-3">
                                          {self.state.orderData.VAT.toFixed(2)}
                                        </div>

                                        <div class = "col-sm-12 col-md-12">
                                          <hr style = {{width:"100%"}}/>
                                        </div>

                                        <div class = "col-xs-9 col-sm-9 col-md-9">
                                          <strong>Total </strong>
                                        </div>
                                        <div class = "col-xs-3 col-sm-3 col-md-3">
                                          <strong>{self.state.orderData.cartTotal.toFixed(2)} </strong>

                                        </div>
                                      </div>
                                    </div>
                                    <div class = "col-sm-12 col-md-12">
                                    {[this.state.orderData.numberOfItemsInCart > 0 ?
                                      <a href ="/Checkout" class = "checkout-link" style = {{textDecoration: "none"}}>
                                        <button class = "button button-primary checkout-button">
                                          <i style={{ marginRight: '10px'}} class="fas fa-user-lock"></i>
                                          checkout securely
                                          </button>
                                      </a>:
                                      <div></div>
                                      ]}
                                    </div>


                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  {/* End Cart Summary*/}

                </div>



              </div>
            </div>
          </div>
          ]
        ]
      )

  }
}

class CheckoutClass extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,

  };

  constructor(props) {
    super(props);


  this.state = {
    orderData: {},
    orderItems: [],
    isLoadingOrderData: true,
    stripePromise: null,
    isLoadingStripePromis: true,
    client_secret: null,
    setProcessing: false,
    setError: false,
    setSucceeded: false,
    setMetadata: null,
    orderComplete: false,
    attendeeInfo : {
      'title': null,
      'firstName': null,
      'lastName': null,
      'countryCode': null,
      'phone': null,
      'companyName': '',
      'address': null,
      'city':null,
      'postCode': null,
      'country': null
    }


  }

  this.getOrderData      = this.getOrderData.bind(this)
  this.confirmAndPay     = this.confirmAndPay.bind(this)
  this.createPaymentIntent  = this.createPaymentIntent.bind(this)
  this.handleConfirmDetails = this.handleConfirmDetails.bind(this)
  this.completeOrder        = this.completeOrder.bind(this)
  this.updateAttendeeForm   = this.updateAttendeeForm.bind(this)
}

 componentDidMount() {
    /*console.log(this.props)*/
    /*console.log("Promise A: ", stripePromiseA )*/

    this.getOrderData()

    // load the stripe elements
    // const stripePromise = await loadStripe(process.REACT_APP_STRIPE_TEST_KEY);
     const stripePromise = loadStripe(process.REACT_APP_STRIPE_TEST_KEY);
     /*console.log("StripePromise: ", stripePromise)*/
     this.setState({
       stripePromise: stripePromise,
       isLoadingStripePromise: false,
     })


}

updateAttendeeForm(e) {

  e.preventDefault()



  const elementId             = e.currentTarget.id;
  var  clickedElement         = document.getElementById(elementId)
  const targetFieldName       = clickedElement.name
  var allFormElementsComplete = true
  var confirmDetailsBtn       = document.getElementById('confirm-attendee-details-btn')
  /*console.log("Element clicked: ", elementId, "Clicked: ", clickedElement, "Event Target: ", clickedElement.value, "Event Name: ", clickedElement.name)*/

  this.setState({
    attendeeInfo : { ... this.state.attendeeInfo, [targetFieldName] : clickedElement.value }
  })

  const currentFormState = { ... this.state.attendeeInfo, [targetFieldName] : clickedElement.value }


  for (var key in currentFormState) {
    if (currentFormState[key] == null){
      allFormElementsComplete = false
    }
  }



  if (allFormElementsComplete == true) {
    confirmDetailsBtn.classList.remove("hidden")
  }


}

handleConfirmDetails(e) {

  e.preventDefault()
  //const elementId     =  e.currentTarget.id;
  const elementId = 'stripe-payment-form'
  var attendeeDetails = {}
  this.createPaymentIntent(elementId)

}


async createPaymentIntent(elementId) {


  var stripeForm         = document.getElementById(elementId)
  var attendeeForm       = document.getElementById('attendee-form')
  var attendeeDetailsBtn = document.getElementById('confirm-attendee-details-btn')
  var formSpacers        = document.getElementById('form-spacers')
  {/*if (this.state.isLoadingOrderData == false)*/}
  var url = "https://www.therealgrape.com/api/cart/create-payment-intent"
  //var url = "http://127.0.0.1:8000/api/cart/create-payment-intent"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'OrderId': this.state.orderData.OrderId,
                        'attendeeDetails' : this.state.attendeeInfo});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {


      this.setState({
        client_secret: response.data.client_secret
      })

      stripeForm.classList.remove("hidden"); // unhide the stripe form
      attendeeDetailsBtn.classList.add("hidden");
      attendeeForm.classList.add("hidden");
      formSpacers.classList.add("hidden");
    })
    .then((data) => {

    })


}
 confirmAndPay = async(event) => {
   // Block native form submission.
    event.preventDefault();

    const {stripe, elements} = this.props;


    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({
      setProcessing: true,
    });

     // Step 3: Use clientSecret from PaymentIntent and the CardElement
     // to confirm payment with stripe.confirmCardPayment()
     const payload = await stripe.confirmCardPayment(this.state.client_secret, {
       payment_method: {
         /*card: elements.getElement(CardElement),*/
         card: elements.getElement(CardNumberElement),

         billing_details: {
           /*name: event.target.name.value,*/
         },
       },
     });

     if (payload.error) {
       /*setError(`Payment failed: ${payload.error.message}`);*/
       this.setState({
         setError: 'Payment failed:' + payload.error.message,
         setProcessing: false,
       });

       console.log("[error]", payload.error);
     } else {

       this.setState({
         setError: null,
         setSucceeded: true,
         setProcessing: false,
         setMetadata: payload.paymentIntent,
       })
       console.log("[PaymentIntent]", payload.paymentIntent);
       this.completeOrder()
     }

  /*
  // Get Stripe.js instance
  const stripe = await this.state.stripePromise;

  // Call your backend to create the Checkout Session
  const response = await fetch('/create-checkout-session', { method: 'POST' });

  const session = await response.json();

  // When the customer clicks on the button, redirect them to Checkout.
  const result = await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  }
*/}

completeOrder(){

  var url = "https://www.therealgrape.com/api/cart/complete-order"
  //var url = "http://127.0.0.1:8000/api/cart/complete-order"

  // GET token from state
  const token = this.props.auth.token;

  // set up the header for the get calls
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // // Provide the authorisation token
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // now we need to stringfy the field that we want to provide
  // Request body

  const body = JSON.stringify({'OrderId': this.state.orderData.OrderId});

  // now post the Data
  axios.post(url, body, config)
  .then((response) => {


    this.setState({
      orderComplete : true
    })

  })
  .then((data) => {
    window.location.replace("/")
  })

}

getOrderData(){


  /*
  this.setState({
    isLoadingOrderData: true,
  })*/

  var url = "https://www.therealgrape.com/api/cart/my-orders"
  //var url = "http://127.0.0.1:8000/api/cart/my-orders"

  // GET token from state
  const token = this.props.auth.token;

  // set up the header for the get calls
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // // Provide the authorisation token
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  axios.get(url, config)
    .then((response) => { this.setState({
    /*.then((response) => {console.log(response); this.setState({*/
      orderData: response.data,
      isLoadingOrderData: false,

      })

      console.log(response.data)


    })
    .catch((error)   => console.log(error));


}



  render() {

    const { isAuthenticated, user } = this.props.auth;
    var self = this

    return(
      (isAuthenticated  &&  !self.state.isLoadingOrderData )? [

        <div>
          <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
            <div class = "app-content-full container">
              <div class = "someSpacing">
              </div>
              <br/>
              <div class = "row">
                <div class = "col-sm-12 col-md-12 col-lg-8" > {/*Delivery Details and Payment Method */}
                <br class="hide-for-small-screens show-for-large-formats"/>
                  <div class = "cart-page cart-page-elevation">
                    <div class = "stack">
                      <div class = "cart-stack-element-title">
                        <div>
                          <div class = "cart-title-container" >
                            <hr class = "title__ruler" style={{marginRight:"3px"}}/>
                            <div class = "cart__element__strapline" style = {{textTransform: "uppercase"}} >Checkout</div>
                            <hr class = "title__ruler" style={{marginLeft:"3px"}}/>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/*start payment accordian*/}
                    <div class = "col-sm-12 col-md-12 col-lg-12" >
                    <div class = "attendee-form-elements " id = "attendee-form">
                        <div class = "cart-page-forms-titles"> 1. Attendee Details </div>
                        <div class = "row" >


                          <div class = "address-form-wrapper" >

                            <div class = "address-form-container">
                              <div class = "col-sm-12 col-md-12">
                                <p class = "cart-form-text-element"> * Required fields </p>
                              </div>

                              <div class = "col-sm-12 col-md-12">
                                <form action ="#" data-testid = "addressForm">
                                  <div class = "row main-form-container">
                                    <div class = "col-xs-12 col-sm-12 col-md-2">
                                      <div class = "address-form-elements-wrapper">
                                        <label class = "">Title</label>
                                      </div>

                                        <select onChange = {(e) => this.updateAttendeeForm(e)}
                                                          name = "title"
                                                          class = "form-field cart-form-styles"
                                                          id = 'addressForm-title'>
                                          <option value=""></option>
                                          {titleList.map((titleSelect) =>

                                            <option value = {titleSelect.optionValue}>{titleSelect.title} </option>

                                          )}
                                        </select>
                                    </div>

                                    <div class = "col-xs-12 col-sm-12 col-md-5">
                                      <div class = "address-form-elements-wrapper">
                                        <label class = "">First Name * </label>
                                      </div>
                                      <input  onChange = {(e) => this.updateAttendeeForm(e)}
                                              name = "firstName"
                                              class="form-field cart-form-styles "
                                              id = 'addressForm-firstName'
                                              type = "text"
                                              if = "firstName"
                                              placeholder = ""></input>
                                    </div>

                                    <div class = "col-xs-12 col-sm-12 col-md-5">
                                      <div class = "address-form-elements-wrapper">
                                        <label class = ""> Last Name * </label>
                                      </div>
                                      <input  onChange = {(e) => this.updateAttendeeForm(e)}
                                                  name = "lastName"
                                                  class="form-field cart-form-styles"
                                                  id = 'addressForm-lastName'
                                                  type = "text"
                                                  if = "lastName"
                                                  placeholder = ""></input>
                                    </div>
                                  </div>


                                  <div class = "address-form-elements-wrapper" style={{marginTop:"20px"}}>
                                   Phone number *
                                  </div>

                                  <div class = "row">
                                    <div class = "col-xs-4 col-sm-4 col-md-4">
                                      <div class = "address-form-elements-wrapper">
                                        <label class = ""> country code </label>
                                      </div>

                                      <select  onChange = {(e) => this.updateAttendeeForm(e)}
                                              name = "countryCode"
                                              class = "form-field cart-form-styles"
                                              id    = 'addressForm-countryCode' >
                                        <option value=""></option>
                                        {internationalCallingCodeList.map((callingCodeSelect) =>

                                          <option value = {callingCodeSelect.optionValue}>{callingCodeSelect.countryCode} </option>

                                        )}
                                      </select>

                                    </div>

                                    <div class = "col-xs-8 col-sm-8 col-md-8">
                                      <div class = "address-form-elements-wrapper">
                                        <label class = ""> Number </label>
                                      </div>
                                      <input onChange = {(e) => this.updateAttendeeForm(e)}
                                             name = "phone"
                                             class = "form-field cart-form-styles"
                                             type="tel"
                                             id    = 'addressForm-phone'
                                             placeholder=""></input>
                                    </div>

                                  </div>
                                  <p class = "cart-form-text-element"> We will use this number to send you notifications about your events and experiences </p>

                                  <div class = "row main-form-container">

                                    <div class = "col-xs-12 col-sm-12 col-md-12">
                                      <div class = "address-form-elements-wrapper">
                                        <label class = ""> Company Name </label>
                                      </div>
                                      <input onChange = {(e) => this.updateAttendeeForm(e)}
                                             name = "companyName"
                                             class="form-field cart-form-styles"
                                             type = "text"
                                             id    = 'addressForm-companyName'
                                             if = "companyName"
                                             placeholder = ""></input>
                                    </div>
                                  </div>
                                  <p class = "cart-form-text-element"> Please fill this field if you are purchasing tickets for a corporate event </p>

                                  <div class = "row main-form-container"> {/*Start Address Section*/}
                                      <div class = "col-xs-12 col-sm-12 col-md-12">
                                        <div class = "address-form-elements-wrapper">
                                          <label class = ""> Address line 1* </label>
                                        </div>
                                        <input  onChange = {(e) => this.updateAttendeeForm(e)}
                                                name = "address"
                                                class="form-field cart-form-styles"
                                                id    = 'addressForm-address'
                                                type = "text"
                                                if = "addressLine1"
                                                placeholder = ""></input>
                                      </div>

                                      <div class = "col-xs-12 col-sm-12 col-md-6">
                                        <div class = "address-form-elements-wrapper">
                                          <label class = ""> City * </label>
                                        </div>
                                        <input  onChange = {(e) => this.updateAttendeeForm(e)}
                                                name = "city"
                                                class="form-field cart-form-styles"
                                                id    = 'addressForm-city'
                                                type = "text"
                                                if = "city"
                                                placeholder = ""></input>
                                      </div>

                                      <div class = "col-xs-12 col-sm-12 col-md-6">
                                        <div class = "address-form-elements-wrapper">
                                          <label class = ""> Postcode * </label>
                                        </div>
                                        <input  onChange = {(e) => this.updateAttendeeForm(e)}
                                                name = "postCode"
                                                class="form-field cart-form-styles"
                                                id    = 'addressForm-postCode'
                                                type = "text"
                                                if = "postCode"
                                                placeholder = ""></input>
                                      </div>

                                      <div class = "col-xs-0 col-sm-0 col-md-3">
                                      </div>
                                      <div class = "col-xs-12 col-sm-12 col-md-6" style ={{paddingBottom:"20px"}}>
                                        <div class = "address-form-elements-wrapper" >
                                          <label class = ""> Country * </label>
                                        </div>
                                        {/*<input  name = "addressLine1" class="form-control form-control-sm form-field " type = "text" if = "addressLine1" placeholder = ""></input>*/}
                                          <select onChange = {(e) => this.updateAttendeeForm(e)}
                                                  name = "country"
                                                  id    = 'addressForm-country'
                                                  class = "form-field cart-form-styles" >
                                            <option value=""></option>
                                            {countryList.map((countrySelect) =>

                                              <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                                            )}
                                          </select>
                                      </div>
                                      <div class = "col-xs-0 col-sm-0 col-md-3">
                                      </div>



                                  </div> {/* End Address Section*/}

                                  <div class = "row main-form-container" > {/*Form Submit Section*/}
                                    <div class = "col-xs-0 col-sm-0 col-md-4">
                                    </div>
                                    <div class = "col-xs-12 col-sm-12 col-md-4" style ={{paddingBottom:"20px"}}>
                                      <button id = 'confirm-attendee-details-btn'
                                              onClick={(e) => this.handleConfirmDetails(e)}
                                              class = "button button-primary checkout-button hidden"
                                              style = {{margin:"4px 4px 4px 4px"}}>

                                        Confirm Details
                                        </button>
                                    </div>
                                    <div class = "col-xs-0 col-sm-0 col-md-4">
                                    </div>
                                  </div>

                                </form>
                              </div>


                            </div>
                          </div>
                        </div>

                      </div>
                      <div class = "form-spacing" id = "form-spacers">
                        <br class="hide-for-small-screens show-for-large-formats"/>
                        <hr style = {{width:"100%"}}></hr>
                        <br class="hide-for-small-screens show-for-large-formats"/>
                      </div>

                      <div class = "payment-form-elements hidden " id = "stripe-payment-form"> {/*start payment-form-elements*/}

                        <div class = "cart-page-forms-titles"> 2. Payment Details </div>
                          <div class = "row">
                            <div class = "payment-details-form-wrapper" >
                              <div class = "payment-details-form-container">


                                    {/*<Elements stripe = {stripePromiseA}>*/}
                                      <div class = "col-xs-12 col-sm-12 col-md-12">
                                        {/*<form action ="/charge" method = "post" id="credit_card-payment-form" data-testid = "credit_card-payment-form">*/}
                                        <form id="payment-form">
                                          <div class = "row " style = {{marginTop:"20px"}}>
                                          <div class = "col-xs-8 col-sm-9 col-md-9">
                                            Credit/Debit Card
                                          </div>

                                          <div class = "col-xs-4 col-sm-3 col-md-3">
                                              <img src = {CreditCardLogos} style = {{width:"100px", alignContent:"right", display:"flex"}}/>
                                          </div>

                                          <div class = "col-xs-12 col-sm-12 col-md-12">
                                            <div class = "address-form-elements-wrapper">
                                              <label class = ""> Name on card * </label>
                                            </div>
                                            <input type = "text" class="form-field cart-form-styles " placeholder ="NAME ON CARD"/>
                                          </div>

                                            <div class = "col-xs-12 col-sm-12 col-md-12">
                                              <div class = "address-form-elements-wrapper">
                                                <label class = ""> Card Number * </label>
                                                 {/*<Elements stripe={stripe}>{children}</Elements>*/}
                                              </div>
                                              {/*<input type = "text" class="form-control form-control-sm form-field "/>*/}
                                              <CardNumberElement
                                                  options={{
                                                    classes : {base: "form-control form-field "},
                                                    style: { width: '100%',

                                                            border: '1px solid black',
                                                      base: {
                                                        fontSize: '16px',
                                                        color: '#424770',
                                                        backgroundColor: 'white',
                                                        '::placeholder': {
                                                          color: '#aab7c4',
                                                        },
                                                      },
                                                      invalid: {
                                                        color: '#9e2146',
                                                      },
                                                    },
                                                  }}
                                                />
                                            </div>



                                            <div class = "col-xs-12 col-sm-12 col-md-12">
                                              <div class = "row">
                                                <div class = "col-xs-12 col-sm-12 col-md-12">
                                                  <div class = "address-form-elements-wrapper">
                                                      <label class = ""> Expiration Date and Security Code * </label>
                                                  </div>
                                                </div>

                                                <div class = "col-xs-6 col-sm-6 col-md-6">
                                                  {/*<input type = "text" class="form-control form-control-sm form-field "/>*/}
                                                  <CardExpiryElement
                                                    options={{
                                                      classes : {base: "form-control"},
                                                      style: { width: '100%',

                                                              border: '1px solid black',
                                                        base: {
                                                          fontSize: '16px',
                                                          color: '#424770',
                                                          backgroundColor: 'white',
                                                          '::placeholder': {
                                                            color: '#aab7c4',
                                                          },
                                                        },
                                                        invalid: {
                                                          color: '#9e2146',
                                                        },
                                                      },
                                                    }}
                                                  />
                                                </div>
                                                <div class = "col-xs-1 col-sm-1 col-md-1">
                                                  /
                                                </div>
                                                <div class = "col-xs-5 col-sm-5 col-md-5">
                                                  {/*<input type = "text" class="form-control form-control-sm form-field "/>*/}
                                                  <CardCvcElement
                                                    options={{
                                                      classes : {base: "form-control"},
                                                      style: { width: '100%',

                                                              border: '1px solid black',
                                                        base: {
                                                          fontSize: '16px',
                                                          color: '#424770',
                                                          backgroundColor: 'white',
                                                          '::placeholder': {
                                                            color: '#aab7c4',
                                                          },
                                                        },
                                                        invalid: {
                                                          color: '#9e2146',
                                                        },
                                                      },
                                                    }}
                                                  />

                                                </div>
                                              </div>
                                            </div>



                                            <div class = "form-check col-sm-12 col-md-12">
                                              <div class = "row">
                                                <div class = "col-xs-1 col-sm-1 col-md-1">
                                                  <input class = ""
                                                  type="checkbox"
                                                  name = "ageCertification"

                                                  id="ageTAndCs"

                                                  />


                                                </div>
                                                <div class = "col-xs-11 col-sm-11 col-md-11">
                                                <div class = "age_confirm__container">
                                                  <p class = "cart-form-text-element"> I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy * </p> </div>
                                                </div>
                                                </div>
                                            </div>

                                            <div class = "col-xs-12 col-sm-12 col-md-12">
                                              <button onClick={(e) => this.confirmAndPay(e)}  name = "confirmAndPay" id = "confirm-and-pay"class = "button button-primary checkout-button" style = {{margin:"4px 4px 4px 4px"}}>
                                                <div class="spinner hidden" id="spinner"></div>
                                                <span id="button-text">Confirm & Pay</span>

                                                </button>
                                            </div>


                                          </div>
                                        </form>
                                      </div>
                                    {/*</Elements>*/}

                                {/*<div class = "col-sm-12 col-md-12">
                                  <form action ="/charge" method = "post" id="payment-form" data-testid = "payment-form">
                                    <div class = "row">
                                      <label for= "card-element">
                                        Credit or debit card
                                      </label>
                                      <div id = "card-element">
                                        {/*a stripe element will be inserted here*/}
                                  {/*    </div>

                                      <div id="card-errors" role = "alert"></div>
                                      <button> Submit Payment</button>
                                    </div>

                                  </form>
                                </div>*/}

                              </div>
                            </div>
                          </div>
                      </div> {/*end payment-form-elements*/}
                    </div>
                  </div>


                </div> {/*End Delivery Details*/}

                <div class = "col-sm-12 col-md-12 col-lg-4" >  {/*Cart Summary*/}
                  <br class="hide-for-small-screens show-for-large-formats"/>
                  <div class = "sticky-wrapper">
                    <div class = "sticky-summary">
                      <div>
                        <div class = "cart-page cart-page-elevation">
                          <div class = "cart-summary-title-container cart-summary-title-container-maxWidthLg">
                            <div class = "cart-title-container">

                              <hr class = "title__ruler" style={{marginRight:"3px"}}/>
                              <div class = "cart__element__strapline" style = {{textTransform: "uppercase"}}> summary</div>
                              <hr class = "title__ruler" style={{marginLeft:"3px"}}/>
                            </div>
                            <div class = "cart-summary-element-container">
                              <div class = "row cart-summary-content">

                                <div class = "col-sm-12 col-md-12">
                                  <div class = "row " style = {{ margin:"2px 2px 2px 2px"}}>
                                    <div class = "col-sm-8 col-md-8">
                                      <div class = "" style = {{display:"flex", justifyContent: "Left", marginLeft:"2px", marginRight:"2px"}}>
                                        Order Details ({this.state.orderData.numberOfItemsInCart} items)
                                      </div>
                                    </div>


                                    <div class = "col-sm-4 col-md-4"> {/* Edit Order*/}
                                      <div class = "" style = {{display:"flex", justifyContent: "center", marginLeft:"2px", marginRight:"2px"}}>
                                        <a href="/Cart" style = {{textDecoration:"none"}}><i class="fas fa-edit" ></i></a>
                                      </div>
                                    </div> {/*End Edit Order*/}

                                    <div class = "col-sm-12 col-md-12">
                                      <div class = "" style = {{display:"flex", justifyContent: "Left", marginLeft:"2px", marginRight:"2px"}}>
                                        <hr style = {{width:"100%"}}/>
                                      </div>
                                    </div>

                                  </div>

                                  <div class = "col-sm-12 col-md-12" style = {{margin:"2px 2px 20px 2px"}}> {/*events summary cards*/}
                                    <ul class = "my-cart-cards" >
                                      {self.state.orderData.cart.map((product, index) =>(
                                        <li key = {`product-${index}`}>
                                          <div class = "cart-entry" style = {{marginTop: "10px", boxShadow:"none"}} > {/*start cart entry*/}
                                            <div class = "row">
                                              <div class = "col-sm-4 col-md-3"> {/*Event Image*/}
                                                <div class = "image-map-wrapper">
                                                  <img alt="" class="cart_Img_Centered"  src = {product.mainExperienceImage}/>
                                                </div>
                                              </div> {/*End Event Image*/}

                                              <div class = "col-sm-8 col-md-9"> {/*main card elements*/}
                                                <div class = "row">
                                                  <div class = "col-sm-11 col-md-11">
                                                    <div class = "cart_Event-title" style = {{fontSize: "14px"}}> {product.orderType === "Events" ? product.event__eventTitle : product.experiences__experienceTitle}</div>
                                                  </div>
                                                </div>

                                                <div class = "row" style = {{fontSize: "12px"}}>
                                                  <div class = "col-sm-12">
                                                    <div class = "row">
                                                      <div class = "col-sm-12 col-md-4" style = {{alignItems:"center"}}>
                                                          {/*increment decrement button*/}
                                                        <div class = "quantity__controls__container" >
                                                          QTY:  {product.quantity}
                                                        </div>
                                                      </div>
                                                      <div class = "col-sm-0 col-md-4">
                                                        <div style = {{textAlign:"center", marginBottom: "5px"}}>
                                                          <span style={{ marginRight: '5px', fontWeight:'500', color: '#000' }}> {getSymbolFromCurrency(product.currency)}{product.price.toFixed(2) }</span>

                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>{/* end main card elements*/}

                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div> {/*events summary card*/}

                                  <div class = "col-sm-12 col-md-12">
                                    <div class = "row order-summary-item-container" style = {{ margin:"2px 2px 20px 2px"}}>
                                      <div class = "col-xs-9 col-sm-9 col-md-9">
                                        Subtotal
                                      </div>
                                      <div class = "col-xs-3 col-sm-3 col-md-3">
                                        {self.state.orderData.subtotal.toFixed(2)}
                                      </div>
                                      <div class = "col-xs-9 col-sm-9 col-md-9">
                                        Booking Fee
                                      </div>
                                      <div class = "col-xs-3 col-sm-3 col-md-3">
                                        {self.state.orderData.bookingFees.toFixed(2)}
                                      </div>

                                      <div class = "col-xs-9 col-sm-9 col-md-9">
                                        VAT
                                      </div>
                                      <div class = "col-xs-3 col-sm-3 col-md-3">
                                        {self.state.orderData.VAT.toFixed(2)}
                                      </div>

                                      <div class = "col-sm-12 col-md-12">
                                        <hr style = {{width:"100%"}}/>
                                      </div>

                                      <div class = "col-xs-9 col-sm-9 col-md-9">
                                        <strong>Total </strong>
                                      </div>
                                      <div class = "col-xs-3 col-sm-3 col-md-3">
                                        <strong>{self.state.orderData.cartTotal.toFixed(2)} </strong>

                                      </div>
                                    </div>
                                  </div>



                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  {/* End Cart Summary*/}

              </div>

            </div>
          </div>
        </div>] :[

          <div>
            <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
              <div class = "app-content-full container">
                <div class = "someSpacing">
                </div>
                <br/>
                <h1>Please log in to check out</h1>
              </div>
            </div>
          </div>
        ]
    )

  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});


const  connectedCartClass = connect(mapStateToProps)(CartClass);
export {connectedCartClass as Cart }

const  connectedCheckoutClass = connect(mapStateToProps)(CheckoutClass);
export {connectedCheckoutClass as Checkout }
