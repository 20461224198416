import React from 'react'
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types"

// THis checks a user is logged in before giving access to the page

const PrivateRouteUnconnected = ({
  component: Component,
  auth,
  ...rest
}) => (
  <Route
    {...rest}
    render = {props => {
        if(auth.isLoading) {
          return <h2> Loading... </h2>
        } else if(!auth.isAuthenticated)  {
              // if we are not logged in then we want to return the re-direct
              return <Redirect to ="/login"/>

        } else {
              // if we are logged in then render
                console.log("loading requested page ....")
                // return <Component {...props} />
                //return <Redirect to ="/Journal"/>
                //return <h2> Already Authenticated</h2>
                return <Component {...props} />;
        }

        //console.log("Authenitcation state: ", auth.isAuthenticated)

    }}
  />
)

const mapStateToProps = state => ({
  auth: state.auth
})


/* function mapStateToProps(state) {
  return{auth: state.auth}
}*/

//export default connect(mapStateToProps)(PrivateRoute);





const  PrivateRoute =  connect(mapStateToProps)(PrivateRouteUnconnected);
export default PrivateRoute
//export {connectedPrivateRoute as PrivateRoute}
/*export default function connect(mapStateToProps)(PrivateRoute);*/

/*const PrvtRouteUnconnected = ({
  component: Component,
  auth,
  ...rest }) =>
  (
    <Route {...rest} component ={(props) => (
      !auth.isAuthenticated ? (<Redirect to ="login"/>)
      :(
          auth.isLoading ? (<h2> Loading Page </h2>) : (<Component {...props}/>)


      )

    )} />
  )*/



  const PrvtRouteUnconnected = ({
    component: Component,
    auth,
    ...rest }) =>
    (
      <Route {...rest} component ={(props) => (
        auth.isAuthenticated ? (<Component {...props}/>) : (<h2> Not Authenticated</h2>)

      )} />
    )

export const PrvtRoute = connect(mapStateToProps)(PrvtRouteUnconnected)
