import React from 'react';
import '../Styles/Jumbotron.css'
import JumboImg from '../img/jumbotron/tuscany_1600x1062.jpg'

export function HomePageHero() {return(


    <div class = "homepage__hero " style = {{background: 'url(' + JumboImg + ') no-repeat center center fixed'}} >{/*style = {{background: 'url(' + JumboImg + ')'}}*/}


      <div class = "mouse">
        <span></span>
      </div>

      <div class = "jumborton_controls">
        <a href = "#main">
          <i class="fa fa-angle-double-down jumbotron__scrolldown"  aria-hidden="true"></i>
        </a>
      </div>

    </div>

  )
}



export function Jumbotron() {return(
    <fragment>



    <div  class = "container">
      {/*1600x1050 https://source.unsplash.com/1600x1200/?wine*/}
      {/*<div class = "jumbotron jumbotron-fluid container-fluid "  style={{background: "url(/img/jumbotron/wine-toast-1600x1067.jpg)"}}>*/}
      <div class = "jumbotron jumbotron-fluid container-fluid " >

        <div class = "jumborton_controls">
          <a href = "#main">
            <i class="fa fa-angle-double-down jumbotron__scrolldown"  aria-hidden="true"></i>
          </a>
        </div>

      </div>
    </div>
    </fragment>
  )
}
