import React from 'react';
import '../Styles/Footer.css'
import '../Styles/SocialMediaLinks.css'
import {SignUpForm, SignUpFormFooter, SignUpFormInterfaced} from "../Forms/Forms"
import {useConfirmSignUpModal} from '../Hooks/useConfirmSignUpModal.js'

// import images used on the site
import FinancialProtection from "../img/logo/TTA_Financial_Protection_150x82.png"

import {connect} from "react-redux";
import PropTypes from "prop-types";


export function Footer() {

  const confirmSignUpModal = useConfirmSignUpModal();

  /*const PropTypes = {
    auth: PropTypes.object.isRequired
  }*/

  //const {isAuthenticated, user} = props.auth;

  return(
  <fragment>
    <div class = "footer">
    <hr class = "align-top"></hr>

      <div class = "container">
        <div class = "row">
          <div class = "col-12" >
            <div class = "footer-subscription ">
              <h5> Stay in touch </h5>
              <p> Be the first to hear about our exclusive news, offers and updates </p>
              <div class = "contact-us">
                <strong> </strong>
                <a>wine@therealgrape.com</a>
              </div>


              <div class = "container sign-up-form">

                {/*<SignUpForm/>*/}
                <SignUpFormInterfaced/>
              </div>

            </div>


          </div>
        </div>


        <div class = "col-sm-12 col-md-12 col-lg-12 socials">

          <h2 class = "social-media-header">
            Follow Us
          </h2>

          <div class = "social-media-links">
            <ul class = "social-media-list">
              <li class = "social-media_item">

                  <a class = "social-media_link" href="https://www.instagram.com/therealgrape">
                    <i class="fab fa-instagram large" />
                  </a>


              </li>
              <li class = "social-media_item" >

                  <a class = "social-media_link" href="https://soundcloud.com/user-57306623" >
                    <i class="fab fa-soundcloud" ></i>
                  </a>


              </li>
              <li class = "social-media_item">

                  <a class = "social-media_link" href="https://www.facebook.com/TheRealGrapeTravel/">
                    <i class="fab fa-facebook-f large" ></i>
                  </a>


              </li>
            </ul>
          </div>
        </div>
      </div>

    <div class = "container footer-notes">
      <div class = "row">
        <div class = "footnote__copyright col-sm-12 col-md-4 col-lg-4">
          <p>Copyright 2020. All rights reserved. I.Q. Pro Limited</p>
        </div>
        <div class = "footnote__financial_protection col-sm-12 col-md-4 col-lg-4">
          <a href = "https://www.thetravelnetworkgroup.co.uk/travel-trust-association">
            <img class = "tta__logo" src = {FinancialProtection} alt = "TTA Financial Protection Guaranteed"/>
          </a>

        </div>
        <div class = "footnote__tsandcs col-sm-12 col-md-4 col-lg-4 ">
          <a href="/PrivacyPolicy">Terms & Conditions</a>
        </div>
      </div>
    </div>


    </div>
  </fragment>
)}



const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(Footer);
