/*export const USER_LOADING = "USER_LOADING";
export const USER_LOADED  = "USER_LOADED";
export const AUTH_ERROR   = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";*/

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const CART_LOADED = 'CART_LOADED';

/* management of wine lists and wine details*/
export const WINE_LIST_LOADED               = 'WINE_LIST_LOADED';
export const WINE_LOADED_BUSINESS           = 'WINE_LOADED'
export const WINE_LOADED_BUSINESS_SUCCESS   = 'WINE_LOADED'
export const WINE_REFDATA                   = 'WINE_REFDATA'
export const WINE_GRAPE_COLOUR_TYPE_REFDATA = 'WINE_GRAPE_COLOUR_TYPE_REFDATA'
export const FOOD_PAIRINGS_REFDATA = 'FOOD_PAIRINGS_REFDATA'

/*management of experiences lists*/
export const EXPERIENCES_LIST_LOADED = 'EXPERIENCES_LIST_LOADED';
export const EVENTS_LIST_LOADED      = 'EVENTS_LIST_LOADED';

/*members access to their bought experiences and events*/
export const MEMBERS_EXPERIENCES_ANDEVENTS_LIST_LOADED = 'MEMBERS_EXPERIENCES_ANDEVENTS_LIST_LOADED';

/*retrieval of wine reviews and member wines from cellars*/
export const WINE_REVIEWS_LOADED      = 'WINE_REVIEWS_LOADED';
export const WINES_FROM_CELLAR_LOADED = 'WINES_FROM_CELLAR_LOADED';

/*retrieval of event reviews*/
export const EVENT_REVIEW_LOADED = 'EVENT_REVIEW_LOADED'
