
import React, {useState, useEffect, useLayoutEffect} from 'react';
import ReactGA from 'react-ga'
import {createBrowserHistory} from 'history'


import { Component, Fragment } from 'react';
import logo from './logo.svg';
import {Footer} from './Pages/Footer.js'
import {Navigation} from './Pages/Navigation.js'
import {Jumbotron, HomePageHero} from './Components/Jumbotron.js'


import {Login, LoginRedux, LoginClass, Login2, Login3} from "./Pages/Login.js"
import {PrivateRoute, PrvtRoute} from "./Components/Common/PrivateRoute.js"


import {RequestInfo, RequestInfoForm, RequestInfoFormInterfaced} from "./Forms/Forms"
import {JournalPostCorrect, journalPosts} from "./Journal/Journal.js"
import {NewsFeed} from "./Pages/NewsFeed.js"
import {Register} from "./Pages/Register.js"
import {Experiences, ExperiencesShopPage} from "./Pages/Experiences.js"
import {Events, EventShopPage, EventPurchasedPage} from "./Pages/Events.js"
import {Cart, Checkout} from "./Pages/Cart.js"
import {MyDashboard} from "./Pages/Mydashboard.js"
import {PrivacyPolicy} from "./Pages/PrivacyPolicy"
import {WineProfiles, WineProfile} from "./Pages/WineLists.js"

//
import axios from 'axios'
import PropTypes from 'prop-types';

// some custom Hooks
import {useJournalPostToDisplay} from "./Hooks/useJournalPostToDisplay"


import { library, icon } from '@fortawesome/fontawesome-svg-core'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

import './App.css';
import './Styles/Testimonials.css'
import './Styles/Navigation.css'
import './Styles/Journal.css'

// Bring in our Redux Store and the Provider from react-redux
import {Provider} from 'react-redux';
import store from './Store.js'
import {connect} from "react-redux";

// bring in the actions to check whether the user is authorised
import {loadUser, logout, getCart} from './Actions/Auth.js'

// import images used on the site

import WineTaste from './img/jumbotron/wine_tasting_1600x1600.jpg'
import LadyInVineyard from './img/jumbotron/young-lady-in-vineyards_1600x1600.jpg'

// get the cookies
import {
  storageType,
  consentPropertyName,
  shouldShowPopup,
  saveToStorage,
  cookieStorage,
  CookiePopUp

} from "./Forms/CookieConsent.js"

// tutorial on creating blogs. start at 1hr:14mins
// https://www.youtube.com/watch?v=JE8w1gvn0Y4&t=2985s

// building a carousel component in React
// https://www.youtube.com/watch?v=eBKcGAhkZUI
// https://www.youtube.com/watch?v=S05EWIHAVcc&list=LL3kwTAD6bJ8FlRBrOubli7A&index=10
// https://www.youtube.com/watch?v=hyr2zasS27w


// use the router for navigating to other webpages
import {
  withRouter, // used in the app export default - expore using with Journal
  BrowserRouter as Router,

  HashRouter, // try using the HashRouter
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from 'react-router-dom'



// https://interweave.dev/ or npm-react-parser
import { Markup } from 'interweave';


// import the react player and embed the player on the journal page
import ReactPlayer from "react-player"

//import google mapStateToProps
import { Loader } from "@googlemaps/js-api-loader"

// stripe
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
// stripe promose
const stripePromiseA = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);





library.add(faCamera)

const camera = icon({ prefix: 'fas', iconName: 'camera' })

// initialise react-ga module
const trackingId = process.env.REACT_APP_REACTGA_TRACKING_ID
ReactGA.initialize(trackingId)

// initialise useHistory
const history = createBrowserHistory();

/*history.listen(location => {
  //ReactGA.pageview(location.pathname)
  ReactGA.pageview(window.location.pathname + window.location.search);

})*/

/*
window.onload = () => {
  if (shouldShowPopup ()) {
    const consent = true; //= confirm('Agree to the terms and conditions of the site?');
    if (consent) {
      saveToStorage();
    }
  }
}*/

/* Strupe Ready checkout form*/
const InjectedCheckoutForm = () => {
  return (
    <Elements stripe={stripePromiseA}>
      <ElementsConsumer >
        {({elements, stripe}) => (
          <Checkout elements={elements} stripe={stripe} />
        )}
      </ElementsConsumer>
    </Elements>
  );
};


class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
    store.dispatch(getCart());

    ReactGA.pageview(window.location.pathname + window.location.search);
    saveToStorage();
  }
  render(){
    return (

      <Provider store={store}>
        <Router history={history}>
          <Fragment>
          <Navigation/>



              <Route exact path='/' component = {HomePage} />
              <Route  exact path = '/Journal' component = {Journal}/>
              <Switch><Route path = '/Journal/:slug' component = {JournalPost} /> {/* here we will implement the journal routing*/}</Switch>
              <Route exact path = '/PrivacyPolicy' component = {PrivacyPolicy}/>
              <Route exact path = '/Register' component = {Register}/>
              <Route exact path = '/Login' component = {LoginClass}/>
              <Route exact path = '/Cart' component = {Cart} />
              {/*<Route exact path = '/Checkout' component = {Checkout} />*/}

              <Route exact path = '/Checkout' component = {InjectedCheckoutForm} />



              <Route exact path = '/Events' component = {Events} />
              <Route exact path = '/Events/:slug' component = {EventShopPage} /> {/* Assifned a private route to this page - but we need to do this for experiences*/}
              {/*<Switch> <PrivateRoute exact path = '/Events' component = {Events} /> </Switch>*/}

              <Route exact path = '/Mydashboard/MyEvents/:slug' component = {EventPurchasedPage} /> {/* Assifned a private route to this page - but we need to do this for experiences*/}


              {/*<PrvtRoute  path = '/CreateEvents' component = {CreateEvents} />*/}
              <Route  exact path = '/Experiences' component = {Experiences} />
              <Route  exact path = '/Experiences/:slug' component = {ExperiencesShopPage} />

              <Switch><PrvtRoute path  = '/Mydashboard' component = {MyDashboard} /></Switch>
              {/*<Route exact path  = "/wine-profile"  component = {WineProfiles} />
              <Route exact path  = "/wine-profile/:slug"  component = {WineProfile} />*/}
              <Route exact path  = "/wine-profile"  component = {WineProfiles} />
              <Route exact path  = "/wine-profile/:slug"  component = {WineProfile} />


            {/*<CookiePopUp/>*/}
          <Footer/>
          </Fragment>
        </Router>
      </Provider>
    );
  }

}




/*

function App() {

  useEffect(()=>{
    store.dispatch(loadUser());
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Navigation/>
          <Route exact path='/' component = {HomePage} />
          <Route exact path = '/Journal' component = {Journal}/>
        /*<Switch><Route path = '/Journal/:slug' component = {JournalPost} /> </Switch>
          <Route exact path = '/Register' component = {Register}/>
          <Route exact path = '/Login' component = {Login2}/>
          <PrivateRoute exact path = '/Events' component = {Events}/>
        <Footer/>
      </Router>
    </Provider>
  );
}
*/



class CreateEventsUnconnected extends Component {


  static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

  render()
  {

    return(
      <fragment>
        <div>
          <h3> Here is where we create events </h3>

          <button type="submit" className="btn btn-primary" onClick = {this.props.logout} >
            Logout
          </button>

        </div>
      </fragment>
    )
  }

}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const CreateEvents = connect(mapStateToProps, { logout })(CreateEventsUnconnected);

function HomePage() {
  return(

    <div>
    {/*<Jumbotron/>*/}






    <div class = "all-page">
    <HomePageHero/>


    <div class="app-page-content" itemprop = "mainContentOfPage" role="main">


      <div class = "app-content-full container">
        <div class = "row">
          <div class = "app-content col-md-12">



            <div section id = "main">
              <div class = "main-page-content">

                <div class="strapline">
                  The Real Grape
                </div>
                <h4 class ="intro-copy">
                Go behind the scenes of our maisons, finesse your palate or embark on an epic adventure with our exclusive experiences.
                </h4>




              </div>

              <div class = "card class = infoCard">
                <div class = "card-body">
                  <div class = "row main-page-intro">

                    <div class = "col-sm-12 col-lg-6 align-self-center" >

                      <p>We are an independent provider of tailor-made wine holidays and experiences. Expertly crafted experiences and exceptional personal service is at the core of what we do.  We invite you to prioritise making memories. </p>
                      <p>Our mission is to take you on immersive wine tour holidays, visiting established and boutique wine producers in Europe. Our tours and experiences are for couples, small groups and corporate retreats. From our small group Champagne experiences, to tailor-made itineraries in Porto or mouth-watering gastronomic experiences in the Rhône, we take time in selecting and crafting the best elements for your perfect holiday.</p>
                      <p>Please get in touch. We are excited to work with you on your next journey.</p>
                      <a href = "#services">
                        <button type="button" class = "btn btn-outline-primary submit-button-style learn_more-button-style" > Learn More </button>
                      </a>
                    </div>
                    <div class = "col-sm-12 col-lg-6 align-self-center" >
                      <div class = "row" >
                        <div class = "col-sm-12" >
                          <div class = "container">
                            <h2 class = "text-center">Start your next experience</h2>
                          </div>
                          <div class = "container  ">
                            <p class = "text-center">Please submit your details.  One of our experts will be in touch to help you plan your next experience.</p>
                          </div>
                        </div>
                        <div class = "col-sm-12" >
                          {/*<RequestInfo/>*/}
                          <RequestInfoFormInterfaced/>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div section id = "services">
                <div class = "container services main-page-content">
                  <h2 class = "text-center body-copy">Services</h2>
                </div>


                <div class = "row">
                  <div class = "col-sm-12 col-lg-6 " >

                  <div class = "col-sm-12 col-lg-12 align-items-center" >
                      <div class = "media">
                        <img class ="align-self-end mt-3 mb-3 img-centered" src = {LadyInVineyard} alt = "Young lady in vineyards"/>
                      </div>
                  </div>
                  <div class = "col-sm-12 col-lg-12" >
                      <div class="offering-content">
                        <div class = "offering-title">
                          Wine and Travel
                        </div>
                        <div class = "offering-copy">
                          Our team leverages local knowledge, partnership networks and advanced analytics to unearth amazing
                          experiences within the vineyard. Thanks to our established relationships and extensive research we
                          curate tailor made experiences that you will treasure. We make it our business to know the latest
                          culinary hangouts, buzzy new wine bars and where to find that special experience. All we ask is that
                          you get ready to capture that special moment.
                        </div>
                      </div>
                  </div>

                  </div>
                  <div class = "col-sm-12 col-lg-6" >
                    <div class = "col-sm-12 col-lg-12" >

                      <div class = "media">
                        <img class ="align-self-end  mt-3 mb-3 img-centered" src = {WineTaste}  alt = "City of London Wine Tasting Event"/>

                      </div>

                    </div>
                    <div class = "col-sm-12 col-lg-12">
                      <div class="offering-content">
                        <div class = "offering-title">
                          Events & Experiences
                        </div>
                        <div class = "offering-copy">
                          Our experts seek out the latest trends in food and wine curating events in London and the United Kingdom.
                           Thanks to our on-the-pulse approach we discover that special red wine or extravagant Champagne. You are
                           invited to join our private wine tastings, food pairings or networking events. We bring people together
                           in a vibrant atmosphere with great food, excellent wines and the amazing sounds of our resident DJ.
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>


              <div section id = "testimonials">
                <div class = "container services main-page-content">
                  <h2 class = "text-center body-copy">TESTIMONIALS</h2>
                </div>
                <div class = "container testimonial">
                   <div id = "testimonial" class = "testimonial">
                      <div class = "wrap">
                       <span id = "right-arrow" class = "arrow right fa fa-chevron-right"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                        <span id = "left-arrow" class = "arrow left fa fa-chevron-left"> <i class="fa fa-chevron-left" aria-hidden="true"></i></span>

                        <ul id = "testimonial-dots" class = "dots">
                          <li class = "dot active"></li>
                          <li class = "dot"></li>
                          <li class = "dot"></li>
                          <li class = "dot"></li>
                        </ul>

                        <div id = "testimonial-content" class = "testim-content">
                          <div class = "active">
                            <div class = "testimonial__text">
                              <p> It was a lovely day! Everything ran smoothly</p>
                            </div>
                            <div class = "testimonial__experience_text">
                              <p> Day-tirp in Champagne</p>
                            </div>
                            <div class = "testimonial__author">
                              <p> Charity worker</p>
                            </div>
                          </div>



                            <div>
                              <div class = "testimonial__text">
                                <p> Wow! What a trip</p>
                              </div>
                              <div class = "testimonial__experience_text">
                                <p> Rhone Wine & Food Experience</p>
                              </div>
                              <div class = "testimonial__author">
                                <p> Investment Banking Executive</p>
                              </div>
                            </div>


                            <div>
                              <div class = "testimonial__text">
                                <p> Awesome wine tasting</p>
                              </div>
                              <div class = "testimonial__experience_text">
                                <p> London Champagne Tasting </p>
                              </div>
                              <div class = "testimonial__author">
                                <p> MBA Student</p>
                              </div>
                            </div>

                            <div>
                              <div class = "testimonial__text">
                                <p> I learned so much about sustainability in Champagne</p>
                              </div>
                              <div class = "testimonial__experience_text">
                                <p> Wine Tasting Event </p>
                              </div>
                              <div class = "testimonial__author">
                                <p> PR Executive</p>
                              </div>
                            </div>



                        </div>


                      </div>
                   </div>
                </div>
            </div>

            </div>


          </div>
        </div>
      </div>



    </div>



    </div>
    </div>
  )
}

/*
function Experiences() {
  return(
    <div>
    </div>
  )
}
*/


function formatName(user) {
  return user.firstName + ' ' + user.lastName;
}

const user = {
  firstName: 'Harper',
  lastName: 'Smith'
};

function Welcome(props){
  return <h5> Hello, {props.name} </h5>
}

class WallClock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: new Date()};
  }

  componentDidMount(){
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount(){
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  render(){
    return(
      <div>
        {this.state.date.toLocaleTimeString()}
      </div>
    );
  }
}

const numbers = [2, 2, 3, 4, 5];
const doubled = numbers.map((number) => number * 2);
const listItems = doubled.map((number) => <li>{number} </li>)

// this is like what we will do with the journa
function NumbersToList(props) {
  const numbers = props.numbers;
  const listItems = numbers.map((number) =>
  <li key = {number.toString()}>
    {number}
  </li>);

  return(
    <ul>{listItems}</ul>
  );

}

function Example(){
  // Declare a new state variable which we'll call "count"
  const [count, setCount] = useState(0);

  useEffect(()=>{
    document.title = "You have clicked ${count} times";
  }, [])

  return(
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>Click</button>
    </div>
  );
}


function GetNews(){

  const NewsAPI = require('newsapi');
  const newsapi = new NewsAPI('15a0d1d1490944e79ca5c16d452506cd');




  return(<div>
    hello zimmi



    </div>)
}



function Journal(props) {

  const journalPostsToOpen =  useJournalPostToDisplay();

  let   history         = useHistory();

  useEffect(() => {

/*    if (history.action === 'POP')
    {

    } else {

      console.log("Checking that there has been a change of state for history")
      console.log(props)
      /*window.location.reload();*/

    /*}*/




  });

  useLayoutEffect(() => {

    /*window.location.reload();*/

  });

  return(



    <div class = "all-page">


      <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
        <div class = "app-content-full container">
          <div id = "journal">

            <div class = "someSpacing">
            </div>
            <br/>

            <div class = "main-page-content">
              <div class="journal__page__strapline">
                Journal
              </div>
              <div class ="journal__page__intro-copy">
                Our team brings to you these globe-trotting stories of wine and food coupled with opinions and trend-forecasting pieces designed to keep you apace with the fast moving world of all things wine, food and travel.
              </div>
              <div class = "journal__page__intro-copy-paragraph">
                We invite you to read our journal that brings to life experiences from sumptuous gastronomy in the  Côte-Rôtie to the majestic vineyards of Champagne. Go behind the scenes of the maisons, get inspired and embark on an epic adventure!
              </div>

            </div>

            <div class = "journalMenu-slider__wrapper">
              <i class="fa fa-chevron-left slider__prev  " aria-hidden="true"></i>
              <i class="fa fa-chevron-right slider__next" aria-hidden="true"></i>
              <div class = "journalMenu-slider">



                {journalPosts.map((post) =>
                    <div key={post.id} class = "journal__post"  >

                    <div class = "row">
                      <div class = "col-sm-12 col-md-12 col-lg-12">
                        <Link style={{ textDecoration: 'none'}} to ={"/Journal/" + post.slug} key={post.id} onClick={() =>  (journalPostsToOpen.setJournalPostToDisplay(post) ) } >
                          <div class = "journal-media">

                              {/*console.log("Trying to find a route to: /Journal/" + post.slug)*/}
                              {/*Somehow I need to dynamically import image*/}
                              {/*console.log("Process env: ", process.env['PUBLIC_URL'])*/}


                              {/*<img class = "  journal-image" src ={require(post.image)} alt={post.title}/> */ }
                              {/*<img class = "  journal-image" src ={process.env.PUBLIC_URL + post.image} alt={post.title}/ >*/}

                              <img class = "  journal-image" src={require(`.${post.image}`)}alt={post.title}/ >
                              {/*<img class = "  journal-image" src ={process.env.PUBLIC_URL +'img/jumbotron/wine_tasting_1600x1600.jpg'} alt={post.title}/ >*/}
                              {/*<img class = "  journal-image" src ={process.env['PUBLIC_URL'] + '/img/jumbotron/wine_tasting_1600x1600.jpg'} alt={post.title}/ >*/}


                          </div>
                        </Link>
                      </div>

                        <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "journal__category">{post.category}</div>
                        </div>

                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <Link style={{ textDecoration: 'none'}} to ={"/Journal/" + post.slug} key={post.id} onClick={() =>  (journalPostsToOpen.setJournalPostToDisplay(post) ) } >
                              <div class = "journal-title">
                                {post.title}
                              </div>
                            </Link>
                          </div>

                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "journal__copy__strapline">{post.strapLine}</div>
                          </div>
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "journal__card__divider"/>
                          </div>
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "journal__copy__date">{dateFormatter(post.date)}</div>
                          </div>
                        </div>
                    </div>

                )}

              </div>
            </div>






          </div>


          {/*<NewsFeed/>*/}

        </div>
      </div>

    </div>
  )
}

export function JournalPost(props) {

  // I am extracting the slug both from the props and the hooks
  // provided by the router

  const [post, setPost] = useState([]);
  let {slug}            = useParams();
  let   history         = useHistory();
  const slugId          = props.match.params.slug;
  const renderPost      = false
  // we will use effects to handle the rendering of the blogs
  // useEffect is called after every rendering
  function updatePost(toPost)
  {
    setPost(toPost);
  }

  function returnToJournal(){
    history.push("/Journal");
    //history.goBack();
    window.location.reload();
  }

  function RenderTextWithLineBreaks(postData) {

    // https://forum.freecodecamp.org/t/newline-in-react-string-solved/68484
    const text2 = "hello world \n this is a\n test text";
    const text  = postData.content;

    // console.log("This is the postData contnent type " + typeof postData.content)

    // forced a line break at the end of every content to ensure it always renders
    postData.content = postData.content + "\n"



    return(
        // the render function will make sure that there is content before renders
        postData.content ?

        postData.content.split("\n").map(
        (parag) =>
        <div class = "journal__content__paragraph">
          {console.log("Paragraph content", typeof(parag))}
          {/*parag*/}
          <Markup content = {parag}/>

        </div>
      ) : "loading ...."
    )

    /*
    return( text ?
        text2.split("\n").map(
        (str) => <p> {str} </p>
      ) : "loading ...."
    )
    */
    /*
    const renderedText = text2.split("\n").map(
      (str) => <p> {str} </p>

    );

    return renderedText;
    */
  }

  useEffect(() => {
    // After every render we make sure we find the correct blog
    // that we want to render
    // use effect will render whenever the blog post or slug is updated

    const postToRender = journalPosts.find(post=>post.slug == slug);
    const postToRender1  = journalPosts.find(post=>post.slug == slugId);

    //setPost(postToRender1);
    setPost(postToRender);
    //console.log(postToRender);

    // history is not required here
  }, [post, slug, history]);

  /*if (!post.image === 'undefined' ){
    renderPost  = true
  }
    console.log("Can I render the blog? " , renderPost, " image ",  post.image, "img typeof", typeof post.image)*/


  if (!post) return <> <div> <h1>Loading page ....</h1></div></>
  //if (!post.image === 'undefined' ) return <><div> <h1>Loading Page ....</h1></div></>




  return (
    <div class = "all-page">
      <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
        <div class = "journal__post-content-full container">
          <div class = "row">
            <div class = "journal__post-content col-md-12">


              <div class = "main-journal-content">

                  <h1 class = "breadcrumbs">
                    {post.category}
                  </h1>

                  <div class = "intro" aria-hidden="true">

                  {/* try various font awwesome icons like fa fa-chevron-left fas fa-backward fas fa-angle-double-left */}
                    <div class = "goBack">
                      <i class="fa fa-backward journal__goBack" onClick={returnToJournal} aria-hidden="true"></i>
                    </div>

                    <h1 class = "journal__article__intro-copy" >
                      {post.title}
                    </h1>
                  </div>

                  <div class = "journal__article__strapline" >
                    {post.strapLine}
                  </div>

                  <div>
                  <div class = "journal-article-hero">
                      <div class = "hero-overlay shadow">
                        <p>Listen to our  playlists, carefully curated to the vibes of {post.category}.</p>
                      </div>
                      {/*<img src={require(`.${post.image}`)} class = "journal-article-hero__image" alt = {post.id}/ >*/}
                      {/*<img src = {post.image } class = "journal-article-hero__image"/ >*/}
                      {/*post.image ? <img src={require(`.${post.image}`)} class = "journal-article-hero__image" alt = {post.id}/ >: <h1>Image</h1>*/}
                      {post.image ? <img src={require(`.${post.image}`)} class = "journal-article-hero__image" alt = {post.id}/ >: <img alt = {post.id}/>}

                      {/*<img class = "journal-article-hero__image" src={require(`.${post.image}`)}alt={post.title}/ >*/}
                      {/*console.log("Trying to find image: ", require(`.${post.image}`))*/}
                      {/*console.log("Trying to find image: ", `.${post.image}`)*/}

                    </div>
                  </div>

                  {RenderTextWithLineBreaks(post)}



                  <div class = "row in-the-mix__soundcloud">
                    <div class = "col-sm-12 col-md-6 align-self-center">
                      <h1> Real Beats</h1>

                      {post.playlistBlurb}
                    </div>
                    <div class = "col-sm-12 col-md-6 align-self-center">
                      <div class = "player-wrapper">
                        <ReactPlayer class = "reactplayer__soundcloud"
                          url={post.playlist}
                        />
                      </div>
                    </div>
                  </div>





                  {/*console.log(history)*/}
                  {/*console.log(props)*/}
                  {/*console.log(post)*/}


              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
  // now we render the post

  /*
  <button type = "button" onClick={() => props.history.goBack()}>
  if (!post) return <><div> <h1>Loading Page ....</h1></div></>
  <button type = "button" onClick={() => props.history.goBack()}>X</button>
  return <>
    <div>
      {post.title}
    </div>
    <div>
     {post.date}
    </div>
    <div>
    {post.content}
    </div>
  </*/
}



//  the below is a function for formatting the dates
export function dateFormatter(date){
  const year     = date.getFullYear()
  const day      = date.getDate()
  const monthInx = date.getMonth()

  const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

  const formattedDate = day.toString() + " "+ months[monthInx] +" "+ year.toString()



  return formattedDate;
  /*19 November 2020
  return date.toLocaleDateString('en-GB');
  */
}

// connected with router so whenever URL changes the props change so the component re-renders
//export default withRouter(App);
export default App;
