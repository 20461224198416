import {useState} from "react";

export function useConfirmSignUpModal(){

  const [openConfirmSignUpModal, setOpenConfirmSignUpModal] = useState();
  return {
    openConfirmSignUpModal,
    setOpenConfirmSignUpModal
  };
}
