import React from 'react';
import axios from 'axios'
import {returnErrors} from './Messages.js'
import store from '../Store.js'
// tutorial on
// https://www.youtube.com/watch?v=kfpY5BsIoFg

import { USER_LOADED ,
         USER_LOADING,
         AUTH_ERROR,
         LOGIN_SUCCESS,
         LOGIN_FAIL,
         LOGOUT_SUCCESS,
         CART_LOADED,
} from '../Actions/Types.js'




// CHECK TOKEN & LOAD THE USER
export const loadUser = () => (dispatch, getState) => {

  // USERAPI urls
  // var url = "https://real-grape-site-us.herokuapp.com/api/auth/user"
  var url = "https://www.therealgrape.com/api/auth/user"
  //var url = "http://127.0.0.1:8000/api/auth/user"

  // User loading
  dispatch({type: USER_LOADING});

  // GET token from state
  const token = getState().auth.token;

  // send some headers with our request
  // 'Content-type': 'application/json',
  //'X-CSRFToken': csrftoken
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // IF token, add to headers config
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // Now let us make our request to load the user
  // which returns a promise
  axios.get(url, config)
    .then(result => {
      dispatch({
        type: USER_LOADED,
        payload: result.data
      });



    }).catch(err => {
      /*dispatch(returnErrors(err.response.data, err.response.status));*/
      dispatch({
        type: AUTH_ERROR
      })
    });

}

// LOGIN USER
export const getCart = ()  => (dispatch, getState) => {

  var url = "https://www.therealgrape.com/api/cart/get-cart-summary"
  //var url = "http://127.0.0.1:8000/api/cart/get-cart-summary"

  // GET token from state
  const token = getState().auth.token;

  // send some headers with our request
  // 'Content-type': 'application/json',
  //'X-CSRFToken': csrftoken
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // IF token, add to headers config
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // Now let us make our request to load the user
  // which returns a promise
  axios.get(url, config)
    .then(result => {
      dispatch({
        type: CART_LOADED,
        payload: result.data
      });




    }).catch(err => {
      /*dispatch(returnErrors(err.response.data, err.response.status));*/
      /*dispatch({

      })*/
    });


}


export const login2 = (username, password) => (dispatch) => {

  // login url
  // var url = "https://real-grape-site-us.herokuapp.com/api/login-user/"
  var url = "https://www.therealgrape.com/api/login-user/"
  // var url = "http://127.0.0.1:8000/api/login-user/"
  // send some headers with our request
  // 'Content-type': 'application/json',

  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // Request body
  const body = JSON.stringify({username, password});

  //console.log("Trying to login user - login function called: ", username)

  // Now let us make our request to load the user
  // which returns a promise
  // var url = "https://real-grape-site-us.herokuapp.com/api/login-user/"
  axios.post(url, body,  config)
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
    }).catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));

    dispatch({
        type: LOGIN_FAIL
      });


    });



}

export function login(username, password)
{
  return dispatch => {
    // login url
    // var url = "https://real-grape-site-us.herokuapp.com/api/login-user/"
    var url = "https://www.therealgrape.com/api/login-user/"
    // var url = "http://127.0.0.1:8000/api/login-user/"
    // send some headers with our request
    // 'Content-type': 'application/json',

    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // Request body
    const body = JSON.stringify({username, password});

    //console.log("Trying to login user - login function called: ", username)

    // Now let us make our request to load the user
    // which returns a promise
    // var url = "https://real-grape-site-us.herokuapp.com/api/login-user/"
    axios.post(url, body,  config)
      .then(res => {

        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        });

        // load cart data
        store.dispatch(getCart());


      }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
          type: LOGIN_FAIL
        });

            console.log("Login failed error: ", err)
      });

  }
}

// LOGOUT USER


export function logout()
{
  return (dispatch, getState)  => {

    // var url = "https://real-grape-site-us.herokuapp.com/api/auth/logout"
    var url = "https://www.therealgrape.com/api/auth/logout"
    // var url = "http://127.0.0.1:8000/api/auth/logout"

    console.log("logging out")

    axios
      .post(url, null, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: 'CLEAR_LEADS' });
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  }
}


/*
export const logout = () => (dispatch, getState) => {
  axios
    .post('/api/auth/logout/', null, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: 'CLEAR_LEADS' });
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};*/

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  return config;
};
