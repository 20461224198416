import {useState} from 'react';

export function useJournalPostToDisplay(){
  const [journalPostToDisplay, setJournalPostToDisplay] = useState(null)

  return{
    journalPostToDisplay,
    setJournalPostToDisplay
  }
}
