import React from 'react';


export function removeZeroFromPhoneNumber(phoneNumber){

  var phoneNumberAsArray = phoneNumber.split('')


  if (phoneNumberAsArray[0] === '0') {
    var removed = phoneNumberAsArray.splice(0, 1)

  }

  phoneNumber = phoneNumberAsArray.join('')

  return phoneNumber

}

export const titleList = [
  {optionValue: "MR", title: "Mr"},
  {optionValue: "MS", title: "Ms"},
  {optionValue: "MRS", title: "Mrs"},
  {optionValue: "DR", title: "Dr"}

]

export const genderList = [
  {optionValue: "Male", title: "Male"},
  {optionValue: "Female", title: "Female"}

]

export const businessTypeList = [
  {optionValue: "CH",  business: "Champagne House"},
  {optionValue: "CF",  business: "Chef"},
  {optionValue: "FT",  business: "Food Tours"},
  {optionValue: "HL",  business: "Hotel"},
  {optionValue: "LBB", business: "Luxury B&B"},
  {optionValue: "RT",  business: "Restaurant"},
  {optionValue: "WB",  business: "Wine Bar"},
  {optionValue: "WM",  business: "Wine Maker"},
  {optionValue: "WY",  business: "Winery"},
  {optionValue: "WT",  business: "Wine Tours"},
  {optionValue: "OAP", business: "Other Accommodation Provider"},
  {optionValue: "OWP", business: "Other Wine Professional"}
]


export const countryList = [{optionValue: "AF", country: "Afghanistan"},
	{optionValue: "AX", country: "Åland Islands"},
	{optionValue: "AL", country: "Albania"},
	{optionValue: "DZ", country: "Algeria"},
	{optionValue: "AS", country: "American Samoa"},
	{optionValue: "AD", country: "Andorra"},
	{optionValue: "AO", country: "Angola"},
	{optionValue: "AI", country: "Anguilla"},
	{optionValue: "AQ", country: "Antarctica"},
	{optionValue: "AG", country: "Antigua and Barbuda"},
	{optionValue: "AR", country: "Argentina"},
	{optionValue: "AM", country: "Armenia"},
	{optionValue: "AW", country: "Aruba"},
	{optionValue: "AU", country: "Australia"},
	{optionValue: "AT", country: "Austria"},
	{optionValue: "AZ", country: "Azerbaijan"},
	{optionValue: "BS", country: "Bahamas"},
	{optionValue: "BH", country: "Bahrain"},
	{optionValue: "BD", country: "Bangladesh"},
	{optionValue: "BB", country: "Barbados"},
	{optionValue: "BY", country: "Belarus"},
	{optionValue: "BE", country: "Belgium"},
	{optionValue: "BZ", country: "Belize"},
	{optionValue: "BJ", country: "Benin"},
	{optionValue: "BM", country: "Bermuda"},
	{optionValue: "BT", country: "Bhutan"},
	{optionValue: "BO", country: "Bolivia, Plurinational State of"},
	{optionValue: "BQ", country: "Bonaire, Sint Eustatius and Saba"},
	{optionValue: "BA", country: "Bosnia and Herzegovina"},
	{optionValue: "BW", country: "Botswana"},
	{optionValue: "BV", country: "Bouvet Island"},
	{optionValue: "BR", country: "Brazil"},
	{optionValue: "IO", country: "British Indian Ocean Territory"},
	{optionValue: "BN", country: "Brunei Darussalam"},
	{optionValue: "BG", country: "Bulgaria"},
	{optionValue: "BF", country: "Burkina Faso"},
	{optionValue: "BI", country: "Burundi"},
	{optionValue: "KH", country: "Cambodia"},
	{optionValue: "CM", country: "Cameroon"},
	{optionValue: "CA", country: "Canada"},
	{optionValue: "CV", country: "Cape Verde"},
	{optionValue: "KY", country: "Cayman Islands"},
	{optionValue: "CF", country: "Central African Republic"},
	{optionValue: "TD", country: "Chad"},
	{optionValue: "CL", country: "Chile"},
	{optionValue: "CN", country: "China"},
	{optionValue: "CX", country: "Christmas Island"},
	{optionValue: "CC", country: "Cocos (Keeling) Islands"},
	{optionValue: "CO", country: "Colombia"},
	{optionValue: "KM", country: "Comoros"},
	{optionValue: "CG", country: "Congo"},
	{optionValue: "CD", country: "Congo, the Democratic Republic of the"},
	{optionValue: "CK", country: "Cook Islands"},
	{optionValue: "CR", country: "Costa Rica"},
	{optionValue: "CI", country: "Côte d'Ivoire"},
	{optionValue: "HR", country: "Croatia"},
	{optionValue: "CU", country: "Cuba"},
	{optionValue: "CW", country: "Curaçao"},
	{optionValue: "CY", country: "Cyprus"},
	{optionValue: "CZ", country: "Czech Republic"},
	{optionValue: "DK", country: "Denmark"},
	{optionValue: "DJ", country: "Djibouti"},
	{optionValue: "DM", country: "Dominica"},
	{optionValue: "DO", country: "Dominican Republic"},
	{optionValue: "EC", country: "Ecuador"},
	{optionValue: "EG", country: "Egypt"},
	{optionValue: "SV", country: "El Salvador"},
	{optionValue: "GQ", country: "Equatorial Guinea"},
	{optionValue: "ER", country: "Eritrea"},
	{optionValue: "EE", country: "Estonia"},
	{optionValue: "ET", country: "Ethiopia"},
	{optionValue: "FK", country: "Falkland Islands (Malvinas)"},
	{optionValue: "FO", country: "Faroe Islands"},
	{optionValue: "FJ", country: "Fiji"},
	{optionValue: "FI", country: "Finland"},
	{optionValue: "FR", country: "France"},
	{optionValue: "GF", country: "French Guiana"},
	{optionValue: "PF", country: "French Polynesia"},
	{optionValue: "TF", country: "French Southern Territories"},
	{optionValue: "GA", country: "Gabon"},
	{optionValue: "GM", country: "Gambia"},
	{optionValue: "GE", country: "Georgia"},
	{optionValue: "DE", country: "Germany"},
	{optionValue: "GH", country: "Ghana"},
	{optionValue: "GI", country: "Gibraltar"},
	{optionValue: "GR", country: "Greece"},
	{optionValue: "GL", country: "Greenland"},
	{optionValue: "GD", country: "Grenada"},
	{optionValue: "GP", country: "Guadeloupe"},
	{optionValue: "GU", country: "Guam"},
	{optionValue: "GT", country: "Guatemala"},
	{optionValue: "GG", country: "Guernsey"},
	{optionValue: "GN", country: "Guinea"},
	{optionValue: "GW", country: "Guinea-Bissau"},
	{optionValue: "GY", country: "Guyana"},
	{optionValue: "HT", country: "Haiti"},
	{optionValue: "HM", country: "Heard Island and McDonald Islands"},
	{optionValue: "VA", country: "Holy See (Vatican City State)"},
	{optionValue: "HN", country: "Honduras"},
	{optionValue: "HK", country: "Hong Kong"},
	{optionValue: "HU", country: "Hungary"},
	{optionValue: "IS", country: "Iceland"},
	{optionValue: "IN", country: "India"},
	{optionValue: "ID", country: "Indonesia"},
	{optionValue: "IR", country: "Iran, Islamic Republic of"},
	{optionValue: "IQ", country: "Iraq"},
	{optionValue: "IE", country: "Ireland"},
	{optionValue: "IM", country: "Isle of Man"},
	{optionValue: "IL", country: "Israel"},
	{optionValue: "IT", country: "Italy"},
	{optionValue: "JM", country: "Jamaica"},
	{optionValue: "JP", country: "Japan"},
	{optionValue: "JE", country: "Jersey"},
	{optionValue: "JO", country: "Jordan"},
	{optionValue: "KZ", country: "Kazakhstan"},
	{optionValue: "KE", country: "Kenya"},
	{optionValue: "KI", country: "Kiribati"},
	{optionValue: "KP", country: "Korea, Democratic People's Republic of"},
	{optionValue: "KR", country: "Korea, Republic of"},
	{optionValue: "KW", country: "Kuwait"},
	{optionValue: "KG", country: "Kyrgyzstan"},
	{optionValue: "LA", country: "Lao People's Democratic Republic"},
	{optionValue: "LV", country: "Latvia"},
	{optionValue: "LB", country: "Lebanon"},
	{optionValue: "LS", country: "Lesotho"},
	{optionValue: "LR", country: "Liberia"},
	{optionValue: "LY", country: "Libya"},
	{optionValue: "LI", country: "Liechtenstein"},
	{optionValue: "LT", country: "Lithuania"},
	{optionValue: "LU", country: "Luxembourg"},
	{optionValue: "MO", country: "Macao"},
	{optionValue: "MK", country: "Macedonia, the former Yugoslav Republic of"},
	{optionValue: "MG", country: "Madagascar"},
	{optionValue: "MW", country: "Malawi"},
	{optionValue: "MY", country: "Malaysia"},
	{optionValue: "MV", country: "Maldives"},
	{optionValue: "ML", country: "Mali"},
	{optionValue: "MT", country: "Malta"},
	{optionValue: "MH", country: "Marshall Islands"},
	{optionValue: "MQ", country: "Martinique"},
	{optionValue: "MR", country: "Mauritania"},
	{optionValue: "MU", country: "Mauritius"},
	{optionValue: "YT", country: "Mayotte"},
	{optionValue: "MX", country: "Mexico"},
	{optionValue: "FM", country: "Micronesia, Federated States of"},
	{optionValue: "MD", country: "Moldova, Republic of"},
	{optionValue: "MC", country: "Monaco"},
	{optionValue: "MN", country: "Mongolia"},
	{optionValue: "ME", country: "Montenegro"},
	{optionValue: "MS", country: "Montserrat"},
	{optionValue: "MA", country: "Morocco"},
	{optionValue: "MZ", country: "Mozambique"},
	{optionValue: "MM", country: "Myanmar"},
	{optionValue: "NA", country: "Namibia"},
	{optionValue: "NR", country: "Nauru"},
	{optionValue: "NP", country: "Nepal"},
	{optionValue: "NL", country: "Netherlands"},
	{optionValue: "NC", country: "New Caledonia"},
	{optionValue: "NZ", country: "New Zealand"},
	{optionValue: "NI", country: "Nicaragua"},
	{optionValue: "NE", country: "Niger"},
	{optionValue: "NG", country: "Nigeria"},
	{optionValue: "NU", country: "Niue"},
	{optionValue: "NF", country: "Norfolk Island"},
	{optionValue: "MP", country: "Northern Mariana Islands"},
	{optionValue: "NO", country: "Norway"},
	{optionValue: "OM", country: "Oman"},
	{optionValue: "PK", country: "Pakistan"},
	{optionValue: "PW", country: "Palau"},
	{optionValue: "PS", country: "Palestinian Territory, Occupied"},
	{optionValue: "PA", country: "Panama"},
	{optionValue: "PG", country: "Papua New Guinea"},
	{optionValue: "PY", country: "Paraguay"},
	{optionValue: "PE", country: "Peru"},
	{optionValue: "PH", country: "Philippines"},
	{optionValue: "PN", country: "Pitcairn"},
	{optionValue: "PL", country: "Poland"},
	{optionValue: "PT", country: "Portugal"},
	{optionValue: "PR", country: "Puerto Rico"},
	{optionValue: "QA", country: "Qatar"},
	{optionValue: "RE", country: "Réunion"},
	{optionValue: "RO", country: "Romania"},
	{optionValue: "RU", country: "Russian Federation"},
	{optionValue: "RW", country: "Rwanda"},
	{optionValue: "BL", country: "Saint Barthélemy"},
	{optionValue: "SH", country: "Saint Helena, Ascension and Tristan da Cunha"},
	{optionValue: "KN", country: "Saint Kitts and Nevis"},
	{optionValue: "LC", country: "Saint Lucia"},
	{optionValue: "MF", country: "Saint Martin (French part)"},
	{optionValue: "PM", country: "Saint Pierre and Miquelon"},
	{optionValue: "VC", country: "Saint Vincent and the Grenadines"},
	{optionValue: "WS", country: "Samoa"},
	{optionValue: "SM", country: "San Marino"},
	{optionValue: "ST", country: "Sao Tome and Principe"},
	{optionValue: "SA", country: "Saudi Arabia"},
	{optionValue: "SN", country: "Senegal"},
	{optionValue: "RS", country: "Serbia"},
	{optionValue: "SC", country: "Seychelles"},
	{optionValue: "SL", country: "Sierra Leone"},
	{optionValue: "SG", country: "Singapore"},
	{optionValue: "SX", country: "Sint Maarten (Dutch part)"},
	{optionValue: "SK", country: "Slovakia"},
	{optionValue: "SI", country: "Slovenia"},
	{optionValue: "SB", country: "Solomon Islands"},
	{optionValue: "SO", country: "Somalia"},
	{optionValue: "ZA", country: "South Africa"},
	{optionValue: "GS", country: "South Georgia and the South Sandwich Islands"},
	{optionValue: "SS", country: "South Sudan"},
	{optionValue: "ES", country: "Spain"},
	{optionValue: "LK", country: "Sri Lanka"},
	{optionValue: "SD", country: "Sudan"},
	{optionValue: "SR", country: "Suriname"},
	{optionValue: "SJ", country: "Svalbard and Jan Mayen"},
	{optionValue: "SZ", country: "Swaziland"},
	{optionValue: "SE", country: "Sweden"},
	{optionValue: "CH", country: "Switzerland"},
	{optionValue: "SY", country: "Syrian Arab Republic"},
	{optionValue: "TW", country: "Taiwan, Province of China"},
	{optionValue: "TJ", country: "Tajikistan"},
	{optionValue: "TZ", country: "Tanzania, United Republic of"},
	{optionValue: "TH", country: "Thailand"},
	{optionValue: "TL", country: "Timor-Leste"},
	{optionValue: "TG", country: "Togo"},
	{optionValue: "TK", country: "Tokelau"},
	{optionValue: "TO", country: "Tonga"},
	{optionValue: "TT", country: "Trinidad and Tobago"},
	{optionValue: "TN", country: "Tunisia"},
	{optionValue: "TR", country: "Turkey"},
	{optionValue: "TM", country: "Turkmenistan"},
	{optionValue: "TC", country: "Turks and Caicos Islands"},
	{optionValue: "TV", country: "Tuvalu"},
	{optionValue: "UG", country: "Uganda"},
	{optionValue: "UA", country: "Ukraine"},
	{optionValue: "AE", country: "United Arab Emirates"},
	{optionValue: "GB", country: "United Kingdom"},
	{optionValue: "US", country: "United States"},
	{optionValue: "UM", country: "United States Minor Outlying Islands"},
	{optionValue: "UY", country: "Uruguay"},
	{optionValue: "UZ", country: "Uzbekistan"},
	{optionValue: "VU", country: "Vanuatu"},
	{optionValue: "VE", country: "Venezuela, Bolivarian Republic of"},
	{optionValue: "VN", country: "Viet Nam"},
	{optionValue: "VG", country: "Virgin Islands, British"},
	{optionValue: "VI", country: "Virgin Islands, U.S."},
	{optionValue: "WF", country: "Wallis and Futuna"},
	{optionValue: "EH", country: "Western Sahara"},
	{optionValue: "YE", country: "Yemen"},
	{optionValue: "ZM", country: "Zambia"},
	{optionValue: "ZW", country: "Zimbabwe"}]

  export const currencyCodesReduced = [{optionValue:"GBP", countryCode:"United Kingdom Pounds"},
  	{optionValue:"EUR", countryCode:"Euro"},
  	{optionValue:"USD", countryCode:"United States Dollars"},
  	{optionValue:"ZAR", countryCode:"South Africa Rand"}
]

export const currencyCodes = [{optionValue:"GBP", countryCode:"United Kingdom Pounds"},
	{optionValue:"EUR", countryCode:"Euro"},
	{optionValue:"USD", countryCode:"United States Dollars"},
	{optionValue:"DZD", countryCode:"Algeria Dinars"},
	{optionValue:"ARP", countryCode:"Argentina Pesos"},
	{optionValue:"AUD", countryCode:"Australia Dollars"},
	{optionValue:"BSD", countryCode:"Bahamas Dollars"},
	{optionValue:"BBD", countryCode:"Barbados Dollars"},
	{optionValue:"BMD", countryCode:"Bermuda Dollars"},
	{optionValue:"BRR", countryCode:"Brazil Real"},
	{optionValue:"BGL", countryCode:"Bulgaria Lev"},
	{optionValue:"CAD", countryCode:"Canada Dollars"},
	{optionValue:"CLP", countryCode:"Chile Pesos"},
	{optionValue:"CNY", countryCode:"China Yuan Renmimbi"},
	{optionValue:"CSK", countryCode:"Czech Republic Koruna"},
	{optionValue:"DKK", countryCode:"Denmark Kroner"},
	{optionValue:"XCD", countryCode:"Eastern Caribbean Dollars"},
	{optionValue:"EGP", countryCode:"Egypt Pounds"},
	{optionValue:"FJD", countryCode:"Fiji Dollars"},
	{optionValue:"GRD", countryCode:"Greece Drachmas"},
	{optionValue:"HKD", countryCode:"Hong Kong Dollars"},
	{optionValue:"HUF", countryCode:"Hungary Forint"},
	{optionValue:"ISK", countryCode:"Iceland Krona"},
	{optionValue:"INR", countryCode:"India Rupees"},
	{optionValue:"IDR", countryCode:"Indonesia Rupiah"},
	{optionValue:"ILS", countryCode:"Israel New Shekels"},
	{optionValue:"ITL", countryCode:"Italy Lira"},
	{optionValue:"JMD", countryCode:"Jamaica Dollars"},
	{optionValue:"JPY", countryCode:"Japan Yen"},
	{optionValue:"JOD", countryCode:"Jordan Dinar"},
	{optionValue:"KRW", countryCode:"Korea (South) Won"},
	{optionValue:"LBP", countryCode:"Lebanon Pounds"},
	{optionValue:"LUF", countryCode:"Luxembourg Francs"},
	{optionValue:"MYR", countryCode:"Malaysia Ringgit"},
	{optionValue:"MXP", countryCode:"Mexico Pesos"},
	{optionValue:"NLG", countryCode:"Netherlands Guilders"},
	{optionValue:"NZD", countryCode:"New Zealand Dollars"},
	{optionValue:"NOK", countryCode:"Norway Kroner"},
	{optionValue:"PKR", countryCode:"Pakistan Rupees"},
	{optionValue:"PHP", countryCode:"Philippines Pesos"},
	{optionValue:"PLZ", countryCode:"Poland Zloty"},
	{optionValue:"PTE", countryCode:"Portugal Escudo"},
	{optionValue:"ROL", countryCode:"Romania Leu"},
	{optionValue:"RUR", countryCode:"Russia Rubles"},
	{optionValue:"SAR", countryCode:"Saudi Arabia Riyal"},
	{optionValue:"SGD", countryCode:"Singapore Dollars"},
	{optionValue:"SKK", countryCode:"Slovakia Koruna"},
	{optionValue:"ZAR", countryCode:"South Africa Rand"},
	{optionValue:"KRW", countryCode:"South Korea Won"},
	{optionValue:"SDD", countryCode:"Sudan Dinar"},
	{optionValue:"SEK", countryCode:"Sweden Krona"},
	{optionValue:"CHF", countryCode:"Switzerland Francs"},
	{optionValue:"TWD", countryCode:"Taiwan Dollars"},
	{optionValue:"THB", countryCode:"Thailand Baht"},
	{optionValue:"TTD", countryCode:"Trinidad and Tobago Dollars"},
	{optionValue:"TRL", countryCode:"Turkey Lira"},
	{optionValue:"VEB", countryCode:"Venezuela Bolivar"},
	{optionValue:"ZMK", countryCode:"Zambia Kwacha"}]

export const internationalCallingCodeList = [{optionValue:"AF", countryCode:"+93"},
{optionValue:"AL", countryCode:"+355"},
{optionValue:"DZ", countryCode:"+213"},
{optionValue:"AS", countryCode:"+1684"},
{optionValue:"AD", countryCode:"+376"},
{optionValue:"AO", countryCode:"+244"},
{optionValue:"AI", countryCode:"+1264"},
{optionValue:"AQ", countryCode:"+672"},
{optionValue:"AR", countryCode:"+54"},
{optionValue:"AM", countryCode:"+374"},
{optionValue:"AW", countryCode:"+297"},
{optionValue:"AU", countryCode:"+61"},
{optionValue:"AT", countryCode:"+43"},
{optionValue:"AZ", countryCode:"+994"},
{optionValue:"BS", countryCode:"+1"},
{optionValue:"BH", countryCode:"+973"},
{optionValue:"BD", countryCode:"+880"},
{optionValue:"BB", countryCode:"+1"},
{optionValue:"BY", countryCode:"+375"},
{optionValue:"BE", countryCode:"+32"},
{optionValue:"BZ", countryCode:"+501"},
{optionValue:"BJ", countryCode:"+229"},
{optionValue:"BM", countryCode:"+1"},
{optionValue:"BT", countryCode:"+975"},
{optionValue:"BO", countryCode:"+591"},
{optionValue:"BA", countryCode:"+387"},
{optionValue:"BW", countryCode:"+267"},
{optionValue:"BR", countryCode:"+55"},
{optionValue:"VG", countryCode:"+1284"},
{optionValue:"BN", countryCode:"+673"},
{optionValue:"BG", countryCode:"+359"},
{optionValue:"BF", countryCode:"+226"},
{optionValue:"BI", countryCode:"+257"},
{optionValue:"KH", countryCode:"+855"},
{optionValue:"CM", countryCode:"+237"},
{optionValue:"CA", countryCode:"+1"},
{optionValue:"CV", countryCode:"+238"},
{optionValue:"KY", countryCode:"+1-345"},
{optionValue:"CF", countryCode:"+236"},
{optionValue:"CL", countryCode:"+56"},
{optionValue:"CN", countryCode:"+86"},
{optionValue:"CO", countryCode:"+57"},
{optionValue:"KM", countryCode:"+269"},
{optionValue:"CK", countryCode:"+682"},
{optionValue:"CR", countryCode:"+506"},
{optionValue:"HR", countryCode:"+385"},
{optionValue:"CU", countryCode:"+53"},
{optionValue:"CW", countryCode:"+599"},
{optionValue:"CY", countryCode:"+357"},
{optionValue:"CZ", countryCode:"+420"},
{optionValue:"CD", countryCode:"+243"},
{optionValue:"DK", countryCode:"+45"},
{optionValue:"DJ", countryCode:"+253"},
{optionValue:"DM", countryCode:"+1"},
{optionValue:"DO", countryCode:"+1"},
{optionValue:"TL", countryCode:"+670"},
{optionValue:"EC", countryCode:"+593"},
{optionValue:"EG", countryCode:"+20"},
{optionValue:"SV", countryCode:"+503"},
{optionValue:"GQ", countryCode:"+240"},
{optionValue:"ER", countryCode:"+291"},
{optionValue:"EE", countryCode:"+372"},
{optionValue:"ET", countryCode:"+251"},
{optionValue:"FK", countryCode:"+500"},
{optionValue:"FO", countryCode:"+298"},
{optionValue:"FJ", countryCode:"+679"},
{optionValue:"FI", countryCode:"+358"},
{optionValue:"FR", countryCode:"+33"},
{optionValue:"PF", countryCode:"+689"},
{optionValue:"GA", countryCode:"+241"},
{optionValue:"GM", countryCode:"+220"},
{optionValue:"GE", countryCode:"+995"},
{optionValue:"DE", countryCode:"+49"},
{optionValue:"GH", countryCode:"+233"},
{optionValue:"GI", countryCode:"+350"},
{optionValue:"GR", countryCode:"+30"},
{optionValue:"GL", countryCode:"+299"},
{optionValue:"GP", countryCode:"+590"},
{optionValue:"GU", countryCode:"+1671"},
{optionValue:"GT", countryCode:"+502"},
{optionValue:"GN", countryCode:"+224"},
{optionValue:"GW", countryCode:"+245"},
{optionValue:"GY", countryCode:"+592"},
{optionValue:"HT", countryCode:"+509"},
{optionValue:"HN", countryCode:"+504"},
{optionValue:"HK", countryCode:"+852"},
{optionValue:"HU", countryCode:"+36"},
{optionValue:"IS", countryCode:"+354"},
{optionValue:"IN", countryCode:"+91"},
{optionValue:"ID", countryCode:"+62"},
{optionValue:"IR", countryCode:"+98"},
{optionValue:"IQ", countryCode:"+964"},
{optionValue:"IE", countryCode:"+353"},
{optionValue:"IM", countryCode:"+44"},
{optionValue:"IL", countryCode:"+972"},
{optionValue:"IT", countryCode:"+39"},
{optionValue:"CI", countryCode:"+225"},
{optionValue:"JM", countryCode:"+1"},
{optionValue:"JP", countryCode:"+81"},
{optionValue:"JO", countryCode:"+962"},
{optionValue:"KZ", countryCode:"+7"},
{optionValue:"KE", countryCode:"+254"},
{optionValue:"KI", countryCode:"+686"},
{optionValue:"XK", countryCode:"+381"},
{optionValue:"KW", countryCode:"+965"},
{optionValue:"KG", countryCode:"+996"},
{optionValue:"LA", countryCode:"+856"},
{optionValue:"LV", countryCode:"+371"},
{optionValue:"LB", countryCode:"+961"},
{optionValue:"LS", countryCode:"+266"},
{optionValue:"LR", countryCode:"+231"},
{optionValue:"LY", countryCode:"+218"},
{optionValue:"LI", countryCode:"+423"},
{optionValue:"LT", countryCode:"+370"},
{optionValue:"LU", countryCode:"+352"},
{optionValue:"MO", countryCode:"+853"},
{optionValue:"MK", countryCode:"+389"},
{optionValue:"MG", countryCode:"+261"},
{optionValue:"MW", countryCode:"+265"},
{optionValue:"MY", countryCode:"+60"},
{optionValue:"MV", countryCode:"+960"},
{optionValue:"ML", countryCode:"+223"},
{optionValue:"MT", countryCode:"+356"},
{optionValue:"MH", countryCode:"+692"},
{optionValue:"MR", countryCode:"+222"},
{optionValue:"MU", countryCode:"+230"},
{optionValue:"MX", countryCode:"+52"},
{optionValue:"FM", countryCode:"+691"},
{optionValue:"MD", countryCode:"+373"},
{optionValue:"MC", countryCode:"+377"},
{optionValue:"MN", countryCode:"+976"},
{optionValue:"ME", countryCode:"+382"},
{optionValue:"MS", countryCode:"+1664"},
{optionValue:"MA", countryCode:"+212"},
{optionValue:"MZ", countryCode:"+258"},
{optionValue:"MM", countryCode:"+95"},
{optionValue:"NA", countryCode:"+264"},
{optionValue:"NR", countryCode:"+674"},
{optionValue:"NP", countryCode:"+977"},
{optionValue:"NL", countryCode:"+31"},
{optionValue:"NC", countryCode:"+687"},
{optionValue:"NZ", countryCode:"+64"},
{optionValue:"NI", countryCode:"+505"},
{optionValue:"NE", countryCode:"+227"},
{optionValue:"NG", countryCode:"+234"},
{optionValue:"NU", countryCode:"+683"},
{optionValue:"NF", countryCode:"+672"},
{optionValue:"KP", countryCode:"+850"},
{optionValue:"MP", countryCode:"+1670"},
{optionValue:"NO", countryCode:"+47"},
{optionValue:"OM", countryCode:"+968"},
{optionValue:"PK", countryCode:"+92"},
{optionValue:"PW", countryCode:"+680"},
{optionValue:"PA", countryCode:"+507"},
{optionValue:"PG", countryCode:"+675"},
{optionValue:"PY", countryCode:"+595"},
{optionValue:"PE", countryCode:"+51"},
{optionValue:"PH", countryCode:"+63"},
{optionValue:"PN", countryCode:"+870"},
{optionValue:"PL", countryCode:"+48"},
{optionValue:"PT", countryCode:"+351"},
{optionValue:"PR", countryCode:"+1"},
{optionValue:"QA", countryCode:"+974"},
{optionValue:"CG", countryCode:"+242"},
{optionValue:"RE", countryCode:"+262"},
{optionValue:"RO", countryCode:"+40"},
{optionValue:"RU", countryCode:"+7"},
{optionValue:"RW", countryCode:"+250"},
{optionValue:"BL", countryCode:"+590"},
{optionValue:"SH", countryCode:"+290"},
{optionValue:"KN", countryCode:"+1"},
{optionValue:"LC", countryCode:"+1"},
{optionValue:"MF", countryCode:"+1599"},
{optionValue:"PM", countryCode:"+508"},
{optionValue:"VC", countryCode:"+1"},
{optionValue:"WS", countryCode:"+685"},
{optionValue:"SM", countryCode:"+378"},
{optionValue:"ST", countryCode:"+239"},
{optionValue:"SA", countryCode:"+966"},
{optionValue:"SN", countryCode:"+221"},
{optionValue:"RS", countryCode:"+381"},
{optionValue:"SC", countryCode:"+248"},
{optionValue:"SL", countryCode:"+232"},
{optionValue:"SG", countryCode:"+65"},
{optionValue:"SK", countryCode:"+421"},
{optionValue:"SI", countryCode:"+386"},
{optionValue:"SB", countryCode:"+677"},
{optionValue:"SO", countryCode:"+252"},
{optionValue:"ZA", countryCode:"+27"},
{optionValue:"KR", countryCode:"+82"},
{optionValue:"SS", countryCode:"+211"},
{optionValue:"ES", countryCode:"+34"},
{optionValue:"LK", countryCode:"+94"},
{optionValue:"SD", countryCode:"+249"},
{optionValue:"SR", countryCode:"+597"},
{optionValue:"SZ", countryCode:"+268"},
{optionValue:"SE", countryCode:"+46"},
{optionValue:"CH", countryCode:"+41"},
{optionValue:"SY", countryCode:"+963"},
{optionValue:"TW", countryCode:"+886"},
{optionValue:"TJ", countryCode:"+992"},
{optionValue:"TZ", countryCode:"+255"},
{optionValue:"TH", countryCode:"+66"},
{optionValue:"TG", countryCode:"+228"},
{optionValue:"TK", countryCode:"+690"},
{optionValue:"TT", countryCode:"+1"},
{optionValue:"TN", countryCode:"+216"},
{optionValue:"TR", countryCode:"+90"},
{optionValue:"TM", countryCode:"+993"},
{optionValue:"TV", countryCode:"+688"},
{optionValue:"UG", countryCode:"+256"},
{optionValue:"UA", countryCode:"+380"},
{optionValue:"AE", countryCode:"+971"},
{optionValue:"GB", countryCode:"+44"},
{optionValue:"US", countryCode:"+1"},
{optionValue:"UY", countryCode:"+598"},
{optionValue:"UZ", countryCode:"+998"},
{optionValue:"VU", countryCode:"+678"},
{optionValue:"VA", countryCode:"+39"},
{optionValue:"VE", countryCode:"+58"},
{optionValue:"VN", countryCode:"+84"},
{optionValue:"EH", countryCode:"+212"},
{optionValue:"YE", countryCode:"+967"},
{optionValue:"ZM", countryCode:"+260"},
{optionValue:"ZW", countryCode:"+263"}]

export function findElementInTitleArray(element, arrayToSearch){

  if (element)
  {
    var elementToDisplay = ''
    const foundObject      = arrayToSearch.find(el => el.optionValue == element)

    elementToDisplay = foundObject.title.toString()
    return elementToDisplay;

  }

  else {
    return ""
  }

}

export function findElementInGenderArray(element, arrayToSearch){

  if (element)
  {
    var elementToDisplay = ''
    const foundObject      = arrayToSearch.find(el => el.optionValue == element)

    elementToDisplay = foundObject.title.toString()
    return elementToDisplay;
  }

  else {
    return ""
  }


}

export function findElementInCountryListArray(element, arrayToSearch){

  if (element)
  {
    var elementToDisplay = ''
    const foundObject      = arrayToSearch.find(el => el.optionValue == element)

    elementToDisplay = foundObject.country.toString()
    return elementToDisplay;
  }

  else {
    return ""
  }


}

export function findElementInCountryCodeListArray(element, arrayToSearch){

  if (element)
  {
    var elementToDisplay = ''
    const foundObject      = arrayToSearch.find(el => el.optionValue == element)

    elementToDisplay = foundObject.countryCode.toString()
    return elementToDisplay;
  }

  else {
    return ""
  }


}

export function findElementInBusinessTypeListArray(element, arrayToSearch){

  if (element)
  {
    var elementToDisplay = ''
    const foundObject      = arrayToSearch.find(el => el.optionValue == element)

    elementToDisplay = foundObject.business.toString()
    return elementToDisplay;
  }

  else {
    return ""
  }


}

export function findElementInCCyListArray(element, arrayToSearch){

  if (element)
  {
    var elementToDisplay = ''
    const foundObject      = arrayToSearch.find(el => el.optionValue == element)

    elementToDisplay = foundObject.countryCode.toString()
    return elementToDisplay;
  }

  else {
    return ""
  }


}

export function PlaceHolderImage() {

  return(
  <fragment>
    <span aria-hidden = "true" role = "presentation" class = "bui-icon my-dashboard-profile_pic-edit-icon bui-icon--smaller bui-icon--color-white">

      <svg viewBox="0 0 24 24" width="1em" height="1em" focusable="false" role="img" aria-hidden="true"><path d="M18.75 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0zm1.5 0a6 6 0 1 0-12 0 6 6 0 0 0 12 0zm-.08-7.085l-1.084-2.171a2.25 2.25 0 0 0-2.013-1.244h-5.646a2.25 2.25 0 0 0-2.013 1.244L8.33 5.663 9 5.25H2.25A2.25 2.25 0 0 0 0 7.5v12a2.25 2.25 0 0 0 2.25 2.25h19.5A2.25 2.25 0 0 0 24 19.5v-12a2.25 2.25 0 0 0-2.25-2.25H19.5l.67.415zm-1.34.67a.75.75 0 0 0 .67.415h2.25a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1-.75-.75v-12a.75.75 0 0 1 .75-.75H9a.75.75 0 0 0 .67-.415l1.086-2.17a.75.75 0 0 1 .67-.415h5.647a.75.75 0 0 1 .671.415l1.085 2.17zM4.124 9.75a.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5 1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5zM6 6V4.5a.75.75 0 0 0-1.5 0V6A.75.75 0 0 0 6 6z"></path>
      </svg>

    </span>
  </fragment>)


}
