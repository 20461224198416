import React, {useState, useEffect} from 'react';
import "../Styles/Forms.css"
import "../Styles/SocialMediaLinks.css"

import {useConfirmSignUpModal} from '../Hooks/useConfirmSignUpModal.js'
import {useFormErrorModal, useFormErrorMsg } from '../Hooks/useFormErrorModal.js'

import WineToast from '../img/jumbotron/wine-toast-1000x1000.jpg'



export function RequestInfoFormInterfaced({setOpenConfirmSignUpModal}){

  // the fonfirm modal is currently set to false as default
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [validRequestForm, setValidRequestForm] = useState(true);
  const confirmSignUpModal                = useConfirmSignUpModal();
  const formErrorModal                    = useFormErrorModal();
  const formErrorMsg                      = useFormErrorMsg()

  // the states for the reqest formModal
  const [infoRequestList, setInfoRequestList] = useState([]);
  const [activeRequestItem, setActiveRequestItem] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "countrySelect": "",
        "city": "",
        "requestContent": "",
        "howDidYouHear": "",
        "ageCertification": false
    });

  useEffect(() =>
  {
      // nothing to do for the moment
      console.log("");

  }, [])


  // get some cookies with ajax
  // we can be more efficient with the cookie calls and management
  // documentation at
  // https://docs.djangoproject.com/en/3.1/ref/csrf/
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }


  function handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setActiveRequestItem({
      ...activeRequestItem,
      [e.target.name]: value
    })



  }

  function handleFormValidation() {


       setValidRequestForm(true)
       var validRequest = true
       var errorCounter = 0
       const errMax     = 3;
       let formIsValid  = true
       let errors       = {}

       // let's start with validating some fields
       if(activeRequestItem['firstName'] === "")
       {


         formIsValid = false
         errors["firstName"] = "Please provide your first name";

         errorCounter += 1
         if(errorCounter < errMax){
            formErrorMsg.setErrorMsg("Please provide your first name")
            formErrorModal.setOpenFormErrorModal(true)
         }



         // regEx: /^[a-zA-Z]+$/,
         setValidRequestForm(false)
         /*
         validRequest = false
         alert("Please provide your first name")
         console.log("Firstname request form is valid: " , validRequestForm)
         */

       }

       if(typeof(activeRequestItem['firstName']) !== "undefined"  ){
         if(!activeRequestItem['firstName'].match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["firstName"] = "First name should be letters only";

            errorCounter += 1
            if(errorCounter < errMax){


              formErrorMsg.setErrorMsg("First name should be letters only")
              formErrorModal.setOpenFormErrorModal(true)


            }

         }
       }

       if(activeRequestItem['lastName'] === "")
       {


         formIsValid = false
         errors["lastName"] = "Please provide your last name";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please provide your last name")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)

         /*
         validRequest = false
         alert("Please provide your last name")
         console.log("Lastname request form is valid: " , validRequestForm)*/

       }

       if(typeof(activeRequestItem['lastName']) !== "undefined"  ){
         if(!activeRequestItem['lastName'].match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["lastName"] = "Last name should be letters only";

            errorCounter += 1
            if(errorCounter < errMax){

              formErrorMsg.setErrorMsg("Last name should be letters only")
              formErrorModal.setOpenFormErrorModal(true)

            }


         }
       }


       if(activeRequestItem['email'] === "")
       {

         formIsValid = false
         errors["email"] = "Please enter a valid email address";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please enter a valid email address")
           formErrorModal.setOpenFormErrorModal(true)


         }

         setValidRequestForm(false)

         /*
         validRequest = false
         alert("Please enter a valid email address")
         console.log("Email request form is valid: " , validRequestForm)
         */
       }


       if(typeof activeRequestItem["email"] !== "undefined"){
          let lastAtPos = activeRequestItem["email"].lastIndexOf('@');
          let lastDotPos = activeRequestItem["email"].lastIndexOf('.');

          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && activeRequestItem["email"].indexOf('@@') == -1 && lastDotPos > 2 && (activeRequestItem["email"].length - lastDotPos) > 2)) {
             formIsValid = false;
             errors["email"] = "Email is not valid";

             errorCounter += 1
             if(errorCounter < errMax){


               formErrorMsg.setErrorMsg("Please enter a valid email address")
               formErrorModal.setOpenFormErrorModal(true)

             }
           }
      }


       if(activeRequestItem['phone'] === "")
       {

         formIsValid = false
         errors["phone"] = "Please enter a valid phone number";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please enter a valid phone number")
           formErrorModal.setOpenFormErrorModal(true)

         }


         setValidRequestForm(false)

         /*
         validRequest = false
         alert("Please enter a valid phone number")
         console.log("Phone request form is valid: " , validRequestForm)*/

       }



       if(activeRequestItem.countrySelect === "")
       {


         formIsValid = false
         errors["countrySelect"] = "Please select a country";


         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please select a country")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)

         /*validRequest = false
         console.log("Country request form is valid: " , validRequestForm)*/

       }

       if(activeRequestItem['city'] === "")
       {

         formIsValid = false
         errors['city'] = "Please enter a city";


         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please enter a city")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)

         /*
         validRequest = false
         console.log("City request form is valid: " , validRequestForm)*/

       }

       if(typeof(activeRequestItem['city']) !== "undefined"  ){
         if(!activeRequestItem['city'].match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["city"] = "City should be letters only";


            errorCounter += 1
            if(errorCounter < errMax){

              formErrorMsg.setErrorMsg("City should be letters only")
              formErrorModal.setOpenFormErrorModal(true)

            }


         }
       }

       if(activeRequestItem['requestContent'] === "")
       {

         formIsValid = false
         errors['requestContent'] = "Please let us know how we can help";


         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please let us know how we can help")
           formErrorModal.setOpenFormErrorModal(true)

         }


         setValidRequestForm(false)

         /*
         validRequest = false
         alert("Please let us know how we can help")
         console.log("Request content request form is valid: " , validRequestForm)*/

       }

       if(activeRequestItem['howDidYouHear'] === "")
       {

         formIsValid = false
         errors['howDidYouHear'] = "Please let us know how you heard about The Real Grape";


         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please let us know how you heard about The Real Grape")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)
         /*
         validRequest = false

         console.log("How did you request form is valid: " , validRequestForm)*/

       }

       if(activeRequestItem['ageCertification'] === false)
       {

         formIsValid = false
         errors['ageCertification'] = "Please certify you are over the age of 18";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please certify you are over the age of 18")
           formErrorModal.setOpenFormErrorModal(true)
         }

          /*
         setValidRequestForm(false)
         validRequest = false
         console.log("Age request form is valid: " , validRequestForm)*/
       }


       /*console.log("The request form is valid: " , validRequestForm)*/
       // only allow the form to submit if data is valid
       //if(validRequestForm)

       return formIsValid;

  }

  function handleSubmit(e) {

    // button type="submit" instead of button type="button"
    e.preventDefault()
    // the url for the create request
    //var url = "http://127.0.0.1:8000/api/requestinfo-create/"
    // the url for the create request we use the web endpoints
    // var url = "https://real-grape-site-us.herokuapp.com/api/requestinfo-create/"
    var url = "https://www.therealgrape.com/api/requestinfo-create/"


   // get a cookie that we will pass in our headers
   const csrftoken = getCookie('csrftoken');
   var isFormValid;

   isFormValid = handleFormValidation();

   //if(validRequestForm)
   if(isFormValid)
  {
     // let's use the fetch method
     // we could even use the AXIOS API but the below is fine

     fetch(url, {
       method:'POST',
       headers:{
               'Content-type': 'application/json',
               'X-CSRFToken': csrftoken
             },
             credentials: 'include',
       body:JSON.stringify(activeRequestItem)
     }).then((response) => {
       // clear the web form
       setActiveRequestItem( {
             "firstName": "",
             "lastName": "",
             "email": "",
             "phone": "",
             "countrySelect": "",
             "city": "",
             "requestContent": "",
             "howDidYouHear": "",
             "ageCertification": false
         })

         console.log("removed csrf token from post method")

         }).catch(function(error){
           console.log('ERROR MSG: ', error)
           console.error('Caught error', error)


     })

     confirmSignUpModal.setOpenConfirmSignUpModal(true)

   }
  }


  return(
    <fragment>

        {console.log('RequestInfoForm Submit button not clicked' +confirmSubmit)}
        <ConfirmSignUpModal {...confirmSignUpModal}/>
        <FormError {...formErrorModal} {...formErrorMsg}/>

        <div class = "container">
          <form class = "form request-info-form" autoComplete="off">

            <div class = "form-row">
              <div class = "form-group col-sm-12 col-md-6 align-items-md-end">
                <label class = "nameDetails" for = "firstName" autoComplete="off">
                  <input onChange = {handleFormChange} value = {activeRequestItem.firstName} name = "firstName" class="form-control form-control-sm form-field " type = "text" if = "firstName" placeholder = "First Name"></input>
                </label>
              </div>

              <div class = "form-group col-sm-12 col-md-6" >
                <label class = "nameDetails" for = "lastName">
                  <input onChange = {handleFormChange} value = {activeRequestItem.lastName} name = "lastName" class = "form-control form-control-sm form-field" type = "text" if = "lastName" placeholder = "Last Name"></input>
                </label>
              </div>


              <div class = "form-group col-sm-12 col-md-6">
                <label class = "adressDetails" for="email">
                  <input onChange = {handleFormChange} value = {activeRequestItem.email}  name = "email" class = "form-control form-control-sm form-field" type="email" id="email" placeholder="Email address"></input>
                </label>
              </div>


              <div class = "form-group col-sm-12 col-md-6">
                <label class = "adressDetails" for="phone">
                  <input onChange = {handleFormChange} value = {activeRequestItem.phone} name = "phone" class = "form-control form-control-sm form-field" type="tel" id="phone" placeholder="Phone Number"></input>
                </label>
              </div>


              <div class = "form-group col-sm-12 col-md-6">
                <label>
                <select  onChange = {handleFormChange} value = {activeRequestItem.countrySelect} name = "countrySelect" class = "form-control form-control-sm form-field" >
                  <option value="">Country</option>
                  {countryList.map((countrySelect) =>

                    <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                  )}
                </select>
                </label>
              </div>

              <div class = "form-group col-sm-12 col-md-6" >
                <label class = "cityDetails" for = "city">
                  <input onChange = {handleFormChange} value = {activeRequestItem.city} name = "city" class = "form-control form-control-sm form-field" type = "text" if = "city" placeholder = "City"></input>
                </label>
              </div>

              <span>
                <p>How can we help</p>
                  <div class = "form-group col-sm-12 col-md-12 ">
                    <label class = "requestDetails" for="message">
                      <textarea onChange = {handleFormChange} value = {activeRequestItem.requestContent} name = "requestContent" class = "form-control form-control-sm textarea__requestDetails medium form-field "  cols="75" id="message" placeholder="I'm looking for a wine tour in the Rhone" aria-required="true" aria-invalid="false"></textarea>
                    </label>
                  </div>
              </span>


              <div class = "form-group col-sm-12 col-md-12">
                <label>
                <select  onChange = {handleFormChange} value = {activeRequestItem.howDidYouHear} name = "howDidYouHear" class = "form-control form-control-sm form-field">
                 <option value="">How did you hear about The Real Grape?</option>
                  {howDidYouHearList.map((sourceSelect) =>
                    <option value = {sourceSelect.optionValue}>{sourceSelect.source} </option>
                  )}
                </select>
                </label>
              </div>

              <div class = "form-check col-sm-12 col-md-12">
                <label class = "form-check-label mr-2">
                   <input class = "form-check-input"
                   type="checkbox"
                   name = "ageCertification"
                   checked = {activeRequestItem.ageCertification}
                   id="ageTAndCs"
                   onChange = {handleFormChange}
                   ></input>
                  I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy
                </label>
              </div>

              <div class = "col-sm-0 col-md-4"></div>

              <div class = "form-group col-sm-12 col-md-4">

                <button type="button" class = "btn btn-outline-primary submit-button-style" onClick={(e) =>
                  handleSubmit(e)}>
                  {/*confirmSignUpModal.setOpenConfirmSignUpModal(true)*/}
                  Submit
                </button>


              </div>

              <div class = "col-sm-0 col-md-4"></div>

            </div>

          </form>
        </div>
    </fragment>
  )

}
export function RequestInfoForm({setOpenConfirmSignUpModal}){

  // the fonfirm modal is currently set to false as default
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const confirmSignUpModal                = useConfirmSignUpModal();

  return(
    <fragment>

        {console.log('RequestInfoForm Submit button not clicked' +confirmSubmit)}
        <ConfirmSignUpModal {...confirmSignUpModal}/>

        <div class = "container">
          <form class = "form request-info-form">

            <div class = "form-row">
              <div class = "form-group col-sm-12 col-md-6 align-items-md-end">
                <label class = "nameDetails" for = "firstName">
                  <input class="form-control form-control-sm form-field " type = "text" if = "firstName" placeholder = "First Name"></input>
                </label>
              </div>

              <div class = "form-group col-sm-12 col-md-6" >
                <label class = "nameDetails" for = "lastName">
                  <input class = "form-control form-control-sm form-field" type = "text" if = "lastName" placeholder = "Last Name"></input>
                </label>
              </div>


              <div class = "form-group col-sm-12 col-md-6">
                <label class = "adressDetails" for="email">
                  <input class = "form-control form-control-sm form-field" type="email" id="email" placeholder="Email address"></input>
                </label>
              </div>


              <div class = "form-group col-sm-12 col-md-6">
                <label class = "adressDetails" for="phone">
                  <input class = "form-control form-control-sm form-field" type="tel" id="phone" placeholder="Phone Number"></input>
                </label>
              </div>


              <div class = "form-group col-sm-12 col-md-6">
                <label>
                <select class = "form-control form-control-sm form-field">
                  {countryList.map((countrySelect) =>
                    <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                  )}
                </select>
                </label>
              </div>

              <div class = "form-group col-sm-12 col-md-6" >
                <label class = "cityDetails" for = "city">
                  <input name = "city" class = "form-control form-control-sm form-field" type = "text" if = "city" placeholder = "City"></input>
                </label>
              </div>

              <span>
                <p>How can we help</p>
                  <div class = "form-group col-sm-12 col-md-12 ">
                    <label class = "requestDetails" for="message">
                      <textarea class = "textarea medium form-field " rows="10" cols="75" id="message" placeholder="I'm looking for a wine tour in the Rhone" aria-required="true" aria-invalid="false"></textarea>
                    </label>
                  </div>
              </span>


              <div class = "form-group col-sm-12 col-md-12">
                <label>
                <select  name = "howDidYouHear" class = "form-control form-control-sm form-field">
                 <option value="">How did you hear about The Real Grape?</option>
                  {howDidYouHearList.map((sourceSelect) =>
                    <option value = {sourceSelect.optionValue}>{sourceSelect.source} </option>
                  )}
                </select>
                </label>
              </div>

              <div class = "form-check col-sm-12 col-md-12">
                <label class = "form-check-label mr-2">
                   <input class = "form-check-input" type="checkbox" id="ageTAndCs"></input>
                  I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy
                </label>
              </div>

              <div class = "col-sm-0 col-md-4"></div>

              <div class = "form-group col-sm-12 col-md-4">
                {console.log('The open confirm hook is', confirmSignUpModal.openConfirmSignUpModal)}
                <button type="button" class = "btn btn-outline-primary submit-button-style" onClick={() =>
                  confirmSignUpModal.setOpenConfirmSignUpModal(true)}>
                  Submit
                </button>


              </div>

              <div class = "col-sm-0 col-md-4"></div>

            </div>

          </form>
        </div>
    </fragment>
  )

}

export function RequestInfo(){
  return(
    <fragment>
      <div class = "container">
        <form class = "form request-info-form">
          <div class = "form-row">
            <div class = "form-group col-sm-12 col-md-6 align-items-md-end">
              <label class = "nameDetails" for = "firstName">
                <input class="form-control form-control-sm form-field " type = "text" if = "firstName" placeholder = "First Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6" >
              <label class = "nameDetails" for = "lastName">
                <input class = "form-control form-control-sm form-field" type = "text" if = "lastName" placeholder = "Last Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="email">
                <input class = "form-control form-control-sm form-field" type="email" id="email" placeholder="Email address"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="phone">
                <input class = "form-control form-control-sm form-field" type="tel" id="phone" placeholder="Phone Number"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-12">
              <label>
              <select class = "form-control form-control-sm form-field">
    	<option value="AF">Afghanistan</option>
    	<option value="AX">Åland Islands</option>
    	<option value="AL">Albania</option>
    	<option value="DZ">Algeria</option>
    	<option value="AS">American Samoa</option>
    	<option value="AD">Andorra</option>
    	<option value="AO">Angola</option>
    	<option value="AI">Anguilla</option>
    	<option value="AQ">Antarctica</option>
    	<option value="AG">Antigua and Barbuda</option>
    	<option value="AR">Argentina</option>
    	<option value="AM">Armenia</option>
    	<option value="AW">Aruba</option>
    	<option value="AU">Australia</option>
    	<option value="AT">Austria</option>
    	<option value="AZ">Azerbaijan</option>
    	<option value="BS">Bahamas</option>
    	<option value="BH">Bahrain</option>
    	<option value="BD">Bangladesh</option>
    	<option value="BB">Barbados</option>
    	<option value="BY">Belarus</option>
    	<option value="BE">Belgium</option>
    	<option value="BZ">Belize</option>
    	<option value="BJ">Benin</option>
    	<option value="BM">Bermuda</option>
    	<option value="BT">Bhutan</option>
    	<option value="BO">Bolivia, Plurinational State of</option>
    	<option value="BQ">Bonaire, Sint Eustatius and Saba</option>
    	<option value="BA">Bosnia and Herzegovina</option>
    	<option value="BW">Botswana</option>
    	<option value="BV">Bouvet Island</option>
    	<option value="BR">Brazil</option>
    	<option value="IO">British Indian Ocean Territory</option>
    	<option value="BN">Brunei Darussalam</option>
    	<option value="BG">Bulgaria</option>
    	<option value="BF">Burkina Faso</option>
    	<option value="BI">Burundi</option>
    	<option value="KH">Cambodia</option>
    	<option value="CM">Cameroon</option>
    	<option value="CA">Canada</option>
    	<option value="CV">Cape Verde</option>
    	<option value="KY">Cayman Islands</option>
    	<option value="CF">Central African Republic</option>
    	<option value="TD">Chad</option>
    	<option value="CL">Chile</option>
    	<option value="CN">China</option>
    	<option value="CX">Christmas Island</option>
    	<option value="CC">Cocos (Keeling) Islands</option>
    	<option value="CO">Colombia</option>
    	<option value="KM">Comoros</option>
    	<option value="CG">Congo</option>
    	<option value="CD">Congo, the Democratic Republic of the</option>
    	<option value="CK">Cook Islands</option>
    	<option value="CR">Costa Rica</option>
    	<option value="CI">Côte d'Ivoire</option>
    	<option value="HR">Croatia</option>
    	<option value="CU">Cuba</option>
    	<option value="CW">Curaçao</option>
    	<option value="CY">Cyprus</option>
    	<option value="CZ">Czech Republic</option>
    	<option value="DK">Denmark</option>
    	<option value="DJ">Djibouti</option>
    	<option value="DM">Dominica</option>
    	<option value="DO">Dominican Republic</option>
    	<option value="EC">Ecuador</option>
    	<option value="EG">Egypt</option>
    	<option value="SV">El Salvador</option>
    	<option value="GQ">Equatorial Guinea</option>
    	<option value="ER">Eritrea</option>
    	<option value="EE">Estonia</option>
    	<option value="ET">Ethiopia</option>
    	<option value="FK">Falkland Islands (Malvinas)</option>
    	<option value="FO">Faroe Islands</option>
    	<option value="FJ">Fiji</option>
    	<option value="FI">Finland</option>
    	<option value="FR">France</option>
    	<option value="GF">French Guiana</option>
    	<option value="PF">French Polynesia</option>
    	<option value="TF">French Southern Territories</option>
    	<option value="GA">Gabon</option>
    	<option value="GM">Gambia</option>
    	<option value="GE">Georgia</option>
    	<option value="DE">Germany</option>
    	<option value="GH">Ghana</option>
    	<option value="GI">Gibraltar</option>
    	<option value="GR">Greece</option>
    	<option value="GL">Greenland</option>
    	<option value="GD">Grenada</option>
    	<option value="GP">Guadeloupe</option>
    	<option value="GU">Guam</option>
    	<option value="GT">Guatemala</option>
    	<option value="GG">Guernsey</option>
    	<option value="GN">Guinea</option>
    	<option value="GW">Guinea-Bissau</option>
    	<option value="GY">Guyana</option>
    	<option value="HT">Haiti</option>
    	<option value="HM">Heard Island and McDonald Islands</option>
    	<option value="VA">Holy See (Vatican City State)</option>
    	<option value="HN">Honduras</option>
    	<option value="HK">Hong Kong</option>
    	<option value="HU">Hungary</option>
    	<option value="IS">Iceland</option>
    	<option value="IN">India</option>
    	<option value="ID">Indonesia</option>
    	<option value="IR">Iran, Islamic Republic of</option>
    	<option value="IQ">Iraq</option>
    	<option value="IE">Ireland</option>
    	<option value="IM">Isle of Man</option>
    	<option value="IL">Israel</option>
    	<option value="IT">Italy</option>
    	<option value="JM">Jamaica</option>
    	<option value="JP">Japan</option>
    	<option value="JE">Jersey</option>
    	<option value="JO">Jordan</option>
    	<option value="KZ">Kazakhstan</option>
    	<option value="KE">Kenya</option>
    	<option value="KI">Kiribati</option>
    	<option value="KP">Korea, Democratic People's Republic of</option>
    	<option value="KR">Korea, Republic of</option>
    	<option value="KW">Kuwait</option>
    	<option value="KG">Kyrgyzstan</option>
    	<option value="LA">Lao People's Democratic Republic</option>
    	<option value="LV">Latvia</option>
    	<option value="LB">Lebanon</option>
    	<option value="LS">Lesotho</option>
    	<option value="LR">Liberia</option>
    	<option value="LY">Libya</option>
    	<option value="LI">Liechtenstein</option>
    	<option value="LT">Lithuania</option>
    	<option value="LU">Luxembourg</option>
    	<option value="MO">Macao</option>
    	<option value="MK">Macedonia, the former Yugoslav Republic of</option>
    	<option value="MG">Madagascar</option>
    	<option value="MW">Malawi</option>
    	<option value="MY">Malaysia</option>
    	<option value="MV">Maldives</option>
    	<option value="ML">Mali</option>
    	<option value="MT">Malta</option>
    	<option value="MH">Marshall Islands</option>
    	<option value="MQ">Martinique</option>
    	<option value="MR">Mauritania</option>
    	<option value="MU">Mauritius</option>
    	<option value="YT">Mayotte</option>
    	<option value="MX">Mexico</option>
    	<option value="FM">Micronesia, Federated States of</option>
    	<option value="MD">Moldova, Republic of</option>
    	<option value="MC">Monaco</option>
    	<option value="MN">Mongolia</option>
    	<option value="ME">Montenegro</option>
    	<option value="MS">Montserrat</option>
    	<option value="MA">Morocco</option>
    	<option value="MZ">Mozambique</option>
    	<option value="MM">Myanmar</option>
    	<option value="NA">Namibia</option>
    	<option value="NR">Nauru</option>
    	<option value="NP">Nepal</option>
    	<option value="NL">Netherlands</option>
    	<option value="NC">New Caledonia</option>
    	<option value="NZ">New Zealand</option>
    	<option value="NI">Nicaragua</option>
    	<option value="NE">Niger</option>
    	<option value="NG">Nigeria</option>
    	<option value="NU">Niue</option>
    	<option value="NF">Norfolk Island</option>
    	<option value="MP">Northern Mariana Islands</option>
    	<option value="NO">Norway</option>
    	<option value="OM">Oman</option>
    	<option value="PK">Pakistan</option>
    	<option value="PW">Palau</option>
    	<option value="PS">Palestinian Territory, Occupied</option>
    	<option value="PA">Panama</option>
    	<option value="PG">Papua New Guinea</option>
    	<option value="PY">Paraguay</option>
    	<option value="PE">Peru</option>
    	<option value="PH">Philippines</option>
    	<option value="PN">Pitcairn</option>
    	<option value="PL">Poland</option>
    	<option value="PT">Portugal</option>
    	<option value="PR">Puerto Rico</option>
    	<option value="QA">Qatar</option>
    	<option value="RE">Réunion</option>
    	<option value="RO">Romania</option>
    	<option value="RU">Russian Federation</option>
    	<option value="RW">Rwanda</option>
    	<option value="BL">Saint Barthélemy</option>
    	<option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
    	<option value="KN">Saint Kitts and Nevis</option>
    	<option value="LC">Saint Lucia</option>
    	<option value="MF">Saint Martin (French part)</option>
    	<option value="PM">Saint Pierre and Miquelon</option>
    	<option value="VC">Saint Vincent and the Grenadines</option>
    	<option value="WS">Samoa</option>
    	<option value="SM">San Marino</option>
    	<option value="ST">Sao Tome and Principe</option>
    	<option value="SA">Saudi Arabia</option>
    	<option value="SN">Senegal</option>
    	<option value="RS">Serbia</option>
    	<option value="SC">Seychelles</option>
    	<option value="SL">Sierra Leone</option>
    	<option value="SG">Singapore</option>
    	<option value="SX">Sint Maarten (Dutch part)</option>
    	<option value="SK">Slovakia</option>
    	<option value="SI">Slovenia</option>
    	<option value="SB">Solomon Islands</option>
    	<option value="SO">Somalia</option>
    	<option value="ZA">South Africa</option>
    	<option value="GS">South Georgia and the South Sandwich Islands</option>
    	<option value="SS">South Sudan</option>
    	<option value="ES">Spain</option>
    	<option value="LK">Sri Lanka</option>
    	<option value="SD">Sudan</option>
    	<option value="SR">Suriname</option>
    	<option value="SJ">Svalbard and Jan Mayen</option>
    	<option value="SZ">Swaziland</option>
    	<option value="SE">Sweden</option>
    	<option value="CH">Switzerland</option>
    	<option value="SY">Syrian Arab Republic</option>
    	<option value="TW">Taiwan, Province of China</option>
    	<option value="TJ">Tajikistan</option>
    	<option value="TZ">Tanzania, United Republic of</option>
    	<option value="TH">Thailand</option>
    	<option value="TL">Timor-Leste</option>
    	<option value="TG">Togo</option>
    	<option value="TK">Tokelau</option>
    	<option value="TO">Tonga</option>
    	<option value="TT">Trinidad and Tobago</option>
    	<option value="TN">Tunisia</option>
    	<option value="TR">Turkey</option>
    	<option value="TM">Turkmenistan</option>
    	<option value="TC">Turks and Caicos Islands</option>
    	<option value="TV">Tuvalu</option>
    	<option value="UG">Uganda</option>
    	<option value="UA">Ukraine</option>
    	<option value="AE">United Arab Emirates</option>
    	<option value="GB">United Kingdom</option>
    	<option value="US">United States</option>
    	<option value="UM">United States Minor Outlying Islands</option>
    	<option value="UY">Uruguay</option>
    	<option value="UZ">Uzbekistan</option>
    	<option value="VU">Vanuatu</option>
    	<option value="VE">Venezuela, Bolivarian Republic of</option>
    	<option value="VN">Viet Nam</option>
    	<option value="VG">Virgin Islands, British</option>
    	<option value="VI">Virgin Islands, U.S.</option>
    	<option value="WF">Wallis and Futuna</option>
    	<option value="EH">Western Sahara</option>
    	<option value="YE">Yemen</option>
    	<option value="ZM">Zambia</option>
    	<option value="ZW">Zimbabwe</option>
              </select>
              </label>
            </div>


            <span>
              <p>How can we help</p>
                <div class = "form-group col-sm-12 col-md-12 ">
                  <label class = "requestDetails" for="message">
                    <textarea class = "textarea medium form-field " rows="10" cols="75" id="message" placeholder="I'm looking for a wine tour in the Rhone" aria-required="true" aria-invalid="false"></textarea>
                  </label>
                </div>
            </span>



            <div class = "form-check col-sm-12 col-md-12">
              <label class = "form-check-label mr-2">
                 <input class = "form-check-input" type="checkbox" id="ageTAndCs"></input>
                I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy
              </label>
            </div>

            <div class = "col-sm-0 col-md-4"></div>
            <div class = "form-group col-sm-12 col-md-4">

              <button type="submit" class = "btn btn-primary btn-block">Submit</button>
            </div>
            <div class = "col-sm-0 col-md-4"></div>
          </div>
        </form>
      </div>
    </fragment>
  )
}

export function RequestInfo_(){

  return(
    <fragment>
      <div class = "container">
        <h1>Info Form</h1>
        <div class = "flex-outer">

          <label for = "firstName">
            <input type = "text" if = "firstName" placeholder = "First Name"></input>
          </label>

          <label for = "lastName">
            <input type = "text" if = "lastName" placeholder = "Last Name"></input>
          </label>

          <label for="email">
            <input type="email" id="email" placeholder="Email address"></input>
          </label>

          <label for="phone">
            <input type="tel" id="phone" placeholder="Phone Number"></input>
          </label>
          <label>
          <select>
	<option value="AF">Afghanistan</option>
	<option value="AX">Åland Islands</option>
	<option value="AL">Albania</option>
	<option value="DZ">Algeria</option>
	<option value="AS">American Samoa</option>
	<option value="AD">Andorra</option>
	<option value="AO">Angola</option>
	<option value="AI">Anguilla</option>
	<option value="AQ">Antarctica</option>
	<option value="AG">Antigua and Barbuda</option>
	<option value="AR">Argentina</option>
	<option value="AM">Armenia</option>
	<option value="AW">Aruba</option>
	<option value="AU">Australia</option>
	<option value="AT">Austria</option>
	<option value="AZ">Azerbaijan</option>
	<option value="BS">Bahamas</option>
	<option value="BH">Bahrain</option>
	<option value="BD">Bangladesh</option>
	<option value="BB">Barbados</option>
	<option value="BY">Belarus</option>
	<option value="BE">Belgium</option>
	<option value="BZ">Belize</option>
	<option value="BJ">Benin</option>
	<option value="BM">Bermuda</option>
	<option value="BT">Bhutan</option>
	<option value="BO">Bolivia, Plurinational State of</option>
	<option value="BQ">Bonaire, Sint Eustatius and Saba</option>
	<option value="BA">Bosnia and Herzegovina</option>
	<option value="BW">Botswana</option>
	<option value="BV">Bouvet Island</option>
	<option value="BR">Brazil</option>
	<option value="IO">British Indian Ocean Territory</option>
	<option value="BN">Brunei Darussalam</option>
	<option value="BG">Bulgaria</option>
	<option value="BF">Burkina Faso</option>
	<option value="BI">Burundi</option>
	<option value="KH">Cambodia</option>
	<option value="CM">Cameroon</option>
	<option value="CA">Canada</option>
	<option value="CV">Cape Verde</option>
	<option value="KY">Cayman Islands</option>
	<option value="CF">Central African Republic</option>
	<option value="TD">Chad</option>
	<option value="CL">Chile</option>
	<option value="CN">China</option>
	<option value="CX">Christmas Island</option>
	<option value="CC">Cocos (Keeling) Islands</option>
	<option value="CO">Colombia</option>
	<option value="KM">Comoros</option>
	<option value="CG">Congo</option>
	<option value="CD">Congo, the Democratic Republic of the</option>
	<option value="CK">Cook Islands</option>
	<option value="CR">Costa Rica</option>
	<option value="CI">Côte d'Ivoire</option>
	<option value="HR">Croatia</option>
	<option value="CU">Cuba</option>
	<option value="CW">Curaçao</option>
	<option value="CY">Cyprus</option>
	<option value="CZ">Czech Republic</option>
	<option value="DK">Denmark</option>
	<option value="DJ">Djibouti</option>
	<option value="DM">Dominica</option>
	<option value="DO">Dominican Republic</option>
	<option value="EC">Ecuador</option>
	<option value="EG">Egypt</option>
	<option value="SV">El Salvador</option>
	<option value="GQ">Equatorial Guinea</option>
	<option value="ER">Eritrea</option>
	<option value="EE">Estonia</option>
	<option value="ET">Ethiopia</option>
	<option value="FK">Falkland Islands (Malvinas)</option>
	<option value="FO">Faroe Islands</option>
	<option value="FJ">Fiji</option>
	<option value="FI">Finland</option>
	<option value="FR">France</option>
	<option value="GF">French Guiana</option>
	<option value="PF">French Polynesia</option>
	<option value="TF">French Southern Territories</option>
	<option value="GA">Gabon</option>
	<option value="GM">Gambia</option>
	<option value="GE">Georgia</option>
	<option value="DE">Germany</option>
	<option value="GH">Ghana</option>
	<option value="GI">Gibraltar</option>
	<option value="GR">Greece</option>
	<option value="GL">Greenland</option>
	<option value="GD">Grenada</option>
	<option value="GP">Guadeloupe</option>
	<option value="GU">Guam</option>
	<option value="GT">Guatemala</option>
	<option value="GG">Guernsey</option>
	<option value="GN">Guinea</option>
	<option value="GW">Guinea-Bissau</option>
	<option value="GY">Guyana</option>
	<option value="HT">Haiti</option>
	<option value="HM">Heard Island and McDonald Islands</option>
	<option value="VA">Holy See (Vatican City State)</option>
	<option value="HN">Honduras</option>
	<option value="HK">Hong Kong</option>
	<option value="HU">Hungary</option>
	<option value="IS">Iceland</option>
	<option value="IN">India</option>
	<option value="ID">Indonesia</option>
	<option value="IR">Iran, Islamic Republic of</option>
	<option value="IQ">Iraq</option>
	<option value="IE">Ireland</option>
	<option value="IM">Isle of Man</option>
	<option value="IL">Israel</option>
	<option value="IT">Italy</option>
	<option value="JM">Jamaica</option>
	<option value="JP">Japan</option>
	<option value="JE">Jersey</option>
	<option value="JO">Jordan</option>
	<option value="KZ">Kazakhstan</option>
	<option value="KE">Kenya</option>
	<option value="KI">Kiribati</option>
	<option value="KP">Korea, Democratic People's Republic of</option>
	<option value="KR">Korea, Republic of</option>
	<option value="KW">Kuwait</option>
	<option value="KG">Kyrgyzstan</option>
	<option value="LA">Lao People's Democratic Republic</option>
	<option value="LV">Latvia</option>
	<option value="LB">Lebanon</option>
	<option value="LS">Lesotho</option>
	<option value="LR">Liberia</option>
	<option value="LY">Libya</option>
	<option value="LI">Liechtenstein</option>
	<option value="LT">Lithuania</option>
	<option value="LU">Luxembourg</option>
	<option value="MO">Macao</option>
	<option value="MK">Macedonia, the former Yugoslav Republic of</option>
	<option value="MG">Madagascar</option>
	<option value="MW">Malawi</option>
	<option value="MY">Malaysia</option>
	<option value="MV">Maldives</option>
	<option value="ML">Mali</option>
	<option value="MT">Malta</option>
	<option value="MH">Marshall Islands</option>
	<option value="MQ">Martinique</option>
	<option value="MR">Mauritania</option>
	<option value="MU">Mauritius</option>
	<option value="YT">Mayotte</option>
	<option value="MX">Mexico</option>
	<option value="FM">Micronesia, Federated States of</option>
	<option value="MD">Moldova, Republic of</option>
	<option value="MC">Monaco</option>
	<option value="MN">Mongolia</option>
	<option value="ME">Montenegro</option>
	<option value="MS">Montserrat</option>
	<option value="MA">Morocco</option>
	<option value="MZ">Mozambique</option>
	<option value="MM">Myanmar</option>
	<option value="NA">Namibia</option>
	<option value="NR">Nauru</option>
	<option value="NP">Nepal</option>
	<option value="NL">Netherlands</option>
	<option value="NC">New Caledonia</option>
	<option value="NZ">New Zealand</option>
	<option value="NI">Nicaragua</option>
	<option value="NE">Niger</option>
	<option value="NG">Nigeria</option>
	<option value="NU">Niue</option>
	<option value="NF">Norfolk Island</option>
	<option value="MP">Northern Mariana Islands</option>
	<option value="NO">Norway</option>
	<option value="OM">Oman</option>
	<option value="PK">Pakistan</option>
	<option value="PW">Palau</option>
	<option value="PS">Palestinian Territory, Occupied</option>
	<option value="PA">Panama</option>
	<option value="PG">Papua New Guinea</option>
	<option value="PY">Paraguay</option>
	<option value="PE">Peru</option>
	<option value="PH">Philippines</option>
	<option value="PN">Pitcairn</option>
	<option value="PL">Poland</option>
	<option value="PT">Portugal</option>
	<option value="PR">Puerto Rico</option>
	<option value="QA">Qatar</option>
	<option value="RE">Réunion</option>
	<option value="RO">Romania</option>
	<option value="RU">Russian Federation</option>
	<option value="RW">Rwanda</option>
	<option value="BL">Saint Barthélemy</option>
	<option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
	<option value="KN">Saint Kitts and Nevis</option>
	<option value="LC">Saint Lucia</option>
	<option value="MF">Saint Martin (French part)</option>
	<option value="PM">Saint Pierre and Miquelon</option>
	<option value="VC">Saint Vincent and the Grenadines</option>
	<option value="WS">Samoa</option>
	<option value="SM">San Marino</option>
	<option value="ST">Sao Tome and Principe</option>
	<option value="SA">Saudi Arabia</option>
	<option value="SN">Senegal</option>
	<option value="RS">Serbia</option>
	<option value="SC">Seychelles</option>
	<option value="SL">Sierra Leone</option>
	<option value="SG">Singapore</option>
	<option value="SX">Sint Maarten (Dutch part)</option>
	<option value="SK">Slovakia</option>
	<option value="SI">Slovenia</option>
	<option value="SB">Solomon Islands</option>
	<option value="SO">Somalia</option>
	<option value="ZA">South Africa</option>
	<option value="GS">South Georgia and the South Sandwich Islands</option>
	<option value="SS">South Sudan</option>
	<option value="ES">Spain</option>
	<option value="LK">Sri Lanka</option>
	<option value="SD">Sudan</option>
	<option value="SR">Suriname</option>
	<option value="SJ">Svalbard and Jan Mayen</option>
	<option value="SZ">Swaziland</option>
	<option value="SE">Sweden</option>
	<option value="CH">Switzerland</option>
	<option value="SY">Syrian Arab Republic</option>
	<option value="TW">Taiwan, Province of China</option>
	<option value="TJ">Tajikistan</option>
	<option value="TZ">Tanzania, United Republic of</option>
	<option value="TH">Thailand</option>
	<option value="TL">Timor-Leste</option>
	<option value="TG">Togo</option>
	<option value="TK">Tokelau</option>
	<option value="TO">Tonga</option>
	<option value="TT">Trinidad and Tobago</option>
	<option value="TN">Tunisia</option>
	<option value="TR">Turkey</option>
	<option value="TM">Turkmenistan</option>
	<option value="TC">Turks and Caicos Islands</option>
	<option value="TV">Tuvalu</option>
	<option value="UG">Uganda</option>
	<option value="UA">Ukraine</option>
	<option value="AE">United Arab Emirates</option>
	<option value="GB">United Kingdom</option>
	<option value="US">United States</option>
	<option value="UM">United States Minor Outlying Islands</option>
	<option value="UY">Uruguay</option>
	<option value="UZ">Uzbekistan</option>
	<option value="VU">Vanuatu</option>
	<option value="VE">Venezuela, Bolivarian Republic of</option>
	<option value="VN">Viet Nam</option>
	<option value="VG">Virgin Islands, British</option>
	<option value="VI">Virgin Islands, U.S.</option>
	<option value="WF">Wallis and Futuna</option>
	<option value="EH">Western Sahara</option>
	<option value="YE">Yemen</option>
	<option value="ZM">Zambia</option>
	<option value="ZW">Zimbabwe</option>
</select>
          </label>
          <span>
            <p>Message</p>
              <label for="message">
                <textarea rows="6" id="message" placeholder="I'm looking for a wine tour in the Rhone"></textarea>
              </label>
          </span>

          <label>
             <input type="checkbox" id="ageTAndCs"></input>
            I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy
          </label>

          <button type="submit">Submit</button>



        </div>
      </div>
    </fragment>
  )
}

//
export function FormError({openFormErrorModal, setOpenFormErrorModal, errorMsg,setErrorMsg}){
  return openFormErrorModal ? <>
      <div class = "container">
        <div class = "confirm-sign-up-modal" id = "fomModal">


          <div class="modal-dialog">
            <div class = "modal-content">
              <div class = "modal-header">
                <div class = "modal-title"> Form has errors</div>
                <button type="button" class= "close" data-dismiss="modal" onClick={() => setOpenFormErrorModal(false) } >&times;</button>
              </div>
              <div class="modal-body">
                {errorMsg}
              </div>

            </div>
          </div>

        </div>
      </div>
   </> : <div/>
}

// modal that is activated when the sign up form is activated
export function ConfirmSignUpModal({openConfirmSignUpModal, setOpenConfirmSignUpModal}){

  return openConfirmSignUpModal ? <>
  <div class = "container">
    <div class = "confirm-sign-up-modal" id = "formModal">
      <div class = "modal-dialog">
        <div class="modal-content">
          <div class ="modal-header">
            <div class = "modal-title"> Thank you for signing-up</div>
            <button type="button" class= "close" data-dismiss="modal" onClick={() => setOpenConfirmSignUpModal(false)} >&times;</button>
          </div>
          <div class="modal-body">
            <div class = "row">

              <div class = "col-xs-0 col-sm-0 col-md-12 col-lg-12 modal-col-img">
                <div class = "media">
                  <img class =" img-centered" src = {WineToast}  alt = "Wine Toast at the beach"/>
                </div>
              </div>

              <div class = "col-sm-12 col-md-12 col-lg-12 ">
                <div class = "offering-copy">
                  Thank you for signing up. Please follow us on social media.
                </div>
              </div>
              <div class = "col-sm-12 col-md-12 col-lg-12 ">
              <div class = "social-media-links">
                <ul class = "social-media-list">
                  <li class = "social-media_item">

                      <a class = "social-media_link" href="https://www.instagram.com/therealgrape">
                        <i class="fab fa-instagram large" />
                      </a>


                  </li>
                  <li class = "social-media_item" >

                      <a class = "social-media_link" href="https://soundcloud.com/user-57306623">
                        <i class="fab fa-soundcloud"></i>
                      </a>


                  </li>
                  <li class = "social-media_item">

                      <a class = "social-media_link" href="https://www.facebook.com/TheRealGrapeTravel/">
                        <i class="fab fa-facebook-f large" ></i>
                      </a>


                  </li>
                </ul>
              </div>
              </div>

            </div>

          </div>
          <div class="modal-footer">


          </div>
        </div>
      </div>
    </div>

    {console.log("modal clicked")}


    </div>
  </> : <div/>

}

// add to the properties the hook to set the openning of the confirm submit
export function SignUpForm({setOpenConfirmSignUpModal}){

  // the confirm modal is currently set to false
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const confirmSignUpModal = useConfirmSignUpModal();


  return(
    <fragment>

      {console.log('Submit button not yet clicked ' + confirmSubmit)}

      <ConfirmSignUpModal {...confirmSignUpModal}/>

      <div class = "container">
        <form class = "form sign-up-form">
          <div class = "form-row">
            <div class = "form-group col-sm-12 col-md-6 align-items-md-end">
              <label class = "nameDetails" for = "firstName">
                <input class="form-control form-control-sm form-field " type = "text" if = "firstName" placeholder = "First Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6" >
              <label class = "nameDetails" for = "lastName">
                <input class = "form-control form-control-sm form-field" type = "text" if = "lastName" placeholder = "Last Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="email">
                <input class = "form-control form-control-sm form-field" type="email" id="email" placeholder="Email address"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="phone">
                <input class = "form-control form-control-sm form-field" type="tel" id="phone" placeholder="Phone Number"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-12">
              <label>
              <select class = "form-control form-control-sm form-field">
                {countryList.map((countrySelect) =>
                  <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                )}
              </select>
              </label>
            </div>

            <div class = "form-check col-sm-12 col-md-12">
              <label class = "form-check-label mr-2">
                 <input class = "form-check-input" type="checkbox" id="ageTAndCs"></input>
                I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy
              </label>
            </div>

            <div class = "col-sm-0 col-md-4"></div>
            <div class = "form-group col-sm-12 col-md-4">
            {console.log('The open confirm hook is', confirmSignUpModal.openConfirmSignUpModal)}
              <button type="button" class = "btn btn-primary btn-block" onClick={() =>
                confirmSignUpModal.setOpenConfirmSignUpModal(true)}>
                Submit
              </button>
            </div>
            <div class = "col-sm-0 col-md-4"></div>
          </div>
        </form>


      </div>
    </fragment>
  )
}

// add to the properties the hook to set the openning of the confirm submit
export function SignUpFormInterfaced({setOpenConfirmSignUpModal}){

  // the confirm modal is currently set to false
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const confirmSignUpModal = useConfirmSignUpModal();

  // form validation and errors
  const [validRequestForm, setValidRequestForm] = useState(true);
  const formErrorModal                    = useFormErrorModal();
  const formErrorMsg                      = useFormErrorMsg()


  const [editing, setEditing]   = useState(false);
  const [todoList, setTodoList] = useState([]);
  const [signupList, setSignupList] = useState([]);
  const [activeItem, setActiveItem] =  useState({
    id:null,
    title: '',
    completed:false
  });

  const [activeSignUp, setActiveSignUp] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "countrySelect": "",
        "city": "",
        "howDidYouHear": "",
        "ageCertification": false
    })

  useEffect(() =>
    {
        // do nothing for the moment
        //fetchTasks();

    }, []);
    //});

    // cookies with ajax
    // documentation at
    // https://docs.djangoproject.com/en/3.1/ref/csrf/
    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
  }



  function fetchTasks(){
    console.log('Task interface, fetching ...')
    /*fetch api data and convert to json*/

    /*CORS needs to be enabled to get the data*/
    /*enable django-cors-header
      A Django App that adds Cross-Origin Resource Sharing (CORS) headers to responses.
      This allows in-browser requests to your Django application from other origins.
    */

    fetch('https://real-grape-site-us.herokuapp.com/api/task-list/')
    .then(response => response.json())
    .then(data => setTodoList(data))
    //.then((response) =>setTodoList(response.json))
    //.then(data =>
      ////console.log('Data:', typeof(data))
      //setTodoList(data)
    //)
    fetch('https://real-grape-site-us.herokuapp.com/api/signup-list/')
    .then(response => response.json())
    .then(signUpData =>
      setSignupList(signUpData)
    )
    .then(signUpData =>
      console.log('Signup Data:', signUpData)
    )

    // set states to be that of the retireved data

    console.log('Tasklist: ' + todoList);
  }

  function handleTaskChange(e){
    var name  = e.target.name
    var value = e.target.value
    console.log('Name: ' + name)
    console.log('Value: ' + value)

    setActiveItem(
      {
          // this will only change the items that are specifically referenced
          ...activeItem,
          title:value
      })

  }

  function handleTaskSubmit(e) {
    //console.log('Sign Up Submitted')
    // firstline is so we can manually submit the form
    e.preventDefault()
    console.log('ITEM:', activeItem)

    // get a cookie that we will pass through the header
    const csrftoken = getCookie('csrftoken');

    var url = 'https://real-grape-site-us.herokuapp.com/api/task-create/'

    // if we are in the editing process we need a different url route to update
    if(editing== true) {
      url = `https://real-grape-site-us.herokuapp.com/api/task-update/${activeItem.id}/`
      setEditing(false)
    }

    // we love the fetch API
    fetch(url, {
      method:'POST',
      headers:{
        'Content-type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body:JSON.stringify(activeItem)
    }).then((response) => {
        fetchTasks()
        // set the active time to the default
        setActiveItem({
          id:null,
          title: '',
          completed:false
        })
    }).catch(function(error){
      console.log('ERROR: ', error )
    })
  }

  function startTaskEdit(task){
    setEditing(true)
    setActiveItem(task)
  }

  function deleteTaskItem(task)
  {

    const csrftoken = getCookie('csrftoken');

    console.log("Task to delete:", task.id)
    fetch(`https://real-grape-site-us.herokuapp.com/api/task-delete/${task.id}`, {
      method: 'DELETE',
      'Content-type': 'application/json',
      'X-CSRFToken': csrftoken
    }).then((respnse) => {
      fetchTasks()

    })


  }

  function handleFormChange(e) {

    // handle multiple form inputs
    // https://www.pluralsight.com/guides/handling-multiple-inputs-with-single-onchange-handler-react
    // console.log("Sign Up Form Change ", e.target.name)



    // const value = e.target.value
    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // target the form component that we want to change
    setActiveSignUp({
      ...activeSignUp,
      [e.target.name]: value
      /*"firstName": value,
      "lastName": value*/
    })


  }

  function handleFormValidation() {


       setValidRequestForm(true)
       var validRequest = true
       var errorCounter = 0
       const errMax     = 3;
       let formIsValid  = true
       let errors       = {}

       // let's start with validating some fields
       if(activeSignUp['firstName'] === "")
       {


         formIsValid = false
         errors["firstName"] = "Please provide your first name";

         errorCounter += 1
         if(errorCounter < errMax){
            formErrorMsg.setErrorMsg("Please provide your first name")
            formErrorModal.setOpenFormErrorModal(true)
         }



         // regEx: /^[a-zA-Z]+$/,
         setValidRequestForm(false)
         /*
         validRequest = false
         alert("Please provide your first name")
         console.log("Firstname request form is valid: " , validRequestForm)
         */

       }

       if(typeof(activeSignUp['firstName']) !== "undefined"  ){
         if(!activeSignUp['firstName'].match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["firstName"] = "First name should be letters only";

            errorCounter += 1
            if(errorCounter < errMax){


              formErrorMsg.setErrorMsg("First name should be letters only")
              formErrorModal.setOpenFormErrorModal(true)


            }

         }
       }

       if(activeSignUp['lastName'] === "")
       {


         formIsValid = false
         errors["lastName"] = "Please provide your last name";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please provide your last name")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)

         /*
         validRequest = false
         alert("Please provide your last name")
         console.log("Lastname request form is valid: " , validRequestForm)*/

       }

       if(typeof(activeSignUp['lastName']) !== "undefined"  ){
         if(!activeSignUp['lastName'].match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["lastName"] = "Last name should be letters only";

            errorCounter += 1
            if(errorCounter < errMax){

              formErrorMsg.setErrorMsg("Last name should be letters only")
              formErrorModal.setOpenFormErrorModal(true)

            }


         }
       }


       if(activeSignUp['email'] === "")
       {

         formIsValid = false
         errors["email"] = "Please enter a valid email address";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please enter a valid email address")
           formErrorModal.setOpenFormErrorModal(true)


         }

         setValidRequestForm(false)

         /*
         validRequest = false
         alert("Please enter a valid email address")
         console.log("Email request form is valid: " , validRequestForm)
         */
       }


       if(typeof activeSignUp["email"] !== "undefined"){
          let lastAtPos = activeSignUp["email"].lastIndexOf('@');
          let lastDotPos = activeSignUp["email"].lastIndexOf('.');

          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && activeSignUp["email"].indexOf('@@') == -1 && lastDotPos > 2 && (activeSignUp["email"].length - lastDotPos) > 2)) {
             formIsValid = false;
             errors["email"] = "Email is not valid";

             errorCounter += 1
             if(errorCounter < errMax){


               formErrorMsg.setErrorMsg("Please enter a valid email address")
               formErrorModal.setOpenFormErrorModal(true)

             }
           }
      }


       if(activeSignUp['phone'] === "")
       {

         formIsValid = false
         errors["phone"] = "Please enter a valid phone number";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please enter a valid phone number")
           formErrorModal.setOpenFormErrorModal(true)

         }


         setValidRequestForm(false)

         /*
         validRequest = false
         alert("Please enter a valid phone number")
         console.log("Phone request form is valid: " , validRequestForm)*/

       }



       if(activeSignUp.countrySelect === "")
       {


         formIsValid = false
         errors["countrySelect"] = "Please select a country";


         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please select a country")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)

         /*validRequest = false
         console.log("Country request form is valid: " , validRequestForm)*/

       }

       if(activeSignUp['city'] === "")
       {

         formIsValid = false
         errors['city'] = "Please enter a city";


         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please enter a city")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)

         /*
         validRequest = false
         console.log("City request form is valid: " , validRequestForm)*/

       }

       if(typeof(activeSignUp['city']) !== "undefined"  ){
         if(!activeSignUp['city'].match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["city"] = "City should be letters only";


            errorCounter += 1
            if(errorCounter < errMax){

              formErrorMsg.setErrorMsg("City should be letters only")
              formErrorModal.setOpenFormErrorModal(true)

            }


         }
       }


       if(activeSignUp['howDidYouHear'] === "")
       {

         formIsValid = false
         errors['howDidYouHear'] = "Please let us know how you heard about The Real Grape";


         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please let us know how you heard about The Real Grape")
           formErrorModal.setOpenFormErrorModal(true)

         }

         setValidRequestForm(false)
         /*
         validRequest = false

         console.log("How did you request form is valid: " , validRequestForm)*/

       }

       if(activeSignUp['ageCertification'] === false)
       {

         formIsValid = false
         errors['ageCertification'] = "Please certify you are over the age of 18";

         errorCounter += 1
         if(errorCounter < errMax){

           formErrorMsg.setErrorMsg("Please certify you are over the age of 18")
           formErrorModal.setOpenFormErrorModal(true)
         }

          /*
         setValidRequestForm(false)
         validRequest = false
         console.log("Age request form is valid: " , validRequestForm)*/
       }


       /*console.log("The request form is valid: " , validRequestForm)*/
       // only allow the form to submit if data is valid
       //if(validRequestForm)

       return formIsValid;

  }


  function handleSubmit(e) {


    e.preventDefault()

    //e.preventDefault()
    //console.log('Signup:', activeSignUp)


    /*var url = 'http://127.0.0.1:8000/api/signup-create/'*/
    /* using the online endpoints for webservice*/
    // var url = 'https://real-grape-site-us.herokuapp.com/api/signup-create/'
    var url = "https://www.therealgrape.com/api/signup-create/"

    // get a cookie that we will pass through the headers
    const csrftoken = getCookie('csrftoken');

    // do minimal in form validation
    var isFormValid;
    isFormValid = handleFormValidation();

    if(isFormValid)
    {      // we love the fetch API
      // We use the post method to submit the form
      fetch(url, {
        method:'POST',
        headers:{
          'Content-type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body:JSON.stringify(activeSignUp)
        /*body:JSON.stringify(    {
          "firstName": "DaveZ",
          "lastName": "GrohlZ",
          "email": "dave.grohl2@foofighters.com",
          "phone": 28334502934,
          "countrySelect": "United Kingdom",
          "city": "London",
          "howDidYouHear": "Search Engine",
          "ageCertification": true
      })*/

      }).then((response) => {

          // display updated
          // we should delete this in future
          // fetchTasks()
          // console.log("Thanks for submitting", activeSignUp)
          setActiveSignUp({
                "firstName": "",
                "lastName": "",
                "email": "",
                "phone": "",
                "countrySelect": "",
                "city": "",
                "howDidYouHear": "",
                "ageCertification": false
            })
      }).catch(function(error){
        console.log('ERROR: ', error )
      })

      confirmSignUpModal.setOpenConfirmSignUpModal(true)
    }// END IF

  }




  return(
    <fragment>
      {/* Task Form */}
{ /*     <div class = "container">
        {console.log('task interface '+ activeItem )}
        <div id = "task-container">

          <div id = "form-wrapper" >
            <form id = "form" onSubmit={handleTaskSubmit}>
              <div class = "flex-wrapper">
                <div style = {{flex:6}}>
                  <input onChange = {handleTaskChange} value = {activeItem.title} class = "form-control" id = "title" type="text" name = "title" placeholder = "Add task.."/>
                </div>
                <div style ={{flex:1}}>
                  <input id = "submit" class = "btn btn-warning" type="submit" name = "Add" />
                </div>
              </div>
            </form>
          </div>

          <div id = "list-wrapper">
            {todoList.map(function(task, index){
              return(
                <div key = {index} class = "task-wrapper flex-wrapper">

                  <div style = {{flex:7}}>
                    <span> {task.title}</span>
                  </div>

                  <div style = {{flex:1}}>
                    <button onClick = {() => startTaskEdit(task) } class = "btn btn-sm btn-outline-info"> Edit </button>
                  </div>

                  <div style = {{flex:1}}>
                    <button onClick = {() =>  deleteTaskItem(task)} class = "btn btn-sm btn-outline-dark delete"> - </button>
                  </div>

                </div>
              )
            })}
          </div>

          <div id = "list-wrapper">
          {signupList.map(function(contact, index){
            return(
              <div key = {index} class = "task-wrapper flex-wrapper">
                <span> {contact.firstName}</span>
              </div>
            )
          })}
        </div>


        </div>
      </div> */}



      <ConfirmSignUpModal {...confirmSignUpModal}/>
      <FormError {...formErrorModal} {...formErrorMsg}/>

      <div class = "container">
        <form class = "form sign-up-form">
          <div class = "form-row">
            <div class = "form-group col-sm-12 col-md-6 align-items-md-end">
              <label class = "nameDetails" for = "firstName">
                <input onChange = {handleFormChange} value = {activeSignUp.firstName} name = "firstName" class="form-control form-control-sm form-field " type = "text" if = "firstName" placeholder = "First Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6" >
              <label class = "nameDetails" for = "lastName">
                <input onChange = {handleFormChange} value = {activeSignUp.lastName} name = "lastName" class = "form-control form-control-sm form-field" type = "text" if = "lastName" placeholder = "Last Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="email">
                <input onChange = {handleFormChange} value = {activeSignUp.email}  name = "email" class = "form-control form-control-sm form-field" type="email" id="email" placeholder="Email address"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="phone">
                <input onChange = {handleFormChange} value = {activeSignUp.phone} name = "phone" class = "form-control form-control-sm form-field" type="tel"  id="phone" placeholder="Phone Number"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label>
              <select  onChange = {handleFormChange} value = {activeSignUp.countrySelect} name = "countrySelect" class = "form-control form-control-sm form-field">
                <option value="">Country</option>
                {countryList.map((countrySelect) =>
                  <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                )}
              </select>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6" >
              <label class = "cityDetails" for = "city">
                <input onChange = {handleFormChange} value = {activeSignUp.city} name = "city" class = "form-control form-control-sm form-field" type = "text" if = "city" placeholder = "City"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-12">
              <label>
              <select onChange = {handleFormChange} value = {activeSignUp.howDidYouHear} name = "howDidYouHear" class = "form-control form-control-sm form-field">
               <option value="">How did you hear about The Real Grape?</option>
                {howDidYouHearList.map((sourceSelect) =>
                  <option value = {sourceSelect.optionValue}>{sourceSelect.source} </option>
                )}
              </select>
              </label>
            </div>




            <div class = "form-check col-sm-12 col-md-12">
              <label class = "form-check-label mr-2">
                 <input class = "form-check-input"
                  type="checkbox"
                  name = "ageCertification"
                  onChange = {handleFormChange}
                  checked = {activeSignUp.ageCertification}
                 id="ageTAndCs"></input>
                I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy
              </label>
            </div>

            <div class = "col-sm-0 col-md-4"></div>
            <div class = "form-group col-sm-12 col-md-4">

              <button type="submit" class = "btn btn-outline-primary submit-button-style" onClick={(e) =>
                 handleSubmit(e)}>
                {/*confirmSignUpModal.setOpenConfirmSignUpModal(true)*/}
                SUBMIT
              </button>
            </div>
            <div class = "col-sm-0 col-md-4"></div>
          </div>
        </form>


      </div>
    </fragment>
  )
}


export class SignUpFormFooter extends React.Component{
  // the constructor function
  constructor(props) {
    super(props);
    this.state = {
      firstNameInput: "Zimmi"
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  // handle inpt change
  handleInputChange(event){
    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name;

    this.setState({
      [name]: value
    });
  }
  // handle state changes on the form -- event driven
  //handleChange(event) {

  //}

  // handle the submission of the form -- event driven
  makeAlert(message) {
    //alert(message);
    return(
      <div class = "modal" id = "formModal">
        <div class = "modal-dialog">
          <div class="modal-content">
            <div class ="modal-header">
              <h5 class = "modal-title"> Modal Title </h5>
              <button class= "close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
              A bit of a modal story
            </div>
            <div class = "modal-footer">
              <button class = "btn btn-secondary" data-dismiss="modal"> Close </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSubmit(event){
    alert("Submission Detected");
    this.makeAlert("this has been a subit");


      event.preventDefault();
    }


  render() {
    return(
      <div class = "container">
        <form class = "form sign-up-form" onSubmit={this.handleSubmit.bind(this)}>
          <div class = "form-row">

            <div class = "form-group col-sm-12 col-md-6 align-items-md-end">
              <label class = "nameDetails" for = "firstName">
                <input name = "firstNameInput" class="form-control form-control-sm form-field " type = "text" if = "firstName" placeholder = "First Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6" >
              <label class = "nameDetails" for = "lastName">
                <input name = "lastNameInput" class = "form-control form-control-sm form-field" type = "text" if = "lastName" placeholder = "Last Name"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="email">
                <input name = "emailInput" class = "form-control form-control-sm form-field" type="email" id="email" placeholder="Email address"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-6">
              <label class = "adressDetails" for="phone">
                <input name = "phoneInput" class = "form-control form-control-sm form-field" type="tel" id="phone" placeholder="Phone Number"></input>
              </label>
            </div>

            <div class = "form-group col-sm-12 col-md-12">
              <label>
                <select class = "form-control form-control-sm form-field">
                  {countryList.map((countrySelect) =>
                    <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                  )}
                </select>
              </label>
            </div>

            <div class = "form-check col-sm-12 col-md-12">
              <label class = "form-check-label mr-2">
                 <input class = "form-check-input" type="checkbox" id="ageTAndCs"></input>
                I confirm that I am over the age of 18 and agree with the Terms & Conditions and Privacy Policy
              </label>
            </div>

            <div class = "col-sm-0 col-md-4"></div>
            <div class = "form-group col-sm-12 col-md-4">

              <button type="submit" class = "btn btn-primary btn-block">Submit</button>
            </div>
            <div class = "col-sm-0 col-md-4"></div>


          </div>
        </form>
      </div>
    );
  }
}

export const howDidYouHearList = [
  {optionValue: "1", source: "Search Engine"},
  {optionValue: "2", source: "Referral"},
  {optionValue: "3", source: "An Event"},
  {optionValue: "4", source: "Social Media"},
  {optionValue: "5", source: "Editorial"}

]
export const countryList = [{optionValue: "AF", country: "Afghanistan"},
	{optionValue: "AX", country: "Åland Islands"},
	{optionValue: "AL", country: "Albania"},
	{optionValue: "DZ", country: "Algeria"},
	{optionValue: "AS", country: "American Samoa"},
	{optionValue: "AD", country: "Andorra"},
	{optionValue: "AO", country: "Angola"},
	{optionValue: "AI", country: "Anguilla"},
	{optionValue: "AQ", country: "Antarctica"},
	{optionValue: "AG", country: "Antigua and Barbuda"},
	{optionValue: "AR", country: "Argentina"},
	{optionValue: "AM", country: "Armenia"},
	{optionValue: "AW", country: "Aruba"},
	{optionValue: "AU", country: "Australia"},
	{optionValue: "AT", country: "Austria"},
	{optionValue: "AZ", country: "Azerbaijan"},
	{optionValue: "BS", country: "Bahamas"},
	{optionValue: "BH", country: "Bahrain"},
	{optionValue: "BD", country: "Bangladesh"},
	{optionValue: "BB", country: "Barbados"},
	{optionValue: "BY", country: "Belarus"},
	{optionValue: "BE", country: "Belgium"},
	{optionValue: "BZ", country: "Belize"},
	{optionValue: "BJ", country: "Benin"},
	{optionValue: "BM", country: "Bermuda"},
	{optionValue: "BT", country: "Bhutan"},
	{optionValue: "BO", country: "Bolivia, Plurinational State of"},
	{optionValue: "BQ", country: "Bonaire, Sint Eustatius and Saba"},
	{optionValue: "BA", country: "Bosnia and Herzegovina"},
	{optionValue: "BW", country: "Botswana"},
	{optionValue: "BV", country: "Bouvet Island"},
	{optionValue: "BR", country: "Brazil"},
	{optionValue: "IO", country: "British Indian Ocean Territory"},
	{optionValue: "BN", country: "Brunei Darussalam"},
	{optionValue: "BG", country: "Bulgaria"},
	{optionValue: "BF", country: "Burkina Faso"},
	{optionValue: "BI", country: "Burundi"},
	{optionValue: "KH", country: "Cambodia"},
	{optionValue: "CM", country: "Cameroon"},
	{optionValue: "CA", country: "Canada"},
	{optionValue: "CV", country: "Cape Verde"},
	{optionValue: "KY", country: "Cayman Islands"},
	{optionValue: "CF", country: "Central African Republic"},
	{optionValue: "TD", country: "Chad"},
	{optionValue: "CL", country: "Chile"},
	{optionValue: "CN", country: "China"},
	{optionValue: "CX", country: "Christmas Island"},
	{optionValue: "CC", country: "Cocos (Keeling) Islands"},
	{optionValue: "CO", country: "Colombia"},
	{optionValue: "KM", country: "Comoros"},
	{optionValue: "CG", country: "Congo"},
	{optionValue: "CD", country: "Congo, the Democratic Republic of the"},
	{optionValue: "CK", country: "Cook Islands"},
	{optionValue: "CR", country: "Costa Rica"},
	{optionValue: "CI", country: "Côte d'Ivoire"},
	{optionValue: "HR", country: "Croatia"},
	{optionValue: "CU", country: "Cuba"},
	{optionValue: "CW", country: "Curaçao"},
	{optionValue: "CY", country: "Cyprus"},
	{optionValue: "CZ", country: "Czech Republic"},
	{optionValue: "DK", country: "Denmark"},
	{optionValue: "DJ", country: "Djibouti"},
	{optionValue: "DM", country: "Dominica"},
	{optionValue: "DO", country: "Dominican Republic"},
	{optionValue: "EC", country: "Ecuador"},
	{optionValue: "EG", country: "Egypt"},
	{optionValue: "SV", country: "El Salvador"},
	{optionValue: "GQ", country: "Equatorial Guinea"},
	{optionValue: "ER", country: "Eritrea"},
	{optionValue: "EE", country: "Estonia"},
	{optionValue: "ET", country: "Ethiopia"},
	{optionValue: "FK", country: "Falkland Islands (Malvinas)"},
	{optionValue: "FO", country: "Faroe Islands"},
	{optionValue: "FJ", country: "Fiji"},
	{optionValue: "FI", country: "Finland"},
	{optionValue: "FR", country: "France"},
	{optionValue: "GF", country: "French Guiana"},
	{optionValue: "PF", country: "French Polynesia"},
	{optionValue: "TF", country: "French Southern Territories"},
	{optionValue: "GA", country: "Gabon"},
	{optionValue: "GM", country: "Gambia"},
	{optionValue: "GE", country: "Georgia"},
	{optionValue: "DE", country: "Germany"},
	{optionValue: "GH", country: "Ghana"},
	{optionValue: "GI", country: "Gibraltar"},
	{optionValue: "GR", country: "Greece"},
	{optionValue: "GL", country: "Greenland"},
	{optionValue: "GD", country: "Grenada"},
	{optionValue: "GP", country: "Guadeloupe"},
	{optionValue: "GU", country: "Guam"},
	{optionValue: "GT", country: "Guatemala"},
	{optionValue: "GG", country: "Guernsey"},
	{optionValue: "GN", country: "Guinea"},
	{optionValue: "GW", country: "Guinea-Bissau"},
	{optionValue: "GY", country: "Guyana"},
	{optionValue: "HT", country: "Haiti"},
	{optionValue: "HM", country: "Heard Island and McDonald Islands"},
	{optionValue: "VA", country: "Holy See (Vatican City State)"},
	{optionValue: "HN", country: "Honduras"},
	{optionValue: "HK", country: "Hong Kong"},
	{optionValue: "HU", country: "Hungary"},
	{optionValue: "IS", country: "Iceland"},
	{optionValue: "IN", country: "India"},
	{optionValue: "ID", country: "Indonesia"},
	{optionValue: "IR", country: "Iran, Islamic Republic of"},
	{optionValue: "IQ", country: "Iraq"},
	{optionValue: "IE", country: "Ireland"},
	{optionValue: "IM", country: "Isle of Man"},
	{optionValue: "IL", country: "Israel"},
	{optionValue: "IT", country: "Italy"},
	{optionValue: "JM", country: "Jamaica"},
	{optionValue: "JP", country: "Japan"},
	{optionValue: "JE", country: "Jersey"},
	{optionValue: "JO", country: "Jordan"},
	{optionValue: "KZ", country: "Kazakhstan"},
	{optionValue: "KE", country: "Kenya"},
	{optionValue: "KI", country: "Kiribati"},
	{optionValue: "KP", country: "Korea, Democratic People's Republic of"},
	{optionValue: "KR", country: "Korea, Republic of"},
	{optionValue: "KW", country: "Kuwait"},
	{optionValue: "KG", country: "Kyrgyzstan"},
	{optionValue: "LA", country: "Lao People's Democratic Republic"},
	{optionValue: "LV", country: "Latvia"},
	{optionValue: "LB", country: "Lebanon"},
	{optionValue: "LS", country: "Lesotho"},
	{optionValue: "LR", country: "Liberia"},
	{optionValue: "LY", country: "Libya"},
	{optionValue: "LI", country: "Liechtenstein"},
	{optionValue: "LT", country: "Lithuania"},
	{optionValue: "LU", country: "Luxembourg"},
	{optionValue: "MO", country: "Macao"},
	{optionValue: "MK", country: "Macedonia, the former Yugoslav Republic of"},
	{optionValue: "MG", country: "Madagascar"},
	{optionValue: "MW", country: "Malawi"},
	{optionValue: "MY", country: "Malaysia"},
	{optionValue: "MV", country: "Maldives"},
	{optionValue: "ML", country: "Mali"},
	{optionValue: "MT", country: "Malta"},
	{optionValue: "MH", country: "Marshall Islands"},
	{optionValue: "MQ", country: "Martinique"},
	{optionValue: "MR", country: "Mauritania"},
	{optionValue: "MU", country: "Mauritius"},
	{optionValue: "YT", country: "Mayotte"},
	{optionValue: "MX", country: "Mexico"},
	{optionValue: "FM", country: "Micronesia, Federated States of"},
	{optionValue: "MD", country: "Moldova, Republic of"},
	{optionValue: "MC", country: "Monaco"},
	{optionValue: "MN", country: "Mongolia"},
	{optionValue: "ME", country: "Montenegro"},
	{optionValue: "MS", country: "Montserrat"},
	{optionValue: "MA", country: "Morocco"},
	{optionValue: "MZ", country: "Mozambique"},
	{optionValue: "MM", country: "Myanmar"},
	{optionValue: "NA", country: "Namibia"},
	{optionValue: "NR", country: "Nauru"},
	{optionValue: "NP", country: "Nepal"},
	{optionValue: "NL", country: "Netherlands"},
	{optionValue: "NC", country: "New Caledonia"},
	{optionValue: "NZ", country: "New Zealand"},
	{optionValue: "NI", country: "Nicaragua"},
	{optionValue: "NE", country: "Niger"},
	{optionValue: "NG", country: "Nigeria"},
	{optionValue: "NU", country: "Niue"},
	{optionValue: "NF", country: "Norfolk Island"},
	{optionValue: "MP", country: "Northern Mariana Islands"},
	{optionValue: "NO", country: "Norway"},
	{optionValue: "OM", country: "Oman"},
	{optionValue: "PK", country: "Pakistan"},
	{optionValue: "PW", country: "Palau"},
	{optionValue: "PS", country: "Palestinian Territory, Occupied"},
	{optionValue: "PA", country: "Panama"},
	{optionValue: "PG", country: "Papua New Guinea"},
	{optionValue: "PY", country: "Paraguay"},
	{optionValue: "PE", country: "Peru"},
	{optionValue: "PH", country: "Philippines"},
	{optionValue: "PN", country: "Pitcairn"},
	{optionValue: "PL", country: "Poland"},
	{optionValue: "PT", country: "Portugal"},
	{optionValue: "PR", country: "Puerto Rico"},
	{optionValue: "QA", country: "Qatar"},
	{optionValue: "RE", country: "Réunion"},
	{optionValue: "RO", country: "Romania"},
	{optionValue: "RU", country: "Russian Federation"},
	{optionValue: "RW", country: "Rwanda"},
	{optionValue: "BL", country: "Saint Barthélemy"},
	{optionValue: "SH", country: "Saint Helena, Ascension and Tristan da Cunha"},
	{optionValue: "KN", country: "Saint Kitts and Nevis"},
	{optionValue: "LC", country: "Saint Lucia"},
	{optionValue: "MF", country: "Saint Martin (French part)"},
	{optionValue: "PM", country: "Saint Pierre and Miquelon"},
	{optionValue: "VC", country: "Saint Vincent and the Grenadines"},
	{optionValue: "WS", country: "Samoa"},
	{optionValue: "SM", country: "San Marino"},
	{optionValue: "ST", country: "Sao Tome and Principe"},
	{optionValue: "SA", country: "Saudi Arabia"},
	{optionValue: "SN", country: "Senegal"},
	{optionValue: "RS", country: "Serbia"},
	{optionValue: "SC", country: "Seychelles"},
	{optionValue: "SL", country: "Sierra Leone"},
	{optionValue: "SG", country: "Singapore"},
	{optionValue: "SX", country: "Sint Maarten (Dutch part)"},
	{optionValue: "SK", country: "Slovakia"},
	{optionValue: "SI", country: "Slovenia"},
	{optionValue: "SB", country: "Solomon Islands"},
	{optionValue: "SO", country: "Somalia"},
	{optionValue: "ZA", country: "South Africa"},
	{optionValue: "GS", country: "South Georgia and the South Sandwich Islands"},
	{optionValue: "SS", country: "South Sudan"},
	{optionValue: "ES", country: "Spain"},
	{optionValue: "LK", country: "Sri Lanka"},
	{optionValue: "SD", country: "Sudan"},
	{optionValue: "SR", country: "Suriname"},
	{optionValue: "SJ", country: "Svalbard and Jan Mayen"},
	{optionValue: "SZ", country: "Swaziland"},
	{optionValue: "SE", country: "Sweden"},
	{optionValue: "CH", country: "Switzerland"},
	{optionValue: "SY", country: "Syrian Arab Republic"},
	{optionValue: "TW", country: "Taiwan, Province of China"},
	{optionValue: "TJ", country: "Tajikistan"},
	{optionValue: "TZ", country: "Tanzania, United Republic of"},
	{optionValue: "TH", country: "Thailand"},
	{optionValue: "TL", country: "Timor-Leste"},
	{optionValue: "TG", country: "Togo"},
	{optionValue: "TK", country: "Tokelau"},
	{optionValue: "TO", country: "Tonga"},
	{optionValue: "TT", country: "Trinidad and Tobago"},
	{optionValue: "TN", country: "Tunisia"},
	{optionValue: "TR", country: "Turkey"},
	{optionValue: "TM", country: "Turkmenistan"},
	{optionValue: "TC", country: "Turks and Caicos Islands"},
	{optionValue: "TV", country: "Tuvalu"},
	{optionValue: "UG", country: "Uganda"},
	{optionValue: "UA", country: "Ukraine"},
	{optionValue: "AE", country: "United Arab Emirates"},
	{optionValue: "GB", country: "United Kingdom"},
	{optionValue: "US", country: "United States"},
	{optionValue: "UM", country: "United States Minor Outlying Islands"},
	{optionValue: "UY", country: "Uruguay"},
	{optionValue: "UZ", country: "Uzbekistan"},
	{optionValue: "VU", country: "Vanuatu"},
	{optionValue: "VE", country: "Venezuela, Bolivarian Republic of"},
	{optionValue: "VN", country: "Viet Nam"},
	{optionValue: "VG", country: "Virgin Islands, British"},
	{optionValue: "VI", country: "Virgin Islands, U.S."},
	{optionValue: "WF", country: "Wallis and Futuna"},
	{optionValue: "EH", country: "Western Sahara"},
	{optionValue: "YE", country: "Yemen"},
	{optionValue: "ZM", country: "Zambia"},
	{optionValue: "ZW", country: "Zimbabwe"}]
