import {
  WINE_LIST_LOADED,
  WINE_LOADED_BUSINESS,
  WINE_REFDATA,
  WINE_GRAPE_COLOUR_TYPE_REFDATA,
  FOOD_PAIRINGS_REFDATA,
  EXPERIENCES_LIST_LOADED,
  EVENTS_LIST_LOADED,
  WINES_FROM_CELLAR_LOADED,
  WINE_REVIEWS_LOADED
} from '../Actions/Types.js'


const initialState = {
  wineList: null,
  wineDetails: null,
  wineDetailsLoaded: false,
  wineRegionReferenceData: null,
  wineRegionReferenceDataLoaded: false,
  wineReferenceData: null,
  wineReferenceDataLoaded: false,
  foodPairingsReferenceData: null,
  foodPairingsReferenceDataLoaded: false,
  experiencesList: null,
  experiencesListLoaded: false,
  eventsList: null,
  eventsListLoaded: false,
  wineReviewsLoaded: false,
  wineReviews: null,
  wineCellarLoaded: false,
  wineCellar: null,


}

export default function (state =initialState, action) {
  switch(action.type) {

    case WINE_LIST_LOADED:
    return {
      ...state,
      wineList: action.payload,
    };

    case WINE_LOADED_BUSINESS:
    return {
      ...state,
      wineDetails: action.payload,
      wineDetailsLoaded: true
    };

    case WINE_REFDATA:
    return {
      ...state,
      wineRegionReferenceData: action.payload,
      wineRegionReferenceDataLoaded: true
    };

    case WINE_GRAPE_COLOUR_TYPE_REFDATA:
    return {
      ...state,
      wineReferenceData: action.payload,
      wineReferenceDataLoaded: true
    };

    case FOOD_PAIRINGS_REFDATA:
    return {
      ...state,
      foodPairingsReferenceData: action.payload,
      foodPairingsReferenceDataLoaded: true
    };

    case EXPERIENCES_LIST_LOADED:
    return {
      ...state,
      experiencesList: action.payload,
      experiencesListLoaded: true,
    };

    case EVENTS_LIST_LOADED:
    return {
      ...state,
      eventsList: action.payload,
      eventsListLoaded: true,
    };

    case WINE_REVIEWS_LOADED:
    return {
      ...state,
      wineReviews: action.payload,
      wineReviewsLoaded: true,
    };

    case WINES_FROM_CELLAR_LOADED:
    return {
      ...state,
      wineCellar: action.payload,
      wineCellarLoaded: true,
    };

    default:
      return state;
  }

}
