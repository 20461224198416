import React, {useState, useEffect, useLayoutEffect} from 'react';
import ReactGA from 'react-ga'
import {createBrowserHistory} from 'history'

import  { Component } from 'react';
import '../App.css';
import '../Styles/MyDashboard.css'
import '../Styles/Events.css'
import '../Styles/Shop.css'
import '../Styles/Reviews.css'
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import "../Actions/Auth.js"

import axios from 'axios'

import {loadUser, logout, getCart} from '../Actions/Auth.js'
import {getEventReview,getEventAndExperienceData} from '../Actions/EventsAndExperiences.js'
import {fetchWineReviews, fetchWinesFromCellar } from '../Actions/WineLists.js'
import store from '../Store.js'

import {StarRating, StarRatingDiabled} from '../Components/RatingsAndReviews.js'


import {
       genderList,
       countryList,
       internationalCallingCodeList,
       findElementInCountryListArray,
       findElementInCountryCodeListArray,
       currencyCodes,
       currencyCodesReduced,
       findElementInCCyListArray,
       removeZeroFromPhoneNumber,
       PlaceHolderImage} from "../Forms/FormComponents.js"

import {
  withRouter, // used in the app export default - expore using with Journal
  BrowserRouter as Router,

  HashRouter, // try using the HashRouter
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from 'react-router-dom'

import {dateElementFromDate, dateFromText, dateFormatterFromString} from "../Components/DashboardComponents.js"
import getSymbolFromCurrency from 'currency-symbol-map'

import HeroImg from '../img/events/darjeeling-biryani-800x533.jpg'

/* Date and time inouts*/
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
//import TimePicker from 'react-time-picker'
import TimeKeeper from 'react-timekeeper';
import TimezoneSelect from 'react-timezone-select'



//needs to be imported after react-dates styles: _datepicker.css
import '../Styles/MyDashboard_datepicker.css'


//import DatePicker from "react-datepicker";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// google maps api
import { Loader } from "@googlemaps/js-api-loader"


var FormData = require('form-data');
//import DateTimePicker from 'react-datetime-picker';

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly"
});
/*const google = window.google*/
declare var google;



function dateFormatter(date){
  var date  = new Date(date)

  const year     = date.getFullYear()
  const day      = date.getDate()
  const monthInx = date.getMonth()

  const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

  const formattedDate = day.toString() + " "+ months[monthInx] +" "+ year.toString()



  return formattedDate;
  /*19 November 2020
  return date.toLocaleDateString('en-GB');
  */
}





class EventShopPageClass extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,

  };

  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      eventData: {},
      isLoadingAnEvent: true,
      ticketQuantitySelect: [],
      ticketQuantity: 1,

      /* rating elements*/
      eventRating: null,
      eventComment: null,
      wineRating: null,
      /*wineRatings: [{'wineId': 4, 'wineRating': 4}, {'wineId': 5, 'wineRating': 3}],*/
      wineRatings: {},
      wineComments: {},
      ratingScale: {1: 'Poor',  2: 'Fair', 3: 'Good' , 4: 'Very Good' , 5: 'Excellent'},
    }

    this.getEventData      = this.getEventData.bind(this)
    this.LocationMap       = this.LocationMap.bind(this)
    this.cart              = this.cart.bind(this)
    this.updateUserOrder   = this.updateUserOrder.bind(this)
    this.handleFormChange  = this.handleFormChange.bind(this)
    this.createWineReview  = this.createWineReview.bind(this)

    this.addWineToCellar              = this.addWineToCellar.bind(this)
    this.createEventExperienceReview  = this.createEventExperienceReview.bind(this)
    this.setRating                    = this.setRating.bind(this)
    this.updateRating                 = this.updateRating.bind(this)
    this.setComment                   = this.setComment.bind(this)

    this.getEventReview               = this.getEventReview.bind(this)
    this.isWineReviewed               = this.isWineReviewed.bind(this)
    this.isEventReviewed              = this.isEventReviewed.bind(this)
    this.provideWineReview            = this.provideWineReview.bind(this)
    this.doNothing                    = this.doNothing.bind(this)


  }

  componentDidMount() {

    var slug           = this.props.match.params.slug
    var slugAsArray    = slug.split("-")
    var slugAsArrayLen = slugAsArray.length
    var id             = slugAsArray[slugAsArrayLen - 1]


    this.setState({
      eventId: id
    })

    this.getEventData(id)

    store.dispatch(fetchWinesFromCellar());
    store.dispatch(fetchWineReviews());
    store.dispatch(getEventReview(id));








  }

  componentDidUpdate(){

    // improve the below location map code

    if (!this.state.isLoadingAnEvent && !this.state.eventData.olineExperience) {
      this.LocationMap()
    }

  }

  getEventReview(itemForReview) {

    //var url = "http://127.0.0.1:8000/api/auth/member-profile/get-event-review/"+itemForReview.toString()
    var url = "https://www.therealgrape.com/api/auth/member-profile/get-event-review/"+itemForReview.toString()

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => {

      })
      .catch((error)   => console.log(error));


  }


  getEventData(itemToEdit){


    this.setState({
      isEditing: true,
      isLoadingAnEvent: true,
    })






    var url = "https://www.therealgrape.com/api/auth/business-profile/event-details/"+itemToEdit.toString()
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/event-details/"+itemToEdit.toString()



    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        eventData: response.data,
        isLoadingAnEvent: false,

        })




        var ticketQuantitySelect = []

        for (var i = 0; i < this.state.eventData.maximumQuantity; i++) {
          ticketQuantitySelect.push(i+1)
        }

        this.setState({
          ticketQuantitySelect: ticketQuantitySelect
        })

        this.getEventReview(itemToEdit)

      })
      .catch((error)   => console.log(error));



  }

  LocationMap(){

    var map
    const locA = {lat: this.state.eventData.lat, lng: this.state.eventData.lng}
    const locB = {lat: 51.522084, lng: -0.090422}
    const locC = [{lat: 51.517845, lng: -0.101729}, {lat: 51.522084, lng: -0.090422}]



    loader.load().then(() => {
      map = new google.maps.Map(document.getElementById('events-experience-venue-location-area'), {
        /*center: { lat: 52.56447, lng: -1.46226 }*/
        center: { lat: this.state.eventData.lat, lng:this.state.eventData.lng },
        zoom: 7,
      });

      const marker = new google.maps.Marker({
        position: locA,
        map: map,
        label: "1",
        icon:{
          color: "0099FF",
          fillColor: "#00FF00",
          strokeColor: "white",
          fillOpacity: 1.0,
          scale: 3,
          labelColor: '#FFFFFF',
        },
        MarkerLabel: {
          fontSize: "32px"
        }
      });



    });

    // add another marketing





    return map


  }

  cart(e) {
    e.preventDefault()
    const { isAuthenticated, user } = this.props.auth;
    var updateBtns = document.getElementsByClassName('update-cart')

    var productId    = updateBtns.item(0).dataset.product
    var action       = updateBtns.item(0).dataset.action
    var productGroup = updateBtns.item(0).dataset.productgroup
    var quantity     = this.state.ticketQuantity


    console.log('productGroup: ', productGroup,'productId: ', productId, 'Action: ', action,'Quantity: ', this.state.ticketQuantity)
    console.log('authenticaed:', isAuthenticated,'user:', user)
    console.log(this.props)
    if (isAuthenticated) {
      console.log("Authenticated", user)
      this.updateUserOrder(productGroup, productId, action, quantity)
    } else {
      console.log("User is not authenticated", user)
    }






    /*console.log(productId)
    console.log(updateBtns)
    console.log(this.props.match.path.split('/')[1])
    console.log(this.props.match.params.slug)
    console.log(action)*/



  }

  addWineToCellar(e, wineId) {

    e.preventDefault()
    var url = "https://www.therealgrape.com/api/wine-profile/add-wine-to-cellar/" + parseInt(wineId)
    // var url = "http://127.0.0.1:8000/api/wine-profile/add-wine-to-cellar/" + "1"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */

    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

  }
  createEventExperienceReview(e, eventId) {

    e.preventDefault()

    var productId        = eventId

    const elementId      = e.currentTarget.id;
    var clickedElement   = document.getElementById(elementId)

    var submitBtnId      = clickedElement.dataset.targetsubmitbutton
    var submitBtn        = document.getElementById(submitBtnId)

    // find the corresponding comment box
    var ratingCommentBox        = document.getElementById('event-comment-input-'+productId.toString())
    var ratingCommentBoxTitle   = document.getElementById('event-comment-input-title-'+productId.toString())
    var ratingCommentBoxDivider = document.getElementById('event-comment-input-divider-'+productId.toString())

    var url = "https://www.therealgrape.com/api/auth/business-profile/create-event-and-experience-review/" + parseInt(eventId)
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/create-event-and-experience-review/" + parseInt(eventId)

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'product': 'event','rating': this.state.eventRating, 'comment': this.state.eventComment});
    //const body = JSON.stringify({'product': 'experience','rating': 4 , 'comment': "Amazing event!"});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      /*get the wine reviews back*/
      store.dispatch(getEventReview(eventId));


    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

    submitBtn.classList.add("hidden")

    // hide the comment boxes
    ratingCommentBox.classList.add("hidden")
    ratingCommentBoxTitle.classList.add("hidden")
    ratingCommentBoxDivider.classList.add("hidden")

  }
  createWineReview(e, wineId) {

    e.preventDefault()

    var productId        = wineId

    const elementId      = e.currentTarget.id;
    var clickedElement   = document.getElementById(elementId)

    var submitBtnId      = clickedElement.dataset.targetsubmitbutton
    var submitBtn        = document.getElementById(submitBtnId)

    // find the corresponding comment box
    var ratingCommentBox        = document.getElementById('wine-comment-input-'+productId.toString())
    var ratingCommentBoxTitle   = document.getElementById('wine-comment-input-title-'+productId.toString())
    var ratingCommentBoxDivider = document.getElementById('wine-comment-input-divider-'+productId.toString())

    var url = "https://www.therealgrape.com/api/wine-profile/create-wine-review/" + parseInt(wineId)
    // var url = "http://127.0.0.1:8000/api/wine-profile/create-wine-review/" + parseInt(wineId)

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'rating': this.state.wineRatings[wineId]['wineRating'] , 'comment': this.state.wineComments[wineId]['wineComment'] });

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      store.dispatch(fetchWineReviews());
    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

    submitBtn.classList.add("hidden")

    // hide the comment boxes
    ratingCommentBox.classList.add("hidden")
    ratingCommentBoxTitle.classList.add("hidden")
    ratingCommentBoxDivider.classList.add("hidden")
  }

  updateUserOrder(productGroup, productId, action, quantity){



    var url = "https://www.therealgrape.com/api/cart/update-item-to-cart"
    // var url = "http://127.0.0.1:8000/api/cart/update-item-to-cart"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'productGroup':productGroup , 'productId':productId, 'action':action, 'quantity': quantity});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      store.dispatch(getCart());
    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

  }

  handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    this.setState({
      [e.target.name]: value.toString()
    })



  }

  updateRating(e)  {
    e.preventDefault()

  }

  setRating(myRating, ratingElement, productId) {



    if (ratingElement === 'wineRating'){



      var wineRatingsLocal = this.state.wineRatings
      wineRatingsLocal[productId] = {'wineRating': myRating}

      // find the corresponding comment box
      var ratingCommentBox        = document.getElementById('wine-comment-input-'+productId.toString())
      var ratingCommentBoxTitle   = document.getElementById('wine-comment-input-title-'+productId.toString())
      var ratingCommentBoxDivider = document.getElementById('wine-comment-input-divider-'+productId.toString())


      this.setState({
        //wineRatings[productId]['wineRating'] : myRating
        //wineRatings:  { [productId] : {'wineRating': myRating}}
        wineRatings:  wineRatingsLocal
      })

      // unhide the comment boxe
      ratingCommentBox.classList.remove("hidden")
      ratingCommentBoxTitle.classList.remove("hidden")
      ratingCommentBoxDivider.classList.remove("hidden")
    }
    else{

      // find the corresponding comment box
      var ratingCommentBox        = document.getElementById('event-comment-input-'+productId.toString())
      var ratingCommentBoxTitle   = document.getElementById('event-comment-input-title-'+productId.toString())
      var ratingCommentBoxDivider = document.getElementById('event-comment-input-divider-'+productId.toString())

      this.setState({
        [ratingElement]: myRating
      })

      // unhide the comment boxe
      ratingCommentBox.classList.remove("hidden")
      ratingCommentBoxTitle.classList.remove("hidden")
      ratingCommentBoxDivider.classList.remove("hidden")


    }


    /*e.preventDefault()*/
  }

  setComment(e) {

    e.preventDefault()

    const elementId      = e.currentTarget.id;
    var clickedElement   = document.getElementById(elementId)
    var commentGroup     = clickedElement.dataset.commentgroup
    var commentid        = clickedElement.dataset.commentid
    var comment          = clickedElement.value
    var submitBtnId      = clickedElement.dataset.targetsubmitbutton
    var submitBtn        = document.getElementById(submitBtnId)

    console.log("Wine comments current state: ", this.state.wineComments)
    console.log("Event comments current state: ", this.state.eventComment)
    console.log("Writing a comment for: ", elementId , ' this is the comment: ', comment, ' the target is: ', commentid)
    console.log("Comment grouop is: ", commentGroup)

    if (commentGroup === 'wineComment'){

      console.log("Let's update the wine comment")
      /*var wineCommentLocal = this.state.wineComments
      wineRatingsLocal[productId] = {'wineComment': myComment}*/

      //  { ... this.state.wineInfo, [clickedElement.name] : selection}

      this.setState({
        wineComments:  {... this.state.wineComments, [commentid]  : {'wineComment': comment}}
      })
    }


    else{

      this.setState({
        [commentGroup]: comment
      })


    }

    // now reveal the submit button
    submitBtn.classList.remove("hidden")


  }

  isWineReviewed(wineId){

    if (this.props.winelist.wineReviews.winesReviewd.includes(wineId)) {
      return true
    }

    else {
      return false
    }


  }

  isEventReviewed(eventId) {
    console.log("The event has the id is :", eventId , "with the following data",  this.props.eventsandexperiences.membersEventReview)
    console.log("the status of the review is: ", this.props.eventsandexperiences.membersEventReview.eventReviewExists)
  }

  provideWineReview(wineId) {
    var wineReview = this.props.winelist.wineReviews.wineReviews.filter(obj => obj.wineId === wineId)
    return wineReview

  }

  doNothing() {

  }



  render()
  {
    var isLoadingAnEvent  = this.state.isLoadingAnEvent
    var self              = this


    return(
      !isLoadingAnEvent ? [
        <div>
          <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
            <div class = "app-content-full container">
              <div class = "event-experience-info-wrapper">



                <div class = "event-experience-info row" >
                  <div class = "col-md-7 col-xl-6">
                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-name"> {this.state.eventData.eventTitle} </div>
                    </div>


                    {this.state.eventData.hashTags.split(" ").map((hashTag, index) =>
                      <span class = "event-experience-inpage-sub-details-title" style={{ marginRight: '10px' }}> {hashTag} </span>
                    )}

                    <div class = "event-experience-meta">
                      {this.state.eventData.ccyExpenseSymbols.split("|").map((symbol, index) =>
                        getSymbolFromCurrency(symbol)
                    )}
                    </div>

                    <div class = "event-experience__intro-copy-paragraph">
                      {this.state.eventData.eventType}
                    </div>

                    <div class = "event-experience-date-and-time-wrapper">
                      <div class = "event-experience-date-and-time">
                      {dateFormatter(this.state.eventData.date)} <div class="separator"></div> {this.state.eventData.time} ({this.state.eventData.timeZone})
                      </div>
                    </div>

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-title"> Make a booking </div>
                    </div>
                    <div >
                      <span style  ={{fontWeight: '500'}} >General Admission </span>
                      <span style = {{color: '#8d8478' }}>(1 ticket admits {this.state.eventData.ticketsPerOrder}) </span>
                    </div>

                    <span style={{ marginRight: '5px', fontWeight:'500', color: '#000' }}>
                      {getSymbolFromCurrency(this.state.eventData.currency)}
                      {this.state.eventData.price.toFixed(2)}
                    </span>
                    <span style={{ marginRight: '5px', fontWeight:'500', fontSize:'12px', color: '#8d8478' }}>
                     +
                    {getSymbolFromCurrency(this.state.eventData.currency)}
                    {this.state.eventData.clientTransactionFee.toFixed(2)} Fee
                    </span>

                    <form> {/*Make a booking form*/}
                      <div class = "row">
                        <div class = "col-sm-12 col-md-6 " style = {{marginBottom:"5px"}}>
                          <div class = "form-field-wrapper">
                            <select onChange = {this.handleFormChange} value = {this.state.ticketQuantity} name = "ticketQuantity"  class = "event-experience-select-form-field">
                              {self.state.ticketQuantitySelect.map((quantity) =>
                                <option value = {quantity.toString()}> {quantity} </option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div class = "col-sm-12 col-md-6 ">
                          <button onClick={(e) => this.cart(e)} data-productGroup = {this.props.match.path.split('/')[1]} data-product={this.state.eventData.id} data-action="add" class = "button button-primary event-experience-add-to-cart update-cart">
                            <i style={{ marginRight: '10px'}} class="fas fa-shopping-cart"></i>
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </form> {/*end booking form*/}

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}> Need to Know </div>
                    </div>

                    <p class = "event-experience__intro-copy-paragraph">
                      {this.state.eventData.description}
                    </p>

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper" style = {{alignItmes:"left"}}>
                      <div class = "row">
                        <div class = "col-sm-12 col-md-12 col-lg-12">
                          <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}>Wine lists </div>
                        </div>

                          <ul class = "my-dashboard-cards">
                            {this.state.eventData.WineListData.map((listing, index) =>
                              <li key = {`listing-${index}`}>
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  <span class = "event-experience-inpage-sub-details-title" style={{marginLeft:'5px' }}>  {listing.wineListName} </span>
                                </div>

                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  <p class = "event-experience__intro-copy-paragraph" style={{marginLeft:'5px' }}>  {listing.wineListdescription} </p>
                                </div>

                                <div class = "col-sm-12 col-md-12 col-lg-12" style ={{padding:"2px 2px 2px 2px"}}>
                                  <div class = "row">
                                    <ul style = {{listStyleType: "none"}}>
                                      <div class = "col-sm-12 col-md-12 col-lg-12">


                                        {listing.wines.map((wineListing, wineIndex) =>
                                          <li key = {`listing-${index}`}>
                                            <div class = "row">
                                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                                <span class = "event-experience__intro-copy-paragraph" style = {{textTransform:'capitalize'}}>
                                                  {wineListing.wine === null ? <></> : <>{wineListing.wine.toString().toLowerCase()}</>}
                                                  {wineListing.region === null ? <></> : <>, {wineListing.region.toString().toLowerCase()}</>}
                                                  {wineListing.country === null ? <></> : <>, {wineListing.country.toString().toLowerCase()}</>}
                                                  {wineListing.vintage === null ? <></> : <>, {wineListing.vintage.toString().toLowerCase()}</>}
                                                  {wineListing.vegetarian === null ? <></>
                                                    : wineListing.vegetarian === true ? <>, <span style = {{fontStyle: "italic"}}>vegetarian </span></> :<></>}
                                                  {wineListing.vegan === null ? <></>
                                                    : wineListing.vegan === true ? <>, <span style = {{fontStyle: "italic"}}>vegan </span></> :<></>}
                                                </span>
                                              </div>



                                            </div>

                                          </li>
                                        )}

                                      </div>

                                    </ul>
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                    </div>



                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}>About  {this.state.eventData.businessName}</div>
                    </div>

                    <p class = "event-experience__intro-copy-paragraph">
                      {this.state.eventData.businessBio}
                    </p>

                    <hr aria-hidden="true" />





                  </div>
                  <div class = "col-md-4 offset-md-1 col-xl-5 offset-xl-1">
                    <div class = "event-experience_Img_wrapper">
                      <img class = "event-experience_Img_Centered" src = {this.state.eventData.mainExperienceImage} />
                    </div>

                    <div class = "venue-location-summary">
                      <div class = "venue-location-summary-content">


                        {
                          !this.state.eventData.olineExperience ? [

                              <div>


                                <div class = "venue-location-summary-Map_Area_Wrapper">
                                    <div class = "venue-location-summary_Map_Area" id="events-experience-venue-location-area"/>
                                </div>



                                <div class = "venue-location-summary-content-info">
                                  <div class = "event-experience-name-breadcrumb">{this.state.eventData.eventTitle}</div>
                                  <p class = "venue-location-summary-content-info-breadcrumb">{this.state.eventData.locationAddress}</p>
                                  <p class = "venue-location-summary-content-info-breadcrumb">{this.state.eventData.locationcity}, {this.state.eventData.locationZip_code}</p>
                                </div>

                                <div class = "venue-location-summary-content-row">
                                  <i class="fas fa-phone-alt">
                                  </i>
                                  <span> <div style={{ marginLeft: '20px' }}>{ findElementInCountryCodeListArray(this.state.eventData.hostPhoneCountryCode,internationalCallingCodeList)  + removeZeroFromPhoneNumber(this.state.eventData.hostPhone)} </div></span>
                                </div>

                                <div class = "venue-location-summary-content-row">
                                  <i class="fas fa-envelope"></i>
                                  <span> <div style={{ marginLeft: '20px' }}> {this.state.eventData.hostEmail} </div></span>
                                </div>

                              </div>
                            ] :
                            [
                            <div>
                              <div class = "venue-location-summary-content-info">
                                <div class = "event-experience-name-breadcrumb">{this.state.eventData.eventTitle}</div>
                                <p class = "venue-location-summary-content-info-breadcrumb"> Streaming details will be shared once the event is purchased. </p>
                              </div>
                              <div class = "venue-location-summary-content-row">
                                <i class="fas fa-phone-alt">
                                </i>
                                <span> <div style={{ marginLeft: '20px' }}>{ findElementInCountryCodeListArray(this.state.eventData.hostPhoneCountryCode,internationalCallingCodeList)  + removeZeroFromPhoneNumber(this.state.eventData.hostPhone)} </div></span>
                              </div>

                              <div class = "venue-location-summary-content-row">
                                <i class="fas fa-envelope"></i>
                                <span> <div style={{ marginLeft: '20px' }}> {this.state.eventData.hostEmail} </div></span>
                              </div>
                            </div>]
                        }
                      </div>

                    </div>
                  </div>


                </div>
              </div>


            </div>
          </div>
        </div>] : [<></>]
    )
  }
}

class EventPurchasedPageClass extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,

  };

  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      eventData: {},
      isLoadingAnEvent: true,
      ticketQuantitySelect: [],
      ticketQuantity: 1,

      /* rating elements*/
      eventRating: null,
      eventComment: null,
      wineRating: null,
      /*wineRatings: [{'wineId': 4, 'wineRating': 4}, {'wineId': 5, 'wineRating': 3}],*/
      wineRatings: {},
      wineComments: {},
      ratingScale: {1: 'Poor',  2: 'Fair', 3: 'Good' , 4: 'Very Good' , 5: 'Excellent'},
    }

    this.getEventData      = this.getEventData.bind(this)
    this.LocationMap       = this.LocationMap.bind(this)
    this.cart              = this.cart.bind(this)
    this.updateUserOrder   = this.updateUserOrder.bind(this)
    this.handleFormChange  = this.handleFormChange.bind(this)
    this.createWineReview  = this.createWineReview.bind(this)

    this.addWineToCellar              = this.addWineToCellar.bind(this)
    this.createEventExperienceReview  = this.createEventExperienceReview.bind(this)
    this.setRating                    = this.setRating.bind(this)
    this.updateRating                 = this.updateRating.bind(this)
    this.setComment                   = this.setComment.bind(this)

    this.getEventReview               = this.getEventReview.bind(this)
    this.isWineReviewed               = this.isWineReviewed.bind(this)
    this.isEventReviewed              = this.isEventReviewed.bind(this)
    this.provideWineReview            = this.provideWineReview.bind(this)
    this.doNothing                    = this.doNothing.bind(this)


  }

  componentDidMount() {

    var slug           = this.props.match.params.slug
    var slugAsArray    = slug.split("-")
    var slugAsArrayLen = slugAsArray.length
    var id             = slugAsArray[slugAsArrayLen - 1]


    this.setState({
      eventId: id
    })

    this.getEventData(id)

    store.dispatch(fetchWinesFromCellar());
    store.dispatch(fetchWineReviews());
    store.dispatch(getEventReview(id));








  }

  componentDidUpdate(){

    // improve the below location map code

    if (!this.state.isLoadingAnEvent && !this.state.eventData.olineExperience) {
      this.LocationMap()
    }

  }

  getEventReview(itemForReview) {

    //var url = "http://127.0.0.1:8000/api/auth/member-profile/get-event-review/"+itemForReview.toString()
    var url = "https://www.therealgrape.com/api/auth/member-profile/get-event-review/"+itemForReview.toString()

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => {

      })
      .catch((error)   => console.log(error));


  }


  getEventData(itemToEdit){


    this.setState({
      isEditing: true,
      isLoadingAnEvent: true,
    })






    var url = "https://www.therealgrape.com/api/auth/business-profile/event-details/"+itemToEdit.toString()
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/event-details/"+itemToEdit.toString()



    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        eventData: response.data,
        isLoadingAnEvent: false,

        })




        var ticketQuantitySelect = []

        for (var i = 0; i < this.state.eventData.maximumQuantity; i++) {
          ticketQuantitySelect.push(i+1)
        }

        this.setState({
          ticketQuantitySelect: ticketQuantitySelect
        })

        this.getEventReview(itemToEdit)

      })
      .catch((error)   => console.log(error));



  }

  LocationMap(){

    var map
    const locA = {lat: this.state.eventData.lat, lng: this.state.eventData.lng}
    const locB = {lat: 51.522084, lng: -0.090422}
    const locC = [{lat: 51.517845, lng: -0.101729}, {lat: 51.522084, lng: -0.090422}]



    loader.load().then(() => {
      map = new google.maps.Map(document.getElementById('events-experience-venue-location-area'), {
        /*center: { lat: 52.56447, lng: -1.46226 }*/
        center: { lat: this.state.eventData.lat, lng:this.state.eventData.lng },
        zoom: 7,
      });

      const marker = new google.maps.Marker({
        position: locA,
        map: map,
        label: "1",
        icon:{
          color: "0099FF",
          fillColor: "#00FF00",
          strokeColor: "white",
          fillOpacity: 1.0,
          scale: 3,
          labelColor: '#FFFFFF',
        },
        MarkerLabel: {
          fontSize: "32px"
        }
      });



    });

    // add another marketing





    return map


  }

  cart(e) {
    e.preventDefault()
    const { isAuthenticated, user } = this.props.auth;
    var updateBtns = document.getElementsByClassName('update-cart')

    var productId    = updateBtns.item(0).dataset.product
    var action       = updateBtns.item(0).dataset.action
    var productGroup = updateBtns.item(0).dataset.productgroup
    var quantity     = this.state.ticketQuantity


    console.log('productGroup: ', productGroup,'productId: ', productId, 'Action: ', action,'Quantity: ', this.state.ticketQuantity)
    console.log('authenticaed:', isAuthenticated,'user:', user)
    console.log(this.props)
    if (isAuthenticated) {
      console.log("Authenticated", user)
      this.updateUserOrder(productGroup, productId, action, quantity)
    } else {
      console.log("User is not authenticated", user)
    }






    /*console.log(productId)
    console.log(updateBtns)
    console.log(this.props.match.path.split('/')[1])
    console.log(this.props.match.params.slug)
    console.log(action)*/



  }

  addWineToCellar(e, wineId) {

    e.preventDefault()
    var url = "https://www.therealgrape.com/api/wine-profile/add-wine-to-cellar/" + parseInt(wineId)
    // var url = "http://127.0.0.1:8000/api/wine-profile/add-wine-to-cellar/" + "1"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */

    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

  }
  createEventExperienceReview(e, eventId) {

    e.preventDefault()

    var productId        = eventId

    const elementId      = e.currentTarget.id;
    var clickedElement   = document.getElementById(elementId)

    var submitBtnId      = clickedElement.dataset.targetsubmitbutton
    var submitBtn        = document.getElementById(submitBtnId)

    // find the corresponding comment box
    var ratingCommentBox        = document.getElementById('event-comment-input-'+productId.toString())
    var ratingCommentBoxTitle   = document.getElementById('event-comment-input-title-'+productId.toString())
    var ratingCommentBoxDivider = document.getElementById('event-comment-input-divider-'+productId.toString())

    var url = "https://www.therealgrape.com/api/auth/business-profile/create-event-and-experience-review/" + parseInt(eventId)
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/create-event-and-experience-review/" + parseInt(eventId)

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'product': 'event','rating': this.state.eventRating, 'comment': this.state.eventComment});
    //const body = JSON.stringify({'product': 'experience','rating': 4 , 'comment': "Amazing event!"});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      /*get the wine reviews back*/
      store.dispatch(getEventReview(eventId));


    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

    submitBtn.classList.add("hidden")

    // hide the comment boxes
    ratingCommentBox.classList.add("hidden")
    ratingCommentBoxTitle.classList.add("hidden")
    ratingCommentBoxDivider.classList.add("hidden")

  }
  createWineReview(e, wineId) {

    e.preventDefault()

    var productId        = wineId

    const elementId      = e.currentTarget.id;
    var clickedElement   = document.getElementById(elementId)

    var submitBtnId      = clickedElement.dataset.targetsubmitbutton
    var submitBtn        = document.getElementById(submitBtnId)

    // find the corresponding comment box
    var ratingCommentBox        = document.getElementById('wine-comment-input-'+productId.toString())
    var ratingCommentBoxTitle   = document.getElementById('wine-comment-input-title-'+productId.toString())
    var ratingCommentBoxDivider = document.getElementById('wine-comment-input-divider-'+productId.toString())

    var url = "https://www.therealgrape.com/api/wine-profile/create-wine-review/" + parseInt(wineId)
    // var url = "http://127.0.0.1:8000/api/wine-profile/create-wine-review/" + parseInt(wineId)

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'rating': this.state.wineRatings[wineId]['wineRating'] , 'comment': this.state.wineComments[wineId]['wineComment'] });

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      store.dispatch(fetchWineReviews());
    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

    submitBtn.classList.add("hidden")

    // hide the comment boxes
    ratingCommentBox.classList.add("hidden")
    ratingCommentBoxTitle.classList.add("hidden")
    ratingCommentBoxDivider.classList.add("hidden")
  }

  updateUserOrder(productGroup, productId, action, quantity){



    var url = "https://www.therealgrape.com/api/cart/update-item-to-cart"
    // var url = "http://127.0.0.1:8000/api/cart/update-item-to-cart"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'productGroup':productGroup , 'productId':productId, 'action':action, 'quantity': quantity});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      store.dispatch(getCart());
    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

  }

  handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    this.setState({
      [e.target.name]: value.toString()
    })



  }

  updateRating(e)  {
    e.preventDefault()

  }

  setRating(myRating, ratingElement, productId) {



    if (ratingElement === 'wineRating'){



      var wineRatingsLocal = this.state.wineRatings
      wineRatingsLocal[productId] = {'wineRating': myRating}

      // find the corresponding comment box
      var ratingCommentBox        = document.getElementById('wine-comment-input-'+productId.toString())
      var ratingCommentBoxTitle   = document.getElementById('wine-comment-input-title-'+productId.toString())
      var ratingCommentBoxDivider = document.getElementById('wine-comment-input-divider-'+productId.toString())


      this.setState({
        //wineRatings[productId]['wineRating'] : myRating
        //wineRatings:  { [productId] : {'wineRating': myRating}}
        wineRatings:  wineRatingsLocal
      })

      // unhide the comment boxe
      ratingCommentBox.classList.remove("hidden")
      ratingCommentBoxTitle.classList.remove("hidden")
      ratingCommentBoxDivider.classList.remove("hidden")
    }
    else{

      // find the corresponding comment box
      var ratingCommentBox        = document.getElementById('event-comment-input-'+productId.toString())
      var ratingCommentBoxTitle   = document.getElementById('event-comment-input-title-'+productId.toString())
      var ratingCommentBoxDivider = document.getElementById('event-comment-input-divider-'+productId.toString())

      this.setState({
        [ratingElement]: myRating
      })

      // unhide the comment boxe
      ratingCommentBox.classList.remove("hidden")
      ratingCommentBoxTitle.classList.remove("hidden")
      ratingCommentBoxDivider.classList.remove("hidden")


    }


    /*e.preventDefault()*/
  }

  setComment(e) {

    e.preventDefault()

    const elementId      = e.currentTarget.id;
    var clickedElement   = document.getElementById(elementId)
    var commentGroup     = clickedElement.dataset.commentgroup
    var commentid        = clickedElement.dataset.commentid
    var comment          = clickedElement.value
    var submitBtnId      = clickedElement.dataset.targetsubmitbutton
    var submitBtn        = document.getElementById(submitBtnId)

    console.log("Wine comments current state: ", this.state.wineComments)
    console.log("Event comments current state: ", this.state.eventComment)
    console.log("Writing a comment for: ", elementId , ' this is the comment: ', comment, ' the target is: ', commentid)
    console.log("Comment grouop is: ", commentGroup)

    if (commentGroup === 'wineComment'){

      console.log("Let's update the wine comment")
      /*var wineCommentLocal = this.state.wineComments
      wineRatingsLocal[productId] = {'wineComment': myComment}*/

      //  { ... this.state.wineInfo, [clickedElement.name] : selection}

      this.setState({
        wineComments:  {... this.state.wineComments, [commentid]  : {'wineComment': comment}}
      })
    }


    else{

      this.setState({
        [commentGroup]: comment
      })


    }

    // now reveal the submit button
    submitBtn.classList.remove("hidden")


  }

  isWineReviewed(wineId){

    if (this.props.winelist.wineReviews.winesReviewd.includes(wineId)) {
      return true
    }

    else {
      return false
    }


  }

  isEventReviewed(eventId) {
    console.log("The event has the id is :", eventId , "with the following data",  this.props.eventsandexperiences.membersEventReview)
    console.log("the status of the review is: ", this.props.eventsandexperiences.membersEventReview.eventReviewExists)
  }

  provideWineReview(wineId) {
    var wineReview = this.props.winelist.wineReviews.wineReviews.filter(obj => obj.wineId === wineId)
    return wineReview

  }

  doNothing() {

  }



  render()
  {
    var isLoadingAnEvent  = this.state.isLoadingAnEvent
    var self              = this


    return(
      !isLoadingAnEvent ? [
        <div>
          <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
            <div class = "app-content-full container">
              <div class = "event-experience-info-wrapper">

                {this.props.auth.isAuthenticated ?
                  [
                     this.props.auth.group[0].name === "members" ?
                     [
                       <div>
                         <div class = "event-experience-info event-experience-summary-header row" >

                           <div class = "col-sm-12 offset-md-10 col-md-2 offset-lg-10 col-lg-2 ">
                             Booking confirmation
                           </div>

                           <div class = "col-sm-12 offset-md-10 col-md-2  offset-lg-10 col-lg-2 confirm-details ">
                             Refference Number: <span class = "confirm-details-highlight">2454.427.098 </span>
                           </div>


                         </div>

                         <div class = "event-experience-info event-experience-summary row" style = {{border:"1px solid grey"}}>
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                           Quantity Bought
                          </div>
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                           Admits per ticket
                          </div>
                         </div>

                         <div class = "event-experience-info event-experience-summary-footer row" >
                         <form class = "form review-form" autoComplete="off">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "review-header"> Create Review </div>
                            </div>
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <hr class = "review-divider"/>
                            </div>
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <span class = "review-sub-heading" >Overall rating</span>
                            </div>

                            <div class = "col-sm-12 col-md-12 col-lg-12 ">

                              {
                                this.props.eventsandexperiences.memberEventReviewLoaded ? [
                                  this.props.eventsandexperiences.membersEventReview.eventReviewExists ? [
                                    <div>

                                      <StarRatingDiabled
                                        rating = {this.props.eventsandexperiences.membersEventReview.rating}
                                        onRating={() => this.doNothing()}
                                        count = {5}/>

                                        {<p>Comment: {this.props.eventsandexperiences.membersEventReview.comment.toString()} </p>}


                                    </div>

                                  ] : [
                                    <div>
                                      <StarRating
                                      id     = 'event-rating'
                                      rating = {this.state.eventRating}
                                      onRating={(myRating) => this.setRating(myRating, 'eventRating', this.state.eventData.id )}
                                      count = {5}/>

                                      {/*this.state.eventRating === null ?
                                          <p> You have not rated the event </p> :
                                          <p> Your rating: {this.state.ratingScale[parseInt(this.state.eventRating)]}  </p>*/}
                                    </div>




                                  ]

                                ]:[<div></div>]
                              }


                            </div>
                            <div class = "col-sm-12-col-md-12 col-lg-12">
                              <hr class = "review-divider hidden"
                                  id    = {`event-comment-input-divider-${this.state.eventData.id}`}
                              />
                            </div>

                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <span class = "review-sub-heading hidden"
                                    id    = {`event-comment-input-title-${this.state.eventData.id}`}
                              >Please add a written review </span>
                            </div>
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "review-comment-section">
                                <div class = "review-comment-textarea-wrapper">
                                  <textarea class = "comment-area hidden"
                                            placeholder="What did you like about the event and venue? What was the occasion for attending? Would you attend another event organised by this host?"
                                            onInput                 = {(e) => this.setComment(e)}
                                            name                    = 'event-comment-input'
                                            data-commentgroup       = 'eventComment'
                                            data-commentid          = {this.state.eventData.id}
                                            data-targetsubmitbutton = {`event-rating-submit-btn-${this.state.eventData.id}`}
                                            id                      = {`event-comment-input-${this.state.eventData.id}`}
                                            ></textarea>
                                </div>

                              </div>

                            </div>

                            <div class = "col-sm-12 col-md-12 col-lg-12 review-button-section">
                              <button
                                class  = "review-button review-button-normal review-button-primary hidden"
                                type   = "button"
                                id     = {`event-rating-submit-btn-${this.state.eventData.id}`}
                                data-targetsubmitbutton = {`event-rating-submit-btn-${this.state.eventData.id}`}
                                onClick={(e) => this.createEventExperienceReview(e, this.state.eventData.id)}
                              > <span class = "review-button-text">Submit review</span> </button>
                            </div>
                          </form>

                         </div>


                        </div>
                     ] : [<></>]
                  ] : [<></>]}

                <div class = "event-experience-info row" >
                  <div class = "col-md-7 col-xl-6">
                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-name"> {this.state.eventData.eventTitle} </div>
                    </div>


                    {this.state.eventData.hashTags.split(" ").map((hashTag, index) =>
                      <span class = "event-experience-inpage-sub-details-title" style={{ marginRight: '10px' }}> {hashTag} </span>
                    )}

                    <div class = "event-experience-meta">
                      {this.state.eventData.ccyExpenseSymbols.split("|").map((symbol, index) =>
                        getSymbolFromCurrency(symbol)
                    )}
                    </div>

                    <div class = "event-experience__intro-copy-paragraph">
                      {this.state.eventData.eventType}
                    </div>

                    <div class = "event-experience-date-and-time-wrapper">
                      <div class = "event-experience-date-and-time">
                      {dateFormatter(this.state.eventData.date)} <div class="separator"></div> {this.state.eventData.time} ({this.state.eventData.timeZone})
                      </div>
                    </div>

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}> Need to Know </div>
                    </div>

                    <p class = "event-experience__intro-copy-paragraph">
                      {this.state.eventData.description}
                    </p>

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper" style = {{alignItmes:"left"}}>
                      <div class = "row">
                        <div class = "col-sm-12 col-md-12 col-lg-12">
                          <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}>Wine lists </div>
                        </div>

                          <ul class = "my-dashboard-cards">
                            {this.state.eventData.WineListData.map((listing, index) =>
                              <li key = {`listing-${index}`}>
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  <span class = "event-experience-inpage-sub-details-title" style={{marginLeft:'5px' }}>  {listing.wineListName} </span>
                                </div>

                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  <p class = "event-experience__intro-copy-paragraph" style={{marginLeft:'5px' }}>  {listing.wineListdescription} </p>
                                </div>

                                <div class = "col-sm-12 col-md-12 col-lg-12" style ={{padding:"2px 2px 2px 2px"}}>
                                  <div class = "row">
                                    <ul style = {{listStyleType: "none"}}>
                                      <div class = "col-sm-12 col-md-12 col-lg-12">


                                        {listing.wines.map((wineListing, wineIndex) =>
                                          <li key = {`listing-${index}`}>
                                            <div class = "row">
                                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                                <span class = "event-experience__intro-copy-paragraph" style = {{textTransform:'capitalize'}}>
                                                  {wineListing.wine === null ? <></> : <>{wineListing.wine.toString().toLowerCase()}</>}
                                                  {wineListing.region === null ? <></> : <>, {wineListing.region.toString().toLowerCase()}</>}
                                                  {wineListing.country === null ? <></> : <>, {wineListing.country.toString().toLowerCase()}</>}
                                                  {wineListing.vintage === null ? <></> : <>, {wineListing.vintage.toString().toLowerCase()}</>}
                                                  {wineListing.vegetarian === null ? <></>
                                                    : wineListing.vegetarian === true ? <>, <span style = {{fontStyle: "italic"}}>vegetarian </span></> :<></>}
                                                  {wineListing.vegan === null ? <></>
                                                    : wineListing.vegan === true ? <>, <span style = {{fontStyle: "italic"}}>vegan </span></> :<></>}
                                                </span>
                                              </div>

                                              {/*member comments and rating section*/}

                                              {this.props.auth.isAuthenticated ?
                                                [
                                                   this.props.auth.group[0].name === "members" ?
                                                   [
                                                     <div>
                                                      <form class = "form review-form" autoComplete="off">
                                                         <div class = "row" >
                                                           <div class = "col-sm-12 col-md-12 col-lg-12 review-button-section">
                                                             <button
                                                               class = "review-button review-button-normal review-button-primary"
                                                               type = "button"
                                                               onClick={(e) => this.addWineToCellar(e, wineListing.wineId)}
                                                             > <span class = "review-button-text">Add wine to cellar</span> </button>
                                                           </div>


                                                           <div class = "col-sm-12 col-md-12 col-lg-12">
                                                             <div class = "review-header"> Create Wine Review </div>
                                                           </div>
                                                           <div class = "col-sm-12 col-md-12 col-lg-12">
                                                             <hr class = "review-divider"/>
                                                           </div>
                                                           <div class = "col-sm-12 col-md-12 col-lg-12">
                                                             <span class = "review-sub-heading" >Overall rating</span>
                                                           </div>

                                                            <div class = "col-sm-12 col-md-12 col-lg-12">

                                                              {
                                                                this.props.winelist.wineReviewsLoaded ?
                                                                [
                                                                  this.isWineReviewed(wineListing.wineId) ? [
                                                                    <div>

                                                                      <StarRatingDiabled
                                                                        rating = {this.provideWineReview(wineListing.wineId)[0]['rating']}
                                                                        onRating={() => this.doNothing()}
                                                                        data-targetsubmitbutton = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                        count = {5}/>

                                                                        {/*onRating={(myRating) => this.setRating(myRating, 'wineRating', wineListing.wineId)}*/}
                                                                        <p>Comment: {this.provideWineReview(wineListing.wineId)[0]['comment'].toString()} </p>


                                                                    </div>
                                                                  ] : [!this.state.wineRatings[wineListing.wineId] ? [
                                                                    <div>
                                                                      <StarRating
                                                                        rating = {0}
                                                                        onRating={(myRating) => this.setRating(myRating, 'wineRating', wineListing.wineId)}
                                                                        data-targetsubmitbutton = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                        count = {5}/>

                                                                      <p> You have not rated the wine </p>

                                                                    </div>

                                                                  ]
                                                                    : [
                                                                      <div>
                                                                        <StarRating
                                                                          rating = {this.state.wineRatings[wineListing.wineId]['wineRating']}
                                                                          onRating={(myRating) => this.setRating(myRating, 'wineRating', wineListing.wineId)}
                                                                          data-targetsubmitbutton = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                          count = {5}/>

                                                                          <p> Your rating: {this.state.ratingScale[parseInt(this.state.wineRatings[wineListing.wineId]['wineRating'])]}  </p>


                                                                      </div>
                                                                    ]

                                                                  ]
                                                                ] : [<div></div>]
                                                              }
                                                              {/*!this.state.wineRatings[wineListing.wineId] ? [
                                                                <div>
                                                                  <StarRating
                                                                    rating = {0}
                                                                    onRating={(myRating) => this.setRating(myRating, 'wineRating', wineListing.wineId)}
                                                                    data-targetsubmitbutton = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                    count = {5}/>

                                                                  <p> You have not rated the wine </p>

                                                                </div>

                                                              ]
                                                                : [
                                                                  <div>
                                                                    <StarRating
                                                                      rating = {this.state.wineRatings[wineListing.wineId]['wineRating']}
                                                                      onRating={(myRating) => this.setRating(myRating, 'wineRating', wineListing.wineId)}
                                                                      data-targetsubmitbutton = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                      count = {5}/>

                                                                      <p> Your rating: {this.state.ratingScale[parseInt(this.state.wineRatings[wineListing.wineId]['wineRating'])]}  </p>


                                                                  </div>
                                                                ]*/}



                                                            </div>


                                                            <div class = "col-sm-12-col-md-12 col-lg-12">
                                                              <hr class = "review-divider hidden"
                                                                  id    = {`wine-comment-input-divider-${wineListing.wineId}`}
                                                              />
                                                            </div>

                                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                                              <span class = "review-sub-heading hidden"
                                                                    id    = {`wine-comment-input-title-${wineListing.wineId}`}
                                                              >Please add a written review </span>
                                                            </div>

                                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                                              <div class = "review-comment-section">
                                                                <div class = "review-comment-textarea-wrapper">
                                                                  <textarea class = "comment-area hidden"
                                                                            placeholder="What did you like about the wine? "
                                                                            onInput                 = {(e) => this.setComment(e)}
                                                                            name                    = 'wine-comment-input'
                                                                            data-commentgroup       = 'wineComment'
                                                                            data-commentid          = {wineListing.wineId}
                                                                            data-targetsubmitbutton = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                            id                      = {`wine-comment-input-${wineListing.wineId}`}
                                                                            ></textarea>
                                                                </div>

                                                              </div>

                                                            </div>


                                                            <div class = "col-sm-12 col-md-12 col-lg-12 review-button-section">
                                                              <button

                                                                class  = "review-button review-button-normal review-button-primary hidden"
                                                                type   = "button"
                                                                id     = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                data-targetsubmitbutton = {`submit-wine-review-btn-${wineListing.wineId}`}
                                                                onClick={(e) => this.createWineReview(e, wineListing.wineId)}
                                                              > <span class = "review-button-text">Submit review</span> </button>
                                                            </div>




                                                         </div>
                                                      </form>
                                                      </div>
                                                   ] : [<></>]
                                                ] : [<></>]}

                                            </div>

                                          </li>
                                        )}

                                      </div>

                                    </ul>
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                    </div>



                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}>About  {this.state.eventData.businessName}</div>
                    </div>

                    <p class = "event-experience__intro-copy-paragraph">
                      {this.state.eventData.businessBio}
                    </p>

                    <hr aria-hidden="true" />





                  </div>
                  <div class = "col-md-4 offset-md-1 col-xl-5 offset-xl-1">
                    <div class = "event-experience_Img_wrapper">
                      <img class = "event-experience_Img_Centered" src = {this.state.eventData.mainExperienceImage} />
                    </div>

                    <div class = "venue-location-summary">
                      <div class = "venue-location-summary-content">


                        {
                          !this.state.eventData.olineExperience ? [

                              <div>


                                <div class = "venue-location-summary-Map_Area_Wrapper">
                                    <div class = "venue-location-summary_Map_Area" id="events-experience-venue-location-area"/>
                                </div>



                                <div class = "venue-location-summary-content-info">
                                  <div class = "event-experience-name-breadcrumb">{this.state.eventData.eventTitle}</div>
                                  <p class = "venue-location-summary-content-info-breadcrumb">{this.state.eventData.locationAddress}</p>
                                  <p class = "venue-location-summary-content-info-breadcrumb">{this.state.eventData.locationcity}, {this.state.eventData.locationZip_code}</p>
                                </div>

                                <div class = "venue-location-summary-content-row">
                                  <i class="fas fa-phone-alt">
                                  </i>
                                  <span> <div style={{ marginLeft: '20px' }}>{ findElementInCountryCodeListArray(this.state.eventData.hostPhoneCountryCode,internationalCallingCodeList)  + removeZeroFromPhoneNumber(this.state.eventData.hostPhone)} </div></span>
                                </div>

                                <div class = "venue-location-summary-content-row">
                                  <i class="fas fa-envelope"></i>
                                  <span> <div style={{ marginLeft: '20px' }}> {this.state.eventData.hostEmail} </div></span>
                                </div>

                              </div>
                            ] :
                            [
                            <div>
                              <div class = "venue-location-summary-content-info">
                                <div class = "event-experience-name-breadcrumb">{this.state.eventData.eventTitle}</div>
                                <p class = "venue-location-summary-content-info-breadcrumb"> Streaming details will be shared once the event is purchased. </p>
                              </div>
                              <div class = "venue-location-summary-content-row">
                                <i class="fas fa-phone-alt">
                                </i>
                                <span> <div style={{ marginLeft: '20px' }}>{ findElementInCountryCodeListArray(this.state.eventData.hostPhoneCountryCode,internationalCallingCodeList)  + removeZeroFromPhoneNumber(this.state.eventData.hostPhone)} </div></span>
                              </div>

                              <div class = "venue-location-summary-content-row">
                                <i class="fas fa-envelope"></i>
                                <span> <div style={{ marginLeft: '20px' }}> {this.state.eventData.hostEmail} </div></span>
                              </div>
                            </div>]
                        }
                      </div>

                    </div>
                  </div>


                </div>
              </div>


            </div>
          </div>
        </div>] : [<></>]
    )
  }
}


 class EventsClass extends Component{
   /*export class Experiences extends Component{*/
   static propTypes = {
     auth: PropTypes.object.isRequired,
     logout: PropTypes.func.isRequired,

   };

   constructor(props) {
     super(props);
     this.state = {
       eventsList: [],
       isLoading: true,
       eventsMap: null
     }
     this.fetchEventsList      = this.fetchEventsList.bind(this)
     this.initEventsMap        = this.initEventsMap.bind(this)
     this.mapAnalytics         = this.mapAnalytics.bind(this)
   }

   componentDidMount() {
     this.fetchEventsList()
     /*this.initEventsMap()*/


   }

   componentDidUpdate() {
     if (!this.state.isLoading) {


       this.initEventsMap()

     }


   }

   mapAnalytics() {

   }

   initEventsMap(){

     var map
     const locA = {lat: 51.517845, lng: -0.101729}
     const locB = {lat: 51.522084, lng: -0.090422}
     const locC = [{lat: 51.517845, lng: -0.101729}, {lat: 51.522084, lng: -0.090422}]

     loader.load().then(() => {
       map = new google.maps.Map(document.getElementById('events-map-area'), {
         /*center: { lat: 52.56447, lng: -1.46226 }*/
         center: { lat: 51.507211, lng:-0.128381 },
         zoom: 7,
       });

       const marker = new google.maps.Marker({
         position: locA,
         map: map,
         label: "1",
         icon:{
           color: "0099FF",
           fillColor: "#00FF00",
           strokeColor: "white",
           fillOpacity: 1.0,
           scale: 3,
           labelColor: '#FFFFFF',
         },
         MarkerLabel: {
           fontSize: "32px"
         }
       });


       const markerOptions = {
         fillColor: "0099FF",
         strokeColor: "white",
         fillOpacity: 0.6,
         scale: 3,
         labelColor: '#FFFFFF'


       }
       const marker2 = new google.maps.Marker({
         position: locB,
         icon: markerOptions,
         map: map,
         label: "2"



       });


     });

     // add another marketing





     return map


   }



   fetchEventsList() {

     var url = "https://www.therealgrape.com/api/auth/business-profile/my-events-listing"
     // var url = "http://127.0.0.1:8000/api/auth/business-profile/my-events-listing"


     // GET token from state
     const token = this.props.auth.token;

     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     axios.get(url, config)
       .then((response) => { this.setState({
       /*.then((response) => {console.log(response); this.setState({*/
           eventsList: response.data,
           isLoading: false,
         })
         this.forceUpdate()
       })
       .catch((error)   => console.log(error));


   }

   render()
   {
     const { isAuthenticated, user } = this.props.auth;
     var isLoading                   = this.state.isLoading
     var self                        = this

     return(

       !isLoading ? [
         <fragment>
         <div class = "all-page">
           <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
             <div class = "app-content-full container">

              <div class = "row">
                <div class = "col-sm-12 col-md-8 col-lg-8">

                  <div class = "events_and_experiences__hero events_and_experiences__hero-md">
                   <div class = "events_and_experiences__hero-container">
                     <div class = "events_and_experiences__hero-image">
                      {/*<div class = "events_and_experiences__hero-image-img-content" style = {{background: 'url(' + HeroImg + ')'}}></div>*/}
                       <img class = "events_and_experiences__hero-image-img-content" src = {'https://real-grape-site-s3-bucket.s3.us-east-2.amazonaws.com/darjeeling-biryani-800x533.jpg'}/>
                       <div class = "events_and_experiences__hero-page-description">
                         < div class = "events_and_experiences__hero-page-description-titlehello world">
                           Events
                         </div>

                         <h2 class = "events_and_experiences__hero-page-description-title-main">
                           Wine Tasting Events and Dinners
                         </h2>

                         <p class = "events_and_experiences__hero-page-description-subtitle">
                           Find that event for a special occasion. Take a break from ...
                           Find that event for a special occasion. Take a break from ...
                           Find that event for a special occasion. Take a break from ...
                           Find that event for a special occasion. Take a break from ...
                         </p>
                       </div>
                     </div>

                   </div>



                  </div>

                </div>

                <div class = "col-sm-12 col-md-4 col-lg-4">

                  <div class = "events_and_experiences__hero events_and_experiences__hero-md">
                    <div class = "HeroDetails" aria-label = "About The Real Grape and Social Media Links">
                      <div class = "HeroDetails__Wrapper">
                        <div class = "Blurb">
                          <h6 class>
                            Discover Restaurants to Love and where to hang out
                          </h6>
                          <p class = "Blurb__Content">
                            Now is the time to support restaurants. With Resy stories and guides, get the latest intel on your favorite spots and find new openings in your city.
                          </p>

                        </div>

                        <div class = "HeroDetails__SocialMedia">

                          <a class = "HeroDetails__social-media_link" href="https://www.instagram.com/therealgrape">
                            <i class="fab fa-instagram large" />
                          </a>

                          <a class = "HeroDetails__social-media_link" href="https://soundcloud.com/user-57306623" >
                            <i class="fab fa-soundcloud" ></i>
                          </a>

                          <a class = "HeroDetails__social-media_link" href="https://www.facebook.com/TheRealGrapeTravel/">
                            <i class="fab fa-facebook-f large" ></i>
                          </a>

                        </div>

                      </div>

                    </div>
                  </div>


                </div>

              </div> {/*end hero row*/}

              <div class = "row">
                <div class = "col-sm-12 col-md-12 col-lg-12">

                <span>
                  <h1 class = "my-dashboard__title_text"> Event Listings  </h1>
                  <div class = "my-dashboard__subtitle"> </div>
                </span>


                </div>
              </div>

              <div class = "row" >

                <div class = "col-sm-12 col-md-4 col-lg-6">
                {/*<div class = "col-md-4 offset-md-1 col-xl-5 offset-xl-1">*/}
                {/*<div class = "col-sm-12 col-md-4 col-lg-4">*/}

                  {/*<div class = "Events__Experiences__Store_Front_Map_Container">*/}
                  <div class = "venue-location-summary-Map_Area_Wrapper">

                    {/*<div class = "Events__Experiences__Store_Front_Map_Area" id="events-map-area" >*/}
                    <div class = "venue-location-summary_Map_Area" id="events-map-area" >
                      {/*style={{height : '100%', width: '100%'}}*/}
                    </div>


                  </div>
                </div> {/*venue map*/}

                <div class = "col-sm-12 col-md-8 col-lg-6">
                {/*<div class = "col-md-7 col-xl-6">*/}
                {/*<div class = "col-sm-12 col-md-8 col-lg-8">*/}

                  <div data-events = "events-cards">

                    <ul class = "my-dashboard-cards">
                      {self.state.eventsList.map((listing, index) => (
                        <li key = {`listing-${index}`}>

                          <div class = "row Events__Experiences__Store_Front_Card_Row_Format"> {/*start card render*/}
                              {/*Image elements*/}





                              <div class = "col-sm-6 col-md-4 col-lg-4">
                                <Link   style={{ textDecoration: 'none'}} to ={"/Events/" + listing.eventTitle.split(" ").join("-").toLowerCase() + "-" + listing.id}>
                                  <img class = "Events__Experiences__Store_Front_Img_Centered" src = {listing.mainExperienceImage} />
                                </Link>
                              </div>


                            {/*Event Details*/}
                            <div class = "col-sm-6 col-md-8 col-lg-8">
                                <div class = "row">
                                  <div class = "col-sm-12 col-md-12 col-lg-12">
                                    <div class = "Events__Experiences__Store_Front_Event-title">
                                      {listing.eventTitle}
                                    </div>
                                  </div>
                                  <div class = "col-sm-12 col-md-12 col-lg-12">
                                    <div class = "Events__Experiences__Store_Front__copy__description">
                                      {listing.eventType}
                                    </div>
                                  </div>
                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__copy__date">
                                      {dateFormatter(listing.date)}
                                    </div>
                                  </div>
                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__copy__date">
                                      {listing.time}
                                    </div>
                                  </div>
                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__copy__date">
                                      {listing.timeZone}
                                    </div>
                                  </div>
                                  <div class = "col-sm-12 col-md-8 col-lg-8">
                                    <div class = "Events__Experiences__Store_Front__copy__strapline">
                                      Tickets From {getSymbolFromCurrency(listing.currency)}{listing.price.toFixed(2)}
                                    </div>
                                  </div>
                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__category">
                                      {listing.olineExperience ? `Online Event` : ``}
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div> {/*end card render*/}

                        </li>
                      ))}
                    </ul>


                  </div>

                </div> {/*listing col*/}


              </div>




             </div>
            </div>
          </div>
         <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
           <div class = "app-content-full container">








           </div>


         </div>



         </fragment>

       ] : <div><div id="map"></div></div>


     );
   }
 }



 class EventsListClass extends Component{

  constructor(props) {
    super(props);
      this.state = {
        eventsList: [],
        isLoading: true,
        isLoadingAnEvent: true,
        isEditing: false,
        eventData : {},

        // variables for uploading data
        eventTitle: null,
        mainExperienceImage: null,
        currency: null,
        description: null,
        eventType: null,
        hashTags: null,
        host: null,
        date: null,
        hostEmail: null,
        hostPhone: null,
        hostPhoneCountryCode: null,
        maximumQuantity: null,
        price: null,
        publishEvent: null,
        quantity: null,
        ticketsPerOrder: null,
        summary: null,
        olineExperience: null,
        locationAddress: null,
        locationCountry: null,
        locationZip_code: null,
        locationcity: null,
        salesEndDate: null,
        salesStartDate: null,
        time: null,
        salesEndTime: null,
        salesEndTime: null,
        timeZone: 'GMT',







        // variables for handling post data
        udpateFieldName: '',
        updateFieldValue: null,
        updatePostData  : {},

        // Pagination variables
        currentItemsList: [],
        currentPage: 0,
        listingsPerPage: 3,
        firtListItemIndx: 0,
        lastListItemIndx: 3,
        numberOfListings: 10,
        pageNumbers:[]
      }

    this.fetchEventsList      = this.fetchEventsList.bind(this)
    this.pagination           = this.pagination.bind(this)
    this.manageEvent          = this.manageEvent.bind(this)
    this.handleInputChange    = this.handleInputChange.bind(this)
    this.handleFileInput      = this.handleFileInput.bind(this)
    this.SaveImage            = this.SaveImage.bind(this)
    this.editEventItem        = this.editEventItem.bind(this)
    this.handleSave           = this.handleSave.bind(this)
    this.cancelEditEventItem  = this.cancelEditEventItem.bind(this)
    this.clearFormElements    = this.clearFormElements.bind(this)
    this.handleDateInput      = this.handleDateInput.bind(this)
    this.HandelDateRangeInput = this.HandelDateRangeInput.bind(this)
    this.handleTimeInput      = this.handleTimeInput.bind(this)
    this.handleTimeZoneInput  = this.handleTimeZoneInput.bind(this)
    this.handlePublish        = this.handlePublish.bind(this)


  }

  static propTypes = {
   auth: PropTypes.object.isRequired,
   logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchEventsList()

  }

  componentDidUpdate(){

    this.fetchEventsList()

  }

  pagination(){
    var pageNumbersArray = []
    for(let i = 1; i <= Math.ceil(this.numberOfListings / this.listingsPerPage); i++ )
    {
      pageNumbersArray.push(i)
    }
    this.setState({
      pageNumbers : pageNumbersArray
    })
  }

  fetchEventsList(){

    var url = "https://www.therealgrape.com/api/auth/business-profile/my-events-listing"
   // var url = "http://127.0.0.1:8000/api/auth/business-profile/my-events-listing"


    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
          eventsList: response.data,
          isLoading: false,
        })})
      .catch((error)   => console.log(error));



  }

  manageEvent(itemToEdit){
    /*
      http://127.0.0.1:8000/api/auth/business-profile/event-details/1
      http://127.0.0.1:8000/api/auth/business-profile/experience-details/1
      http://127.0.0.1:8000/api/auth/business-profile/wine-list-details/1
      http://127.0.0.1:8000/api/auth/business-profile/wine-details/1
    */

    this.setState({
      isEditing: true,
      isLoadingAnEvent: true,
    })






    var url = "https://www.therealgrape.com/api/auth/business-profile/event-details/"+itemToEdit.toString()
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/event-details/"+itemToEdit.toString()



    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        eventData: response.data,
        isLoadingAnEvent: false,
        })


      })
      .catch((error)   => console.log(error));



  }

  handleInputChange(event){

    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name
    // handle the create button
    var createBtn     = document.getElementById('create-event__create_button')

    this.setState({
      [name]: value,
      updateFieldName: name,
      updateFieldValue: value,
    })

    var postData   = this.state.updatePostData
    postData[name] = value
    this.setState({
      updatePostData: postData
    })


  }

  handleFileInput(event) {
    // handle validations
    // const file = event.target.files[0];
    this.setState({
      mainExperienceImage: event.target.files[0]
    })
    /*if (file.size > 1024)
    onFileSelectError({ error: "File size cannot exceed more than 1MB" });
    else onFileSelectSuccess(file);*/
  }

  SaveImage(event) {
      event.preventDefault()

      var imgData  = new FormData();

      imgData.append('mainExperienceImage',this.state.mainExperienceImage)
      //imgData.append("name", name);

      var url = "https://www.therealgrape.com/api/auth/business-profile/event-image-update/"+ this.state.eventData.id.toString()+"/"
      // var url = "http://127.0.0.1:8000/api/auth/business-profile/event-image-update/"+ this.state.eventData.id.toString()+"/"

      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now post the Data
      axios.post(url, imgData, config)
      .then()

      // clear the form elements
      this.clearFormElements()

      // refresh form data
      this.manageEvent(this.state.eventData.id)


  }

  editEventItem(event){

    event.preventDefault()

    const elementId     =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''


    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide form element, save button and camcel button
    formElement.classList.remove("hidden");
    saveBtn.classList.remove("hidden");
    cancelBtn.classList.remove("hidden");

    // hide the edit button
    editBtn.classList.add("hidden");

  }

  handleSave(event) {

    event.preventDefault()

    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-save", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    if (this.state.updateFieldValue != null)
    {

      var field2Update         = this.state.udpateFieldName
      const constUpdateValue   = this.state.updateFieldValue
      const postDataFinal      = this.state.updatePostData

      var postData = {}
      postData[field2Update] = constUpdateValue

      var url = "https://www.therealgrape.com/api/auth/business-profile/event-details-update/"+ this.state.eventData.id.toString()+"/"
      //var url = "http://127.0.0.1:8000/api/auth/business-profile/event-details-update/"+ this.state.eventData.id.toString()+"/"

      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now we need to stringfy the field that we want to provide
      // Request body


      const body = JSON.stringify(postDataFinal);

      // now post the Data
      axios.post(url, body, config)
      .then()

      // reset the state variables for field updates to null
      this.setState({
        udpateFieldName: '',
        updateFieldValue: null,
        editing:false
      })

      // clear anything that is currently in the updatePostData

      this.setState({
          updatePostData : {}
      })

      // return all form data to original state

      this.setState({
        eventTitle: null
      })

    }

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");

    // clear the form elements
    this.clearFormElements()

    // refresh form data
    this.manageEvent(this.state.eventData.id)
  }

  clearFormElements() {

    document.getElementById("eventTitle-editMode-form").value = ""
    document.getElementById("mainExperienceImage-editMode-form").value = ""

  }
  cancelEditEventItem(event) {

    /* handle the cancel button*/
    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-cancel", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");


    // clear anything that is currently in the updatePostData

    this.setState({
        updatePostData : {}
    })

    // return all form data to original state

    this.setState({

        eventTitle: null


    })

    // clear all form elements
    this.clearFormElements()

  }

  handleDateInput(e) {


    /*console.log(typeof e.toDate())
    var date = e.toDate().toString()*/
    var dateToSave = new Date(e.format())
    var name       = "date"

    this.setState({date: e,
      updateFieldName: "date",
      updateFieldValue: dateToSave
    })

    var postData   = this.state.updatePostData
    postData[name] = dateToSave
    this.setState({
      updatePostData: postData
    })


  }

  HandelDateRangeInput(e){

    var postData   = this.state.updatePostData

    if (e.startDate !== null) {

      this.setState({
        salesStartDate: e.startDate

      })

      var dateToSave = new Date(e.startDate.format())
      postData['salesStartDate'] = dateToSave
      this.setState({
        updatePostData: postData,
        updateFieldName: "salesStartDate",
        updateFieldValue: dateToSave

      })



    }

    if (e.endDate !== null) {

      this.setState({
        salesEndDate: e.endDate
      })

      var dateToSave = new Date(e.endDate.format())
      postData['salesEndDate'] = dateToSave
      this.setState({
        updatePostData: postData,
        updateFieldName: "salesEndDate",
        updateFieldValue: dateToSave

      })

    }



  }

  handleTimeInput(e, fieldName){

    const timeToSave       = e.formatted24
    var postData   = this.state.updatePostData
    postData[fieldName] = timeToSave



    this.setState({
      time: timeToSave,
      updatePostData: postData,
      updateFieldName: fieldName,
      updateFieldValue: timeToSave

    })

  }

  handleTimeZoneInput(e, fieldName){

    const timeZoneToSave = e.label.split(")")[0].split("(")[1]


    var postData   = this.state.updatePostData
    postData[fieldName] = timeZoneToSave

    this.setState({
      timeZone: timeZoneToSave,
      updatePostData: postData,
      updateFieldName: fieldName,
      updateFieldValue: timeZoneToSave
    })

  }

  handlePublish(e) {

    // do not execute with the postdata
    var postData             = {}
    postData['publishEvent'] = true

    var url = "https://www.therealgrape.com/api/auth/business-profile/event-details-update/"+ this.state.eventData.id.toString()+"/"
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/event-details-update/"+ this.state.eventData.id.toString()+"/"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body
    const body = JSON.stringify(postData);

    // now post the Data
    axios.post(url, body, config)
    .then()

  }



   render()
   {
     const { isAuthenticated, user } = this.props.auth;
     var             isLoading       = this.state.isLoading
     var      isLoadingAnEvent       = this.state.isLoadingAnEvent
     var             isEditing       = this.state.isEditing
     var                      self   = this


     // variables for pagination
     const indexOfLastListedItem     = this.state.currentPage * this.state.listingsPerPage
     const indexOfFirstListedItem    = indexOfLastListedItem  - this.state.listingsPerPage


     return(
      !isLoading ?
        (!isEditing ) ?

         <div>
          <div class = "dashboard-containeer">
            <div class = "form dashboard-form"  id = "my-create-event-form">

              <div class = "row dashboard-row-wrapper">
                <div class ="col-sm-10 col-md-10 col-lg-10">
                  <span>
                    <h1 class = "my-dashboard__title_text"> My Events   </h1>
                    <div class = "my-dashboard__subtitle">Click on an event, make changes and publish test</div>
                  </span>
                </div>

                <div class ="col-sm-2 col-md-2 col-lg-2">

                </div>

              </div> {/*End row element*/}

              <div class = "row dashboard-row-wrapper">
                <div class ="col-sm-12 col-md-12 col-lg-12">
                  <div class = "event_experience-stack">
                    <div class = "event_experience-stack-element-inner">
                      <ul class = "event_experience-cards" >
                        {self.state.eventsList.map((listing, index) => (
                          <li key={`listing-${index}`} >

                          <div class = "event_experience-entry" style = {{marginTop: "10px"}} > {/*winelist-entry*/}

                            <div class = "row" >
                              <div class = "col-sm-12 col-md-12 col-lg-12" onClick={() => self.manageEvent(listing.id)}>
                                  <div class = "event_experience-entry-inner">
                                    <div class = "event_experience-entry-title" style = {{marginTop: "5px"}}> {listing.eventTitle} {listing.olineExperience ? "(Online)" : ""}</div>
                                  </div>
                              </div>
                            </div> {/*Title row*/}

                            <div class = "row">
                              <div class = "col-sm-12 col-md-2 col-lg-2">

                                <div class = "my-dashboard-cards__acard__img">
                                  <div class = "image-map-wrapper">

                                    {/*<img alt="" class="cart_Img_Centered" src = "https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png"/>*/}
                                    <img alt="" class="cart_Img_Centered" src = {listing.mainExperienceImage === null ? 'https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png' : listing.mainExperienceImage}/>

                                  </div>
                                </div>

                              </div> {/*end image col*/}

                              <div class = "col-sm-12 col-md-10 col-lg-10">
                                <div class = "row">
                                  <div class = "col-sm-12 col-md-6 col-lg-6">

                                    <div class = "row">
                                      <div class = "col-sm-12 col-md-12 col-lg-12">
                                        <div class = "event_experience-entry-inner">
                                          <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div>  Venue: {listing.olineExperience ? "Online Experience" : listing.locationAddress} </div> </div>
                                        </div>
                                      </div>
                                      <div class = "col-sm-12 col-md-12 col-lg-12">
                                        <div class = "event_experience-entry-inner">
                                          <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Date and time: {dateFormatterFromString(listing.date,listing.time) }</div> </div>
                                        </div>
                                      </div>
                                      <div class = "col-sm-12 col-md-12 col-lg-12">
                                        <div class = "event_experience-entry-inner">
                                          <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Timezone: {listing.timeZone} </div> </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div> {/*venue, date and time*/}
                                  <div class = "col-sm-12 col-md-6 col-lg-6">

                                    <div class = "row">

                                      <div class = "col-sm-12 col-md-12 col-lg-12">
                                        <div class = "event_experience-entry-inner">
                                          <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Status: {listing.status} </div> </div>
                                        </div>
                                      </div>
                                      <div class = "col-sm-12 col-md-12 col-lg-12">
                                        <div class = "event_experience-entry-inner">
                                          <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Sales: {listing.ticketsSold}/{listing.quantity} </div> </div>
                                        </div>
                                      </div>
                                      <div class = "col-sm-12 col-md-12 col-lg-12">
                                        <div class = "event_experience-entry-inner">
                                          <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Gross: {getSymbolFromCurrency(listing.currency)}{listing.grossSales} / {getSymbolFromCurrency(listing.currency)}{listing.potentialSales} </div> </div>
                                        </div>
                                      </div>


                                    </div>

                                  </div> {/*status sales and gross*/}
                                </div>
                              </div> {/*details col*/}

                            </div>
                          </div>


                          </li>
                        ))}

                      </ul>
                    </div>
                  </div>
                </div>
              </div>


              <div class = "row dashboard-row-wrapper">
                <div class ="col-sm-2 col-md-2 col-lg-2">

                  <div class = "my-dashboard__subtitle"> Page</div>
                </div>
                <div class ="col-sm-8 col-md-8 col-lg-8">

                </div>
                <div class ="col-sm-2 col-md-2 col-lg-2">

                </div>
              </div> {/*End row element*/}

            </div>
          </div>
        </div>



          : <fragment>

            <div class = "my-dashboard-page">



              {!isLoadingAnEvent ?
                [

                  <div>
                    <div class = "dashboard-containeer">
                      <div class = "form dashboard-form"  id = "my-event-info-form">

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-10 col-md-10 col-lg-10">
                            <span>
                              <h1 class = "my-dashboard__title_text"> Manage Event</h1>
                              <div class = "my-dashboard__subtitle"> A strapline </div>
                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            {
                              (self.state.eventData.readyToPublish && self.state.eventData.status == 'Draft') ?
                              [
                                <span>
                                  <button onClick = {(e) => self.handlePublish(e)}  id = "create-event__publish_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-activate-outlined" type = "button">
                                    <span class="bui-button__text">Publish</span>
                                  </button>
                                </span>
                              ] : <></>
                            }

                            {
                              (self.state.eventData.status == 'Active') ?
                              [
                                <span>
                                  <button id = "create-event__cancel_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-activate-outlined" type = "button">
                                    <span class="bui-button__text">Cancel</span>
                                  </button>
                                </span>
                              ] : <></>
                            }

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  > Sales Summary </div>
                          </div>
                          <div class ="col-sm-10 col-md-10 col-lg-10">
                            <span>
                             <div class = "my-dashboard__subtitle"> Event Status: {self.state.eventData.status} </div>
                             <div class = "my-dashboard__subtitle"> Gross sales:  {getSymbolFromCurrency(self.state.eventData.currency)}{self.state.eventData.grossSales} / {getSymbolFromCurrency(self.state.eventData.currency)}{self.state.eventData.potentialSales} </div>
                            </span>
                          </div>

                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Event Title</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.eventTitle}

                                  <label class = "dashboard__input__text__label" for = "eventTitle">
                                    <input   value = {self.state.eventTitle}
                                             onChange={this.handleInputChange}
                                             id = "eventTitle-editMode-form"
                                    name = "eventTitle" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "eventTitle"
                                    placeholder = "Event title"></input>
                                  </label>





                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "eventTitle-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "eventTitle-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "eventTitle-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Event Image</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>



                              <div class = "my-dashboard-cards__acard__img">
                                <a href = "#" class = "my-dashboard-listings-edit-link">
                                  <img src = {self.state.eventData.mainExperienceImage} class = "my-dashboard-listings__img"/>
                                </a>
                              </div>

                              <label class = "dashboard__input__text__label" for = "mainExperienceImage">
                               <input
                                      onChange={this.handleFileInput}
                                      id = "mainExperienceImage-editMode-form"
                                      required name = "mainExperienceImage"
                                      class="dashboard__select__button__style dashboard__input__text__control hidden "
                                      type = "file"
                                      if = "mainExperienceImage"/>
                              </label>



                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "mainExperienceImage-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.SaveImage(e)} id = "mainExperienceImage-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "mainExperienceImage-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Payment Currency</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              <p> {findElementInCCyListArray(self.state.eventData.currency,currencyCodes )} ({getSymbolFromCurrency(self.state.eventData.currency)})</p>


                              <select  value={self.state.currency}
                                       onChange={self.handleInputChange}
                                       id = "currency-editMode-form"
                                       name = "currency"
                                       class = "dashboard__select__button__style dashboard__input__text__control hidden" >
                                <option value="GBP">{self.state.eventData.currency} </option>
                                {currencyCodesReduced.map((ccyCodesSelect) =>

                                  <option value = {ccyCodesSelect.optionValue}>{ccyCodesSelect.countryCode} </option>

                                )}
                               </select>



                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "currency-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "currency-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "currency-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>


                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Description</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.description}



                                  <label class = "dashboard__input__text__label" for = "wineListdescription">
                                    <textarea value = {self.state.description}
                                              onChange={this.handleInputChange}
                                              id = "description-editMode-form"
                                              name = "description" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "wineListdescription"
                                              placeholder = "Describe your wine list"></textarea>
                                  </label>


                                <div class = "dashboard-input-information-caption">
                                  Please provide a description of the event.
                                </div>

                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "description-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "description-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "description-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Event Type</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.eventType}

                                  <label class = "dashboard__input__text__label" for = "eventType">
                                    <input   value = {self.state.eventType}
                                             onChange={this.handleInputChange}
                                             id = "eventType-editMode-form"
                                    name = "eventType" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "eventType"
                                    placeholder = "Event type"></input>
                                  </label>





                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "eventType-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "eventType-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "eventType-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Hashtags</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.hashTags}

                                  <label class = "dashboard__input__text__label" for = "hashTags">
                                    <input   value = {self.state.hashTags}
                                             onChange={this.handleInputChange}
                                             id = "hashTags-editMode-form"
                                    name = "hashTags" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "hashTags"
                                    placeholder = "Hashtags"></input>
                                  </label>


                                  <div class = "dashboard-input-information-caption">
                                    Please list you hashags like: #wine #wineoclock
                                  </div>


                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "hashTags-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "hashTags-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "hashTags-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}



                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Host</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.host}

                                  <label class = "dashboard__input__text__label" for = "host">
                                    <input   value = {self.state.host}
                                             onChange={this.handleInputChange}
                                             id = "host-editMode-form"
                                    name = "host" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "host"
                                    placeholder = "Host"></input>
                                  </label>


                                  <div class = "dashboard-input-information-caption">
                                    Please provide the name of the host of the event.
                                  </div>


                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "host-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "host-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "host-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>
                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Date </div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                            {self.state.eventData.date}

                            {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                            <div class = "hidden"
                                 id    = "date-editMode-form">
                              <SingleDatePicker
                                 date={this.state.date} // momentPropTypes.momentObj or null

                                 onDateChange={date => self.handleDateInput(date)}

                                 focused={this.state.focused} // PropTypes.bool
                                 onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                 numberOfMonths={1}
                                 /*daySize = {10}*/
                                 /*isOutsideRange*/
                                 id="date-single-date-picker" // PropTypes.string.isRequired,

                             />
                            </div>



                           {/*onDateChange={date => this.setState({date})}*/}




                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "date-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "date-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "date-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Time</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                            {self.state.eventData.time}

                            {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                            <div class = "hidden"
                                 id    = "time-editMode-form">

                                 <TimeKeeper
                                   time={self.state.time}
                                   onChange={(e) => self.handleTimeInput(e, 'time')}
                                 />

                                 {/*<TimePicker
                                 onChange={e => self.handleTimeInput(e)}
                                 value={self.state.time}
                                 />*/}

                            </div>



                           {/*onDateChange={date => this.setState({date})}*/}




                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "time-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "time-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "time-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Time Zone</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                            {self.state.eventData.timeZone}


                            <div class = "hidden"
                                 id    = "timeZone-editMode-form">
                                 <TimezoneSelect
                                   value={self.state.timeZone}
                                   onChange={(e) => self.handleTimeZoneInput(e, 'timeZone')}
                                 />
                            </div>

                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "timeZone-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "timeZone-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "timeZone-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Sales Start Time</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                            {self.state.eventData.salesStartTime}

                            {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                            <div class = "hidden"
                                 id    = "salesStartTime-editMode-form">

                                 <TimeKeeper
                                   time={self.state.salesStartTime}
                                   onChange={(e) => self.handleTimeInput(e, 'salesStartTime')}
                                 />

                                 {/*<TimePicker
                                 onChange={e => self.handleTimeInput(e)}
                                 value={self.state.time}
                                 />*/}

                            </div>



                           {/*onDateChange={date => this.setState({date})}*/}




                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "salesStartTime-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "salesStartTime-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "salesStartTime-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Sales End Time</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                            {self.state.eventData.salesEndTime}

                            {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                            <div class = "hidden"
                                 id    = "salesEndTime-editMode-form">

                                 <TimeKeeper
                                   time={self.state.salesEndTime}
                                   onChange={(e) => self.handleTimeInput(e, 'salesEndTime')}
                                 />

                                 {/*<TimePicker
                                 onChange={e => self.handleTimeInput(e)}
                                 value={self.state.time}
                                 />*/}

                            </div>



                           {/*onDateChange={date => this.setState({date})}*/}




                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "salesEndTime-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "salesEndTime-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "salesEndTime-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}



                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Sales Dates</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                            {self.state.eventData.salesStartDate} to {self.state.eventData.salesEndDate}


                            {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                            <div class = " hidden"
                                 id    = "salesDateRange-editMode-form">

                                 <DateRangePicker
                                   startDate={this.state.salesStartDate} // momentPropTypes.momentObj or null,
                                   startDateId="salesStartDate-date-range-picker" // PropTypes.string.isRequired,
                                   endDate={this.state.salesEndDate} // momentPropTypes.momentObj or null,
                                   endDateId="salesEndDate-date-range-picker" // PropTypes.string.isRequired,
                                    // PropTypes.func.isRequired,
                                   onDatesChange={(e) => this.HandelDateRangeInput(e)}
                                   numberOfMonths={1}
                                   focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                   onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,

                                 />


                            </div>
                            {/*onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}*/}


                           {/*onDateChange={date => this.setState({date})}*/}




                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "salesDateRange-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "salesDateRange-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "salesDateRange-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Host Email</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.hostEmail}

                                  <label class = "dashboard__input__text__label" for = "hostEmail">
                                    <input   value = {self.state.hostEmail}
                                             onChange={this.handleInputChange}
                                             id = "hostEmail-editMode-form"
                                    name = "hostEmail" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "email" if = "hostEmail"
                                    placeholder = "hostEmail"></input>
                                  </label>


                                  <div class = "dashboard-input-information-caption">
                                    Please provide the name of the host of the event.
                                  </div>


                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "hostEmail-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "hostEmail-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "hostEmail-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}



                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Host Phone Number</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                            {`${findElementInCountryCodeListArray(self.state.eventData.hostPhoneCountryCode,internationalCallingCodeList) + self.state.eventData.hostPhone}  `}

                            <div id = "hostPhone-editMode-form" class = "dashboard-iux-phone-number hidden">


                                  <select  value={self.state.hostPhoneCountryCode}  onChange={self.handleInputChange} name = "hostPhoneCountryCode" id = "hostPhoneCountryCode-editMode-form-countryCode" class = "dashboard__select__button__style">
                                    <option value="">{findElementInCountryListArray(self.state.eventData.hostPhoneCountryCode, countryList)}</option>
                                    {countryList.map((phoneCountryCode) =>
                                      <option value = {phoneCountryCode.optionValue}>{phoneCountryCode.country} </option>

                                    )}
                                  </select>



                                  <label class = "dashboard__input__text__label" for="hostPhone">
                                    {/*form-control form-control-sm form-field*/}
                                    <div class = "dashboard__input__text__field">
                                      <input  value={self.state.hostPhone}  onChange={self.handleInputChange} name = "hostPhone" id = "hostPhone.phone-editMode-form-phoneNumber" class = "dashboard__select__button__style dashboard__input__text__control" type="tel"  placeholder="Host Phone Number"></input>
                                    </div>
                                  </label>

                              </div>
                              <div class = "dashboard-input-information-caption">
                                We will use this as your primary contact number for the host. This will be provided to the attendees.
                              </div>
                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "hostPhone-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}            id = "hostPhone-editMode-save"   class="bui-button bui-button-tertiary-danger hidden" ><span>Save</span></button></span>
                            <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "hostPhone-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" ><span> Cancel </span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Maximum Quanity </div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.maximumQuantity}

                                  <label class = "dashboard__input__text__label" for = "maximumQuantity">
                                    <input   value = {self.state.maximumQuantity}
                                             onChange={this.handleInputChange}
                                             id = "maximumQuantity-editMode-form"
                                    name = "maximumQuantity" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "number" if = "maximumQuantity"
                                    placeholder = "Number of tickets issued"></input>
                                  </label>


                                  <div class = "dashboard-input-information-caption">
                                    Maximum number of tickets that can be purchased by a guest.
                                  </div>


                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "maximumQuantity-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "maximumQuantity-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "maximumQuantity-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Price </div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.price}

                                  <label class = "dashboard__input__text__label" for = "price">
                                    <input   value = {self.state.price}
                                             onChange={this.handleInputChange}
                                             id = "price-editMode-form"
                                    name = "price" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "price"
                                    placeholder = "Price per ticket"></input>
                                  </label>


                                  <div class = "dashboard-input-information-caption">
                                    Price per ticket.
                                  </div>


                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "price-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "price-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "price-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Quantity</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.quantity}

                                  <label class = "dashboard__input__text__label" for = "quantity">
                                    <input   value = {self.state.quantity}
                                             onChange={this.handleInputChange}
                                             id = "quantity-editMode-form"
                                    name = "quantity" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "quantity"
                                    placeholder = "Quantity"></input>
                                  </label>


                                  <div class = "dashboard-input-information-caption">
                                    This is the total number of tickets to be issued for the event.
                                  </div>


                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "quantity-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "quantity-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "quantity-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Tickets Per Order</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.ticketsPerOrder}


                                  <label class = "dashboard__input__text__label" for = "ticketsPerOrder">
                                    <input   value = {self.state.ticketsPerOrder}
                                             onChange={this.handleInputChange}
                                             id = "ticketsPerOrder-editMode-form"
                                    name = "ticketsPerOrder" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "ticketsPerOrder"
                                    placeholder = "Number of guests admited per ticket"></input>
                                  </label>


                                  <div class = "dashboard-input-information-caption">
                                    Number of guests admitted per ticket.
                                  </div>


                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "ticketsPerOrder-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}   id = "ticketsPerOrder-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "ticketsPerOrder-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Summary</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>

                              {self.state.eventData.summary}



                                  <label class = "dashboard__input__text__label" for = "summary">
                                    <textarea value = {self.state.summary}
                                              onChange={this.handleInputChange}
                                              id = "summary-editMode-form"
                                              name = "summary" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "summary"
                                              placeholder = "Summary of your event"></textarea>
                                  </label>


                                <div class = "dashboard-input-information-caption">
                                  Please provide a summary of the event.
                                </div>

                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "summary-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "summary-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "summary-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}

                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Online Event</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>
                                 {self.state.eventData.olineExperience ? <div> Online </div> :<div> Live</div>}

                                  <label class = "dashboard__input__text__label" for = "olineExperience">
                                    <input value={this.state.olineExperience}
                                    onChange={this.handleInputChange}
                                    id = "olineExperience-editMode-form"
                                    name = "olineExperience"
                                    class="dashboard__select__checkbox__style hidden"
                                    type = "checkbox" if = "olineExperience"
                                    placeholder = "Online Experience"></input>
                                  </label>

                                  <div class = "dashboard-input-information-caption">
                                    Please select the checkbox if the event will be streamed online. If it will be physically hosted then leave the box unchecked.
                                  </div>

                            </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)}  id = "olineExperience-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)} id = "olineExperience-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "olineExperience-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                          </div>
                        </div> {/*End row element*/}


                        <div class = "row dashboard-row-wrapper">
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <div class = "dashboard-row-title"  >Address</div>
                          </div>
                          <div class ="col-sm-8 col-md-8 col-lg-8">
                            <span>


                            {self.state.eventData.locationAddress} <br/>


                            {self.state.eventData.locationcity}  <br/>

                            {self.state.eventData.locationZip_code}  <br/>

                            {findElementInCountryListArray(self.state.eventData.locationCountry, countryList)}  <br/>

                                                      <div id = "address-editMode-form" class = "hidden">
                                                        <div class = "form-group col-sm-12 col-md-6">
                                                          <label class = "adressDetails" >
                                                            <input value={this.state.locationAddress}
                                                                   onChange={this.handleInputChange}
                                                                   name = "locationAddress"
                                                                   class = "form-control form-control-sm form-field"
                                                                   type="text" id="locationAddress"
                                                                   placeholder="Address"></input>
                                                          </label>
                                                        </div>


                                                          <div class = "form-group col-sm-12 col-md-6">
                                                            <label class = "locationcity" >
                                                              <input value={this.state.locationcity}
                                                              onChange={this.handleInputChange}
                                                              name = "locationcity"
                                                              class = "form-control form-control-sm form-field"
                                                              type="text"
                                                              id="locationcity"
                                                              placeholder="City"></input>
                                                            </label>
                                                          </div>



                                                         <div class = "form-group col-sm-12 col-md-6">
                                                           <label class = "locationZip_code" >
                                                             <input value={this.state.locationZip_code}
                                                             onChange={this.handleInputChange}
                                                             name = "locationZip_code"
                                                             class = "form-control form-control-sm form-field"
                                                             type="text"
                                                             id="locationZip_code"
                                                             placeholder="Postcode"></input>
                                                           </label>
                                                         </div>



                                                         <div class = "form-group col-sm-12 col-md-6">
                                                           <label>
                                                           <select value={this.state.locationCountry}
                                                                   onChange={this.handleInputChange}
                                                                   name = "locationCountry"
                                                                   class = "form-control form-control-sm form-field" >
                                                             <option value="">{findElementInCountryListArray(self.state.eventData.locationCountry, countryList)}</option>
                                                             {countryList.map((countrySelect) =>

                                                               <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                                                             )}
                                                           </select>
                                                           </label>
                                                         </div>
                                                      </div>

                              </span>
                          </div>
                          <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span>
                              <button onClick = {(e) => self.editEventItem(e)} id = "address-editMode"  target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                <span class="bui-button__text">Edit</span>
                              </button>
                            </span>

                            <span><button onClick = {(e) => self.handleSave(e)}            id = "address-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span>Save</span></button></span>
                            <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "address-editMode-cancel"  class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span> Cancel </span></button></span>

                          </div>
                        </div> {/*End row element*/}





                      </div>
                    </div>
                  </div>

                  ] : [<div></div>]
              }

            </div>

         </fragment> :<fragment>
         </fragment>
     );
   }
 }


class CreateEventsUnconnected extends Component {

   constructor(props) {
     super(props);
       this.state = {
         eventTitle: null,
         olineExperience: false
       }

       this.handleInputChange = this.handleInputChange.bind(this)
       this.handleCreate      = this.handleCreate.bind(this)
       this.clearFormElements = this.clearFormElements.bind(this)

   }

   static propTypes = {
     auth: PropTypes.object.isRequired,
     logout: PropTypes.func.isRequired,
   };

   handleInputChange(event){
    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    // handle the create button
    var createBtn     = document.getElementById('create-event__create_button')

    this.setState({
      [name]: value
    })

    // if an event name is provided then unhide buttons
    if (name === "eventTitle")
    {

      createBtn.classList.remove("hidden");
    }

   }

   handleCreate(event) {

     event.preventDefault()

     // handle the create button
     var createBtn     = document.getElementById('create-event__create_button')

     // capture the post data
     var postData = {}
     postData['eventTitle']      = this.state.eventTitle
     postData['olineExperience'] = this.state.olineExperience


     var url = "https://www.therealgrape.com/api/auth/business-profile/create-event"
     // var url = "http://127.0.0.1:8000/api/auth/business-profile/create-event"

     // GET token from state
     const token = this.props.auth.token;


     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     // now we need to stringfy the field that we want to provide
     // Request body

     const body = JSON.stringify(postData);

     // now post the Data
     axios.post(url, body, config)
     .then()

     // clear the form elements and return statevariables to starting state
     this.setState({
       eventTitle: null,
       olineExperience: false
     })

     this.clearFormElements()

     // hide the create event button
     createBtn.classList.add("hidden");

   }

   clearFormElements() {
     document.getElementById("create-event__eventTitle").value = null
     document.getElementById("create-event__olineExperience").checked = false
   }

   render()
   {
     var     self   = this
     return(

       <div>
        <div class = "dashboard-containeer">
          <div class = "form dashboard-form"  id = "my-create-event-form">
            <div class = "row dashboard-row-wrapper">
              <div class ="col-sm-10 col-md-10 col-lg-10">
                <span>
                  <h1 class = "my-dashboard__title_text"> Create an Event  </h1>
                  <div class = "my-dashboard__subtitle">Create a new event</div>
                </span>
              </div>

              <div class ="col-sm-2 col-md-2 col-lg-2">
                <span>
                  <button onClick = {(e) => self.handleCreate(e)}  id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                    <span class="bui-button__text">Create</span>
                  </button>
                </span>
              </div>

            </div> {/*End row element*/}

            <div class = "row dashboard-row-wrapper">
              <div class ="col-sm-2 col-md-2 col-lg-2">
                <div class = "dashboard-row-title"  >Event Title</div>
              </div>
              <div class ="col-sm-8 col-md-8 col-lg-8">
                <span>

                      <label class = "dashboard__input__text__label" for = "eventTitle">
                        <input value={this.state.eventTitle}  onChange={this.handleInputChange}  id = "create-event__eventTitle"
                        name = "eventTitle" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "eventTitle"
                        placeholder = "Event Title"></input>
                      </label>

                </span>
              </div>
              <div class ="col-sm-2 col-md-2 col-lg-2">

              </div>
            </div> {/*End row element*/}

            <div class = "row dashboard-row-wrapper hidden">
              <div class ="col-sm-2 col-md-2 col-lg-2">
                <div class = "dashboard-row-title"  >Online Event</div>
              </div>
              <div class ="col-sm-8 col-md-8 col-lg-8">
                <span>

                      <label class = "dashboard__input__text__label" for = "olineExperience">
                        <input value={this.state.olineExperience}  onChange={this.handleInputChange}  id = "create-event__olineExperience"
                        name = "olineExperience" class="dashboard__select__checkbox__style" type = "checkbox" if = "olineExperience"
                        placeholder = "Online Experience"></input>
                      </label>

                      <div class = "dashboard-input-information-caption">
                        Please select the checkbox if the event will be streamed online. If it will be physically hosted then leave the box unchecked.
                      </div>

                </span>
              </div>
              <div class ="col-sm-2 col-md-2 col-lg-2">

              </div>
            </div> {/*End row element*/}

          </div>
        </div>
       </div>




     )
   }

 }

 class CreateEventsUnconnectedDepricated extends Component {

   constructor(props) {
     super(props);
       this.state = {
         eventTitle: null,
         olineExperience: false
       }

       this.handleInputChange = this.handleInputChange.bind(this)
       this.handleCreate      = this.handleCreate.bind(this)
       this.clearFormElements = this.clearFormElements.bind(this)

   }

   static propTypes = {
     auth: PropTypes.object.isRequired,
     logout: PropTypes.func.isRequired,
   };

   handleInputChange(event){
    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    // handle the create button
    var createBtn     = document.getElementById('create-event__create_button')

    this.setState({
      [name]: value
    })

    // if an event name is provided then unhide buttons
    if (name === "eventTitle")
    {

      createBtn.classList.remove("hidden");
    }

   }

   handleCreate(event) {

     event.preventDefault()

     // handle the create button
     var createBtn     = document.getElementById('create-event__create_button')

     // capture the post data
     var postData = {}
     postData['eventTitle']      = this.state.eventTitle
     postData['olineExperience'] = this.state.olineExperience


     var url = "https://www.therealgrape.com/api/auth/business-profile/create-event"
     // var url = "http://127.0.0.1:8000/api/auth/business-profile/create-event"

     // GET token from state
     const token = this.props.auth.token;


     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     // now we need to stringfy the field that we want to provide
     // Request body

     const body = JSON.stringify(postData);

     // now post the Data
     axios.post(url, body, config)
     .then()

     // clear the form elements and return statevariables to starting state
     this.setState({
       eventTitle: null,
       olineExperience: false
     })

     this.clearFormElements()

     // hide the create event button
     createBtn.classList.add("hidden");

   }

   clearFormElements() {
     document.getElementById("create-event__eventTitle").value = null
     document.getElementById("create-event__olineExperience").checked = false
   }

   render()
   {
     var     self   = this
     return(
       <fragment>

         <div class = "my-dashboard-page">
          <div class = "my-dashboard-flex-row">
            <div class = "container my-profile-container">
              <div class = "my-dashboard-container">
                <div class = "my-dashboard-title-wrapper flex-wrapper">  {/*start dashboard element*/}
                  <div style={{flex:8}}>
                      <span>
                        <h1 class = "my-dashboard__title_text"> Create an Event  </h1>
                        <div class = "my-dashboard__subtitle"> Create a new event</div>

                      </span>
                    </div>
                    <div style={{flex:1}}>
                      <span>
                        <button onClick = {(e) => self.handleCreate(e)}  id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                          <span class="bui-button__text">Create</span>
                        </button>
                      </span>

                    </div>


                 </div>

                 <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                   <div style={{flex:2}}><span> Event Title </span></div>
                   <div style={{flex:6}}>
                     <span>

                           <label class = "dashboard__input__text__label" for = "eventTitle">
                             <input value={this.state.eventTitle}  onChange={this.handleInputChange}  id = "create-event__eventTitle"
                             name = "eventTitle" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "eventTitle"
                             placeholder = "Event Title"></input>
                           </label>

                     </span></div>
                   <div style={{flex:1}}>


                   </div>
                 </div>{/*end dashboard element*/}

                 <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                   <div style={{flex:2}}><span> Online Event </span></div>
                   <div style={{flex:6}}>
                     <span>

                           <label class = "dashboard__input__text__label" for = "olineExperience">
                             <input value={this.state.olineExperience}  onChange={this.handleInputChange}  id = "create-event__olineExperience"
                             name = "olineExperience" class="dashboard__select__checkbox__style" type = "checkbox" if = "olineExperience"
                             placeholder = "Online Experience"></input>
                           </label>

                           <div class = "dashboard-input-information-caption">
                             Please select the checkbox if the event will be streamed online. If it will be physically hosted then leave the box unchecked.
                           </div>

                     </span></div>
                   <div style={{flex:1}}>


                   </div>
                 </div>{/*end dashboard element*/}







              </div>
             </div>
           </div>
         </div>

       </fragment>
     )
   }

 }

class MyEventsClass extends Component{

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEditing: false,
    }
  }

  static propTypes = {
   auth: PropTypes.object.isRequired,
   logout: PropTypes.func.isRequired,
  };

  async componentDidMount() {

    await store.dispatch(getEventAndExperienceData());


  }

   render(){

     const { isAuthenticated, user } = this.props.auth;
     var             isLoading       = this.state.isLoading
     var                      self   = this

     return (
       this.props.eventsandexperiences.membersExperiencesAndEventsListLoaded ?



         <div>
           <div class = "dashboard-containeer">
             <div class = "form dashboard-form"  id = "my-create-event-form">

               <div class = "row dashboard-row-wrapper">
                 <div class ="col-sm-10 col-md-10 col-lg-10">
                   <span>
                     <h1 class = "my-dashboard__title_text"> Event Bookings  </h1>
                     <div class = "my-dashboard__subtitle"> Click on an event, rate and review wine lists</div>
                   </span>
                 </div>

                 <div class ="col-sm-2 col-md-2 col-lg-2">

                 </div>

               </div> {/*End row element*/}

               <div class = "row dashboard-row-wrapper">
                 <div class ="col-sm-12 col-md-12 col-lg-12">
                   <div class = "event_experience-stack">
                     <div class = "event_experience-stack-element-inner">
                       <ul class = "event_experience-cards" >
                       {self.props.eventsandexperiences.membersExperiencesAndEventsList.boughtEvents.map((listing, index) => (
                         <li key={`listing-${index}`} >
                          <div class = "event_experience-entry" style = {{marginTop: "10px"}} > {/*winelist-entry*/}
                            <div class = "row" >

                              <div class = "col-sm-12 col-md-8 col-lg-8" >
                              {/*<div class = "col-sm-12 col-md-8 col-lg-8" onClick={() => self.manageEvent(listing.id)}>*/}
                                  <div class = "event_experience-entry-inner">
                                    <div class = "event_experience-entry-title" style = {{marginTop: "5px"}}> {listing.event__eventTitle} </div>
                                  </div>
                              </div>

                              <div class = "col-sm-12 col-md-4 col-lg-4" >
                              {/*<div class = "col-sm-12 col-md-4 col-lg-4" onClick={() => self.manageEvent(listing.id)}>*/}
                                  <div class = "event_experience-entry-inner">
                                    <div class = "event_experience-entry-title" style = {{marginTop: "5px"}}> Date purchased: {dateFormatter(listing.order__orderDate)} </div>
                                  </div>
                              </div>

                            </div> {/*Title row*/}

                            <div class = "row">
                              <div class = "col-sm-12 col-md-2 col-lg-2">

                                <div class = "my-dashboard-cards__acard__img">
                                  <div class = "image-map-wrapper">

                                    {/*<img alt="" class="cart_Img_Centered" src = "https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png"/>*/}
                                    {/*<a   style={{ textDecoration: 'none'}} href ={"/Events/" + listing.event__eventTitle.split(" ").join("-").toLowerCase() + "-" + listing.event__id}>*/}
                                    <a   style={{ textDecoration: 'none'}} href ={"/Mydashboard/MyEvents/" + listing.event__eventTitle.split(" ").join("-").toLowerCase() + "-" + listing.event__id}>
                                      <img alt="" class="cart_Img_Centered" src = {listing.mainExperienceImage === null ? 'https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png' : listing.mainExperienceImage}/>
                                    </a>

                                  </div>
                                </div>

                              </div> {/*end image col*/}

                              <div class = "col-sm-12 col-md-10 col-lg-10" >
                                <div class = "row" style = {{marginTop:"10px"}}>

                                  <div class = "col-sm-12 col-md-12 col-lg-12">
                                    <div class = "event_experience-entry-inner">
                                      <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment">  {listing.event__eventType} </div> </div>
                                    </div>
                                  </div>

                                  <div class = "col-sm-12 col-md-12 col-lg-12">
                                    <div class = "event_experience-entry-inner">
                                      <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment">

                                        <span style={{ marginRight: '5px', fontWeight:'500', color: '#000' }}> {getSymbolFromCurrency(listing.currency)}{listing.price.toFixed(2) }</span>
                                        <span style={{ marginRight: '5px', fontWeight:'500', fontSize:'12px', color: '#8d8478' }}> + {listing.clientTransactionFee.toFixed(2)} Fee </span>

                                      </div> </div>
                                    </div>
                                  </div>

                                  <div class = "col-sm-12 col-md-12 col-lg-12">
                                    <div class = "event_experience-entry-inner">
                                      <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment">  Quantity bought: {listing.quantity} </div> </div>
                                    </div>
                                  </div>

                                </div>


                              </div> {/*end details col*/}

                            </div>

                          </div>

                         </li>
                       ))}

                       </ul>
                      </div>
                    </div>
                  </div>
                </div>


             </div>
           </div>

         </div> :
         <div></div>
     )
   }
 }

 const mapStateToProps = (state) => ({
   auth: state.auth,
   eventsandexperiences: state.eventsandexperiences,
   winelist : state.winelist,
   membersEventReview : state.membersEventReview,
   memberEventReviewLoaded : state.memberEventReviewLoaded
 });

const  CreateEventConnected       = connect(mapStateToProps, { logout })(CreateEventsUnconnected);
export {CreateEventConnected as CreateEvent}


const  connectedEventShopPageClass = connect(mapStateToProps)(EventShopPageClass);
export {connectedEventShopPageClass as EventShopPage }

const  connectedEventPurchasedPageClass = connect(mapStateToProps)(EventPurchasedPageClass);
export {connectedEventPurchasedPageClass as EventPurchasedPage }

const  connectedEventsClass  = connect(mapStateToProps)(EventsClass);
export {connectedEventsClass as Events}

const  connectedEventsList  = connect(mapStateToProps)(EventsListClass);
export {connectedEventsList as EventsList}

const  connectedMyEvents  = connect(mapStateToProps)(MyEventsClass);
export {connectedMyEvents as MyEvents}
