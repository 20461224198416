import React from 'react'
import { useState, useMemo } from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types"

import '../Styles/RatingsAndReviews.css'

// const starRating = ({count, rating, color, onRating}) => {
//{/*<i class="fas fa-star"></i>*/}
export const StarRating = ({props, count, rating, color, onRating, onChange, textShadow}) => {

  const [hoverRating, setHoverRating] = useState(0);

  const getColor = index => {
    if(hoverRating >= index){
      return color.filled;

    } else if (!hoverRating && rating >= index) {
      return color.filled;
    }

    return color.unFilled
  }

  const getBorder = index => {
    if(hoverRating >= index){
      return textShadow.filled;

    } else if (!hoverRating && rating >= index) {
      return textShadow.filled;
    }

    return textShadow.unFilled
  }

  const rate = useMemo(() => {

    return Array(count)
    .fill(0)
    .map((_, i) => i + 1)
    .map((idx) =>


      <i class="fas fa-star start-rating"
        key = {idx}
        /*className = "cursor-pointer"*/
        icon = "star"
        onClick={() => onRating(idx)}
        style = {{color: getColor(idx), textShadow: getBorder(idx) }}
        onMouseEnter = {() => setHoverRating(idx)}
        onMouseLeave = {() => setHoverRating(0)}
      ></i>

    );
  }, [count, rating, hoverRating ])

  return (
    <div >
      {rate}
    </div>
  )
}

StarRating.propTypes = {

  count: PropTypes.number,
  rating:PropTypes.number,
  onChange : PropTypes.func,
  /*onRating: PropTypes.number,*/
  color: {
    filled: PropTypes.string,
    unFilled: PropTypes.string
  },
  textShadow:
  {
    filled: "3px 3px 16px #272634",
    unFilled: "none"
  }

}

StarRating.defaultProps = {
  count: 5,
  rating: 0,
  color: {
    filled: "#f5eb3b",
    unFilled: "#DCDCDC"
  },
  textShadow:
  {
    filled: "3px 3px 16px #272634",
    unFilled: "none"
  }
}

//export default starRating

export const StarRatingDiabled = ({props, count, rating, color, onRating, onChange, textShadow}) => {

  const [hoverRating, setHoverRating] = useState(0);

  const getColor = index => {
    if(hoverRating >= index){
      return color.filled;

    } else if (!hoverRating && rating >= index) {
      return color.filled;
    }

    return color.unFilled
  }

  const getBorder = index => {
    if(hoverRating >= index){
      return textShadow.filled;

    } else if (!hoverRating && rating >= index) {
      return textShadow.filled;
    }

    return textShadow.unFilled
  }

  const rate = useMemo(() => {

    return Array(count)
    .fill(0)
    .map((_, i) => i + 1)
    .map((idx) =>


      <i class="fas fa-star start-rating"
        key = {idx}
        /*className = "cursor-pointer"*/
        icon = "star"
        onClick={() => onRating(idx)}
        style = {{color: getColor(idx), textShadow: getBorder(idx) }}

        
      ></i>

    );
  }, [count, rating, hoverRating ])

  return (
    <div >
      {rate}
    </div>
  )
}

StarRatingDiabled.propTypes = {

  count: PropTypes.number,
  rating:PropTypes.number,
  onChange : PropTypes.func,
  /*onRating: PropTypes.number,*/
  color: {
    filled: PropTypes.string,
    unFilled: PropTypes.string
  },
  textShadow:
  {
    filled: "3px 3px 16px #272634",
    unFilled: "none"
  }

}

StarRatingDiabled.defaultProps = {
  count: 5,
  rating: 0,
  color: {
    filled: "#f5eb3b",
    unFilled: "#DCDCDC"
  },
  textShadow:
  {
    filled: "3px 3px 16px #272634",
    unFilled: "none"
  }
}
