import {combineReducers} from "redux"
import auth from './auth.js'
import winelist from './winelist.js'
import eventsandexperiences from './eventsandexperiences.js'

// this is a meeting place for all our reducers
// I have dadded these reducers to the website

export default combineReducers({
  auth,
  winelist,
  eventsandexperiences,
});
