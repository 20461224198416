import React, {useState,
              useEffect,
              useContext,
              createContext} from 'react';

import ReactDOM from "react-dom";
import axios from "axios";
import '../Styles/NewsFeed.css'

// building the news app based upon this tutorial
// https://www.youtube.com/watch?v=tbizvJ0kT4Y

export const NewsContext = createContext()
export const NewsContextProvider = (props) => {

  const [count, setCount] = useState(0)
  const [data, setData] = useState()
  //const [data, setData] = useState([])
  const apiKey2         = "d9d2f746953048a1bad4229c2966d23a"
  const apiKey          = "15a0d1d1490944e79ca5c16d452506cd";
  const apiKey3          = "NoIDThisISAFakeTestKey";



  useEffect(() => {
    //axios.get('https://newsapi.org/v2/top-headlines?country=gb&apiKey='+apiKey
    //axios.get('http://newsapi.org/v2/everything?q=champagne&from=2020-10-02&sortBy=publishedAt&apiKey='+apiKey
    //axios.get('http://newsapi.org/v2/everything?domains=decanter.com&apiKey='+apiKey
    axios.get('http://newsapi.org/v2/everything?q=champagne&domains=decanter.com&from=2020-09-15&to=2020-10-03&apiKey='+apiKey2
    //9-03&to=2020-10-03&apiKey='+apiKey
  )
  .then((response) => setData(response.data), console.log(data))
  .catch((error) => console.log(error));

  setCount(count+1)
  console.log("We have made calls to axios:", count)

  // an empty array should stop it being called multiple times
  // now it will also be run on mounting and unmounting the component
  // initially I had [data]
},[])


  return(
    // the below line exports the data
    <NewsContext.Provider value = {{data}}>{props.children}
    {console.log(data)}
    </NewsContext.Provider>


  )


};

export function NewsArticle({data}){
  return(
    <div class = "newsItem" >
      <h1 class = "newsItem__title">{data.title}</h1>
      <p class = "newsItem__desc">{data.description}</p>
      <span class = "newsItem__author">{data.author}</span>
      <br/>
      <span class = "newsItem__publishedAt news__info">{data.publishedAt}</span>
      <span class = "newsItem__source news__info">{data.source.name}</span>
    </div>
  )
}

export function NewsItem(props){
  const {data} = useContext(NewsContext);
  return (

    <div class= "newsGridHeading">
      <h1>What's Hot in Champagne</h1>
      <div class = "newsGrid">
        {console.log('data in the context', data)}
        {data ? data.articles.map(newsItem => (
          <NewsArticle data={newsItem} key={newsItem.url}/>
        )

      ): "Loading..."}
      </div>
    </div>
  )
}


export function NewsFeed(){

  return(
    <NewsContextProvider>
      <div>

      </div>
      <NewsItem/>
    </NewsContextProvider>
  );
}
