/*get unique objects in a JSON array*/
export function getUnique(arr, comp) {

                    // store the comparison  values in array
   const unique =  arr.map(e => e[comp])

                  // store the indexes of the unique objects
                  .map((e, i, final) => final.indexOf(e) === i && i)

                  // eliminate the false indexes & return unique objects
                 .filter((e) => arr[e]).map(e => arr[e]);

   return unique;
}


//  the below is a function for formatting the dates
export function dateFormatter(date){
  const year     = date.getFullYear()
  const day      = date.getDate()
  const monthInx = date.getMonth()

  const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

  const formattedDate = day.toString() + " "+ months[monthInx] +" "+ year.toString()



  return formattedDate;
  /*19 November 2020
  return date.toLocaleDateString('en-GB');
  */
}


//  the below is a function for formatting the dates
export function dateElementFromDate(date, element){
  const year     = date.getFullYear()
  const day      = date.getDate()
  const monthInx = date.getMonth()

  const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
}

  var formattedDate = ""

  if (date) {
    if (element === "day")
    {
       formattedDate = day.toString()
    }


    if (element === "month")
    {
       formattedDate = months[monthInx].toString()
    }

    if (element === "year")
    {
       formattedDate = year.toString()
    }

  }
  else {
     formattedDate = " ".toString()
  }



  return formattedDate;
  /*19 November 2020
  return date.toLocaleDateString('en-GB');
  */
}

//  the below is a function for formatting the dates
export function dateFormatterFromString(myDate, time){

  var dateArray = []
  var date   = new Date()
  var newDate   = new Date()
  var formattedDate = ""
  // new Date("6/28/2018, GMT")

  var year     = ""
  var day      = ""
  var monthInx = 0
  var hrs      = 0
  var mins     = 0
  var seconds  = 0

  var months = {}
  var showTime = true
  var timeZone = ""

  if (time === null)
  {
    time = "00:00:00"
    //showTime = false
  }

  if (myDate !== null) {
    dateArray = myDate.split("-")
    // newDate   = Date(dateAstext[0], dateAstext[1], dateAstext[2] )
    //new Date(year, month, day, hours, minutes, seconds, milliseconds)
    var timeArray = time.split(":")

    // newDate   = new Date( dateArray[1] + "/" + dateArray[2] + "/" +dateArray[0] +", GMT"   )
    // Date and time method
    // new Date(1776, 6, 4, 12, 30, 0, 0);
    newDate   = new Date( dateArray[0] , (dateArray[1]-1) ,dateArray[2], timeArray[0], timeArray[1], timeArray[2]   )
    console.log(timeArray[0])

  }





  if (myDate === null){
    return formattedDate;
  }
  else{

      year     = newDate.getFullYear()
      day      = newDate.getDate()
      monthInx = newDate.getMonth()

      hrs      = newDate.getHours()

      mins     = newDate.getMinutes()
      seconds  = newDate.getSeconds()
       //getMilliseconds()

      //timeZone = newDate.getTimezone();

      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      months = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec'
    }


    if(showTime){
      formattedDate =weekday[newDate.getDay()].toString() + ", " +day.toString() + " "+ months[monthInx] +" "+ year.toString() + " at " +addZero(hrs).toString() + ":" + addZero(mins).toString()
    }
    else
    {
       formattedDate =weekday[newDate.getDay()].toString() + ", " +day.toString() + " "+ months[monthInx] +" "+ year.toString()
     }


    return formattedDate;

  }





  /*19 November 2020
  return date.toLocaleDateString('en-GB');
  */
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export function dateFromText(dateAstext, element) {

  var dateArray = []
  var newDate   = new Date()
  var formattedDate = ""
  // new Date("6/28/2018, GMT")

  var year     = ""
  var day      = ""
  var monthInx = 0

  var months = {}

  try{
    dateArray = dateAstext.split("-")
    // newDate   = Date(dateAstext[0], dateAstext[1], dateAstext[2] )
    newDate   = new Date( dateAstext[2] + "/" + dateAstext[1] + "/" +dateAstext[0] +", GMT" )

  }
  catch(e)
  {

  }

  year     = newDate.getFullYear()
  day      = newDate.getDate()
  monthInx = newDate.getMonth()

  months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
  }

  if (element === "day")
  {
    if (isNaN(day)) {
        formattedDate = ""
        return formattedDate
    }
    else
    {
      return formattedDate = day.toString()
    }


  }


  if (element === "month")
  {
      if (isNaN(monthInx)) {
          formattedDate = ""
          return formattedDate
      }
      else {
          return formattedDate = months[monthInx].toString()
          //console.log(formattedDate)
      }

  }


  if (element === "year")
  {
    if (isNaN(year)) {
        formattedDate = ""
        return formattedDate
    }
    else
    {
      return formattedDate = year.toString()
    }

  }

  if (isNaN(formattedDate))
  {
    formattedDate = ""
  }

  return formattedDate

}
