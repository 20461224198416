import React, {useState, useEffect, useLayoutEffect} from 'react';
import ReactGA from 'react-ga'
import {createBrowserHistory} from 'history'

import  { Component } from 'react';
import '../App.css';
import '../Styles/MyDashboard.css'
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import "../Actions/Auth.js"

import axios from 'axios'

import {loadUser, logout} from '../Actions/Auth.js'
import {SideNavigation, SideNavigationClass } from "./SideNavigation.js"

import {titleList,
       genderList,
       countryList,
       internationalCallingCodeList,
       findElementInTitleArray,
       findElementInGenderArray,
       findElementInCountryListArray,
       findElementInCountryCodeListArray,
       businessTypeList,
       findElementInBusinessTypeListArray,
       PlaceHolderImage} from "../Forms/FormComponents.js"

 class MyProfileUnconnected  extends Component{


   /*state = {
     username: '',
     password: '',
   };*/
   constructor(props) {
     super(props);
       this.state = {
         memberProfile:[],
         activeItem: {
         fieldName:'',
         },
         editing:false,
         isLoading: true,
         'member.title': '',
         first_name: '',
         last_name: '',
         email: '',
         'member.gender': '',
         'member.phone' : null,
         'member.DOB' : null,
         'member.phone' : null,
         'member.phoneCountryCode' : null,
         'member.address': null,
         'member.city':  null,
         'member.zip_code': null,
         'member.countrySelect': null,
         udpateFieldName: '',
         updateFieldValue: null,
         updatePostData  : {}


       };

       this.fetchProfile          = this.fetchProfile.bind(this)
       this.editProfileItem       = this.editProfileItem.bind(this)
       this.handleProfileItemEdit = this.handleProfileItemEdit.bind(this)
       this.handleSave            = this.handleSave.bind(this)
       this.cancelEditProfileItem = this.cancelEditProfileItem.bind(this)

   };

   /*export class Experiences extends Component{*/
   static propTypes = {
     auth: PropTypes.object.isRequired,

   };

   componentDidMount() {
     //this.props.dispatch(fetchMemberProfile());
     // this.props.fetchMemberProfile();
     this.fetchProfile()
   }

   componentDidUpdate(){
     /* If the profile has updated let's call the api*/
     this.fetchProfile()
   }

   /*componentWillMount(){

     this.fetchProfile()
   }*/

   fetchProfile(){
     // var url = "https://real-grape-site-us.herokuapp.com/api/auth/member-profile-page"
     var url = "https://www.therealgrape.com/api/auth/member-profile-page"
     // var url = "http://127.0.0.1:8000/api/auth/member-profile-page"

     // GET token from state
     const token = this.props.auth.token;

     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     axios.get(url, config)
       .then((response) => { this.setState({
       /*.then((response) => {console.log(response); this.setState({*/
           memberProfile: response.data,
           isLoading: false
         })})
       .catch((error)   => console.log(error));
       /*.then(response => console.log("Data: ", response.data) )*/

       /*.then((response) =>  response.json())*/
       /*.then((response) =>
         this.setState({
           memberProfile:response
         }),

           console.log("Member profile set as follows ", this.props.memberProfile)

         )*/
       /*.then(data => console.log("This is the data to save into the member profile: ",  data))*/
       /*.catch((error) => console.log(error));*/
   }

   editProfileItem(event) {

     // var elementId = this.val('elementId')
     // const elementId       = event.target.id //value('profile-elementId')
     event.preventDefault()

     const elementId     =  event.currentTarget.id;
     var formElementId   = ''
     var saveElementId   = ''
     var cancelElementId = ''


     formElementId   = elementId + "-form"
     saveElementId   = elementId + "-save"
     cancelElementId = elementId + "-cancel"

     var editBtn     = document.getElementById(elementId)
     var formElement = document.getElementById(formElementId)
     var saveBtn     = document.getElementById(saveElementId)
     var cancelBtn   = document.getElementById(cancelElementId)


     // unhide form element, save button and camcel button
     formElement.classList.remove("hidden");
     saveBtn.classList.remove("hidden");
     cancelBtn.classList.remove("hidden");

     // hide the edit button
     editBtn.classList.add("hidden");

   }

   cancelEditProfileItem(event) {
     /* handle the cancel button*/
     // start by getting the id "member.title-editMode-cancel"
     var elementId =  event.currentTarget.id;
     var formElementId   = ''
     var saveElementId   = ''
     var cancelElementId = ''

     // remove the cancel from the end of the id
     elementId     = elementId.replace("-cancel", "")

     // find the associated form elements and buttons
     formElementId   = elementId + "-form"
     saveElementId   = elementId + "-save"
     cancelElementId = elementId + "-cancel"

     var editBtn     = document.getElementById(elementId)
     var formElement = document.getElementById(formElementId)
     var saveBtn     = document.getElementById(saveElementId)
     var cancelBtn   = document.getElementById(cancelElementId)

     // unhide the edit button
     editBtn.classList.remove("hidden");

     // hide form element, save button and camcel button
     formElement.classList.add("hidden");
     saveBtn.classList.add("hidden");
     cancelBtn.classList.add("hidden");

     // clear anything that is currently in the updatePostData

     this.setState({
         updatePostData : {}
     })

     // return all form data to original state

     this.setState({

       'member.title': '',
       first_name: '',
       last_name: '',
       email: '',
       'member.gender': '',
       'member.phone' : null,
       'member.DOB' : null,
       'member.phone' : null,
       'member.phoneCountryCode' : null,
       'member.address': null,
       'member.city':  null,
       'member.zip_code': null,
       'member.countrySelect': null
     })





   }

   handleProfileItemEdit(event) {



     const target = event.target;
     const value = target.type === 'checkbox' ? event.target.checked : event.target.value;
     const name = target.name;



     this.setState({
       [event.target.name]: value,
       udpateFieldName: name,
       updateFieldValue: value,

     })


     var postData   = this.state.updatePostData
     postData[name] = value
     this.setState({
         updatePostData : postData
     })


   }

   handleSave(event) {

     event.preventDefault()

     // start by getting the id "member.title-editMode-cancel"
     var elementId =  event.currentTarget.id;
     var formElementId   = ''
     var saveElementId   = ''
     var cancelElementId = ''

     // remove the cancel from the end of the id
     elementId     = elementId.replace("-save", "")

     // find the associated form elements and buttons
     formElementId   = elementId + "-form"
     saveElementId   = elementId + "-save"
     cancelElementId = elementId + "-cancel"

     var editBtn     = document.getElementById(elementId)
     var formElement = document.getElementById(formElementId)
     var saveBtn     = document.getElementById(saveElementId)
     var cancelBtn   = document.getElementById(cancelElementId)

     if (this.state.updateFieldValue != null)
     {

       var field2Update         = this.state.udpateFieldName
       const constUpdateValue   = this.state.updateFieldValue
       const postDataFinal      = this.state.updatePostData

       var postData = {}
       postData[field2Update] = constUpdateValue

       var url = "https://www.therealgrape.com/api/auth/member-profile-update"
       // var url = "http://127.0.0.1:8000/api/auth/member-profile-update"

       // GET token from state
       const token = this.props.auth.token;

       // set up the header for the get calls
       const config = {
         headers: {
           'Content-type': 'application/json',
         }
       }

       // // Provide the authorisation token
       if(token) {
         config.headers['Authorization'] = `Token ${token}`
       }

       // now we need to stringfy the field that we want to provide
       // Request body

       const body = JSON.stringify(postDataFinal);

       // now post the Data
       axios.post(url, body, config)
       .then()

       // reset the state variables for field updates to null
       this.setState({
         udpateFieldName: '',
         updateFieldValue: null,
         editing:false
       })

       // clear anything that is currently in the updatePostData

       this.setState({
           updatePostData : {}
       })

       // return all form data to original state

       this.setState({

         'member.title': '',
         first_name: '',
         last_name: '',
         email: '',
         'member.gender': '',
         'member.phone' : null,
         'member.DOB' : null,
         'member.phone' : null,
         'member.phoneCountryCode' : null,
         'member.address': null,
         'member.city':  null,
         'member.zip_code': null,
         'member.countrySelect': null
       })
     }

     // unhide the edit button
     editBtn.classList.remove("hidden");

     // hide form element, save button and camcel button
     formElement.classList.add("hidden");
     saveBtn.classList.add("hidden");
     cancelBtn.classList.add("hidden");

   }



   render()
   {

   const { isAuthenticated} = this.props.auth;
   var      profileElements = this.state.memberProfile
   var      isLoading       = this.state.isLoading
   var      title           = this.state.title
   var      phone           = this.state.phone
   var      self   = this



   var       defaultDate = new Date();
    defaultDate =  defaultDate.getDate();
   //curr.setDate(curr.getDate() + 3);
   //var date = curr.toISOString().substr(0,10);

   return(
       !isLoading ?
       <div>

         <div class = "my-dashboard-page">
           <div class = "my-dashboard-flex-row">



           </div> {/* end flex by row*/}

           {/* Profile Table*/}
           <div className = "container">
             <div class = "my-dashboard-container">
               {/*<div class = "my-dashboard-form-wrapper">
                 <form id = "form">
                   <div class = "flex-wrapper">
                     <div style = {{flex:6}}>
                       <input className="form-control" id="title"  type="text" name="title" placeholder="Add task.." />
                     </div>
                     <div style = {{flex:1}}>
                       <input class="my-dashboard-submit" className="btn btn-warning" type="submit" name="Add" />
                     </div>

                   </div>
                 </form>
               </div>*/}

             <div class = "form my-profile" id = "my-profile-form">

             <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
               <div style={{flex:8}}>
                 <span>
                   <h1 class = "my-dashboard__title_text"> Personal Details </h1>
                   <div class = "my-dashboard__subtitle"> Update your information and how it is used</div>
                 </span>
               </div>
               <div style={{flex:1}}>
                 <span>

                   <button aria-label = "Change your profile picture" class = "bui-button bui-button-tertiary">
                     <span class = "bui-button__text">
                       <div class = "my-dashboard-profile_pic-wrapper">
                         <div class = "bui-profile_pic bui-profile_pic--larger bui-profile_pic--outline-complement ">
                           {isAuthenticated ? <img class = "profile-pic" src = {profileElements.member.progile_pic} / >  : <PlaceHolderImage/>}
                         </div>
                                {/*'http://127.0.0.1:8000' + '/media/TTA_Financial_Protection_150x82.png'*/}




                       </div> {/* End profile pic wrapper*/}
                     </span>
                   </button> {/* change prfile picture section*/}
                 </span>
               </div>

             </div>{/*end dashboard element*/}

               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Title</span></div>
                 <div style={{flex:6}}><span>
                   {isAuthenticated ? ` ${findElementInTitleArray(profileElements.member.title, titleList)} ` : `Mr`}



                     <select  value={this.state.title}  onChange={this.handleProfileItemEdit} name = "member.title" id = "member.title-editMode-form" class = "form-control form-control-sm form-field hidden">
                       <option value="">{findElementInTitleArray(profileElements.member.title, titleList)}</option>
                       {titleList.map((titleSelect) =>
                         <option value = {titleSelect.optionValue}>{titleSelect.title} </option>

                       )}
                     </select>




                 </span></div>
                 <div style={{flex:1}}>
                   <span>
                     {/*onClick = {() => self.editProfileItem('member.title')}*/}
                     <button onClick = {(e) => self.editProfileItem(e)} id = "member.title-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                       <span class="bui-button__text">Edit</span>
                     </button>
                   </span>

                   <span><button onClick = {(e) => self.handleSave(e)} id = "member.title-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                   <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "member.title-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>
                 </div>
               </div>{/*end dashboard element*/}

               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Name</span></div>
                 <div style={{flex:6}}>
                   <span>
                     {isAuthenticated ? ` ${profileElements.first_name}  ${profileElements.last_name} ` : `Firstname Lastname`}

                     <div id = "name-editMode-form" class = "hidden">
                       <div class = "form-group col-sm-12 col-md-12 align-items-md-end">
                         <label class = "nameDetails" for = "firstName">
                           <input value={this.state.first_name}  onChange={this.handleProfileItemEdit}  name = "first_name" class="form-control form-control-sm form-field " type = "text" if = "first_name" placeholder = "First Name"></input>
                         </label>
                       </div>

                       <div class = "form-group col-sm-12 col-md-12" >
                         <label class = "nameDetails" for = "lastName">
                           <input  value={this.state.last_name}  onChange={this.handleProfileItemEdit}  name = "last_name"  class = "form-control form-control-sm form-field" type = "text" if = "last_name" placeholder = "Last Name"></input>
                         </label>
                       </div>
                     </div>

                   </span></div>
                 <div style={{flex:1}}>
                   <span>
                     <button onClick = {(e) => self.editProfileItem(e)} id = "name-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                       <span class="bui-button__text">Edit</span>
                     </button>
                   </span>

                   <span><button onClick = {(e) => self.handleSave(e)}            id = "name-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                   <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "name-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                 </div>
               </div>{/*end dashboard element*/}


               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Email </span></div>
                 <div style={{flex:6}}>
                   <span>
                     {isAuthenticated ? ` ${profileElements.email}  ` : `Email`}
                       <div class = "form-group col-sm-12 col-md-6">
                         <label class = "adressDetails" for="email">
                           <input   value={this.state.email}  onChange={this.handleProfileItemEdit}  name = "email" id = "email-editMode-form" class = "form-control form-control-sm form-field hidden" type="email"  placeholder="Email address"></input>
                         </label>
                       </div>
                   </span>
                 </div>
                 <div style={{flex:1}}>
                   <span>
                     <button onClick = {(e) => self.editProfileItem(e)} id = "email-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                       <span class="bui-button__text">Edit</span>
                     </button>
                   </span>

                   <span><button onClick = {(e) => self.handleSave(e)} id = "email-editMode-save"  class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                   <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "email-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>
                 </div>
               </div>{/*end dashboard element*/}

               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Phone Number  </span></div>
                 <div style={{flex:6}}>
                   <span>
                     {isAuthenticated ? ` ${findElementInCountryCodeListArray(profileElements.member.phoneCountryCode, internationalCallingCodeList) + profileElements.member.phone}  ` : `+2634495253`}

                   <div id = "member.phone-editMode-form" class = "hidden">
                       <select  value={this.state.phoneCountryCode}  onChange={this.handleProfileItemEdit} name = "member.phoneCountryCode" class = "form-control form-control-sm form-field">
                         <option value="">{findElementInCountryListArray(profileElements.member.phoneCountryCode, countryList)}</option>
                         {countryList.map((phoneCountryCode) =>
                           <option value = {phoneCountryCode.optionValue}>{phoneCountryCode.country} </option>

                         )}
                       </select>

                       <div class = "form-group col-sm-12 col-md-6">
                         <label class = "phoneNumberDetails" for="phoneNumber">
                           <input  value={this.state.phone}  onChange={this.handleProfileItemEdit} name = "member.phone" class = "form-control form-control-sm form-field" type="tel"  placeholder="Phone Number"></input>
                         </label>
                       </div>
                     </div>

                   </span>
                 </div>
                 <div style={{flex:1}}>
                   <span>
                     <button onClick = {(e) => self.editProfileItem(e)} id = "member.phone-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                       <span class="bui-button__text">Edit</span>
                     </button>
                   </span>

                   <span><button onClick = {(e) => self.handleSave(e)}            id = "member.phone-editMode-save"   class="bui-button bui-button-tertiary-danger hidden" ><span>Save</span></button></span>
                   <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "member.phone-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" ><span> Cancel </span></button></span>
                 </div>
               </div>{/*end dashboard element*/}

               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Date of Birth </span></div>
                 <div style={{flex:6}}>
                   <span> {isAuthenticated ? ` ${profileElements.member.DOB}  ` : `01-01-2021`}

                     <div class = "form-group col-sm-12 col-md-12 align-items-md-end">
                       <label class = "dobBetails" for = "dateOfBirth">
                         <input value={this.state.DOB}  onChange={this.handleProfileItemEdit}  name = "member.DOB" id = "member.DOB-editMode-form"  class="form-control form-control-sm form-field hidden " type = "date" if = "member.DOB" placeholder = "" defaultValue={defaultDate}></input>
                       </label>
                     </div>

                   </span>
                 </div>
                 <div style={{flex:1}}>
                   <span>
                     <button onClick = {(e) => self.editProfileItem(e)} id = "member.DOB-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                       <span class="bui-button__text">Edit</span>
                     </button>
                   </span>


                   <span><button onClick = {(e) => self.handleSave(e)} id = "member.DOB-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                   <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "member.DOB-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>
                 </div>
               </div>{/*end dashboard element*/}


               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Gender </span></div>
                 <div style={{flex:6}}>
                   <span>
                     {isAuthenticated ? ` ${findElementInGenderArray(profileElements.member.gender, genderList)}  ` : `Gender`}



                     <select  value={this.state.gender}  onChange={this.handleProfileItemEdit}  name = "member.gender" id = "member.gender-editMode-form" class = "form-control form-control-sm form-field hidden">
                       <option value="">{findElementInGenderArray(profileElements.member.gender, genderList)}</option>
                       {genderList.map((genderSelect) =>
                         <option value = {genderSelect.optionValue}>{genderSelect.title} </option>

                       )}
                     </select>

                   </span>
                 </div>
                 <div style={{flex:1}}>
                   <span>
                     <button onClick = {(e) => self.editProfileItem(e)} id = "member.gender-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                       <span class="bui-button__text">Edit</span>
                     </button>
                   </span>

                   <span><button onClick = {(e) => self.handleSave(e)}  id = "member.gender-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                   <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "member.gender-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>
                 </div>
               </div>{/*end dashboard element*/}

               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Address </span></div>
                 <div style={{flex:6}}><span>  {isAuthenticated ? ` ${profileElements.member.address}  ` : `Adreess line 1`} <br/>


                 {isAuthenticated ? ` ${profileElements.member.city}  ` : `City`}  <br/>

                 {isAuthenticated ? ` ${profileElements.member.zip_code}  ` : `Postcode`} <br/>

                 {isAuthenticated ? ` ${findElementInCountryListArray(profileElements.member.countrySelect, countryList)}  ` : `Country`}

                                           <div id = "member.address-editMode-form" class = "hidden">
                                             <div class = "form-group col-sm-12 col-md-6">
                                               <label class = "adressDetails" >
                                                 <input value={this.state.address}  onChange={this.handleProfileItemEdit} name = "member.address" class = "form-control form-control-sm form-field" type="text" id="member.address" placeholder="Address"></input>
                                               </label>
                                             </div>


                                               <div class = "form-group col-sm-12 col-md-6">
                                                 <label class = "cityDetails" >
                                                   <input value={this.state.city}  onChange={this.handleProfileItemEdit} name = "member.city" class = "form-control form-control-sm form-field" type="text" id="member.city" placeholder="City"></input>
                                                 </label>
                                               </div>



                                              <div class = "form-group col-sm-12 col-md-6">
                                                <label class = "postCode" >
                                                  <input value={this.state.zip_code}  onChange={this.handleProfileItemEdit} name = "member.zip_code" class = "form-control form-control-sm form-field" type="text" id="member.zip_code" placeholder="Postcode"></input>
                                                </label>
                                              </div>



                                              <div class = "form-group col-sm-12 col-md-6">
                                                <label>
                                                <select  value={this.state.countrySelect}  onChange={this.handleProfileItemEdit} name = "member.countrySelect" class = "form-control form-control-sm form-field" >
                                                  <option value="">{findElementInCountryListArray(profileElements.member.countrySelect, countryList)}</option>
                                                  {countryList.map((countrySelect) =>

                                                    <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                                                  )}
                                                </select>
                                                </label>
                                              </div>
                                           </div>

                                       </span>
                 </div>


                 <div style={{flex:1}}>
                   <span>
                     <button onClick = {(e) => self.editProfileItem(e)} id = "member.address-editMode"  target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                       <span class="bui-button__text">Edit</span>
                     </button>
                   </span>

                   <span><button onClick = {(e) => self.handleSave(e)}            id = "member.address-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span>Save</span></button></span>
                   <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "member.address-editMode-cancel"  class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span> Cancel </span></button></span>
                 </div>
               </div>{/*end dashboard element*/}

             </div> {/*end profile from*/}

             </div>
           </div>
         </div>
       </div> : <div> Loading...</div>
     )}
 }

 class BusinessProfileUnconnected  extends Component{

   constructor(props) {
     super(props);
     this.state = {
       businessProfile:[],
       isLoading: true,
       'professional.title': '',
       first_name: '',
       last_name: '',
       email: '',

       'professional.phone' : null,
       'professional.phoneCountryCode' : null,
       'professional.address': null,
       'professional.city':  null,
       'professional.zip_code': null,
       'professional.countrySelect': null,
       'professional.businessName': null,
       'professional.businessBio': null,
       'professional.businessType': null,

       udpateFieldName: '',
       updateFieldValue: null,
       updatePostData  : {},




     };

     this.fetchProfile          = this.fetchProfile.bind(this)
     this.handleProfileItemEdit = this.handleProfileItemEdit.bind(this)
     this.editProfileItem       = this.editProfileItem.bind(this)
     this.handleSave            = this.handleSave.bind(this)
     this.cancelEditProfileItem = this.cancelEditProfileItem.bind(this)
     this.clearFormElements     = this.clearFormElements.bind(this)



   }

   static propTypes = {
     auth: PropTypes.object.isRequired,

   };

   componentDidMount() {
     //this.props.dispatch(fetchMemberProfile());
     // this.props.fetchMemberProfile();
     this.fetchProfile()
   }

   componentDidUpdate(){
     /* If the profile has updated let's call the api*/
     this.fetchProfile()
   }


   fetchProfile(){
     // var url = "https://real-grape-site-us.herokuapp.com/api/auth/business-profile-page"
     var url = "https://www.therealgrape.com/api/auth/business-profile-page"
     // var url = "http://127.0.0.1:8000/api/auth/business-profile-page"

     // GET token from state
     const token = this.props.auth.token;

     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     axios.get(url, config)
       .then((response) => { this.setState({
       /*.then((response) => {console.log(response); this.setState({*/
           businessProfile: response.data,
           isLoading: false
         })})
       .catch((error)   => console.log(error));
   }

   handleProfileItemEdit(event) {

     const target = event.target;
     const value  = target.type === 'checkbox' ? event.target.checked : event.target.value;
     const name   = target.name

     this.setState({
       [event.target.name]: value,
       updateFieldName: name,
       updateFieldValue: value,
     })

     var postData   = this.state.updatePostData
     postData[name] = value
     this.setState({
       updatePostData: postData
     })
   }

   editProfileItem(event) {

     // var elementId = this.val('elementId')
     // const elementId       = event.target.id //value('profile-elementId')
     event.preventDefault()

     const elementId     =  event.currentTarget.id;
     var formElementId   = ''
     var saveElementId   = ''
     var cancelElementId = ''


     formElementId   = elementId + "-form"
     saveElementId   = elementId + "-save"
     cancelElementId = elementId + "-cancel"

     var editBtn     = document.getElementById(elementId)
     var formElement = document.getElementById(formElementId)
     var saveBtn     = document.getElementById(saveElementId)
     var cancelBtn   = document.getElementById(cancelElementId)


     // unhide form element, save button and camcel button
     formElement.classList.remove("hidden");
     saveBtn.classList.remove("hidden");
     cancelBtn.classList.remove("hidden");

     // hide the edit button
     editBtn.classList.add("hidden");

   }

   handleSave(event) {

     event.preventDefault()

     // start by getting the id "member.title-editMode-cancel"
     var elementId =  event.currentTarget.id;
     var formElementId   = ''
     var saveElementId   = ''
     var cancelElementId = ''

     // remove the cancel from the end of the id
     elementId     = elementId.replace("-save", "")

     // find the associated form elements and buttons
     formElementId   = elementId + "-form"
     saveElementId   = elementId + "-save"
     cancelElementId = elementId + "-cancel"

     var editBtn     = document.getElementById(elementId)
     var formElement = document.getElementById(formElementId)
     var saveBtn     = document.getElementById(saveElementId)
     var cancelBtn   = document.getElementById(cancelElementId)

     if (this.state.updateFieldValue != null)
     {

       var field2Update         = this.state.udpateFieldName
       const constUpdateValue   = this.state.updateFieldValue
       const postDataFinal      = this.state.updatePostData

       var postData = {}
       postData[field2Update] = constUpdateValue

       var url = "https://www.therealgrape.com/api/auth/business-profile-update"
       // var url = "http://127.0.0.1:8000/api/auth/business-profile-update"

       // GET token from state
       const token = this.props.auth.token;

       // set up the header for the get calls
       const config = {
         headers: {
           'Content-type': 'application/json',
         }
       }

       // // Provide the authorisation token
       if(token) {
         config.headers['Authorization'] = `Token ${token}`
       }

       // now we need to stringfy the field that we want to provide
       // Request body

       const body = JSON.stringify(postDataFinal);

       // now post the Data
       axios.post(url, body, config)
       .then()

       // reset the state variables for field updates to null
       this.setState({
         udpateFieldName: '',
         updateFieldValue: null,
         editing:false
       })

       // clear anything that is currently in the updatePostData

       this.setState({
           updatePostData : {}
       })

       // return all form data to original state

       this.setState({

         'professional.title': '',
         first_name: '',
         last_name: '',
         email: '',

         'professional.phone' : null,
         'professional.phoneCountryCode' : null,
         'professional.address': null,
         'professional.city':  null,
         'professional.zip_code': null,
         'professional.countrySelect': null,
         'professional.businessName': null,
         'professional.businessBio': null,
         'professional.businessType': null




       })
     }

     // unhide the edit button
     editBtn.classList.remove("hidden");

     // hide form element, save button and camcel button
     formElement.classList.add("hidden");
     saveBtn.classList.add("hidden");
     cancelBtn.classList.add("hidden");

     // clear the form elements
     this.clearFormElements()

   }

   cancelEditProfileItem(event) {
     /* handle the cancel button*/
     // start by getting the id "member.title-editMode-cancel"
     var elementId =  event.currentTarget.id;
     var formElementId   = ''
     var saveElementId   = ''
     var cancelElementId = ''

     // remove the cancel from the end of the id
     elementId     = elementId.replace("-cancel", "")

     // find the associated form elements and buttons
     formElementId   = elementId + "-form"
     saveElementId   = elementId + "-save"
     cancelElementId = elementId + "-cancel"

     var editBtn     = document.getElementById(elementId)
     var formElement = document.getElementById(formElementId)
     var saveBtn     = document.getElementById(saveElementId)
     var cancelBtn   = document.getElementById(cancelElementId)

     // unhide the edit button
     editBtn.classList.remove("hidden");

     // hide form element, save button and camcel button
     formElement.classList.add("hidden");
     saveBtn.classList.add("hidden");
     cancelBtn.classList.add("hidden");


     // clear anything that is currently in the updatePostData

     this.setState({
         updatePostData : {}
     })

     // return all form data to original state

     this.setState({

       'professional.title': '',
       first_name: '',
       last_name: '',
       email: '',

       'professional.phone' : null,
       'professional.phoneCountryCode' : null,
       'professional.address': null,
       'professional.city':  null,
       'professional.zip_code': null,
       'professional.countrySelect': null,
       'professional.businessName': null,
       'professional.businessBio': null,
       'professional.businessType': null



     })

     // clear all form elements
     this.clearFormElements()






   }

   clearFormElements() {
     document.getElementById("professional.businessName-editMode-form").value = ""
     document.getElementById("professional.businessBio-editMode-form").value = ""
     document.getElementById("professional.businessType-editMode-form").value = ""
     document.getElementById("name-editMode-form-first_name").value = ""
     document.getElementById("name-editMode-form-last_name").value = ""
     document.getElementById("professional.title-editMode-form").value = ""
     document.getElementById("email-editMode-form").value = ""
     document.getElementById("professional.phone-editMode-form-countryCode").value = ""
     document.getElementById("professional.phone-editMode-form-phoneNumber").value = ""
     document.getElementById("professional.address-editMode-form-address").value = ""
     document.getElementById("professional.address-editMode-form-city").value = ""
     document.getElementById("professional.address-editMode-form-zip_code").value = ""
     document.getElementById("professional.address-editMode-form-countrySelect").value = ""
   }



   render()
   {

     const {isAuthenticated} = this.props.auth;
     var     isLoading       = this.state.isLoading
     var     profileElements = this.state.businessProfile
     var     self   = this


      return(
        !isLoading ?
        <div>

          <div class = "dashboard-containeer">
            <div class = "form dashboard-form"  id = "my-profile-form">
                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-10 col-md-10 col-lg-10">
                    <span>
                      <h1 class = "my-dashboard__title_text"> Business Details  </h1>
                      <div class = "my-dashboard__subtitle"> Update your information and how it is used</div>
                    </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <button aria-label = "Change your profile picture" class = "bui-button bui-button-tertiary">
                      <span class = "bui-button__text">
                        <div class = "my-dashboard-profile_pic-wrapper">
                          <div class = "bui-profile_pic bui-profile_pic--larger bui-profile_pic--outline-complement ">
                            {isAuthenticated ? <img class = "profile-pic" src = {profileElements.professional.progile_pic} / >  : <PlaceHolderImage/>}
                          </div>
                                 {/*'http://127.0.0.1:8000' + '/media/TTA_Financial_Protection_150x82.png'*/}




                        </div> {/* End profile pic wrapper*/}
                      </span>
                    </button> {/* change prfile picture section*/}

                  </div>
                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  >Business Name </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                  <span>
                    {isAuthenticated ? ` ${profileElements.professional.businessName}   ` : `Business Name`}



                        <label class = "dashboard__input__text__label" for = "professional.businessName">
                          <input value={this.state.businessName}  onChange={this.handleProfileItemEdit}  id = "professional.businessName-editMode-form"
                          name = "professional.businessName" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "first_name"
                          placeholder = "Business Name"></input>
                        </label>



                  </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      <button onClick = {(e) => self.editProfileItem(e)} id = "professional.businessName-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.businessName-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.businessName-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                  </div>
                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  > Business Bio </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                    <span>
                      {isAuthenticated ? ` ${profileElements.professional.businessBio}   ` : `Business Bio`}



                          <label class = "dashboard__input__text__label" for = "professional.businessBio">
                            <textarea value={this.state.businessBio}  onChange={this.handleProfileItemEdit}  id = "professional.businessBio-editMode-form"
                            name = "professional.businessBio" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "first_name"
                            placeholder = "About your business and services"></textarea>
                            {/*<textarea value={this.state.businessBio}  onChange={this.handleProfileItemEdit}  id = "professional.businessBio-editMode-form"
                            name = "professional.businessBio" class = "form-control form-control-sm textarea__requestDetails medium form-field hidden "  cols="75" id="message" placeholder="About your business and services" aria-required="true" aria-invalid="false"></textarea>*/}
                          </label>


                        <div class = "dashboard-input-information-caption">
                          Please provide a description of your business and services you provide. Please also detail the type of events and experiences you provide.
                        </div>

                    </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      <button onClick = {(e) => self.editProfileItem(e)} id = "professional.businessBio-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.businessBio-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.businessBio-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                  </div>
                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  > Business Type </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                    <span>
                      {isAuthenticated ? ` ${findElementInBusinessTypeListArray(profileElements.professional.businessType, businessTypeList)} ` : `Mr`}
                      {/*form-control form-control-sm form-field*/}
                      <select  value={this.state.businessType}  onChange={this.handleProfileItemEdit} name = "professional.businessType" id = "professional.businessType-editMode-form" class = "dashboard__select__button__style hidden">
                        <option value="">{findElementInBusinessTypeListArray(profileElements.professional.businessType, businessTypeList)}</option>
                        {businessTypeList.map((businessTypeListSelect) =>
                          <option value = {businessTypeListSelect.optionValue}>{businessTypeListSelect.business} </option>

                        )}
                      </select>



                    </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      {/*onClick = {() => self.editProfileItem('member.title')}*/}
                      <button onClick = {(e) => self.editProfileItem(e)} id = "professional.businessType-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)} id = "professional.businessType-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.businessType-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                  </div>
                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  > Contact </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                    <span>
                      {isAuthenticated ? ` ${profileElements.first_name}  ${profileElements.last_name} ` : `Firstname Lastname`}

                      <div id = "name-editMode-form" class = "awrapper hidden">
                          <label class = "dashboard__input__text__label" >
                              <input value={this.state.first_name}  onChange={this.handleProfileItemEdit}  name = "first_name" id = "name-editMode-form-first_name" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "first_name" placeholder = "First Name"></input>
                          </label>




                          <label class = "dashboard__input__text__label" >
                            <input  value={this.state.last_name}  onChange={this.handleProfileItemEdit}  name = "last_name" id = "name-editMode-form-last_name" class = "dashboard__select__button__style dashboard__input__text__control" type = "text" if = "last_name" placeholder = "Last Name"></input>
                          </label>

                      </div>

                    </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      <button onClick = {(e) => self.editProfileItem(e)} id = "name-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)}            id = "name-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "name-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                  </div>
                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  > Title </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                    <span>
                      {isAuthenticated ? ` ${findElementInTitleArray(profileElements.professional.title, titleList)} ` : `Mr`}

                      <select  value={this.state.title}  onChange={this.handleProfileItemEdit} name = "professional.title" id = "professional.title-editMode-form" class = "dashboard__select__button__style hidden">
                        <option value="">{findElementInTitleArray(profileElements.professional.title, titleList)}</option>
                        {titleList.map((titleSelect) =>
                          <option value = {titleSelect.optionValue}>{titleSelect.title} </option>

                        )}
                      </select>


                    </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      {/*onClick = {() => self.editProfileItem('member.title')}*/}
                      <button onClick = {(e) => self.editProfileItem(e)} id = "professional.title-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)} id = "professional.title-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.title-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                  </div>
                </div> {/*End row element*/}


                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  > Email </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                    <span>
                      {isAuthenticated ? ` ${profileElements.email}  ` : `Email`}

                          <label class = "dashboard__input__text__label" for="email">
                            <input   value={this.state.email}  onChange={this.handleProfileItemEdit}  name = "email" id = "email-editMode-form" class = "dashboard__select__button__style dashboard__input__text__control hidden" type="email"  placeholder="Email address"></input>
                          </label>

                        <div class = "dashboard-input-information-caption">
                          We will only email you any necessary correspondence to this mailbox. use this as your primary contact number and will not pass it on.
                        </div>
                    </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      <button onClick = {(e) => self.editProfileItem(e)} id = "email-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)} id = "email-editMode-save"  class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "email-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                  </div>
                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  > Phone Number </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                    <span>
                      {isAuthenticated ? ` ${findElementInCountryCodeListArray(profileElements.professional.phoneCountryCode, internationalCallingCodeList) + profileElements.professional.phone}  ` : `+2634495253`}

                    <div id = "professional.phone-editMode-form" class = "dashboard-iux-phone-number hidden">


                          <select  value={this.state.phoneCountryCode}  onChange={this.handleProfileItemEdit} name = "professional.phoneCountryCode" id = "professional.phone-editMode-form-countryCode" class = "dashboard__select__button__style">
                            <option value="">{findElementInCountryListArray(profileElements.professional.phoneCountryCode, countryList)}</option>
                            {countryList.map((phoneCountryCode) =>
                              <option value = {phoneCountryCode.optionValue}>{phoneCountryCode.country} </option>

                            )}
                          </select>



                          <label class = "dashboard__input__text__label" for="phoneNumber">
                            {/*form-control form-control-sm form-field*/}
                            <div class = "dashboard__input__text__field">
                              <input  value={this.state.phone}  onChange={this.handleProfileItemEdit} name = "professional.phone" id = "professional.phone-editMode-form-phoneNumber" class = "dashboard__select__button__style dashboard__input__text__control" type="tel"  placeholder="Phone Number"></input>
                            </div>
                          </label>

                      </div>
                      <div class = "dashboard-input-information-caption">
                        We will use this as your primary contact number and will not pass it on.
                      </div>
                    </span>

                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      <button onClick = {(e) => self.editProfileItem(e)} id = "professional.phone-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.phone-editMode-save"   class="bui-button bui-button-tertiary-danger hidden" ><span>Save</span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.phone-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" ><span> Cancel </span></button></span>

                  </div>
                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <div class = "dashboard-row-title"  > Address </div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">
                    <span>  {isAuthenticated ? ` ${profileElements.professional.address}  ` : `Adreess line 1`} <br/>


                    {isAuthenticated ? ` ${profileElements.professional.city}  ` : `City`}  <br/>

                    {isAuthenticated ? ` ${profileElements.professional.zip_code}  ` : `Postcode`} <br/>

                    {isAuthenticated ? ` ${findElementInCountryListArray(profileElements.professional.countrySelect, countryList)}  ` : `Country`}

                                              <div id = "professional.address-editMode-form" class = "hidden">

                                                  <label class = "dashboard__input__text__label" >
                                                    <input value={this.state.address}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-address" name = "professional.address" class = "dashboard__select__button__style dashboard__input__text__control" type="text" placeholder="Address"></input> {/*id="professional.address" */}
                                                  </label>




                                                    <label class = "dashboard__input__text__label" >
                                                      <input value={this.state.city}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-city" name = "professional.city" class = "dashboard__select__button__style dashboard__input__text__control" type="text"  placeholder="City"></input> {/*id="professional.city"*/}
                                                    </label>





                                                   <label class = "dashboard__input__text__label" >
                                                     <input value={this.state.zip_code}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-zip_code" name = "professional.zip_code" class = "dashboard__select__button__style dashboard__input__text__control" type="text"  placeholder="Postcode"></input> {/*id="professional.zip_code"*/}
                                                   </label>






                                                   <select  value={this.state.countrySelect}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-countrySelect" name = "professional.countrySelect" class = "dashboard__select__button__style" >
                                                     <option value="">{findElementInCountryListArray(profileElements.professional.countrySelect, countryList)}</option>
                                                     {countryList.map((countrySelect) =>

                                                       <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                                                     )}
                                                   </select>


                                              </div>

                    </span>
                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">
                    <span>
                      <button onClick = {(e) => self.editProfileItem(e)} id = "professional.address-editMode"  target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                        <span class="bui-button__text">Edit</span>
                      </button>
                    </span>

                    <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.address-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span>Save</span></button></span>
                    <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.address-editMode-cancel"  class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span> Cancel </span></button></span>

                  </div>
                </div> {/*End row element*/}
            </div>
          </div>



        </div> : <></>
    )
    }
  }
class BusinessProfileUnconnectedDepricated  extends Component{

  constructor(props) {
    super(props);
    this.state = {
      businessProfile:[],
      isLoading: true,
      'professional.title': '',
      first_name: '',
      last_name: '',
      email: '',

      'professional.phone' : null,
      'professional.phoneCountryCode' : null,
      'professional.address': null,
      'professional.city':  null,
      'professional.zip_code': null,
      'professional.countrySelect': null,
      'professional.businessName': null,
      'professional.businessBio': null,
      'professional.businessType': null,

      udpateFieldName: '',
      updateFieldValue: null,
      updatePostData  : {},




    };

    this.fetchProfile          = this.fetchProfile.bind(this)
    this.handleProfileItemEdit = this.handleProfileItemEdit.bind(this)
    this.editProfileItem       = this.editProfileItem.bind(this)
    this.handleSave            = this.handleSave.bind(this)
    this.cancelEditProfileItem = this.cancelEditProfileItem.bind(this)
    this.clearFormElements     = this.clearFormElements.bind(this)



  }

  static propTypes = {
    auth: PropTypes.object.isRequired,

  };

  componentDidMount() {
    //this.props.dispatch(fetchMemberProfile());
    // this.props.fetchMemberProfile();
    this.fetchProfile()
  }

  componentDidUpdate(){
    /* If the profile has updated let's call the api*/
    this.fetchProfile()
  }


  fetchProfile(){
    // var url = "https://real-grape-site-us.herokuapp.com/api/auth/business-profile-page"
    var url = "https://www.therealgrape.com/api/auth/business-profile-page"
    // var url = "http://127.0.0.1:8000/api/auth/business-profile-page"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
          businessProfile: response.data,
          isLoading: false
        })})
      .catch((error)   => console.log(error));
  }

  handleProfileItemEdit(event) {

    const target = event.target;
    const value  = target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name   = target.name

    this.setState({
      [event.target.name]: value,
      updateFieldName: name,
      updateFieldValue: value,
    })

    var postData   = this.state.updatePostData
    postData[name] = value
    this.setState({
      updatePostData: postData
    })
  }

  editProfileItem(event) {

    // var elementId = this.val('elementId')
    // const elementId       = event.target.id //value('profile-elementId')
    event.preventDefault()

    const elementId     =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''


    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)


    // unhide form element, save button and camcel button
    formElement.classList.remove("hidden");
    saveBtn.classList.remove("hidden");
    cancelBtn.classList.remove("hidden");

    // hide the edit button
    editBtn.classList.add("hidden");

  }

  handleSave(event) {

    event.preventDefault()

    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-save", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    if (this.state.updateFieldValue != null)
    {

      var field2Update         = this.state.udpateFieldName
      const constUpdateValue   = this.state.updateFieldValue
      const postDataFinal      = this.state.updatePostData

      var postData = {}
      postData[field2Update] = constUpdateValue

      var url = "https://www.therealgrape.com/api/auth/business-profile-update"
      // var url = "http://127.0.0.1:8000/api/auth/business-profile-update"

      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now we need to stringfy the field that we want to provide
      // Request body

      const body = JSON.stringify(postDataFinal);

      // now post the Data
      axios.post(url, body, config)
      .then()

      // reset the state variables for field updates to null
      this.setState({
        udpateFieldName: '',
        updateFieldValue: null,
        editing:false
      })

      // clear anything that is currently in the updatePostData

      this.setState({
          updatePostData : {}
      })

      // return all form data to original state

      this.setState({

        'professional.title': '',
        first_name: '',
        last_name: '',
        email: '',

        'professional.phone' : null,
        'professional.phoneCountryCode' : null,
        'professional.address': null,
        'professional.city':  null,
        'professional.zip_code': null,
        'professional.countrySelect': null,
        'professional.businessName': null,
        'professional.businessBio': null,
        'professional.businessType': null




      })
    }

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");

    // clear the form elements
    this.clearFormElements()

  }

  cancelEditProfileItem(event) {
    /* handle the cancel button*/
    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-cancel", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");


    // clear anything that is currently in the updatePostData

    this.setState({
        updatePostData : {}
    })

    // return all form data to original state

    this.setState({

      'professional.title': '',
      first_name: '',
      last_name: '',
      email: '',

      'professional.phone' : null,
      'professional.phoneCountryCode' : null,
      'professional.address': null,
      'professional.city':  null,
      'professional.zip_code': null,
      'professional.countrySelect': null,
      'professional.businessName': null,
      'professional.businessBio': null,
      'professional.businessType': null



    })

    // clear all form elements
    this.clearFormElements()






  }

  clearFormElements() {
    document.getElementById("professional.businessName-editMode-form").value = ""
    document.getElementById("professional.businessBio-editMode-form").value = ""
    document.getElementById("professional.businessType-editMode-form").value = ""
    document.getElementById("name-editMode-form-first_name").value = ""
    document.getElementById("name-editMode-form-last_name").value = ""
    document.getElementById("professional.title-editMode-form").value = ""
    document.getElementById("email-editMode-form").value = ""
    document.getElementById("professional.phone-editMode-form-countryCode").value = ""
    document.getElementById("professional.phone-editMode-form-phoneNumber").value = ""
    document.getElementById("professional.address-editMode-form-address").value = ""
    document.getElementById("professional.address-editMode-form-city").value = ""
    document.getElementById("professional.address-editMode-form-zip_code").value = ""
    document.getElementById("professional.address-editMode-form-countrySelect").value = ""
  }



  render()
  {

    const {isAuthenticated} = this.props.auth;
    var     isLoading       = this.state.isLoading
    var     profileElements = this.state.businessProfile
    var     self   = this


     return(
       !isLoading ?
       <div>

        <div class = "my-dashboard-page">

          <div class = "my-dashboard-flex-row">

            <div class = "container my-profile-container">
              <div class = "my-dashboard-container">
                <div class = "form my-profile" id = "my-profile-form">

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:8}}>
                      <span>
                        <h1 class = "my-dashboard__title_text"> Business Details </h1>
                        <div class = "my-dashboard__subtitle"> Update your information and how it is used</div>
                      </span>
                    </div>
                    <div style={{flex:1}}>
                      <span>

                        <button aria-label = "Change your profile picture" class = "bui-button bui-button-tertiary">
                          <span class = "bui-button__text">
                            <div class = "my-dashboard-profile_pic-wrapper">
                              <div class = "bui-profile_pic bui-profile_pic--larger bui-profile_pic--outline-complement ">
                                {isAuthenticated ? <img class = "profile-pic" src = {profileElements.professional.progile_pic} / >  : <PlaceHolderImage/>}
                              </div>
                                     {/*'http://127.0.0.1:8000' + '/media/TTA_Financial_Protection_150x82.png'*/}




                            </div> {/* End profile pic wrapper*/}
                          </span>
                        </button> {/* change prfile picture section*/}
                      </span>
                    </div>

                  </div>{/*end dashboard element*/}

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Business Name </span></div>
                    <div style={{flex:6}}>
                      <span>
                        {isAuthenticated ? ` ${profileElements.professional.businessName}   ` : `Business Name`}



                            <label class = "dashboard__input__text__label" for = "professional.businessName">
                              <input value={this.state.businessName}  onChange={this.handleProfileItemEdit}  id = "professional.businessName-editMode-form"
                              name = "professional.businessName" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "first_name"
                              placeholder = "Business Name"></input>
                            </label>



                      </span></div>
                    <div style={{flex:1}}>
                      <span>
                        <button onClick = {(e) => self.editProfileItem(e)} id = "professional.businessName-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.businessName-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.businessName-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                    </div>
                  </div>{/*end dashboard element*/}


                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Business Bio </span></div>
                    <div style={{flex:6}}>
                      <span>
                        {isAuthenticated ? ` ${profileElements.professional.businessBio}   ` : `Business Bio`}



                            <label class = "dashboard__input__text__label" for = "professional.businessBio">
                              <textarea value={this.state.businessBio}  onChange={this.handleProfileItemEdit}  id = "professional.businessBio-editMode-form"
                              name = "professional.businessBio" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "first_name"
                              placeholder = "About your business and services"></textarea>
                              {/*<textarea value={this.state.businessBio}  onChange={this.handleProfileItemEdit}  id = "professional.businessBio-editMode-form"
                              name = "professional.businessBio" class = "form-control form-control-sm textarea__requestDetails medium form-field hidden "  cols="75" id="message" placeholder="About your business and services" aria-required="true" aria-invalid="false"></textarea>*/}
                            </label>


                          <div class = "dashboard-input-information-caption">
                            Please provide a description of your business and services you provide. Please also detail the type of events and experiences you provide.
                          </div>

                      </span></div>
                    <div style={{flex:1}}>
                      <span>
                        <button onClick = {(e) => self.editProfileItem(e)} id = "professional.businessBio-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.businessBio-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.businessBio-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                    </div>
                  </div>{/*end dashboard element*/}

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Business Type</span></div>
                    <div style={{flex:6}}><span>
                      {isAuthenticated ? ` ${findElementInBusinessTypeListArray(profileElements.professional.businessType, businessTypeList)} ` : `Mr`}
                      {/*form-control form-control-sm form-field*/}
                      <select  value={this.state.businessType}  onChange={this.handleProfileItemEdit} name = "professional.businessType" id = "professional.businessType-editMode-form" class = "dashboard__select__button__style hidden">
                        <option value="">{findElementInBusinessTypeListArray(profileElements.professional.businessType, businessTypeList)}</option>
                        {businessTypeList.map((businessTypeListSelect) =>
                          <option value = {businessTypeListSelect.optionValue}>{businessTypeListSelect.business} </option>

                        )}
                      </select>



                    </span></div>

                    <div style={{flex:1}}>
                      <span>
                        {/*onClick = {() => self.editProfileItem('member.title')}*/}
                        <button onClick = {(e) => self.editProfileItem(e)} id = "professional.businessType-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)} id = "professional.businessType-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.businessType-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                    </div>

                  </div>{/*end dashboard element*/}

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Contact</span></div>
                    <div style={{flex:6}}>
                      <span>
                        {isAuthenticated ? ` ${profileElements.first_name}  ${profileElements.last_name} ` : `Firstname Lastname`}

                        <div id = "name-editMode-form" class = "awrapper hidden">
                            <label class = "dashboard__input__text__label" >
                                <input value={this.state.first_name}  onChange={this.handleProfileItemEdit}  name = "first_name" id = "name-editMode-form-first_name" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "first_name" placeholder = "First Name"></input>
                            </label>




                            <label class = "dashboard__input__text__label" >
                              <input  value={this.state.last_name}  onChange={this.handleProfileItemEdit}  name = "last_name" id = "name-editMode-form-last_name" class = "dashboard__select__button__style dashboard__input__text__control" type = "text" if = "last_name" placeholder = "Last Name"></input>
                            </label>

                        </div>

                      </span></div>
                    <div style={{flex:1}}>
                      <span>
                        <button onClick = {(e) => self.editProfileItem(e)} id = "name-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)}            id = "name-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "name-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                    </div>
                  </div>{/*end dashboard element*/}

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Title</span></div>
                    <div style={{flex:6}}><span>
                      {isAuthenticated ? ` ${findElementInTitleArray(profileElements.professional.title, titleList)} ` : `Mr`}

                      <select  value={this.state.title}  onChange={this.handleProfileItemEdit} name = "professional.title" id = "professional.title-editMode-form" class = "dashboard__select__button__style hidden">
                        <option value="">{findElementInTitleArray(profileElements.professional.title, titleList)}</option>
                        {titleList.map((titleSelect) =>
                          <option value = {titleSelect.optionValue}>{titleSelect.title} </option>

                        )}
                      </select>


                    </span></div>

                    <div style={{flex:1}}>
                      <span>
                        {/*onClick = {() => self.editProfileItem('member.title')}*/}
                        <button onClick = {(e) => self.editProfileItem(e)} id = "professional.title-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)} id = "professional.title-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.title-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                    </div>

                  </div>{/*end dashboard element*/}

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Email </span></div>
                    <div style={{flex:6}}>
                      <span>
                        {isAuthenticated ? ` ${profileElements.email}  ` : `Email`}

                            <label class = "dashboard__input__text__label" for="email">
                              <input   value={this.state.email}  onChange={this.handleProfileItemEdit}  name = "email" id = "email-editMode-form" class = "dashboard__select__button__style dashboard__input__text__control hidden" type="email"  placeholder="Email address"></input>
                            </label>

                          <div class = "dashboard-input-information-caption">
                            We will only email you any necessary correspondence to this mailbox. use this as your primary contact number and will not pass it on.
                          </div>
                      </span>
                    </div>
                    <div style={{flex:1}}>
                      <span>
                        <button onClick = {(e) => self.editProfileItem(e)} id = "email-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)} id = "email-editMode-save"  class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "email-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>
                    </div>
                  </div>{/*end dashboard element*/}

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Phone Number  </span></div>
                    <div style={{flex:6}}>
                      <span>
                        {isAuthenticated ? ` ${findElementInCountryCodeListArray(profileElements.professional.phoneCountryCode, internationalCallingCodeList) + profileElements.professional.phone}  ` : `+2634495253`}

                      <div id = "professional.phone-editMode-form" class = "dashboard-iux-phone-number hidden">


                            <select  value={this.state.phoneCountryCode}  onChange={this.handleProfileItemEdit} name = "professional.phoneCountryCode" id = "professional.phone-editMode-form-countryCode" class = "dashboard__select__button__style">
                              <option value="">{findElementInCountryListArray(profileElements.professional.phoneCountryCode, countryList)}</option>
                              {countryList.map((phoneCountryCode) =>
                                <option value = {phoneCountryCode.optionValue}>{phoneCountryCode.country} </option>

                              )}
                            </select>



                            <label class = "dashboard__input__text__label" for="phoneNumber">
                              {/*form-control form-control-sm form-field*/}
                              <div class = "dashboard__input__text__field">
                                <input  value={this.state.phone}  onChange={this.handleProfileItemEdit} name = "professional.phone" id = "professional.phone-editMode-form-phoneNumber" class = "dashboard__select__button__style dashboard__input__text__control" type="tel"  placeholder="Phone Number"></input>
                              </div>
                            </label>

                        </div>
                        <div class = "dashboard-input-information-caption">
                          We will use this as your primary contact number and will not pass it on.
                        </div>
                      </span>
                    </div>
                    <div style={{flex:1}}>
                      <span>
                        <button onClick = {(e) => self.editProfileItem(e)} id = "professional.phone-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.phone-editMode-save"   class="bui-button bui-button-tertiary-danger hidden" ><span>Save</span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.phone-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" ><span> Cancel </span></button></span>
                    </div>
                  </div>{/*end dashboard element*/}

                  <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                    <div style={{flex:2}}><span> Address </span></div>
                    <div style={{flex:6}}><span>  {isAuthenticated ? ` ${profileElements.professional.address}  ` : `Adreess line 1`} <br/>


                    {isAuthenticated ? ` ${profileElements.professional.city}  ` : `City`}  <br/>

                    {isAuthenticated ? ` ${profileElements.professional.zip_code}  ` : `Postcode`} <br/>

                    {isAuthenticated ? ` ${findElementInCountryListArray(profileElements.professional.countrySelect, countryList)}  ` : `Country`}

                                              <div id = "professional.address-editMode-form" class = "hidden">

                                                  <label class = "dashboard__input__text__label" >
                                                    <input value={this.state.address}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-address" name = "professional.address" class = "dashboard__select__button__style dashboard__input__text__control" type="text" placeholder="Address"></input> {/*id="professional.address" */}
                                                  </label>




                                                    <label class = "dashboard__input__text__label" >
                                                      <input value={this.state.city}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-city" name = "professional.city" class = "dashboard__select__button__style dashboard__input__text__control" type="text"  placeholder="City"></input> {/*id="professional.city"*/}
                                                    </label>





                                                   <label class = "dashboard__input__text__label" >
                                                     <input value={this.state.zip_code}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-zip_code" name = "professional.zip_code" class = "dashboard__select__button__style dashboard__input__text__control" type="text"  placeholder="Postcode"></input> {/*id="professional.zip_code"*/}
                                                   </label>






                                                   <select  value={this.state.countrySelect}  onChange={this.handleProfileItemEdit} id = "professional.address-editMode-form-countrySelect" name = "professional.countrySelect" class = "dashboard__select__button__style" >
                                                     <option value="">{findElementInCountryListArray(profileElements.professional.countrySelect, countryList)}</option>
                                                     {countryList.map((countrySelect) =>

                                                       <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                                                     )}
                                                   </select>


                                              </div>

                                          </span>
                    </div>


                    <div style={{flex:1}}>
                      <span>
                        <button onClick = {(e) => self.editProfileItem(e)} id = "professional.address-editMode"  target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                          <span class="bui-button__text">Edit</span>
                        </button>
                      </span>

                      <span><button onClick = {(e) => self.handleSave(e)}            id = "professional.address-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span>Save</span></button></span>
                      <span><button onClick = {(e) => self.cancelEditProfileItem(e)} id = "professional.address-editMode-cancel"  class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span> Cancel </span></button></span>
                    </div>
                  </div>{/*end dashboard element*/}

                </div>
              </div>
            </div>
          </div>
        </div>
       </div> : <></>
   )
   }
 }



 const mapStateToProps = (state) => ({
   auth: state.auth,
 });

const BusinessProfile   = connect(mapStateToProps)(BusinessProfileUnconnected);
const MyProfile         = connect(mapStateToProps)(MyProfileUnconnected);
export {MyProfile as MyProfile}
export {BusinessProfile as BusinessProfile}
