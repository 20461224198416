import React, {useState, useEffect, useLayoutEffect} from 'react';
import  { Component } from 'react';
import '../App.css';
import '../Styles/Shop.css'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import "../Actions/Auth.js"

import axios from 'axios'

import {loadUser, logout, getCart} from '../Actions/Auth.js'
import {getEventAndExperienceData} from '../Actions/EventsAndExperiences.js'
import store from '../Store.js'

import {
       genderList,
       countryList,
       internationalCallingCodeList,
       findElementInCountryListArray,
       findElementInCountryCodeListArray,
       currencyCodes,
       currencyCodesReduced,
       findElementInCCyListArray,
       removeZeroFromPhoneNumber,
       PlaceHolderImage} from "../Forms/FormComponents.js"

import {
  withRouter, // used in the app export default - expore using with Journal
  BrowserRouter as Router,

  HashRouter, // try using the HashRouter
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from 'react-router-dom'


import {dateElementFromDate, dateFromText, dateFormatterFromString} from "../Components/DashboardComponents.js"
import getSymbolFromCurrency from 'currency-symbol-map'

/* Date and time inouts*/

import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import TimeKeeper from 'react-timekeeper';
import TimezoneSelect from 'react-timezone-select'

// google maps api

import { Loader } from "@googlemaps/js-api-loader"

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly"
});
/*const google = window.google*/
declare var google;


function dateFormatter(date){
  var date  = new Date(date)

  const year     = date.getFullYear()
  const day      = date.getDate()
  const monthInx = date.getMonth()

  const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

  const formattedDate = day.toString() + " "+ months[monthInx] +" "+ year.toString()



  return formattedDate;
  /*19 November 2020
  return date.toLocaleDateString('en-GB');
  */
}



class ExperiencesShopPageClass extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,

  };

  constructor(props) {
    super(props);
    this.state = {
    experienceId: null,
    experienceData: {},
    isLoadingAnEvent: true,
    ticketQuantitySelect: [],
    ticketQuantity: 1,
    }

    this.getEventData      = this.getEventData.bind(this)
    this.LocationMap       = this.LocationMap.bind(this)
    this.cart              = this.cart.bind(this)
    this.updateUserOrder   = this.updateUserOrder.bind(this)
    this.handleFormChange  = this.handleFormChange.bind(this)


  }

  componentDidMount() {

    var slug           = this.props.match.params.slug
    var slugAsArray    = slug.split("-")
    var slugAsArrayLen = slugAsArray.length
    var id = slugAsArray[slugAsArrayLen - 1]

    this.setState({
      experienceId: id
    })

    this.getEventData(id)



  }

  componentDidUpdate(){

    if (!this.state.isLoadingAnEvent && !this.state.experienceData.olineExperience) {
      this.LocationMap()
    }

    console.log(this.state.experienceData)

  }



  getEventData(itemToEdit){


    this.setState({
      isEditing: true,
      isLoadingAnEvent: true,
    })

    var url = "https://www.therealgrape.com/api/auth/business-profile/experience-details/"+itemToEdit.toString()
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/experience-details/"+itemToEdit.toString()

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        experienceData: response.data,
        isLoadingAnEvent: false,
        })



        var ticketQuantitySelect = []

        for (var i = 0; i < this.state.experienceData.maximumQuantity; i++) {
          ticketQuantitySelect.push(i+1)
        }

        this.setState({
          ticketQuantitySelect: ticketQuantitySelect
        })


      })
      .catch((error)   => console.log(error));


  }

  LocationMap(){

    var map
    const locA = {lat: this.state.experienceData.lat, lng: this.state.experienceData.lng}

    loader.load().then(() => {
      map = new google.maps.Map(document.getElementById('events-experience-venue-location-area'), {
        /*center: { lat: 52.56447, lng: -1.46226 }*/
        center: { lat: this.state.experienceData.lat, lng:this.state.experienceData.lng },
        zoom: 7,
      });

      const marker = new google.maps.Marker({
        position: locA,
        map: map,
        label: "1",
        icon:{
          color: "0099FF",
          fillColor: "#00FF00",
          strokeColor: "white",
          fillOpacity: 1.0,
          scale: 3,
          labelColor: '#FFFFFF',
        },
        MarkerLabel: {
          fontSize: "32px"
        }
      });


    });

    // add another marketing





    return map


  }

  cart(e) {
    e.preventDefault()
    const { isAuthenticated, user } = this.props.auth;
    var updateBtns = document.getElementsByClassName('update-cart')

    var productId    = updateBtns.item(0).dataset.product
    var action       = updateBtns.item(0).dataset.action
    var productGroup = updateBtns.item(0).dataset.productgroup
    var quantity     = this.state.ticketQuantity


        console.log('productGroup: ', productGroup,'productId: ', productId, 'Action: ', action,'Quantity: ', this.state.ticketQuantity)
    console.log('authenticaed:', isAuthenticated,'user:', user)
    console.log(this.props)
    if (isAuthenticated) {

      this.updateUserOrder(productGroup, productId, action, quantity)
    } else {

    }






  }

  updateUserOrder(productGroup, productId, action, quantity){
    console.log("Updating users order")


    var url = "https://www.therealgrape.com/api/cart/update-item-to-cart"
    //var url = "http://127.0.0.1:8000/api/cart/update-item-to-cart"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'productGroup':productGroup , 'productId':productId, 'action':action, 'quantity': quantity});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      store.dispatch(getCart());
    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })

  }

  handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    this.setState({
      [e.target.name]: value.toString()
    })


  }




  render()
  {
    var isLoadingAnEvent  = this.state.isLoadingAnEvent

    return(
      !isLoadingAnEvent ? [
        <div>
          <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
            <div class = "app-content-full container">
              <div class = "event-experience-info-wrapper">

              {this.props.auth.isAuthenticated ?
                [
                   this.props.auth.group[0].name === "members" ?
                   [
                      <div>

                        <div class = "event-experience-info event-experience-summary-header row" >

                          <div class = "col-sm-12 offset-md-10 col-md-2 offset-lg-10 col-lg-2 ">
                            Booking confirmation
                          </div>

                          <div class = "col-sm-12 offset-md-10 col-md-2  offset-lg-10 col-lg-2 confirm-details ">
                            Refference Number: <span class = "confirm-details-highlight">2454.427.098 </span>
                          </div>


                        </div>

                         <div class = "event-experience-info event-experience-summary row" style = {{border:"1px solid grey"}}>
                           <div class = "col-sm-12 col-md-12 col-lg-12">
                            Quantity Bought
                           </div>
                           <div class = "col-sm-12 col-md-12 col-lg-12">
                            Admits per ticket
                           </div>
                         </div>

                         <div class = "event-experience-info event-experience-summary-footer row" >

                           <div class = "col-sm-12 col-md-12 col-lg-12">
                             Rate Event
                           </div>

                           <div class = "col-sm-12 col-md-12 col-lg-12">
                             Add Comment
                           </div>



                         </div>

                      </div>
                   ] : [<></>]
                ] : [<></>]}

                <div class = "event-experience-info row" >
                  <div class = "col-md-7 col-xl-6">

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-name"> {this.state.experienceData.experienceTitle} </div>
                    </div>

                    {this.state.experienceData.hashTags.split(" ").map((hashTag, index) =>
                      <span style={{ marginRight: '10px', fontWeight:'500', color: '#8d8478' }}> {hashTag} </span>
                    )}

                    <div class = "event-experience-meta">
                      {this.state.experienceData.ccyExpenseSymbols.split("|").map((symbol, index) =>
                        getSymbolFromCurrency(symbol)
                    )}
                    </div>

                    <div class = "event-experience__intro-copy-paragraph">
                      {this.state.experienceData.experienceType}
                    </div>


                    <div class = "event-experience-date-and-time-wrapper">
                      <div class = "event-experience-date-and-time">
                      {dateFormatter(this.state.experienceData.date)} <div class="separator"></div> {this.state.experienceData.time} ({this.state.experienceData.timeZone})
                      </div>
                    </div>

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-title"> Make a booking </div>
                    </div>
                    <div >
                      <span style  ={{fontWeight: '500'}} >General Admission </span>
                      <span style = {{color: '#8d8478' }}>(1 ticket admits {this.state.experienceData.ticketsPerOrder}) </span>
                    </div>

                    <span style={{ marginRight: '5px', fontWeight:'500', color: '#000' }}>
                      {getSymbolFromCurrency(this.state.experienceData.currency)}
                      {this.state.experienceData.price.toFixed(2)}
                    </span>
                    <span style={{ marginRight: '5px', fontWeight:'500', fontSize:'12px', color: '#8d8478' }}>
                     +
                    {getSymbolFromCurrency(this.state.experienceData.currency)}
                    {this.state.experienceData.clientTransactionFee.toFixed(2)} Fee
                    </span>

                    <form> {/*Make a booking form*/}
                      <div class = "row">
                        <div class = "col-sm-12 col-md-6 " style = {{marginBottom:"5px"}}>
                          <div class = "form-field-wrapper">
                            <select onChange = {this.handleFormChange} value = {this.state.ticketQuantity} name = "ticketQuantity" class = "event-experience-select-form-field">
                              {this.state.ticketQuantitySelect.map((quantity) =>
                                <option value = {quantity.toString()}> {quantity} </option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div class = "col-sm-12 col-md-6 ">
                          <button onClick={(e) => this.cart(e)} data-productGroup = {this.props.match.path.split('/')[1]} data-product={this.state.experienceData.id} data-action="add"  class = "button button-primary event-experience-add-to-cart update-cart">
                            <i style={{ marginRight: '10px'}} class="fas fa-shopping-cart"></i>
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </form> {/*end booking form*/}

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}> Need to Know </div>
                    </div>

                    <p class = "event-experience__intro-copy-paragraph">
                      {this.state.experienceData.description}
                    </p>

                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper" style = {{alignItmes:"left"}}>
                      <div class = "row">
                        <div class = "col-sm-12 col-md-12 col-lg-12">
                          <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}>Wine lists </div>
                        </div>

                        
                      </div>
                    </div>



                    <hr aria-hidden="true" />

                    <div class = "event-experience-name-wrapper">
                      <div class = "event-experience-inpage-details-title" style = {{textAlign:"left"}}>About  {this.state.experienceData.businessName}</div>
                    </div>

                    <p class = "event-experience__intro-copy-paragraph">
                      {this.state.experienceData.businessBio}
                    </p>

                    <hr aria-hidden="true" />


                  </div>



                  <div class = "col-md-4 offset-md-1 col-xl-5 offset-xl-1">
                    <div class = "event-experience_Img_wrapper">
                      <img class = "event-experience_Img_Centered" src = {this.state.experienceData.mainExperienceImage} />
                    </div>

                    <div class = "venue-location-summary">
                      <div class = "venue-location-summary-content">

                        {
                          !this.state.experienceData.olineExperience ? [

                              <div>


                                <div class = "venue-location-summary-Map_Area_Wrapper">
                                    <div class = "venue-location-summary_Map_Area" id="events-experience-venue-location-area"/>
                                </div>



                                <div class = "venue-location-summary-content-info">
                                  <div class = "event-experience-name-breadcrumb">{this.state.experienceData.experienceTitle}</div>
                                  <p class = "venue-location-summary-content-info-breadcrumb">{this.state.experienceData.locationAddress}</p>
                                  <p class = "venue-location-summary-content-info-breadcrumb">{this.state.experienceData.locationcity}, {this.state.experienceData.locationZip_code}</p>
                                </div>

                                <div class = "venue-location-summary-content-row">
                                  <i class="fas fa-phone-alt">
                                  </i>
                                  <span> <div style={{ marginLeft: '20px' }}>{ findElementInCountryCodeListArray(this.state.experienceData.hostPhoneCountryCode,internationalCallingCodeList)  + removeZeroFromPhoneNumber(this.state.experienceData.hostPhone)} </div></span>
                                </div>

                                <div class = "venue-location-summary-content-row">
                                  <i class="fas fa-envelope"></i>
                                  <span> <div style={{ marginLeft: '20px' }}> {this.state.experienceData.hostEmail} </div></span>
                                </div>

                              </div>
                            ] :
                            [
                            <div>
                              <div class = "venue-location-summary-content-info">
                                <div class = "event-experience-name-breadcrumb">{this.state.experienceData.eventTitle}</div>
                                <p class = "venue-location-summary-content-info-breadcrumb"> Streaming details will be shared once the event is purchased. </p>
                              </div>
                              <div class = "venue-location-summary-content-row">
                                <i class="fas fa-phone-alt">
                                </i>
                                <span> <div style={{ marginLeft: '20px' }}>{ findElementInCountryCodeListArray(this.state.experienceData.hostPhoneCountryCode,internationalCallingCodeList)  + removeZeroFromPhoneNumber(this.state.experienceData.hostPhone)} </div></span>
                              </div>

                              <div class = "venue-location-summary-content-row">
                                <i class="fas fa-envelope"></i>
                                <span> <div style={{ marginLeft: '20px' }}> {this.state.experienceData.hostEmail} </div></span>
                              </div>
                            </div>]
                        }


                      </div>
                    </div>

                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>] : [<></>]
    )
  }
}



class ExperiencesClass extends Component{
  /*export class Experiences extends Component{*/
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      experiencesList: [],
      isLoading: true,
      eventsMap: null
    }
    this.fetchExperiencesList      = this.fetchExperiencesList.bind(this)
    this.initEventsMap        = this.initEventsMap.bind(this)
  }

  componentDidMount() {
    this.fetchExperiencesList()
  }

  componentDidUpdate() {
    if (!this.state.isLoading) {

      this.initEventsMap()

    }


  }

  initEventsMap(){

    var map
    const locA = {lat: 51.517845, lng: -0.101729}
    const locB = {lat: 51.522084, lng: -0.090422}
    const locC = [{lat: 51.517845, lng: -0.101729}, {lat: 51.522084, lng: -0.090422}]

    loader.load().then(() => {
      map = new google.maps.Map(document.getElementById('events-map-area'), {
        /*center: { lat: 52.56447, lng: -1.46226 }*/
        center: { lat: 51.507211, lng:-0.128381 },
        zoom: 7,
      });

      const marker = new google.maps.Marker({
        position: locA,
        map: map,
        label: "1",
        icon:{
          color: "0099FF",
          fillColor: "#00FF00",
          strokeColor: "white",
          fillOpacity: 1.0,
          scale: 3,
          labelColor: '#FFFFFF',
        },
        MarkerLabel: {
          fontSize: "32px"
        }
      });





    });


  }


  fetchExperiencesList() {

     var url = "https://www.therealgrape.com/api/auth/business-profile/my-experiences-listing"
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/my-experiences-listing"



    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
          experiencesList: response.data,
          isLoading: false,
        })})
      .catch((error)   => console.log(error));


  }




  render()
  {
    const { isAuthenticated, user } = this.props.auth;
    var isLoading                   = this.state.isLoading
    var self                        = this

    return(
      !isLoading ? [
        <fragment>


          <div class = "all-page">
            <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
              <div class = "app-content-full container">

                <div class = "row">

                  <div class = "col-sm-12 col-md-8 col-lg-8">

                    <div class = "events_and_experiences__hero events_and_experiences__hero-md">
                     <div class = "events_and_experiences__hero-container">
                       <div class = "events_and_experiences__hero-image">
                        {/*<div class = "events_and_experiences__hero-image-img-content" style = {{background: 'url(' + HeroImg + ')'}}></div>*/}
                         <img class = "events_and_experiences__hero-image-img-content" src = {'https://real-grape-site-s3-bucket.s3.us-east-2.amazonaws.com/darjeeling-biryani-800x533.jpg'}/>
                         <div class = "events_and_experiences__hero-page-description">
                           < div class = "events_and_experiences__hero-page-description-titlehello world">
                             Events
                           </div>

                           <h2 class = "events_and_experiences__hero-page-description-title-main">
                             Wine Tasting Events and Dinners
                           </h2>

                           <p class = "events_and_experiences__hero-page-description-subtitle">
                             Find that event for a special occasion. Take a break from ...
                             Find that event for a special occasion. Take a break from ...
                             Find that event for a special occasion. Take a break from ...
                             Find that event for a special occasion. Take a break from ...
                           </p>
                         </div>
                       </div>

                     </div>



                    </div>

                  </div>

                  <div class = "col-sm-12 col-md-4 col-lg-4">

                    <div class = "events_and_experiences__hero events_and_experiences__hero-md">
                      <div class = "HeroDetails" aria-label = "About The Real Grape and Social Media Links">
                        <div class = "HeroDetails__Wrapper">
                          <div class = "Blurb">
                            <h6 class>
                              Discover Restaurants to Love and where to hang out
                            </h6>
                            <p class = "Blurb__Content">
                              Now is the time to support restaurants. With Resy stories and guides, get the latest intel on your favorite spots and find new openings in your city.
                            </p>

                          </div>

                          <div class = "HeroDetails__SocialMedia">

                            <a class = "HeroDetails__social-media_link" href="https://www.instagram.com/therealgrape">
                              <i class="fab fa-instagram large" />
                            </a>

                            <a class = "HeroDetails__social-media_link" href="https://soundcloud.com/user-57306623" >
                              <i class="fab fa-soundcloud" ></i>
                            </a>

                            <a class = "HeroDetails__social-media_link" href="https://www.facebook.com/TheRealGrapeTravel/">
                              <i class="fab fa-facebook-f large" ></i>
                            </a>

                          </div>

                        </div>

                      </div>
                    </div>


                  </div>

                </div> {/*end hero row*/}

                <div class = "row">
                  <div class = "col-sm-12 col-md-12 col-lg-12">

                  <span>
                    <h1 class = "my-dashboard__title_text"> Experience Listings  </h1>
                    <div class = "my-dashboard__subtitle"> </div>
                  </span>


                  </div>
                </div>

                <div class = "row" >

                  {/*<div class = "col-md-4 col-xl-5 ">*/}
                  <div class = "col-sm-12 col-md-4 col-lg-6">

                      <div class = "venue-location-summary-Map_Area_Wrapper">
                        <div class = "venue-location-summary_Map_Area" id="events-map-area" />
                      </div>

                  </div> {/*venue map*/}

                  {/*<div class = "col-md-7 offset-md-1  col-xl-6 offset-xl-1">*/}
                  <div class = "col-sm-12 col-md-8 col-lg-6">

                    <div data-experiences = "events-cards">
                      <ul class = "my-dashboard-cards">
                        {self.state.experiencesList.map((listing, index) => (
                          <li key = {`listing-${index}`}>
                            <div class = "row Events__Experiences__Store_Front_Card_Row_Format"> {/*start card render*/}
                              {/*Image elements*/}

                              <div class = "col-sm-6 col-md-4 col-lg-4">

                                <Link   style={{ textDecoration: 'none'}} to ={"/Experiences/" + listing.experienceTitle.split(" ").join("-").toLowerCase() + "-" + listing.id}>
                                  <img class = "Events__Experiences__Store_Front_Img_Centered" src = {listing.mainExperienceImage} />
                                </Link>
                              </div>

                              {/*Experience Details*/}
                              <div class = "col-sm-6 col-md-8 col-lg-8">
                                <div class = "row">
                                  <div class = "col-sm-12 col-md-12 col-lg-12">
                                    <div class = "Events__Experiences__Store_Front_Event-title">
                                      {listing.experienceTitle}
                                    </div>
                                  </div>


                                  <div class = "col-sm-12 col-md-12 col-lg-12">
                                    <div class = "Events__Experiences__Store_Front__copy__description">
                                      {listing.experienceType}
                                    </div>
                                  </div>

                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__copy__date">
                                      {dateFormatter(listing.date)}
                                    </div>
                                  </div>

                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__copy__date">
                                      {listing.time}
                                    </div>
                                  </div>

                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__copy__date">
                                      {listing.timeZone}
                                    </div>
                                  </div>

                                  <div class = "col-sm-12 col-md-8 col-lg-8">
                                    <div class = "Events__Experiences__Store_Front__copy__strapline">
                                      Tickets From {getSymbolFromCurrency(listing.currency)}{listing.price}
                                    </div>
                                  </div>

                                  <div class = "col-sm-12 col-md-4 col-lg-4">
                                    <div class = "Events__Experiences__Store_Front__category">
                                      {listing.olineExperience ? `Online Event` : ``}
                                    </div>
                                  </div>



                                </div>
                              </div>



                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                  </div>


                </div>

              </div>
            </div>
          </div>


        </fragment>] : <div></div>
    );
  }
}



class ExperiencesListClass extends Component{

  constructor(props) {
    super(props);

    this.state = {
      experiencesList:[],
      isLoading: true,
      isLoadingAnEvent: true,
      isEditing: false,
      experienceData: {},

      // variables for uploading data
      experienceTitle: null,
      mainExperienceImage: null,
      currency: null,
      description: null,
      hashTags: null,
      host: null,
      date: null,
      hostEmail: null,
      hostPhone: null,
      hostPhoneCountryCode: null,
      maximumQuantity: null,
      price: null,
      publishEvent: null,
      quantity: null,
      ticketsPerOrder: null,
      summary: null,
      olineExperience: null,
      locationAddress: null,
      locationCountry: null,
      locationZip_code: null,
      locationcity: null,
      salesEndDate: null,
      salesStartDate: null,
      time: null,
      salesEndTime: null,
      salesEndTime: null,
      timeZone: 'GMT',



      // variables for handling post data
      udpateFieldName: '',
      updateFieldValue: null,
      updatePostData  : {},

      // Pagination variables
      currentItemsList: [],
      currentPage: 0,
      listingsPerPage: 3,
      firtListItemIndx: 0,
      lastListItemIndx: 3,
      pageNumbers:[]
    }

    this.fetchExperiencesList = this.fetchExperiencesList.bind(this)
    this.pagination      = this.pagination.bind(this)
    this.manageEvent     = this.manageEvent.bind(this)

    this.handleInputChange   = this.handleInputChange.bind(this)
    this.handleFileInput     = this.handleFileInput.bind(this)
    this.SaveImage           = this.SaveImage.bind(this)
    this.editEventItem       = this.editEventItem.bind(this)
    this.handleSave          = this.handleSave.bind(this)
    this.cancelEditEventItem = this.cancelEditEventItem.bind(this)
    this.clearFormElements   = this.clearFormElements.bind(this)
    this.handleDateInput     = this.handleDateInput.bind(this)
    this.HandelDateRangeInput = this.HandelDateRangeInput.bind(this)
    this.handleTimeZoneInput  = this.handleTimeZoneInput.bind(this)
    this.handlePublish        = this.handlePublish.bind(this)

  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {

    this.fetchExperiencesList()
  }

  componentDidUpdate(){

    this.fetchExperiencesList()
  }

  pagination(){
    var pageNumbersArray = []
    for(let i = 1; i <= Math.ceil(this.numberOfListings / this.listingsPerPage); i++ )
    {
      pageNumbersArray.push(i)
    }
    this.setState({
      pageNumbers : pageNumbersArray
    })
  }

  fetchExperiencesList() {

    var url = "https://www.therealgrape.com/api/auth/business-profile/my-experiences-listing"
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/my-experiences-listing"



    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
          experiencesList: response.data,
          isLoading: false,
        })})
      .catch((error)   => console.log(error));


  }

  manageEvent(itemToEdit){


    this.setState({
      isEditing: true,
      isLoadingAnEvent: true,
    })

    var url = "https://www.therealgrape.com/api/auth/business-profile/experience-details/"+itemToEdit.toString()
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/experience-details/"+itemToEdit.toString()

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        experienceData: response.data,
        isLoadingAnEvent: false,
        })})
      .catch((error)   => console.log(error));


  }

  handleInputChange(event){

      const target = event.target;
      const value  = target.type === 'checkbox' ? target.checked : target.value;
      const name   = target.name

      // handle the create button
      var createBtn     = document.getElementById('create-event__create_button')

      this.setState({
        [name]: value,
        updateFieldName: name,
        updateFieldValue: value,
      })

      var postData   = this.state.updatePostData
      postData[name] = value
      this.setState({
        updatePostData: postData
      })

  }
  handleFileInput(event) {

    this.setState({
      mainExperienceImage: event.target.files[0]
    })

  }
  SaveImage(event) {

    event.preventDefault()

    var imgData  = new FormData();

    imgData.append('mainExperienceImage',this.state.mainExperienceImage)
    //imgData.append("name", name);

    var url = "https://www.therealgrape.com/api/auth/business-profile/experience-image-update/"+ this.state.experienceData.id.toString()+"/"
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/experience-image-update/"+ this.state.experienceData.id.toString()+"/"


    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now post the Data
    axios.post(url, imgData, config)
    .then()

    // clear the form elements
    this.clearFormElements()

    // refresh form data
    this.manageEvent(this.state.experienceData.id)

  }
  editEventItem(event){

    event.preventDefault()

    const elementId     =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''


    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide form element, save button and camcel button
    formElement.classList.remove("hidden");
    saveBtn.classList.remove("hidden");
    cancelBtn.classList.remove("hidden");

    // hide the edit button
    editBtn.classList.add("hidden");

  }
  handleSave(event) {

    event.preventDefault()
    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-save", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    if (this.state.updateFieldValue != null)
    {

      var field2Update         = this.state.udpateFieldName
      const constUpdateValue   = this.state.updateFieldValue
      const postDataFinal      = this.state.updatePostData

      var postData = {}
      postData[field2Update] = constUpdateValue

      var url = "https://www.therealgrape.com/api/auth/business-profile/experience-details-update/"+ this.state.experienceData.id.toString()+"/"
      // var url = "http://127.0.0.1:8000/api/auth/business-profile/experience-details-update/"+ this.state.experienceData.id.toString()+"/"

      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now we need to stringfy the field that we want to provide
      // Request body

      const body = JSON.stringify(postDataFinal);

      // now post the Data
      axios.post(url, body, config)
      .then()

      // reset the state variables for field updates to null
      this.setState({
        udpateFieldName: '',
        updateFieldValue: null,
        editing:false
      })

      // clear anything that is currently in the updatePostData

      this.setState({
          updatePostData : {}
      })

      // return all form data to original state

      this.setState({
        experienceTitle: null
      })

    }

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");

    // clear the form elements
    this.clearFormElements()

    // refresh form data
    this.manageEvent(this.state.experienceData.id)

    // update the component
    this.forceUpdate();

  }
  clearFormElements() {

    document.getElementById("experienceTitle-editMode-form").value = ""
    document.getElementById("mainExperienceImage-editMode-form").value = ""

  }
  cancelEditEventItem(event) {

    /* handle the cancel button*/
    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-cancel", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");


    // clear anything that is currently in the updatePostData

    this.setState({
        updatePostData : {}
    })

    // return all form data to original state

    this.setState({

        eventTitle: null


    })

    // clear all form elements
    this.clearFormElements()



  }

  handleDateInput(e) {


    /*console.log(typeof e.toDate())
    var date = e.toDate().toString()*/
    var dateToSave = new Date(e.format())
    var name       = "date"

    this.setState({date: e,
      updateFieldName: "date",
      updateFieldValue: dateToSave
    })

    var postData   = this.state.updatePostData
    postData[name] = dateToSave
    this.setState({
      updatePostData: postData
    })


  }

  HandelDateRangeInput(e){

    var postData   = this.state.updatePostData

    if (e.startDate !== null) {

      this.setState({
        salesStartDate: e.startDate

      })

      var dateToSave = new Date(e.startDate.format())
      postData['salesStartDate'] = dateToSave
      this.setState({
        updatePostData: postData,
        updateFieldName: "salesStartDate",
        updateFieldValue: dateToSave

      })



    }

    if (e.endDate !== null) {

      this.setState({
        salesEndDate: e.endDate
      })

      var dateToSave = new Date(e.endDate.format())
      postData['salesEndDate'] = dateToSave
      this.setState({
        updatePostData: postData,
        updateFieldName: "salesEndDate",
        updateFieldValue: dateToSave

      })

    }



  }

  handleTimeInput(e, fieldName){

    const timeToSave       = e.formatted24
    var postData   = this.state.updatePostData
    postData[fieldName] = timeToSave



    this.setState({
      time: timeToSave,
      updatePostData: postData,
      updateFieldName: fieldName,
      updateFieldValue: timeToSave

    })

  }

  handleTimeZoneInput(e, fieldName){

    const timeZoneToSave = e.label.split(")")[0].split("(")[1]


    var postData   = this.state.updatePostData
    postData[fieldName] = timeZoneToSave

    this.setState({
      timeZone: timeZoneToSave,
      updatePostData: postData,
      updateFieldName: fieldName,
      updateFieldValue: timeZoneToSave
    })

  }

  handlePublish(e) {

    // do not execute with the postdata
    var postData             = {}
    postData['publishEvent'] = true


    var url = "https://www.therealgrape.com/api/auth/business-profile/experience-details-update/"+ this.state.experienceData.id.toString()+"/"
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/experience-details-update/"+ this.state.experienceData.id.toString()+"/"



    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body
    const body = JSON.stringify(postData);

    // now post the Data
    axios.post(url, body, config)
    .then()

  }


  render()
  {
    const { isAuthenticated, user } = this.props.auth;
    var             isLoading       = this.state.isLoading
    var      isLoadingAnEvent       = this.state.isLoadingAnEvent
    var             isEditing       = this.state.isEditing
    var                      self   = this

    // variables for pagination
    const indexOfLastListedItem     = this.state.currentPage * this.state.listingsPerPage
    const indexOfFirstListedItem    = indexOfLastListedItem  - this.state.listingsPerPage

    return(
      !isLoading ?
        !isEditing ?

          <div>
            <div class = "dashboard-containeer">
              <div class = "form dashboard-form"  id = "my-create-event-form">

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-10 col-md-10 col-lg-10">
                    <span>
                      <h1 class = "my-dashboard__title_text"> My Experiences  </h1>
                      <div class = "my-dashboard__subtitle">Click on an experience, make changes and publish</div>
                    </span>
                  </div>

                  <div class ="col-sm-2 col-md-2 col-lg-2">

                  </div>

                </div> {/*End row element*/}

                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-12 col-md-12 col-lg-12">
                    <div class = "event_experience-stack">
                      <div class = "event_experience-stack-element-inner">
                        <ul class = "event_experience-cards" >
                          {self.state.experiencesList.map((listing, index) => (

                              <li key={`listing-${index}`}>

                                <div class = "event_experience-entry" style = {{marginTop: "10px"}} > {/*winelist-entry*/}
                                  <div class = "row" >
                                    <div class = "col-sm-12 col-md-12 col-lg-12" onClick={() => self.manageEvent(listing.id)}>
                                        <div class = "event_experience-entry-inner">
                                          <div class = "event_experience-entry-title" style = {{marginTop: "5px"}}> {listing.experienceTitle} {listing.olineExperience ? "(Online)" : ""}</div>
                                        </div>
                                    </div>
                                  </div> {/*Title row*/}

                                  <div class = "row">
                                    <div class = "col-sm-12 col-md-2 col-lg-2">

                                      <div class = "my-dashboard-cards__acard__img">
                                        <div class = "image-map-wrapper">
                                          {/*<img alt="" class="cart_Img_Centered" src = "https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png"/>*/}
                                          {/*<img alt="" class="cart_Img_Centered" src = {listing.mainExperienceImage}/>*/}
                                          <img alt="" class="cart_Img_Centered" src = {listing.mainExperienceImage === null ? 'https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png' : listing.mainExperienceImage}/>

                                        </div>
                                      </div>

                                    </div> {/*image col*/}
                                    <div class = "col-sm-12 col-md-10 col-lg-10">
                                      <div class = "row">
                                        <div class = "col-sm-12 col-md-6 col-lg-6">

                                          <div class = "row">
                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                              <div class = "event_experience-entry-inner">
                                                <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div>  Venue: {listing.olineExperience ? "Online Experience" : listing.locationAddress} </div> </div>
                                              </div>
                                            </div>
                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                              <div class = "event_experience-entry-inner">
                                                <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Date and time: {dateFormatterFromString(listing.date,listing.time) } </div> </div>
                                              </div>
                                            </div>
                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                              <div class = "event_experience-entry-inner">
                                                <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Timezone: {listing.timeZone} </div> </div>
                                              </div>
                                            </div>

                                          </div>

                                        </div> {/*venue, date and time*/}
                                        <div class = "col-sm-12 col-md-6 col-lg-6">

                                          <div class = "row">

                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                              <div class = "event_experience-entry-inner">
                                                <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Status: {listing.status} </div> </div>
                                              </div>
                                            </div>
                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                              <div class = "event_experience-entry-inner">
                                                <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Sales: {listing.ticketsSold}/{listing.quantity} </div> </div>
                                              </div>
                                            </div>
                                            <div class = "col-sm-12 col-md-12 col-lg-12">
                                              <div class = "event_experience-entry-inner">
                                                <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Gross: {getSymbolFromCurrency(listing.currency)}{listing.grossSales} / {getSymbolFromCurrency(listing.currency)}{listing.potentialSales} </div> </div>
                                              </div>
                                            </div>


                                          </div>

                                        </div> {/*status sales and gross*/}
                                      </div>
                                    </div> {/*details col*/}

                                  </div> {/*end body row*/}
                                </div>
                              </li>

                          ))}
                        </ul>


                      </div>
                    </div>
                  </div>
                </div>



                <div class = "row dashboard-row-wrapper">
                  <div class ="col-sm-2 col-md-2 col-lg-2">

                    <div class = "my-dashboard__subtitle"> Page</div>
                  </div>
                  <div class ="col-sm-8 col-md-8 col-lg-8">

                  </div>
                  <div class ="col-sm-2 col-md-2 col-lg-2">

                  </div>
                </div> {/*End row element*/}

              </div>
            </div>
          </div>








           : <fragment>

            <div class = "my-dashboard-page">
            {!isLoadingAnEvent ?
                [

                  <div>
                    <div class = "dashboard-containeer">
                      <div class = "form dashboard-form"  id = "my-event-info-form">

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-10 col-md-10 col-lg-10">
                              <span>
                                <h1 class = "my-dashboard__title_text">Manage Experience </h1>
                                <div class = "my-dashboard__subtitle"> A strapline</div>
                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              {
                                (self.state.experienceData.readyToPublish && self.state.experienceData.status == 'Draft') ?
                                [
                                  <span>
                                    <button onClick = {(e) => self.handlePublish(e)}  id = "create-event__publish_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-activate-outlined" type = "button">
                                      <span class="bui-button__text">Publish</span>
                                    </button>
                                  </span>
                                ] : <></>
                              }

                              {
                                (self.state.experienceData.status == 'Active') ?
                                [
                                  <span>
                                    <button id = "create-event__cancel_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-activate-outlined" type = "button">
                                      <span class="bui-button__text">Cancel</span>
                                    </button>
                                  </span>
                                ] : <></>
                              }

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">

                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  > Sales Summary </div>
                            </div>
                            <div class ="col-sm-10 col-md-10 col-lg-10">


                              <span>
                                <div class = "my-dashboard__subtitle"> Event Status: {self.state.experienceData.status} </div>
                                 <div class = "my-dashboard__subtitle"> Gross sales:  {getSymbolFromCurrency(self.state.experienceData.currency)}{self.state.experienceData.grossSales} / {getSymbolFromCurrency(self.state.experienceData.currency)}{self.state.experienceData.potentialSales} </div>
                              </span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Experience Title</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.experienceTitle}



                                    <label class = "dashboard__input__text__label" for = "experienceTitle">
                                      <input value = {self.state.experienceTitle}
                                             onChange={this.handleInputChange}
                                             id = "experienceTitle-editMode-form"
                                             name = "experienceTitle" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "experienceTitle"
                                      placeholder = "Experience title"></input>
                                    </label>



                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button  onClick = {(e) => self.editEventItem(e)} id = "experienceTitle-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "experienceTitle-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "experienceTitle-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Experience Image</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>



                                <div class = "my-dashboard-cards__acard__img">
                                  <a href = "#" class = "my-dashboard-listings-edit-link">
                                    <img src = {self.state.experienceData.mainExperienceImage} class = "my-dashboard-listings__img"/>
                                  </a>
                                </div>

                                <label class = "dashboard__input__text__label" for = "mainExperienceImage">
                                 <input
                                        onChange={this.handleFileInput}
                                        id = "mainExperienceImage-editMode-form"
                                        required name = "mainExperienceImage"
                                        class="dashboard__select__button__style dashboard__input__text__control hidden "
                                        type = "file"
                                        if = "mainExperienceImage"/>
                                </label>



                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "mainExperienceImage-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.SaveImage(e)} id = "mainExperienceImage-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "mainExperienceImage-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Payment Currency</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>




                                <p> {findElementInCCyListArray(self.state.experienceData.currency,currencyCodes )} ({getSymbolFromCurrency(self.state.experienceData.currency)})</p>


                                <select  value={self.state.currency}
                                         onChange={self.handleInputChange}
                                         id = "currency-editMode-form"
                                         name = "currency"
                                         class = "dashboard__select__button__style dashboard__input__text__control hidden" >
                                  <option value="GBP">{self.state.experienceData.currency} </option>
                                  {currencyCodesReduced.map((ccyCodesSelect) =>

                                    <option value = {ccyCodesSelect.optionValue}>{ccyCodesSelect.countryCode} </option>

                                  )}
                                 </select>




                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "currency-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}   id = "currency-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "currency-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Description</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.description}



                                    <label class = "dashboard__input__text__label" for = "wineListdescription">
                                      <textarea value = {self.state.description}
                                                onChange={this.handleInputChange}
                                                id = "description-editMode-form"
                                                name = "description" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "wineListdescription"
                                                placeholder = "Describe your wine list"></textarea>
                                    </label>


                                  <div class = "dashboard-input-information-caption">
                                    Please provide a description of the experience.
                                  </div>

                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "description-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}            id = "description-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "description-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Experience Type</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.experienceType}



                                    <label class = "dashboard__input__text__label" for = "experienceType">
                                      <input value = {self.state.experienceType}
                                             onChange={this.handleInputChange}
                                             id = "experienceType-editMode-form"
                                             name = "experienceType" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "experienceType"
                                      placeholder = "Experience type"></input>
                                    </label>



                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button  onClick = {(e) => self.editEventItem(e)} id = "experienceType-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "experienceType-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "experienceType-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Hastags</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.hashTags}



                                    <label class = "dashboard__input__text__label" for = "hashTags">
                                      <input value = {self.state.hashTags}
                                             onChange={this.handleInputChange}
                                             id = "hashTags-editMode-form"
                                             name = "hashTags" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "hashTags"
                                      placeholder = "Hashtags"></input>
                                    </label>

                                    <div class = "dashboard-input-information-caption">
                                      Please list you hashags like: #wine #wineoclock
                                    </div>


                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button  onClick = {(e) => self.editEventItem(e)} id = "hashTags-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "hashTags-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "hashTags-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Host</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.host}



                                    <label class = "dashboard__input__text__label" for = "host">
                                      <input value = {self.state.host}
                                             onChange={this.handleInputChange}
                                             id = "host-editMode-form"
                                             name = "host" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "host"
                                      placeholder = "host"></input>
                                    </label>

                                    <div class = "dashboard-input-information-caption">
                                      Please provide the name of the host of the event.
                                    </div>


                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button  onClick = {(e) => self.editEventItem(e)} id = "host-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "host-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "host-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Date</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                              {self.state.experienceData.date}

                              {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                              <div class = "hidden"
                                   id    = "date-editMode-form">
                                <SingleDatePicker
                                   date={this.state.date} // momentPropTypes.momentObj or null

                                   onDateChange={date => self.handleDateInput(date)}

                                   focused={this.state.focused} // PropTypes.bool
                                   onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                   numberOfMonths={1}
                                   /*daySize = {10}*/
                                   /*isOutsideRange*/
                                   id="date-single-date-picker" // PropTypes.string.isRequired,

                               />
                              </div>



                             {/*onDateChange={date => this.setState({date})}*/}




                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "date-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "date-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "date-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Time </div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                              {self.state.experienceData.time}

                              {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                              <div class = "hidden"
                                   id    = "time-editMode-form">

                                   <TimeKeeper
                                     time={self.state.time}
                                     onChange={(e) => self.handleTimeInput(e, 'time')}
                                   />

                                   {/*<TimePicker
                                   onChange={e => self.handleTimeInput(e)}
                                   value={self.state.time}
                                   />*/}

                              </div>



                             {/*onDateChange={date => this.setState({date})}*/}




                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "time-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "time-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "time-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Time Zone</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                              {self.state.experienceData.timeZone}


                              <div class = "hidden"
                                   id    = "timeZone-editMode-form">
                                   <TimezoneSelect
                                     value={self.state.timeZone}
                                     onChange={(e) => self.handleTimeZoneInput(e, 'timeZone')}
                                   />
                              </div>

                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "timeZone-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "timeZone-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "timeZone-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Sales Start Time</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                              {self.state.experienceData.salesStartTime}

                              {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                              <div class = "hidden"
                                   id    = "salesStartTime-editMode-form">

                                   <TimeKeeper
                                     time={self.state.salesStartTime}
                                     onChange={(e) => self.handleTimeInput(e, 'salesStartTime')}
                                   />

                                   {/*<TimePicker
                                   onChange={e => self.handleTimeInput(e)}
                                   value={self.state.time}
                                   />*/}

                              </div>



                             {/*onDateChange={date => this.setState({date})}*/}




                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "salesStartTime-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "salesStartTime-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "salesStartTime-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Sales End Time</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                              {self.state.experienceData.salesEndTime}

                              {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                              <div class = "hidden"
                                   id    = "salesEndTime-editMode-form">

                                   <TimeKeeper
                                     time={self.state.salesEndTime}
                                     onChange={(e) => self.handleTimeInput(e, 'salesEndTime')}
                                   />

                                   {/*<TimePicker
                                   onChange={e => self.handleTimeInput(e)}
                                   value={self.state.time}
                                   />*/}

                              </div>



                             {/*onDateChange={date => this.setState({date})}*/}




                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "salesEndTime-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "salesEndTime-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "salesEndTime-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Sales Dates</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                              {self.state.experienceData.salesStartDate} to {self.state.experienceData.salesEndDate}


                              {/*DateRangePicker, SingleDatePicker, DayPickerRangeController */}
                              <div class = " hidden"
                                   id    = "salesDateRange-editMode-form">

                                   <DateRangePicker
                                     startDate={this.state.salesStartDate} // momentPropTypes.momentObj or null,
                                     startDateId="salesStartDate-date-range-picker" // PropTypes.string.isRequired,
                                     endDate={this.state.salesEndDate} // momentPropTypes.momentObj or null,
                                     endDateId="salesEndDate-date-range-picker" // PropTypes.string.isRequired,
                                      // PropTypes.func.isRequired,
                                     onDatesChange={(e) => this.HandelDateRangeInput(e)}
                                     numberOfMonths={1}
                                     focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                     onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,

                                   />


                              </div>
                              {/*onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}*/}


                             {/*onDateChange={date => this.setState({date})}*/}




                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "salesDateRange-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "salesDateRange-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "salesDateRange-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Host Email</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.hostEmail}



                                    <label class = "dashboard__input__text__label" for = "hostEmail">
                                      <input value = {self.state.hostEmail}
                                             onChange={self.handleInputChange}
                                             id = "hostEmail-editMode-form"
                                             name = "hostEmail" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "email" if = "hostEmail"
                                      placeholder = "hostEmail"></input>
                                    </label>

                                    <div class = "dashboard-input-information-caption">
                                      Please provide the name of the host of the event.
                                    </div>


                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button  onClick = {(e) => self.editEventItem(e)} id = "hostEmail-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "hostEmail-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "hostEmail-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Host Phone Number</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                              {`${findElementInCountryCodeListArray(self.state.experienceData.hostPhoneCountryCode,internationalCallingCodeList) + self.state.experienceData.hostPhone}  `}

                              <div id = "hostPhone-editMode-form" class = "dashboard-iux-phone-number hidden">


                                    <select  value={self.state.hostPhoneCountryCode}  onChange={self.handleInputChange} name = "hostPhoneCountryCode" id = "hostPhoneCountryCode-editMode-form-countryCode" class = "dashboard__select__button__style">
                                      <option value="">{findElementInCountryListArray(self.state.experienceData.hostPhoneCountryCode, countryList)}</option>
                                      {countryList.map((phoneCountryCode) =>
                                        <option value = {phoneCountryCode.optionValue}>{phoneCountryCode.country} </option>

                                      )}
                                    </select>



                                    <label class = "dashboard__input__text__label" for="hostPhone">
                                      {/*form-control form-control-sm form-field*/}
                                      <div class = "dashboard__input__text__field">
                                        <input  value={self.state.hostPhone}  onChange={self.handleInputChange} name = "hostPhone" id = "hostPhone.phone-editMode-form-phoneNumber" class = "dashboard__select__button__style dashboard__input__text__control" type="tel"  placeholder="Host Phone Number"></input>
                                      </div>
                                    </label>

                                </div>
                                <div class = "dashboard-input-information-caption">
                                  We will use this as your primary contact number for the host. This will be provided to the attendees.
                                </div>
                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "hostPhone-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}            id = "hostPhone-editMode-save"   class="bui-button bui-button-tertiary-danger hidden" ><span>Save</span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "hostPhone-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" ><span> Cancel </span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Maximum Quanity</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.maximumQuantity}

                                    <label class = "dashboard__input__text__label" for = "maximumQuantity">
                                      <input   value = {self.state.maximumQuantity}
                                               onChange={this.handleInputChange}
                                               id = "maximumQuantity-editMode-form"
                                      name = "maximumQuantity" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "number" if = "maximumQuantity"
                                      placeholder = "Number of tickets issued"></input>
                                    </label>


                                    <div class = "dashboard-input-information-caption">
                                      Maximum number of tickets that can be purchased by a guest.
                                    </div>


                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "maximumQuantity-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}   id = "maximumQuantity-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "maximumQuantity-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Price</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.price}

                                    <label class = "dashboard__input__text__label" for = "price">
                                      <input   value = {self.state.price}
                                               onChange={this.handleInputChange}
                                               id = "price-editMode-form"
                                      name = "price" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "price"
                                      placeholder = "Price per ticket"></input>
                                    </label>


                                    <div class = "dashboard-input-information-caption">
                                      Price per ticket.
                                    </div>


                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "price-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}   id = "price-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "price-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Quantity</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.quantity}

                                    <label class = "dashboard__input__text__label" for = "quantity">
                                      <input   value = {self.state.quantity}
                                               onChange={this.handleInputChange}
                                               id = "quantity-editMode-form"
                                      name = "quantity" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "quantity"
                                      placeholder = "Quantity"></input>
                                    </label>


                                    <div class = "dashboard-input-information-caption">
                                      This is the total number of tickets to be issued for the event.
                                    </div>


                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "quantity-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}   id = "quantity-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "quantity-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Tickets Per Order</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.ticketsPerOrder}


                                    <label class = "dashboard__input__text__label" for = "ticketsPerOrder">
                                      <input   value = {self.state.ticketsPerOrder}
                                               onChange={this.handleInputChange}
                                               id = "ticketsPerOrder-editMode-form"
                                      name = "ticketsPerOrder" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "ticketsPerOrder"
                                      placeholder = "Number of guests admited per ticket"></input>
                                    </label>


                                    <div class = "dashboard-input-information-caption">
                                      Number of guests admitted per ticket.
                                    </div>


                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "ticketsPerOrder-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}   id = "ticketsPerOrder-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "ticketsPerOrder-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Summary</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                {self.state.experienceData.summary}




                                    <label class = "dashboard__input__text__label" for = "summary">
                                      <textarea value = {self.state.summary}
                                                onChange={this.handleInputChange}
                                                id = "summary-editMode-form"
                                                name = "summary" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "summary"
                                                placeholder = "Summary of your event"></textarea>
                                    </label>


                                  <div class = "dashboard-input-information-caption">
                                    Please provide a summary of the event.
                                  </div>

                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "summary-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "summary-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "summary-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Online Experience</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>

                                   {self.state.experienceData.olineExperience ? <div> Online </div> :<div> Live</div>}

                                    <label class = "dashboard__input__text__label" for = "olineExperience">
                                      <input value={this.state.olineExperience}
                                      onChange={this.handleInputChange}
                                      id = "olineExperience-editMode-form"
                                      name = "olineExperience"
                                      class="dashboard__select__checkbox__style hidden"
                                      type = "checkbox" if = "olineExperience"
                                      placeholder = "Online Experience"></input>
                                    </label>

                                    <div class = "dashboard-input-information-caption">
                                      Please select the checkbox if the event will be streamed online. If it will be physically hosted then leave the box unchecked.
                                    </div>

                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)}  id = "olineExperience-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)} id = "olineExperience-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "olineExperience-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Address</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>


                              {self.state.experienceData.locationAddress} <br/>


                              {self.state.experienceData.locationcity}  <br/>

                              {self.state.experienceData.locationZip_code}  <br/>

                              {findElementInCountryListArray(self.state.experienceData.locationCountry, countryList)}  <br/>



                                                        <div id = "address-editMode-form" class = "hidden">
                                                          <div class = "form-group col-sm-12 col-md-6">
                                                            <label class = "adressDetails" >
                                                              <input value={this.state.locationAddress}
                                                                     onChange={this.handleInputChange}
                                                                     name = "locationAddress"
                                                                     class = "form-control form-control-sm form-field"
                                                                     type="text" id="locationAddress"
                                                                     placeholder="Address"></input>
                                                            </label>
                                                          </div>


                                                            <div class = "form-group col-sm-12 col-md-6">
                                                              <label class = "locationcity" >
                                                                <input value={this.state.locationcity}
                                                                onChange={this.handleInputChange}
                                                                name = "locationcity"
                                                                class = "form-control form-control-sm form-field"
                                                                type="text"
                                                                id="locationcity"
                                                                placeholder="City"></input>
                                                              </label>
                                                            </div>



                                                           <div class = "form-group col-sm-12 col-md-6">
                                                             <label class = "locationZip_code" >
                                                               <input value={this.state.locationZip_code}
                                                               onChange={this.handleInputChange}
                                                               name = "locationZip_code"
                                                               class = "form-control form-control-sm form-field"
                                                               type="text"
                                                               id="locationZip_code"
                                                               placeholder="Postcode"></input>
                                                             </label>
                                                           </div>



                                                           <div class = "form-group col-sm-12 col-md-6">
                                                             <label>
                                                             <select value={this.state.locationCountry}
                                                                     onChange={this.handleInputChange}
                                                                     name = "locationCountry"
                                                                     class = "form-control form-control-sm form-field" >
                                                               <option value="">{findElementInCountryListArray(self.state.experienceData.locationCountry, countryList)}</option>
                                                               {countryList.map((countrySelect) =>

                                                                 <option value = {countrySelect.optionValue}>{countrySelect.country} </option>

                                                               )}
                                                             </select>
                                                             </label>
                                                           </div>
                                                        </div>

                                                    </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <span>
                                <button onClick = {(e) => self.editEventItem(e)} id = "address-editMode"  target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                                  <span class="bui-button__text">Edit</span>
                                </button>
                              </span>

                              <span><button onClick = {(e) => self.handleSave(e)}            id = "address-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span>Save</span></button></span>
                              <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "address-editMode-cancel"  class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span> Cancel </span></button></span>

                            </div>
                          </div> {/*End row element*/}





                      </div>
                    </div>
                  </div>







              ]: [<div></div>]
              }
            </div>

          </fragment> : <fragment>
          </fragment>
    );
  }
}

class CreateExperiencesUnconnected extends Component {

  constructor(props) {
    super(props);
      this.state = {
        experienceTitle: null,
        olineExperience: false
      }

      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleCreate      = this.handleCreate.bind(this)
      this.clearFormElements = this.clearFormElements.bind(this)


  }

  static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};



   handleInputChange(event){

    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    // handle the create button
    var createBtn     = document.getElementById('create-event__create_button')

    this.setState({
      [name]: value
    })

    // if an event name is provided then unhide buttons
    if (name === "experienceTitle")
    {

      createBtn.classList.remove("hidden");
    }



   }

handleCreate(event) {

  event.preventDefault()

  // handle the create button
  var createBtn     = document.getElementById('create-event__create_button')

  // capture the post data
  var postData = {}
  postData['experienceTitle'] = this.state.experienceTitle
  postData['olineExperience'] = this.state.olineExperience



  var url = "https://www.therealgrape.com/api/auth/business-profile/create-experience"
  // var url = "http://127.0.0.1:8000/api/auth/business-profile/create-experience"

  // GET token from state
  const token = this.props.auth.token;

  // set up the header for the get calls
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // // Provide the authorisation token
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // now we need to stringfy the field that we want to provide
  // Request body

  const body = JSON.stringify(postData);

  // now post the Data
  axios.post(url, body, config)
  .then()

  // clear the form elements and return statevariables to starting state
  this.setState({
    experienceTitle: null,
    olineExperience: false
  })

  this.clearFormElements()

  // hide the create event button
  createBtn.classList.add("hidden");

}

clearFormElements() {

  document.getElementById("create-expereience__experienceTitle").value = null
  document.getElementById("create-expereience__olineExperience").checked = false
}



  render()
  {

    var     self   = this

    return(

      <div>
       <div class = "dashboard-containeer">
         <div class = "form dashboard-form"  id = "my-create-experience-form">

           <div class = "row dashboard-row-wrapper">
             <div class ="col-sm-10 col-md-10 col-lg-10">
               <span>
                 <h1 class = "my-dashboard__title_text"> Create an Experience  </h1>
                 <div class = "my-dashboard__subtitle"> Create a new experience</div>
               </span>
             </div>

             <div class ="col-sm-2 col-md-2 col-lg-2">
               <span>
                 <button onClick = {(e) => self.handleCreate(e)} id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                   <span class="bui-button__text">Create</span>
                 </button>
               </span>
             </div>

           </div> {/*End row element*/}

           <div class = "row dashboard-row-wrapper">
             <div class ="col-sm-2 col-md-2 col-lg-2">
               <div class = "dashboard-row-title"  >Experience Title</div>
             </div>
             <div class ="col-sm-8 col-md-8 col-lg-8">
               <span>

                     <label class = "dashboard__input__text__label" for = "experienceTitle">
                       <input value={this.state.experienceTitle}  onChange={this.handleInputChange}  id = "create-expereience__experienceTitle"
                       name = "experienceTitle" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "experienceTitle"
                       placeholder = "Experience Title"></input>
                     </label>

               </span>
             </div>
             <div class ="col-sm-2 col-md-2 col-lg-2">

             </div>
           </div> {/*End row element*/}

           <div class = "row dashboard-row-wrapper hidden">
             <div class ="col-sm-2 col-md-2 col-lg-2">
               <div class = "dashboard-row-title"  >Online Event</div>
             </div>
             <div class ="col-sm-8 col-md-8 col-lg-8">
               <span>

                     <label class = "dashboard__input__text__label" for = "olineExperience">
                       <input value={this.state.olineExperience}  onChange={this.handleInputChange}  id = "create-expereience__olineExperience"
                       name = "olineExperience" class="dashboard__select__checkbox__style" type = "checkbox" if = "olineExperience"
                       placeholder = "Online Experience"></input>
                     </label>

                     <div class = "dashboard-input-information-caption">
                       Please select the checkbox if the event will be streamed online. If it will be physically hosted then leave the box unchecked.
                     </div>

               </span>
             </div>
             <div class ="col-sm-2 col-md-2 col-lg-2">

             </div>
           </div> {/*End row element*/}

         </div>
        </div>
      </div>





    )
  }

}

class CreateExperiencesUnconnectedDepricated extends Component {

  constructor(props) {
    super(props);
      this.state = {
        experienceTitle: null,
        olineExperience: false
      }

      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleCreate      = this.handleCreate.bind(this)
      this.clearFormElements = this.clearFormElements.bind(this)


  }

  static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};



   handleInputChange(event){

    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    // handle the create button
    var createBtn     = document.getElementById('create-event__create_button')

    this.setState({
      [name]: value
    })

    // if an event name is provided then unhide buttons
    if (name === "experienceTitle")
    {

      createBtn.classList.remove("hidden");
    }



   }

handleCreate(event) {

  event.preventDefault()

  // handle the create button
  var createBtn     = document.getElementById('create-event__create_button')

  // capture the post data
  var postData = {}
  postData['experienceTitle'] = this.state.experienceTitle
  postData['olineExperience'] = this.state.olineExperience



  var url = "https://www.therealgrape.com/api/auth/business-profile/create-experience"
  // var url = "http://127.0.0.1:8000/api/auth/business-profile/create-experience"

  // GET token from state
  const token = this.props.auth.token;

  // set up the header for the get calls
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // // Provide the authorisation token
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // now we need to stringfy the field that we want to provide
  // Request body

  const body = JSON.stringify(postData);

  // now post the Data
  axios.post(url, body, config)
  .then()

  // clear the form elements and return statevariables to starting state
  this.setState({
    experienceTitle: null,
    olineExperience: false
  })

  this.clearFormElements()

  // hide the create event button
  createBtn.classList.add("hidden");

}

clearFormElements() {

  document.getElementById("create-expereience__experienceTitle").value = null
  document.getElementById("create-expereience__olineExperience").checked = false
}



  render()
  {

    var     self   = this

    return(
      <fragment>
        <div class = "my-dashboard-page">
         <div class = "my-dashboard-flex-row">
           <div class = "container my-profile-container">
             <div class = "my-dashboard-container">
               <div class = "my-dashboard-title-wrapper flex-wrapper">  {/*start dashboard element*/}
                <div style={{flex:8}}>
                   <span>
                     <h1 class = "my-dashboard__title_text"> Create an Experience </h1>
                     <div class = "my-dashboard__subtitle"> Create a new experience</div>

                   </span>
                  </div>
                  <div style={{flex:1}}>
                    <span>
                      <button onClick = {(e) => self.handleCreate(e)} id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                        <span class="bui-button__text">Create</span>
                      </button>
                    </span>

                  </div>
                </div>

                <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                  <div style={{flex:2}}><span> Experience Title </span></div>
                  <div style={{flex:6}}>
                    <span>

                          <label class = "dashboard__input__text__label" for = "experienceTitle">
                            <input value={this.state.experienceTitle}  onChange={this.handleInputChange}  id = "create-expereience__experienceTitle"
                            name = "experienceTitle" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "experienceTitle"
                            placeholder = "Experience Title"></input>
                          </label>

                    </span></div>
                  <div style={{flex:1}}>


                  </div>
                </div>{/*end dashboard element*/}

                <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                  <div style={{flex:2}}><span> Online Event </span></div>
                  <div style={{flex:6}}>
                    <span>

                          <label class = "dashboard__input__text__label" for = "olineExperience">
                            <input value={this.state.olineExperience}  onChange={this.handleInputChange}  id = "create-expereience__olineExperience"
                            name = "olineExperience" class="dashboard__select__checkbox__style" type = "checkbox" if = "olineExperience"
                            placeholder = "Online Experience"></input>
                          </label>

                          <div class = "dashboard-input-information-caption">
                            Please select the checkbox if the event will be streamed online. If it will be physically hosted then leave the box unchecked.
                          </div>

                    </span></div>
                  <div style={{flex:1}}>


                  </div>
                </div>{/*end dashboard element*/}











             </div>
            </div>
          </div>
        </div>

      </fragment>
    )
  }

}

class MyExperiencesClass extends Component{

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEditing: false,
    }
  }

  static propTypes = {
   auth: PropTypes.object.isRequired,
   logout: PropTypes.func.isRequired,
  };

  async componentDidMount() {

    await store.dispatch(getEventAndExperienceData());


  }

  render(){

    const { isAuthenticated, user } = this.props.auth;
    var             isLoading       = this.state.isLoading
    var                      self   = this

    return(
      this.props.eventsandexperiences.membersExperiencesAndEventsListLoaded ?
        <div>

          <div class = "dashboard-containeer">
            <div class = "form dashboard-form"  id = "my-create-event-form">

              <div class = "row dashboard-row-wrapper">
                <div class ="col-sm-10 col-md-10 col-lg-10">
                  <span>
                    <h1 class = "my-dashboard__title_text"> Experience Bookings  </h1>
                    <div class = "my-dashboard__subtitle"> Click on an event, rate and review wine lists </div>
                  </span>
                </div>

                <div class ="col-sm-2 col-md-2 col-lg-2">

                </div>

              </div> {/*End row element*/}

              <div class = "row dashboard-row-wrapper">
                <div class ="col-sm-12 col-md-12 col-lg-12">
                  <div class = "event_experience-stack">
                    <div class = "event_experience-stack-element-inner">
                      <ul class = "event_experience-cards" >
                        {self.props.eventsandexperiences.membersExperiencesAndEventsList.boughtExperiences.map((listing, index) => (

                          <li key={`listing-${index}`} >
                            <div class = "event_experience-entry" style = {{marginTop: "10px"}} > {/*winelist-entry*/}

                              <div class = "row" >

                                {/*<div class = "col-sm-12 col-md-8 col-lg-8" onClick={() => self.manageEvent(listing.id)}>*/}
                                <div class = "col-sm-12 col-md-8 col-lg-8">
                                    <div class = "event_experience-entry-inner">
                                      <div class = "event_experience-entry-title" style = {{marginTop: "5px"}}> {listing.experiences__experienceTitle} </div>
                                    </div>
                                </div>

                                {/*<div class = "col-sm-12 col-md-4 col-lg-4" onClick={() => self.manageEvent(listing.id)}>*/}
                                <div class = "col-sm-12 col-md-4 col-lg-4" >
                                    <div class = "event_experience-entry-inner">
                                      <div class = "event_experience-entry-title" style = {{marginTop: "5px"}}> Date purchased: {dateFormatter(listing.order__orderDate)} </div>
                                    </div>
                                </div>

                              </div> {/*Title row*/}

                              <div class = "row">
                                <div class = "col-sm-12 col-md-2 col-lg-2">

                                  <div class = "my-dashboard-cards__acard__img">
                                    <div class = "image-map-wrapper">

                                      {/*<img alt="" class="cart_Img_Centered" src = "https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png"/>*/}
                                      <a   style={{ textDecoration: 'none'}} href ={"/Experiences/" + listing.experiences__experienceTitle.split(" ").join("-").toLowerCase() + "-" + listing.experiences__id}>
                                        <img alt="" class="cart_Img_Centered" src = {listing.mainExperienceImage === null ? 'https://cdn.evbstatic.com/s3-build/perm_001/5287e3/django/images/pages/organizations/no-event-image-placeholder-2x.png' : listing.mainExperienceImage}/>
                                      </a>

                                    </div>
                                  </div>

                                </div> {/*end image col*/}

                                <div class = "col-sm-12 col-md-10 col-lg-10" >
                                  <div class = "row" style = {{marginTop:"10px"}}>

                                    <div class = "col-sm-12 col-md-12 col-lg-12">
                                      <div class = "event_experience-entry-inner">
                                        <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment">  {listing.experiences__experienceType} </div> </div>
                                      </div>
                                    </div>

                                    <div class = "col-sm-12 col-md-12 col-lg-12">
                                      <div class = "event_experience-entry-inner">
                                        <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment">

                                          <span style={{ marginRight: '5px', fontWeight:'500', color: '#000' }}> {getSymbolFromCurrency(listing.currency)}{listing.price.toFixed(2) }</span>
                                          <span style={{ marginRight: '5px', fontWeight:'500', fontSize:'12px', color: '#8d8478' }}> + {listing.clientTransactionFee.toFixed(2)} Fee </span>

                                        </div> </div>
                                      </div>
                                    </div>

                                    <div class = "col-sm-12 col-md-12 col-lg-12">
                                      <div class = "event_experience-entry-inner">
                                        <div class = "event_experience-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment">  Quantity bought: {listing.quantity} </div> </div>
                                      </div>
                                    </div>

                                  </div>


                                </div> {/*end details col*/}
                              </div>

                            </div>
                          </li>

                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>

        </div> :
      <div></div>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
  eventsandexperiences: state.eventsandexperiences,
});

const CreateExperiencesConnected = connect(mapStateToProps, { logout })(CreateExperiencesUnconnected);
export {CreateExperiencesConnected as CreateExperiences}


const connectedExperiencesShopPageClass = connect(mapStateToProps)(ExperiencesShopPageClass);
export {connectedExperiencesShopPageClass as ExperiencesShopPage}

const  connectedExperiencesClass  = connect(mapStateToProps)(ExperiencesClass);
export {connectedExperiencesClass as Experiences}

const  connectedExperiencesList  = connect(mapStateToProps)(ExperiencesListClass);
export {connectedExperiencesList as ExperiencesList}

const  connectedMyExperiences  = connect(mapStateToProps)(MyExperiencesClass);
export {connectedMyExperiences as MyExperiences}
