import  { Component } from 'react';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import '../Styles/Authentication.css'
import '../App.css'
import {Link, Redirect} from "react-router-dom"
import {connect} from "react-redux";
import PropTypes from "prop-types"
import { login, getCart} from '../Actions/Auth.js'
import store from '../Store.js'
import axios from 'axios'




class LoginClass extends Component {

  /*constructor(props) {
    super(props);
    console.log("Authentication status:",  this.props.isAuthenticated);
    console.log("LoginClass Props: ", this.props);
  }*/
  state = {
    username: '',
    password: '',
  };


  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };


  /*static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };*/


  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);

    console.log("LoginClass Props: ", this.props);
  };

  onChange = (e) => this.setState({
    [e.target.name]: e.target.value
  });

  render()
  {
    if (this.props.isAuthenticated){
      return <Redirect to ="/"/>
      console.log("User is authenticated")
    }
    const {username , password} = this.state;
    return(
      <div class = "all-page">
        <div class = "app-page-content" itemprop = "mainContentOfPage" role =" main">

        <div class = "authentication__page__shell">
          <div class = "authentication__page__content">
            <div class = "authentication__panel__container" role = "group">
                <div class = "authentication__panel">
                  <div class = "panel__header">
                    <div class = "pannel__header__leftbox"/>
                    <div class = "pannel__header__title"> Log in </div>
                    <div class = "pannel__header__rightbox"/>
                  </div> {/* End Authentication Form Header*/}

                  <div class = "login__panel__containter">
                    <form method = "POST" action = "">

                    <div class = "input-group mb-3">
                      <div class = "input-group-append">
                        <span class = "input-group-text"> <i class = "fas fa-user"></i></span>
                      </div>
                      {/*<input onChange={this.onChange} value={username} type="text"  name="username" className="form-control"   />*/}
                      <input onChange={this.onChange} value={username} type = "text" name="username" placeholder = "Username" class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text "><i class = "fas fa-key"></i></span>
                      </div>
                      {/*<input onChange={this.onChange} value={password} type = "password" name="password"                           className="form-control"   />                    */}
                      <input onChange={this.onChange} value={password} type = "password" name = "password" placeholder = "Password" class = "form-control"/>
                    </div>

                      <div class = "d-flex justify-content-center mt-3 login_containter">
                        {/*<input class ="btn login_btn" type="submit" /> */}
                        {/*<button class = "btn login_btn" type="submit"> Login </button>*/}
                        <button class = "btn login_btn" type="submit" onClick={(e) =>
                          this.onSubmit(e)}> Login </button>
                      </div>


                      <div class = "mt-4">
                        <div class = "d-flex justify-content-center links">
                          <p> Don't have an account? {/*<Link to = "/register" class = "login__register__link">Register</Link>*/} Join the waiting list by filling in our <span class = "login__register__link">stay in touch</span> form at the bottom of the page.
                          </p>
                        </div>
                      </div>

                    </form> {/* End login form */}
                  </div> {/* End login__panel container*/}

                </div> {/* End Authentication Pannel*/}
            </div>
          </div>
        </div>

        <div class = "authentication__page__bottom"/>


        </div> {/* end app-page-content div*/}
      </div> /* end all-page div*/
    );/* end return*/

  } /* ender render*/
}

class LoginClassDepricated extends Component {

  /*constructor(props) {
    super(props);
    console.log("Authentication status:",  this.props.isAuthenticated);
    console.log("LoginClass Props: ", this.props);
  }*/
  state = {
    username: '',
    password: '',
  };


  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };


  /*static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };*/


  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);

    console.log("LoginClass Props: ", this.props);
  };

  onChange = (e) => this.setState({
    [e.target.name]: e.target.value
  });

  render()
  {
    if (this.props.isAuthenticated){
      return <Redirect to ="/"/>
      console.log("User is authenticated")
    }
    const {username , password} = this.state;
    return(
      <div class = "all-page">
        <div class = "app-page-content" itemprop = "mainContentOfPage" role =" main">

        <div class = "authentication__page__shell">
          <div class = "authentication__page__content">
            <div class = "authentication__panel__container" role = "group">
                <div class = "authentication__panel">
                  <div class = "panel__header">
                    <div class = "pannel__header__leftbox"/>
                    <div class = "pannel__header__title"> Log in </div>
                    <div class = "pannel__header__rightbox"/>
                  </div> {/* End Authentication Form Header*/}

                  <div class = "login__panel__containter">
                    <form method = "POST" action = "">

                    <div class = "input-group mb-3">
                      <div class = "input-group-append">
                        <span class = "input-group-text"> <i class = "fas fa-user"></i></span>
                      </div>
                      {/*<input onChange={this.onChange} value={username} type="text"  name="username" className="form-control"   />*/}
                      <input onChange={this.onChange} value={username} type = "text" name="username" placeholder = "Username..." class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                      </div>
                      {/*<input onChange={this.onChange} value={password} type = "password" name="password"                           className="form-control"   />                    */}
                      <input onChange={this.onChange} value={password} type = "password" name = "password" placeholder = "Password..." class = "form-control"/>
                    </div>

                      <div class = "d-flex justify-content-center mt-3 login_containter">
                        {/*<input class ="btn login_btn" type="submit" /> */}
                        {/*<button class = "btn login_btn" type="submit"> Login </button>*/}
                        <button class = "btn login_btn" type="submit" onClick={(e) =>
                          this.onSubmit(e)}> Login </button>
                      </div>


                      <div class = "mt-4">
                        <div class = "d-flex justify-content-center links">
                          <p> Don't have an account? <Link to = "/register">Register</Link>
                          </p>
                        </div>
                      </div>

                    </form> {/* End login form */}
                  </div> {/* End login__panel container*/}

                </div> {/* End Authentication Pannel*/}
            </div>
          </div>
        </div>

          <div class = "container h-100">
            <div class = "d-flex justify-content-center h-100">
              <div class = "user-card">

                <div class = "d-flex justify-content-center">
                  <h3>LOGIN</h3>
                </div>

                <div class = "d-flex justify-content-center form_container">



                  {/*<form onSubmit={this.onSubmit}>*/}
                  <form method = "POST" action = "">

                  <div class = "input-group mb-3">
                    <div class = "input-group-append">
                      <span class = "input-group-text"> <i class = "fas fa-user"></i></span>
                    </div>
                    {/*<input onChange={this.onChange} value={username} type="text"  name="username" className="form-control"   />*/}
                    <input onChange={this.onChange} value={username} type = "text" name="username" placeholder = "Username..." class = "form-control"/>
                  </div>

                  <div class = "input-group mb-2">
                    <div class = "input-group-append">
                      <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                    </div>
                    {/*<input onChange={this.onChange} value={password} type = "password" name="password"                           className="form-control"   />                    */}
                    <input onChange={this.onChange} value={password} type = "password" name = "password" placeholder = "Password..." class = "form-control"/>
                  </div>

                    <div class = "d-flex justify-content-center mt-3 login_containter">
                      {/*<input class ="btn login_btn" type="submit" /> */}
                      {/*<button class = "btn login_btn" type="submit"> Login </button>*/}
                      <button class = "btn login_btn" type="submit" onClick={(e) =>
                        this.onSubmit(e)}> Login </button>
                    </div>


                    <div class = "mt-4">
                      <div class = "d-flex justify-content-center links">
                        <p> Don't have an account? <Link to = "/register">Register</Link>
                        </p>
                      </div>
                    </div>

                  </form> {/* end form*/}

                </div> {/* End Form Container*/}
              </div> {/* end user-card*/}
            </div> {/* end justify-content-center h-100*/}
          </div> {/*end h-100*/}
        </div> {/* end app-page-content div*/}
      </div> /* end all-page div*/
    );/* end return*/

  } /* ender render*/
}

export class Login3 extends Component {

  constructor(props)
  {
    super(props)
    this.props.login = this.props.login.bind(this);
    console.log(props)
  }


  state = {
    username: '',
    password: '',
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
    console.log("Tried to login")
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    const { username, password } = this.state;
    return (
      <div className="col-md-6 m-auto">
        <div className="card card-body mt-5">
          <h2 className="text-center">Login</h2>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                name="username"
                onChange={this.onChange}
                value={username}
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={this.onChange}
                value={password}
              />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
            <p>
              Don't have an account? <Link to="/register">Register</Link>
            </p>
          </form>
        </div>
      </div>
    );
  }
}

/*LoginClass.propTypes = {
  loginProvider: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}*/

/*
LoginClass.contextTypes = {
  router: PropTypes.object.isRequired
}*/


export class Login2 extends Component {
  state = {
    username: '',
    password: '',
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    login(this.state.username, this.state.password);
    console.log("Tried to login")
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    const { username, password } = this.state;
    return (
      <div className="col-md-6 m-auto">
        <div className="card card-body mt-5">
          <h2 className="text-center">Login</h2>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                name="username"
                onChange={this.onChange}
                value={username}
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={this.onChange}
                value={password}
              />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
            <p>
              Don't have an account? <Link to="/register">Register</Link>
            </p>
          </form>
        </div>
      </div>
    );
  }
}

export function LoginRedux(){

  const [activeRequestItem, setActiveRequestItem] = useState({
    "username": "",
    "password": ""
  })

  console.log("Login Redux")
  //static propTypes
  const propTypes = {
   login: PropTypes.func.isRequired,
   isAuthenticated: PropTypes.bool
  }

  // get some cookies with ajax
  // we can be more efficient with the cookie calls and management
  // documentation at
  // https://docs.djangoproject.com/en/3.1/ref/csrf/
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  function handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // capture the state of the form when we log a change
    setActiveRequestItem({
      ...activeRequestItem,
      [e.target.name]: value
    })



  }


  function handleSubmit(e){

    e.preventDefault();
    //
    login(activeRequestItem.username,
       activeRequestItem.password)

  }

  console.log("The current authentication status is: ", propTypes.isAuthenticated)

  return(
    <fragment>
    <div class = "all-page">
      <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "container h-100">
            <div class = "d-flex justify-content-center h-100">
              <div class = "user_card">

                <div class = "d-flex justify-content-center">
                  <h3>LOGIN</h3>
                </div>

                <div class = "d-flex justify-content-center form_container">
                  <form method = "POST" action = "">
                    {/* csrf_token*/}
                    <div class = "input-group mb-3">
                      <div class = "input-group-append">
                        <span class = "input-group-text"> <i class = "fas fa-user"></i></span>
                      </div>
                      <input onChange = {handleFormChange} value = {activeRequestItem.username} type = "text" name="username" placeholder = "Username..." class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                      </div>
                        <input onChange = {handleFormChange} value = {activeRequestItem.password} type = "password" name = "password" placeholder = "Password..." class = "form-control"/>
                    </div>

                    <div class = "d-flex justify-content-center mt-3 login_containter">
                      <input class ="btn login_btn" type="submit" onClick={(e) =>
                        handleSubmit(e)} />
                    </div>

                  </form> {/* End Form*/}
                </div> {/* End Form Container*/}

                {/* % for message in messages%*/}
                  <p id = "messages">{/*{message}*/} </p>
                {/* % endfor %*/}

                <div class = "mt-4">
                  <div class = "d-flex justify-content-center links">
                    Don't have an account?
                    {/*<a href ="{% url 'register' %}" class = "ml-2"> */}
                    {/*<a href ="/register" class = "ml-2">

                    Register </a>*/}
                    <Link to = "/register"> Register </Link>
                  </div>
                </div>

              </div> {/* End Card */}
            </div>
          </div> {/*End Containter*/}
        </div> {/*app-page-content*/}
      </div> {/*"all-page"*/}
    </fragment>
  )
}




//https://www.youtube.com/watch?v=EmAc4wQikwY
// authentication and routing

export function Login(){

  const [activeRequestItem, setActiveRequestItem] = useState({
    "username": "",
    "password": ""
  })

  // get some cookies with ajax
  // we can be more efficient with the cookie calls and management
  // documentation at
  // https://docs.djangoproject.com/en/3.1/ref/csrf/
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  function handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // capture the state of the form when we log a change
    setActiveRequestItem({
      ...activeRequestItem,
      [e.target.name]: value
    })



  }


  function handleSubmit(e){

    e.preventDefault();

    // var url = "http://127.0.0.1:8000/api/login-user/"
    // the url for the create request we use the web endpoints
    var url = "https://real-grape-site-us.herokuapp.com/api/login-user/"

    // get a cookie that we will pass in our headers
    const csrftoken = getCookie('csrftoken');

    // here would be the place to do some validation in the browser

    //now let us create the POST request
    fetch(url, {
      method:'POST',
      headers:{
              'Content-type': 'application/json',
              'X-CSRFToken': csrftoken
            },
            credentials: 'include',
      body:JSON.stringify(activeRequestItem)
    }).then((response) => {
      // clear the web form
      setActiveRequestItem( {
        "username": "",
        "password": ""
        })



        }).catch(function(error){
          console.log('ERROR MSG: ', error)
          console.error('Caught error', error)


    })
  }


  return(
    <fragment>
    <div class = "all-page">
      <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "container h-100">
            <div class = "d-flex justify-content-center h-100">
              <div class = "user_card">

                <div class = "d-flex justify-content-center">
                  <h3>LOGIN</h3>
                </div>

                <div class = "d-flex justify-content-center form_container">
                  <form method = "POST" action = "">
                    {/* csrf_token*/}
                    <div class = "input-group mb-3">
                      <div class = "input-group-append">
                        <span class = "input-group-text"> <i class = "fas fa-user"></i></span>
                      </div>
                      <input onChange = {handleFormChange} value = {activeRequestItem.username} type = "text" name="username" placeholder = "Username..." class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                      </div>
                        <input onChange = {handleFormChange} value = {activeRequestItem.password} type = "password" name = "password" placeholder = "Password..." class = "form-control"/>
                    </div>

                    <div class = "d-flex justify-content-center mt-3 login_containter">
                      <input class ="btn login_btn" type="submit" onClick={(e) =>
                        handleSubmit(e)} />
                    </div>

                  </form> {/* End Form*/}
                </div> {/* End Form Container*/}

                {/* % for message in messages%*/}
                  <p id = "messages">{/*{message}*/} </p>
                {/* % endfor %*/}

                <div class = "mt-4">
                  <div class = "d-flex justify-content-center links">
                    Don't have an account?
                    {/*<a href ="{% url 'register' %}" class = "ml-2"> */}
                    {/*<a href ="/register" class = "ml-2">

                    Register </a>*/}
                    <Link to = "/register"> Register </Link>
                  </div>
                </div>

              </div> {/* End Card */}
            </div>
          </div> {/*End Containter*/}
        </div> {/*app-page-content*/}
      </div> {/*"all-page"*/}
    </fragment>
  )
}


/*const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
*/

export function loginProvider(username, password) {

  return(
    <fragment>
    <div>
      <h3> This will be our login code</h3>
      {console.log("This is the login provider")}
    </div>
    </fragment>

)
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const connectedLoginPage  = connect(mapStateToProps, {login})(LoginClass);
export {connectedLoginPage as LoginClass}
//export default connect(mapStateToProps, {loginProvider})(LoginClass);
//connect(mapStateToProps, {login})(LoginClass);
