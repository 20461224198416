import React from 'react';
import  { Component } from 'react';
import '../Styles/Navigation.css'
import {Link, Route, Redirect} from "react-router-dom"
import {withRouter} from 'react-router-dom';

import {connect} from "react-redux";
import PropTypes from 'prop-types';
import "../Actions/Auth.js"
import {logout} from '../Actions/Auth.js'




class NavigationUnconnected extends Component
{

  constructor(props) {
    super(props);

    this.State = {
      redirect : false
    }

    this.setRedirect         = this.setRedirect.bind(this)
    this.logoutUser          = this.logoutUser.bind(this)

  }
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };



  setRedirect(event){

    event.preventDefault()


    /*this.setState({
      redirect : true
    })*/

    this.props.logout()


    //console.log(this.props.history.push)
    // refresh component
    this.forceUpdate()

  }

  logoutUser()
  {
    /*event.preventDefault()
    this.props.logout()
    return <Redirect to ="/"/>*/

//    if (this.state.redirect) {

      /*this.setState({
        redirect : true
      })

      return <Redirect to='/' />*/
  //  }
  }

  render()
  {
  const { isAuthenticated} = this.props.auth;
  var self = this;

  return(
      <div class = "navigation " id = "main-nav">
        <div class = "container">

          <div class = "main-nav-banner main-nav-banner-2">
            <div class = "main-nav-banner-container">

            <div class ="main-nav-banner-other">
              { isAuthenticated ?
              <div class = "main-nav-banner-other-inner">

                  <button class = "main-nav-banner-account-controls" tabindex = "0" type = "button">
                    <span class = "main-nav-banner-account-controls-container">
                      <span class = "main-nav-banner-account-controls-icon" aria-hidden = "true">

                        {/*<i class="far fa-clipboard user-logo"></i>*/}
                        <i class="far fa-address-card user-logo"></i>
                      </span>
                      <span class = "main-nav-banner-account-login ">
                        <a class = "main-nav-banner-account-login-a-link" href="/Mydashboard" ariel-label ="my account" tabindex = "0"> account</a>
                      </span>
                    </span>
                  </button>
              </div> : <></>}
            </div>

            <div class = "main-nav-banner-title">
              <div class = "main-nav-banner-title-inner">
                <div class = "main-nav-banner-title-logo">
                  <a href="/">
                    <h1 class = "text-center">The Real Grape</h1>
                  </a>
                </div>
                <div class = "main-nav-banner-title-logo-strapline">
                  Wine Tours, Events & Experiences
                </div>
              </div>
            </div>

            <div class ="main-nav-banner-account" >
              <div class = "main-nav-banner-account-inner">
                {/*<a href="" ariel-label ="my account" tabindex = "0">*/}
                  <button class = "main-nav-banner-account-controls" tabindex = "0" type = "button">
                    <span class = "main-nav-banner-account-controls-container">
                      <span class = "main-nav-banner-account-controls-icon" aria-hidden = "true">

                        <i class="far fa-user user-logo"></i>
                      </span>
                      <span class = "main-nav-banner-account-login">
                      {/*<div>
                        <button type="submit" className="btn btn-primary" onClick = {this.props.logout} >
                            Logout
                        </button>

                        </div>*/}
                        { isAuthenticated ?
                          <div>
                            {/*self.logoutUser()*/}
                            <div onClick = {(e) => self.setRedirect(e)} class = "main-nav-banner-account-login-a-link"  ariel-label ="my account" tabindex = "0"> Logout </div>

                          </div>
                          :
                          <div><a class = "main-nav-banner-account-login-a-link" href="/login" ariel-label ="my account" tabindex = "0"> Login </a></div>
                        }
                      </span>

                    </span>
                  </button>
                  <button class = "main-nav-banner-account-controls" tabindex = "0" type = "button" style = {{marginLeft: "5px"}}>
                    <span class = "main-nav-banner-account-controls-container">
                      <span class = "main-nav-banner-account-controls-icon" aria-hidden = "true">

                        <i class="fas fa-shopping-cart"></i>

                        {this.props.auth.cartSummary== null ? `` :
                          <span class=" cart__summary__display">
                            {this.props.auth.cartSummary.numberOfItemsInCart}
                          </span>}
                      </span>
                      <span class = "main-nav-banner-account-login">

                            <div> <a class = "main-nav-banner-account-login-a-link" href="/Cart"   ariel-label ="my account" tabindex = "0"> CART </a></div>
                      </span>

                    </span>
                  </button>
                {/*</a>*/}

              </div>
            </div>


            </div>


          </div>

          <section>
            <div class = "main-nav-banner-links main-nav-banner-links-2">
              <hr class = "main-nav-banner-divider" />
                <div class = "main-nav-banner-links-container">
                  <div class = "main-nav-banner-links-contents">
                    <div class = "main-nav-banner-links-contents-inner">
                      <div class = "main-nav-banner-links-list">

                        <button class = "main-nav-banner-nav-link" tabindex="-1" type="button" role="tab">
                          <span class = "main-nav-banner-nav-link-item">
                            <a class = "main-nav-banner-nav-link-item-a-link" href ="/">  Services </a>
                            {/*<Link class = "main-nav-banner-nav-link-item-a-link" to ="/">  Services </Link>*/}
                          </span>
                        </button>

                        { isAuthenticated ?
                          <div>
                          <button class = "main-nav-banner-nav-link" tabindex="-1" type="button" role="tab">
                            <span class = "main-nav-banner-nav-link-item">
                              <a class = "main-nav-banner-nav-link-item-a-link" href="/Events"> Events </a>
                              {/*<Link class = "main-nav-banner-nav-link-item-a-link" to = "/Journal"> Journal </Link>*/}
                            </span>
                          </button>

                          <button class = "main-nav-banner-nav-link" tabindex="-1" type="button" role="tab">
                            <span class = "main-nav-banner-nav-link-item">
                              <a class = "main-nav-banner-nav-link-item-a-link" href="/Experiences"> Experiences </a>
                              {/*<Link class = "main-nav-banner-nav-link-item-a-link" to = "/Journal"> Journal </Link>*/}
                            </span>
                          </button>
                          </div> : <></>
                      }

                        <button class = "main-nav-banner-nav-link" tabindex="-1" type="button" role="tab">
                          <span class = "main-nav-banner-nav-link-item">
                            <a class = "main-nav-banner-nav-link-item-a-link" href="/Journal"> Journal </a>
                            {/*<Link class = "main-nav-banner-nav-link-item-a-link" to = "/Journal"> Journal </Link>*/}
                          </span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              {/*<hr class = "main-nav-banner-divider" />*/}
            </div>
          </section>


        </div>
      </div>
)}
}

export function NavigationOriginal() {return(

      <div class = "navigation " id = "main-nav">



        <div class = "container">
            <div class = "row" >

              <div class = "col-12"  >
                <a href="/">
                  <h1 class = "text-center">The Real Grape</h1>
                </a>

              </div>

            </div>
            <div class = "row " >
              <div class = "col-12 justify-center align-items-center "  >

                  <ul class = "navigation-menu">
                    <li class = "navigation-item">
                      {/*<a href="#services"> Services </a>*/}
                      {/*<a href="/"> Services </a>*/}
                      <Link to ="/">  Services </Link>
                    </li>
                    {/*<li class = "navigation-item">
                      <a href="#News"> Experiences </a>
                    </li>*/}
                    <li class = "navigation-item">
                      <a href="/Journal"> Journal </a>
                      {/*<Link to ="/Journal"> Journal </Link>*/}
                    </li>
                  </ul>
              </div>
            </div>
        </div>
      </div>
)
}


const mapStateToProps = (state) => ({
  auth: state.auth,
});

const NavigationClass         = withRouter(connect(mapStateToProps, { logout })(NavigationUnconnected));
export {NavigationClass as Navigation}
