import { USER_LOADED ,
         USER_LOADING,
         AUTH_ERROR,
         LOGIN_SUCCESS,
         LOGIN_FAIL,
         CART_LOADED

} from '../Actions/Types.js'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: false,
  user: null,

  // the below additions are to be able to understand the group membership
  group: null, // always have a comma at the last statefull item
  membership: null,

  // always have a comma at the last statefull item
  cartSummary: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      // here we do some logic to extract the stateful data
      //const membershipType = "none allocated"

      // below is a hack. this needs to be updated for when the user has more than 1 role
      const membershipType = action.payload.groups[0].name

      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        group: action.payload.groups,

        membership: membershipType,
      };
    case LOGIN_SUCCESS:

      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:


      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,

        group: null, // always have a comma at the last statefull item
        membership: null,
      };
    /* This case is added to give transparency on the number of items in the shopping cart*/
    case CART_LOADED:
    return {
      ...state,
      cartSummary: action.payload,
    };
    default:
      return state;
  }
}

/*
const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: false,
  user: null
}

export default function(state = initialState, action) {
  switch(action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case USER_LOADED:
      return{
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };
      // if we have a successful login we want to store the token,
      // which is in the action.payload
      case LOGIN_SUCCESS:
        localStorage.setItem('token', action.payload.token);
        return{
          ...state,
          ...action.payload,
          isAuthenticated: true,
          isLoading: false
        }
      // AUTH_ERROR and LOGIN_FAIL do the same thing
      case AUTH_ERROR:
      case LOGIN_FAIL:
      localStorage.removeItem('token');
        return {
          ...state,
          token: null,
          user: null,
          isAuthenticated: false,
          isLoading: false
        };

    default:
      return state;
  }
}
*/
