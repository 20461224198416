import React from 'react';
import axios from 'axios'
import {returnErrors} from './Messages.js'
import store from '../Store.js'
// tutorial on
// https://www.youtube.com/watch?v=kfpY5BsIoFg

import { WINE_LIST_LOADED,
         WINE_LOADED_BUSINESS,
         WINE_REFDATA,
         WINE_GRAPE_COLOUR_TYPE_REFDATA,
         FOOD_PAIRINGS_REFDATA,
         EXPERIENCES_LIST_LOADED,
         EVENTS_LIST_LOADED,
         WINE_REVIEWS_LOADED,
         WINES_FROM_CELLAR_LOADED
        } from '../Actions/Types.js'


export const getWineList= ()  => (dispatch, getState) => {

  var url = "https://www.therealgrape.com/api/auth/business-profile/my-winelist-listing"
  // var url = "http://127.0.0.1:8000/api/auth/business-profile/my-winelist-listing"


  // GET token from state
  const token = getState().auth.token;

  // send some headers with our request
  // 'Content-type': 'application/json',
  //'X-CSRFToken': csrftoken
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // IF token, add to headers config
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // Now let us make our request to load the user
  // which returns a promise
  axios.get(url, config)
    .then(result => {
      dispatch({
        type: WINE_LIST_LOADED,
        payload: result.data
      });




    }).catch(err => {
      /*dispatch(returnErrors(err.response.data, err.response.status));*/
      /*dispatch({

      })*/
    });


}


export const getWineDetail= (query )  => (dispatch, getState) => {

  var url = "https://www.therealgrape.com/api/wine-profile/retrieve-wine-details/search"  + query
  //var url = "http://127.0.0.1:8000/api/wine-profile/retrieve-wine-details/search"  + query



  // GET token from state
  const token = getState().auth.token;

  // send some headers with our request
  // 'Content-type': 'application/json',
  //'X-CSRFToken': csrftoken
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // IF token, add to headers config
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // Now let us make our request to load the user
  // which returns a promise
  axios.get(url, config)
    .then(result => {
      dispatch({
        type: WINE_LOADED_BUSINESS,
        payload: result.data
      });




    }).catch(err => {
      /*dispatch(returnErrors(err.response.data, err.response.status));*/
      /*dispatch({

      })*/
      console.log("Wine details fail error: ", err)
    });



}

export const fetchWinesFromCellar= ()  => (dispatch, getState) =>
  new Promise(function(resolve, reject)
    {

      var url = "https://www.therealgrape.com/api/auth/member-profile/get-wines-from-cellar"
      //var url = "http://127.0.0.1:8000/api/auth/member-profile/get-wines-from-cellar"



     // GET token from state
     const token = getState().auth.token;

     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     axios.get(url, config)
      .then(result => {
        dispatch({
          type: WINES_FROM_CELLAR_LOADED,
          payload: result.data
        })

        resolve(result)

      })
      .catch((err)   => console.log("Failed to load wines from cellar: ", err));

    })

    export const fetchWineReviews = ()  => (dispatch, getState) =>
      new Promise(function(resolve, reject)
        {

          var url = "https://www.therealgrape.com/api/wine-profile/get-wine-reviews/"
          //var url = "http://127.0.0.1:8000/api/wine-profile/get-wine-reviews/"



         // GET token from state
         const token = getState().auth.token;

         // set up the header for the get calls
         const config = {
           headers: {
             'Content-type': 'application/json',
           }
         }

         // // Provide the authorisation token
         if(token) {
           config.headers['Authorization'] = `Token ${token}`
         }

         axios.get(url, config)
          .then(result => {
            dispatch({
              type: WINE_REVIEWS_LOADED,
              payload: result.data
            })

            resolve(result)

          })
          .catch((err)   => console.log("Failed to load wine reviews: ", err));

        })


export const fetchWineRegionReferenceData= (query )  => (dispatch, getState) =>
  new Promise(function(resolve, reject)
    {

    //console.log("Fetching wine details")
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-region-reference-data" + query
    var url = "https://www.therealgrape.com/api/auth/business-profile/wine-region-reference-data" + query

    // GET token from state
    const token = getState().auth.token;

    // send some headers with our request
    // 'Content-type': 'application/json',
    //'X-CSRFToken': csrftoken
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // IF token, add to headers config
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // Now let us make our request to load the user
    // which returns a promise
    axios.get(url, config)
      .then(result => {
        dispatch({
          type: WINE_REFDATA,
          payload: result.data
        });

        resolve(result)




      }).catch(err => {
        /*dispatch(returnErrors(err.response.data, err.response.status));*/
        /*dispatch({

        })*/

        console.log("Wine details fail error: ", err)
        reject(err)
      });

      return true;

  })

  export const fetchWineReferenceData= (query )  => (dispatch, getState) =>
    new Promise(function(resolve, reject)
      {

      //console.log("Fetching wine details")
       //var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-reference-data" + query
      var url = "https://www.therealgrape.com/api/auth/business-profile/wine-reference-data" + query

      // GET token from state
      const token = getState().auth.token;

      // send some headers with our request
      // 'Content-type': 'application/json',
      //'X-CSRFToken': csrftoken
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // IF token, add to headers config
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // Now let us make our request to load the user
      // which returns a promise
      axios.get(url, config)
        .then(result => {
          dispatch({
            type: WINE_GRAPE_COLOUR_TYPE_REFDATA,
            payload: result.data
          });

          resolve(result)




        }).catch(err => {
          /*dispatch(returnErrors(err.response.data, err.response.status));*/
          /*dispatch({

          })*/

          console.log("Wine details fail error: ", err)
          reject(err)
        });

        return true;

    })

    export const fetchMyExperiencesList= ()  => (dispatch, getState) =>
      new Promise(function(resolve, reject)
        {

          var url = "https://www.therealgrape.com/api/auth/business-profile/my-experiences-listing"
         // var url = "http://127.0.0.1:8000/api/auth/business-profile/my-experiences-listing"



         // GET token from state
         const token = getState().auth.token;

         // set up the header for the get calls
         const config = {
           headers: {
             'Content-type': 'application/json',
           }
         }

         // // Provide the authorisation token
         if(token) {
           config.headers['Authorization'] = `Token ${token}`
         }

         axios.get(url, config)
          .then(result => {
            dispatch({
              type: EXPERIENCES_LIST_LOADED,
              payload: result.data
            })

            resolve(result)

          })
          .catch((err)   => console.log("Failed to load experiences list: ", err));

        })

    export const fetchMyEventsList= ()  => (dispatch, getState) =>
      new Promise(function(resolve, reject)
        {

         var url = "https://www.therealgrape.com/api/auth/business-profile/my-events-listing"
         //var url = "http://127.0.0.1:8000/api/auth/business-profile/my-events-listing"


          // GET token from state
          const token = getState().auth.token;

          // set up the header for the get calls
          const config = {
            headers: {
              'Content-type': 'application/json',
            }
          }

          // // Provide the authorisation token
          if(token) {
            config.headers['Authorization'] = `Token ${token}`
          }

          axios.get(url, config)
           .then(result => {

             dispatch({
               type: EVENTS_LIST_LOADED,
               payload: result.data
             })

             resolve(result)

           })
           .catch((err)   => console.log("Failed to load experiences list: ", err));


        })

    export const fetchFoodPairingsReferenceData= (query )  => (dispatch, getState) =>
      new Promise(function(resolve, reject)
        {

        //console.log("Fetching wine details")
        //var url = "http://127.0.0.1:8000/api/auth/business-profile/food-pairings-reference-data" + query
        var url = "https://www.therealgrape.com/api/auth/business-profile/food-pairings-reference-data" + query

        // GET token from state
        const token = getState().auth.token;

        // send some headers with our request
        // 'Content-type': 'application/json',
        //'X-CSRFToken': csrftoken
        const config = {
          headers: {
            'Content-type': 'application/json',
          }
        }

        // IF token, add to headers config
        if(token) {
          config.headers['Authorization'] = `Token ${token}`
        }

        // Now let us make our request to load the user
        // which returns a promise
        axios.get(url, config)
          .then(result => {
            dispatch({
              type: FOOD_PAIRINGS_REFDATA,
              payload: result.data
            });

            resolve(result)




          }).catch(err => {
            /*dispatch(returnErrors(err.response.data, err.response.status));*/
            /*dispatch({

            })*/

            console.log( "Food pairings details fail error: ", err)
            reject(err)
          });

          return true;

      })

/*export const fetchWineRegionReferenceData= ( )  => (dispatch, getState) => {

  console.log("Fetching wine details")
  var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-region-reference-data"+"/COUNTRY=*"
  //var url = "https://www.therealgrape.com/api/auth/business-profile/wine-region-reference-data"+"/COUNTRY=*"

  // GET token from state
  const token = getState().auth.token;

  // send some headers with our request
  // 'Content-type': 'application/json',
  //'X-CSRFToken': csrftoken
  const config = {
    headers: {
      'Content-type': 'application/json',
    }
  }

  // IF token, add to headers config
  if(token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  // Now let us make our request to load the user
  // which returns a promise
  axios.get(url, config)
    .then(result => {
      dispatch({
        type: WINE_REFDATA,
        payload: result.data
      });





    }).catch(err => {
      /*dispatch(returnErrors(err.response.data, err.response.status));*/
      /*dispatch({

      })*/
  /*    console.log("Wine details fail error: ", err)
    });



}*/
