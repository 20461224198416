import {useState} from "react";

export function useFormErrorModal(){

  const [openFormErrorModal, setOpenFormErrorModal] = useState(false);
  /*const [errorMsg,setErrorMsg] = useState();*/
  /*setOpenFormErrorModal(false)
  setErrorMsg("")*/
  return {


    openFormErrorModal,
    setOpenFormErrorModal,
    /*errorMsg,
    setErrorMsg*/
  };

}

export function useFormErrorMsg(){

  const [errorMsg,setErrorMsg] = useState("No Form Errors");

  return {
    errorMsg,
    setErrorMsg
  };

}
