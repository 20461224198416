import React, {useState, useEffect, useLayoutEffect} from 'react';
import  { Component } from 'react';
import '../App.css';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import "../Actions/Auth.js"
import '../Styles/WineLists.css'

import axios from 'axios'

import {loadUser, logout} from '../Actions/Auth.js'
import {getWineList,
        getWineDetail,
        fetchWineRegionReferenceData,
        fetchWineReferenceData,
        fetchFoodPairingsReferenceData,
        fetchMyExperiencesList,
        fetchMyEventsList }      from '../Actions/WineLists.js'

import store from '../Store.js'

import {countryList} from "../Forms/FormComponents.js"

import {
  withRouter, // used in the app export default - expore using with Journal
  BrowserRouter as Router,

  HashRouter, // try using the HashRouter
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from 'react-router-dom'

import {dateElementFromDate, dateFromText, dateFormatterFromString, getUnique} from "../Components/DashboardComponents.js"
import getSymbolFromCurrency from 'currency-symbol-map'


class WineListsClass extends Component{

  constructor(props) {
    super(props);

    this.state = {
      wineList:[],
      isLoading: true,
      isLoadingAnEvent: true,
      isEditing: false,
      wineListData: {},

      // variables for uploading data
      wineListName: null,
      wineListdescription: null,

      // variables for handling post data
      udpateFieldName: '',
      updateFieldValue: null,
      updatePostData  : {},

      // manage a new wine Entry
      displayWineMenu: false,
      updateWineFormFieldName: '',
      updateWineFormFieldValue: null,
      updateWineFormPostData: {},
      isLoadingWineRegionDetails: true,
      wineRegionData: null,
      countryMap           : [],
      countryMapFiltered   : [],
      regionMap            : [],
      regionMapFiltered    : [],
      subRegionMap         : [],
      subRegionMapFiltered : [],
      siteMap              : [],
      siteMapFiltered      : [],
      parcelMap            : [],
      parcelMapFiltered    : [],

      acidity: null,
      alcohol: null,
      appearance: null,
      appelation: null,
      body: null,
      colorIntensity: null,
      country: null,
      drinkingTime: null,
      endToDrink: null,
      flavourIntensity: null,
      length: null,
      mouse: null,
      nose: null,
      noseintensity: null,
      pairWith: null,
      palate: null,
      producer: null,
      quality: null,
      readinessToDrink: null,
      region: null,
      startToDrink: null,
      sweetness: null,
      tanin: null,
      vintage: null,
      wine: null,
      subRegion: null,
      site: null,
      parcel: null,

      // variables for selecting which winelist to display
      wineListToDisplay: null,


      // Pagination variables
      currentItemsList: [],
      currentPage: 0,
      listingsPerPage: 3,
      firtListItemIndx: 0,
      lastListItemIndx: 3,
      numberOfListings: 10,
      pageNumbers:[],

      wineDetails : {
        'wine': null,
        'producer': null,
        'country': null,
        'region': null,
        'subRegion': null,
        'site': null,
        'parcel': null
      },

      // Add To Event Or Experience States
      experience_Select   : null,
      event_Select        : null,
      selectedWineListId : null,


    }

    this.fetchWineList = this.fetchWineList.bind(this)
    this.pagination      = this.pagination.bind(this)
    this.manageEvent     = this.manageEvent.bind(this)
    this.handleInputChange   = this.handleInputChange.bind(this)
    this.editEventItem       = this.editEventItem.bind(this)
    this.handleSave          = this.handleSave.bind(this)
    this.cancelEditEventItem = this.cancelEditEventItem.bind(this)
    this.clearFormElements   = this.clearFormElements.bind(this)
    this.toggleAddWineMenu   = this.toggleAddWineMenu.bind(this)
    this.addWineToList       = this.addWineToList.bind(this)
    this.cancelAddWineToList = this.cancelAddWineToList.bind(this)

    // wine form
    this.handleWineFormChange   = this.handleWineFormChange.bind(this)
    this.fetchWineRegionDetails = this.fetchWineRegionDetails.bind(this)
    this.onlyUnique             = this.onlyUnique.bind(this)

    this.addWineDetails         = this.addWineDetails.bind(this)
    this.saveWineDetails        = this.saveWineDetails.bind(this)
    this.filterFormElement      = this.filterFormElement.bind(this)

    // add winelist to event or experience
    this.revealAddWinelistToEventOrExperience = this.revealAddWinelistToEventOrExperience.bind(this)
    this.hideAddWinelistToEventOrExperience   = this.hideAddWinelistToEventOrExperience.bind(this)
    this.cancelAddWinelistToEventOrExperience = this.cancelAddWinelistToEventOrExperience.bind(this)
    this.manageAddWinelistToEventOrExperience = this.manageAddWinelistToEventOrExperience.bind(this)
    this.handleSelection                      = this.handleSelection.bind(this)
    this.updateWineListStatus                 = this.updateWineListStatus.bind(this)

  }

  /*export class Experiences extends Component{*/
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    winelist: PropTypes.func.isRequired,
  };

  async componentDidMount() {

    this.fetchWineList()
    store.dispatch(getWineList());

    await store.dispatch(fetchMyEventsList());
    await store.dispatch(fetchMyExperiencesList( ));

    //await store.dispatch(fetchMyExperiencesList( ));
    //await store.dispatch(fetchMyEventsList());

  }

  async componentDidUpdate(){

    //store.dispatch(fetchMyExperiencesList( ));
    //store.dispatch(fetchMyEventsList());
    /*this.fetchWineList()*/

  }

  pagination(){
    var pageNumbersArray = []
    for(let i = 1; i <= Math.ceil(this.numberOfListings / this.listingsPerPage); i++ )
    {
      pageNumbersArray.push(i)
    }
    this.setState({
      pageNumbers : pageNumbersArray
    })
  }

  fetchWineList() {

    var url = "https://www.therealgrape.com/api/auth/business-profile/my-winelist-listing"
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/my-winelist-listing"


    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)

      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
          wineList: response.data,
          isLoading: false,
        })

      })
      .catch((error)   => console.log(error));


  }

  filterFormElement(e) {
      e.preventDefault()

      const elementId             = e.currentTarget.id;
      var  clickedElement         = document.getElementById(elementId)
      const targetFieldName       = clickedElement.name
      //var data                    = clickedElement.dataset.filterdata
      //var data                    = this.state.countryMap
      var objectToFilter          = clickedElement.dataset.objecttofilter
      var filteredObject          = clickedElement.dataset.filteredobject
      var filterField             = clickedElement.dataset.filterfield
      var data                    = this.state[objectToFilter]
      var filteredData = []

      console.log("Clicked entry: " + clickedElement.name + " clicke entry value: " + clickedElement.value)
      console.log("Filtered Data: ", data)


      // run the filter - we could do this in the backend
      for (var i = 0; i < data.length; i++ ){

        if (data[i][filterField] == null) {

        }
        else{


          var value   = clickedElement.value.toLowerCase()
          var filterObject = data[i][filterField].toLowerCase()

          if (filterObject.includes(value)) {
            // could also use startsWith
            filteredData.push(data[i])
          }
        }
      }

      //save filtered data
      this.setState({
        [filteredObject] : filteredData
      })


      console.log(filteredObject)

  }

  addWineDetails(e) {

    e.preventDefault()

    const elementId             = e.currentTarget.id;
    var  clickedElement         = document.getElementById(elementId)
    const targetFieldName       = clickedElement.name

    var  btnToReveal            = clickedElement.dataset.target_save_btn
    var  btnToRevealClass       = document.getElementById(btnToReveal)


    this.setState({
      wineDetails : { ... this.state.wineDetails ,  [targetFieldName] : clickedElement.value }
    })

    btnToRevealClass.classList.remove("hidden")


  }

  saveWineDetails(e) {

    e.preventDefault()

    var url = "https://www.therealgrape.com/api/auth/business-profile/wine-list-details-updat-an-save/"+ this.state.wineListData.id.toString()+"/"
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-list-details-updat-an-save/"+ this.state.wineListData.id.toString()+"/"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    const body = JSON.stringify({'wineDetails': this.state.wineDetails});

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {

      store.dispatch(getWineList())


    })
    .then((data) => {

    })

    this.setState({
      wineDetails : {
        'wine': null,
        'producer': null,
        'country': null,
        'region': null,
        'subRegion': null,
        'site': null,
        'parcel': null
      }
    })



  }



  fetchWineRegionDetails() {

    var countryMapData   = []
    var regionMapData    = []
    var subRegionMapData = []
    var siteMapData = []
    var parcelMapData =[]


    //var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-region-details"+"/COUNTRY=*"
    var url = "https://www.therealgrape.com/api/auth/business-profile/wine-region-details"+"/COUNTRY=*"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        wineRegionData: response.data,
        isLoadingWineRegionDetails: false,
        })

      /* create an array of country data*/
      this.state.wineRegionData.map((cntryData) =>
        countryMapData.push({"COUNTRY_CODE": cntryData.COUNTRY_CODE, "COUNTRY": cntryData.COUNTRY})
      )

      /* create an array of region data*/
      this.state.wineRegionData.map((regionData) =>
        regionMapData.push({"REGION_CODE": regionData.REGION_CODE, "REGION": regionData.REGION})
      )

      /*create an array of subregion data*/
      this.state.wineRegionData.map((subRegionData) =>
        subRegionMapData.push({"SUB_REGION_CODE": subRegionData.SUB_REGION_CODE, "SUB_REGION": subRegionData.SUB_REGION})
      )

      /*create an array of site data*/
      this.state.wineRegionData.map((siteData) =>
        siteMapData.push({"SITE_CODE": siteData.SITE_CODE, "SITE": siteData.SITE})
      )

      /*create an array of parcel data*/
      this.state.wineRegionData.map((parcelData) =>
        parcelMapData.push({"PARCEL_CODE": parcelData.PARCEL_CODE, "PARCEL": parcelData.PARCEL})
      )

      const uniqueWineCountries  = getUnique(countryMapData, 'COUNTRY')
      const uniqueWineRegions    = getUnique(regionMapData, 'REGION')
      const uniqueWineSubRegions = getUnique(subRegionMapData, 'SUB_REGION')
      const uniqueWineSites      = getUnique(siteMapData, 'SITE')
      const uniqueWineParcels    = getUnique(parcelMapData, 'PARCEL')

      this.setState({
        countryMap    : uniqueWineCountries,
        countryMapFiltered : uniqueWineCountries,

        regionMap     : uniqueWineRegions,
        regionMapFiltered     : uniqueWineRegions,

        subRegionMap  : uniqueWineSubRegions,
        subRegionMapFiltered  : uniqueWineSubRegions,

        siteMap       : uniqueWineSites,
        siteMapFiltered       : uniqueWineSites,

        parcelMap     : uniqueWineParcels,
        parcelMapFiltered     : uniqueWineParcels,
      })


      })
      .catch((error)   => console.log(error));




  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  manageEvent(itemToEdit, wineListIndex){


    // wineListIndex this is the index in  the array --> self.props.winelist.wineList[self.state.wineListToDisplay] that will pop out the correct winelist

    // var itemToEditAsArrayLoc = itemToEdit - 1
    var itemToEditAsArrayLoc = itemToEdit - 1

    this.setState({
      isEditing: true,
      isLoadingAnEvent: true,
    })


         var url = "https://www.therealgrape.com/api/auth/business-profile/wine-list-details/"+itemToEdit.toString()
        // var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-list-details/"+itemToEdit.toString()

        // GET token from state
        const token = this.props.auth.token;

        // set up the header for the get calls
        const config = {
          headers: {
            'Content-type': 'application/json',
          }
        }

        // // Provide the authorisation token
        if(token) {
          config.headers['Authorization'] = `Token ${token}`
        }

        axios.get(url, config)
          .then((response) => { this.setState({
          /*.then((response) => {console.log(response); this.setState({*/
            wineListData: response.data,
            isLoadingAnEvent: false,
            //wineListToDisplay: itemToEditAsArrayLoc,
            wineListToDisplay: wineListIndex,
            // need to make sure the correct winelist is capture in here
            })

          })
          .catch((error)   => console.log(error));

  }

  handleInputChange(event){

    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    // handle the create button
    var createBtn     = document.getElementById('create-event__create_button')

    this.setState({
      [name]: value,
      updateFieldName: name,
      updateFieldValue: value,
    })

    var postData   = this.state.updatePostData
    postData[name] = value
    this.setState({
      updatePostData: postData
    })

  }
  editEventItem(event) {

    event.preventDefault()

    const elementId     =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''


    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide form element, save button and camcel button
    formElement.classList.remove("hidden");
    saveBtn.classList.remove("hidden");
    cancelBtn.classList.remove("hidden");

    // hide the edit button
    editBtn.classList.add("hidden");


  }
  handleSave(event) {

    event.preventDefault()

    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-save", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    if (this.state.updateFieldValue != null)
    {

      var field2Update         = this.state.udpateFieldName
      const constUpdateValue   = this.state.updateFieldValue
      const postDataFinal      = this.state.updatePostData

      var postData = {}
      postData[field2Update] = constUpdateValue

      var url = "https://www.therealgrape.com/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"
      // var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"


      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now we need to stringfy the field that we want to provide
      // Request body

      const body = JSON.stringify(postDataFinal);

      // now post the Data
      axios.post(url, body, config)
      .then()

      // reset the state variables for field updates to null
      this.setState({
        udpateFieldName: '',
        updateFieldValue: null,
        editing:false
      })

      // clear anything that is currently in the updatePostData

      this.setState({
          updatePostData : {}
      })

      // return all form data to original state

      this.setState({
        wineListName: null,
        wineListdescription: null,
      })

    }

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");

    // clear the form elements
    this.clearFormElements()

    // refresh form data
    this.manageEvent(this.state.wineListData.id)


  }
  cancelEditEventItem(event) {

    /* handle the cancel button*/
    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-cancel", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");


    // clear anything that is currently in the updatePostData

    this.setState({
        updatePostData : {}
    })

    // return all form data to original state

    this.setState({

        wineListName: null,
        wineListdescription: null,


    })

    // clear all form elements
    this.clearFormElements()

  }
  clearFormElements() {
    document.getElementById("wineListName-editMode-form").value = ""
  }

  toggleAddWineMenu(e) {
    e.preventDefault()

    this.setState({
        displayWineMenu: true
    })

    // fetch the details of the regions i.e. Country -> Region -> Subregion -> Site ->  Plot  map
    this.fetchWineRegionDetails()

  }
  addWineToList(e) {
    e.preventDefault()
  }

  cancelAddWineToList(e) {
    e.preventDefault()
    this.setState({
        displayWineMenu: false,
        isLoadingWineRegionDetails: false,
    })

    this.setState({
      acidity: null,
      alcohol: null,
      appearance: null,
      appelation: null,
      body: null,
      colorIntensity: null,
      country: null,
      drinkingTime: null,
      endToDrink: null,
      flavourIntensity: null,
      length: null,
      mouse: null,
      nose: null,
      noseintensity: null,
      pairWith: null,
      palate: null,
      producer: null,
      quality: null,
      readinessToDrink: null,
      region: null,
      startToDrink: null,
      sweetness: null,
      tanin: null,
      vintage: null,
      wine: null
    })

    //this.forceUpdate();

  }

  handleWineFormChange(event){

    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    this.setState({
      [name]: value,
      updateWineFormFieldName: name,
      updateWineFormFieldValue: value,
    })

    var postData   = this.state.updateWineFormPostData
    postData[name] = value
    this.setState({
      updateWineFormPostData: postData
    })



  }

  handleSaveWineDetails(event) {

    event.preventDefault()



    if (this.state.updateWineFormFieldValue != null)
    {

      var field2Update         = this.state.updateWineFormFieldName
      const constUpdateValue   = this.state.updateWineFormFieldValue
      const postDataFinal      = this.state.updateWineFormPostData
      postDataFinal['addWine'] = true

      var postData = {}
      postData[field2Update] = constUpdateValue



      var url = "https://www.therealgrape.com/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"
      // var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"



      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now we need to stringfy the field that we want to provide
      // Request body

      const body = JSON.stringify(postDataFinal);

      // now post the Data
      axios.post(url, body, config)
      .then(
        store.dispatch(getWineList())
      )
      .catch((error)   => console.log(error));

      // reset the state variables for field updates to null
      this.setState({
        updateWineFormFieldName: '',
        updateWineFormFieldValue: null
      })

      // clear anything that is currently in the       updateWineFormPostData

      this.setState({
        updateWineFormPostData : {}
      })

      // return all form data to original state

      this.setState({
        /*acidity: null,
        alcohol: null,
        appearance: null,
        appelation: null,
        body: null,
        colorIntensity: null,
        country: null,
        drinkingTime: null,
        endToDrink: null,
        flavourIntensity: null,
        length: null,
        mouse: null,
        nose: null,
        noseintensity: null,
        pairWith: null,
        palate: null,
        producer: null,
        quality: null,
        readinessToDrink: null,
        region: null,
        startToDrink: null,
        sweetness: null,
        tanin: null,
        vintage: null,
        wine:null*/
      })

    }

    // refresh form data
    this.manageEvent(this.state.wineListData.id)
    this.forceUpdate();


  }

  revealAddWinelistToEventOrExperience(e) {
    e.preventDefault()
    const elementId           = e.currentTarget.id;
    var   clickedElement      = document.getElementById(elementId)
    var   elementsToReveal    = clickedElement.dataset.target_hidden_elements
    var   cancelBtnsToReveal  = clickedElement.dataset.target_hidden_cancel_btn
    var   actionBtnToHide     = clickedElement.dataset.target_action_btn
    var   elementsToDisplay   = document.getElementById(elementsToReveal)
    var   cancelBtnsToDisplay = document.getElementById(cancelBtnsToReveal)



    elementsToDisplay.classList.remove("hidden")
    cancelBtnsToDisplay.classList.remove("hidden")
    document.getElementById(actionBtnToHide).classList.add("hidden")
  }

  hideAddWinelistToEventOrExperience(e) {

    e.preventDefault()
    const elementId                     = e.currentTarget.id;
    var   clickedElement                = document.getElementById(elementId)
    var   elementsToHide                = clickedElement.dataset.target_hidden_elements
    var   cancelBtnsToHide              = clickedElement.dataset.target_hidden_cancel_btn
    var   actionBtnToReveal             = clickedElement.dataset.target_action_btn
    var   elementsToRemoveFromDisplay   = document.getElementById(elementsToHide)
    var   cancelBtnsToRemoveFromDisplay = document.getElementById(cancelBtnsToHide)


    console.log("cllicked: ", clickedElement,
              "target elements to hide: ", elementsToHide,
              "target cancel: ", cancelBtnsToHide,
              "target actions: ", actionBtnToReveal)

    elementsToRemoveFromDisplay.classList.add("hidden")
    cancelBtnsToRemoveFromDisplay.classList.add("hidden")
    document.getElementById(actionBtnToReveal).classList.remove("hidden")
  }

  cancelAddWinelistToEventOrExperience(e) {

    this.hideAddWinelistToEventOrExperience(e)

  }

  updateWineListStatus(wineListId, action){


    var url = "https://www.therealgrape.com/api/auth/business-profile/update-wine-list-status"
     //var url = "http://127.0.0.1:8000/api/auth/business-profile/update-wine-list-status"

    var request = {}

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    if(action === 'add_to_event_experience'){


      request = { 'wineListId'   : this.state.selectedWineListId ,
                                    'eventId'     : this.state.event_Select,
                                    'experienceId': this.state.experience_Select,
                                    'action'      : action }

    }

    if(action === 'publish-winelist'){

      request =  { 'wineListId'      : wineListId ,
                                    'publishWineList' : true,
                                    'action'          : action }

    }

    if(action === 'delete-winelist'){

      request =  { 'wineListId'      : wineListId ,
                                      'action'          : action }
    }

    if(action === 'remove-association'){/*TO DO some backend work*/}

    const body = JSON.stringify(request);


    console.log("This is what we want to post", body)

    // now post the Data
    axios.post(url, body, config)
    .then((response) => {
      /*dispatch */
      //store.dispatch(getWineList())
    })
    .then((data) => {
      /*location.reload()*/
      /*document.location.reload()*/
    })


  }

  manageAddWinelistToEventOrExperience(e) {
    e.preventDefault(e)

    const elementId         = e.currentTarget.id;
    var   clickedElement    = document.getElementById(elementId)
    var   wineListId        = clickedElement.dataset.winelist_id
    var   action            = clickedElement.dataset.action

    if (this.state.selectedWineListId === wineListId) {


      this.updateWineListStatus( wineListId, action)
      if (action === "add_to_event_experience") {
        this.hideAddWinelistToEventOrExperience(e)
      }

    }
    else {

      if (action === 'publish-winelist') {

        this.updateWineListStatus( wineListId, action)
        this.hideAddWinelistToEventOrExperience(e)

      }
      if (action === 'delete-winelist') {

        this.updateWineListStatus( wineListId, action)
        this.hideAddWinelistToEventOrExperience(e)

      }
    }






  }

  handleSelection(e) {
    e.preventDefault()

    const elementId         = e.currentTarget.id;
    var   clickedElement    = document.getElementById(elementId)
    var   wineListId        = clickedElement.dataset.winelist_id

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    console.log("Selected element: ", clickedElement.name, "selected winelist: ", wineListId.toString(), "value to update with is", value)
    console.log("State variables are currently, selectedWineListId: ", this.state.selectedWineListId ," experience_Select :", this.state.experience_Select , "event_Select :", this.state.event_Select)



    // check if the winelist selected is the same as the prior winelist
    if (wineListId === this.state.selectedWineListId) {

      console.log("Editing the same wine list to ", this.state.selectedWineListId)
      /* no need to clear state variables just make the required updates*/

      /* make the required updates */
      this.setState({
        selectedWineListId : wineListId,
        [e.target.name]: value,
      })

    }
    else {
      console.log("Edditing a different wine list to ", this.state.selectedWineListId)
      /* clear all selection state variables*/
      this.setState({
        selectedWineListId : null,
        experience_Select  : null,
        event_Select        : null,
      })

      /* make the required updates */
      this.setState({
        selectedWineListId : wineListId,
        [e.target.name]: value,
      })

    }

  }


  render()
  {
    const { isAuthenticated, user } = this.props.auth;
    var             isLoading       = this.state.isLoading
    var      isLoadingAnEvent       = this.state.isLoadingAnEvent
    var             isEditing       = this.state.isEditing
    var                      self   = this

    // variables for pagination
    const indexOfLastListedItem     = this.state.currentPage * this.state.listingsPerPage
    const indexOfFirstListedItem    = indexOfLastListedItem  - this.state.listingsPerPage

    return(
      !isLoading ?
        !isEditing ?


          <div>
           <div class = "dashboard-containeer">
             <div class = "form dashboard-form"  id = "my-wine-lists-form">
               <div class = "row dashboard-row-wrapper">
                 <div class ="col-sm-10 col-md-10 col-lg-10">
                   <span>
                     <h1 class = "my-dashboard__title_text"> My Wine Lists </h1>
                     <div class = "my-dashboard__subtitle"> Click on a wine list to make changes </div>
                   </span>
                 </div>

                 <div class ="col-sm-2 col-md-2 col-lg-2">

                 </div>

               </div> {/*End row element*/}

               <div class = "row dashboard-row-wrapper">
                 <div class ="col-sm-12 col-md-12 col-lg-12">
                   <div class = "winelist-stack">
                     <div class = "winelist-stack-element-inner">

                       <ul class = "winelist-listing-cards" >

                         {/*self.state.wineList.slice(self.state.firtListItemIndx,self.state.lastListItemIndx).map((listing, index) => (*/}
                         {self.state.wineList.map((listing, index) => (
                           /*<li key={`listing-${index}`} >*/
                           <li key={`listing-${listing.id}`} >
                             <div class = "winelist-listing-entry" style = {{marginTop: "10px"}} > {/*winelist-entry*/}

                               <div class = "row">
                                 <div class = "col-sm-12 col-md-8 col-lg-8" onClick={() => self.manageEvent(listing.id, index)}>
                                   <div class = "winelist-stack-entry-inner">
                                     <div class = "winelist-listing-entry-title" style = {{marginTop: "5px"}}> {listing.wineListName}</div>
                                   </div>
                                 </div>

                                 <div class = "col-sm-12 col-md-2 col-lg-2">

                                 <div class = "winelist-stack-entry-inner">
                                   <button class = "btn add_to_event_experience_btn"
                                           id = {`publish-winelist-btn-${listing.id}`}
                                           style = {{fontSize: "8", textAlign: "center", padding:"2px 2px 2px 2px"}}
                                           data-winelist_id = {listing.id}
                                           data-target_hidden_elements   = {`confirm-publish-winelist-btn-${listing.id}`}
                                           data-target_hidden_cancel_btn = {`cancel-publish-btn-${listing.id}`}
                                           data-target_action_btn        = {`publish-winelist-btn-${listing.id}`}
                                           onClick = {(e) => self.revealAddWinelistToEventOrExperience(e)}> Publish </button>
                                 </div>

                                   <div class = "winelist-stack-entry-inner">
                                     <button class = "btn add_to_event_experience_btn hidden"
                                             id = {`confirm-publish-winelist-btn-${listing.id}`}
                                             style = {{fontSize: "8", textAlign: "center", padding:"2px 2px 2px 2px"}}
                                             data-target_hidden_elements   = {`confirm-publish-winelist-btn-${listing.id}`}
                                             data-target_hidden_cancel_btn = {`cancel-publish-btn-${listing.id}`}
                                             data-target_action_btn        = {`publish-winelist-btn-${listing.id}`}
                                             data-winelist_id = {listing.id}
                                             data-action      = "publish-winelist"
                                             onClick = {(e) => self.manageAddWinelistToEventOrExperience(e)}> Confirm </button>
                                   </div>
                                 </div> {/*end col*/}

                                 <div class = "col-sm-12 col-md-2 col-lg-2">
                                   <div class = "winelist-stack-entry-inner">
                                     <button class = "btn add_to_event_experience_btn"
                                             id = {`delete-winelist-btn-${listing.id}`}
                                             style = {{fontSize: "8", textAlign: "center", padding:"2px 2px 2px 2px"}}
                                             data-target_hidden_elements   = {`confirm-delete-winelist-btn-${listing.id}`}
                                             data-target_hidden_cancel_btn = {`cancel-delete-btn-${listing.id}`}
                                             data-target_action_btn        = {`delete-winelist-btn-${listing.id}`}
                                             data-winelist_id = {listing.id}
                                             data-action      = "delete-winelist"
                                             onClick = {(e) => self.revealAddWinelistToEventOrExperience(e)}
                                             > Delete </button>
                                   </div>

                                   <div class = "winelist-stack-entry-inner">
                                     <button class = "btn add_to_event_experience_btn hidden"
                                             id = {`confirm-delete-winelist-btn-${listing.id}`}
                                             style = {{fontSize: "8", textAlign: "center", padding:"2px 2px 2px 2px"}}
                                             data-target_hidden_elements   = {`confirm-delete-winelist-btn-${listing.id}`}
                                             data-target_hidden_cancel_btn = {`cancel-delete-btn-${listing.id}`}
                                             data-target_action_btn        = {`delete-winelist-btn-${listing.id}`}
                                             data-winelist_id = {listing.id}
                                             data-action      = "delete-winelist"
                                             onClick = {(e) => self.manageAddWinelistToEventOrExperience(e)}
                                             > Confirm </button>
                                   </div>
                                 </div>

                                 <div class = "cols-sm-12 col-md-4 offset-md-8 col-lg-4 offset-lg-8">
                                   <div class = "winelist-stack-entry-inner">
                                     <button class = "btn add_to_event_experience_btn hidden"
                                             id = {`cancel-publish-btn-${listing.id}`}
                                             data-target_hidden_elements   = {`confirm-publish-winelist-btn-${listing.id}`}
                                             data-target_hidden_cancel_btn = {`cancel-publish-btn-${listing.id}`}
                                             data-target_action_btn        = {`publish-winelist-btn-${listing.id}`}
                                             data-winelist_id = {listing.id}
                                             data-action      = "cancel-publish-and-delete"
                                             onClick = {(e) => self.hideAddWinelistToEventOrExperience(e)}
                                             > cancel publish </button>
                                   </div>
                                 </div>

                                 <div class = "cols-sm-12 col-md-4 offset-md-8 col-lg-4 offset-lg-8">
                                   <div class = "winelist-stack-entry-inner">
                                     <button class = "btn add_to_event_experience_btn hidden"
                                             id = {`cancel-delete-btn-${listing.id}`}
                                             data-target_hidden_elements   = {`confirm-delete-winelist-btn-${listing.id}`}
                                             data-target_hidden_cancel_btn = {`cancel-delete-btn-${listing.id}`}
                                             data-target_action_btn        = {`delete-winelist-btn-${listing.id}`}
                                             data-winelist_id = {listing.id}
                                             data-action      = "cancel-publish-and-delete"
                                             onClick = {(e) => self.hideAddWinelistToEventOrExperience(e)}
                                             > cancel delete </button>
                                   </div>
                                 </div>

                                 <div class = "col-sm-12 col-md-12 col-lg-12">
                                   <div class = "winelist-stack-entry-inner">
                                     <hr/>
                                     <div class = "winelist-listing-entry__description" style = {{padding:"0 5px 0 5px"}}> {listing.wineListdescription} </div>
                                   </div>
                                 </div>

                                 <div class = "col-sm-12 col-md-12 col-lg-12">
                                   <div class = "winelist-stack-entry-inner">
                                     <div class = "winelist-listing-entry__description" style = {{padding:"0 5px 0 5px"}}><div class = "split-with-separator-alignment"> <div class="separator"></div>  Number of wines on list is {listing.numOfWines} </div></div>
                                   </div>
                                 </div>

                                 <div class = "col-sm-12 col-md-12 col-lg-12">
                                   <div class = "winelist-stack-entry-inner">
                                     <div class = "winelist-listing-entry__description" style = {{padding:"0 5px 0 5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div>  Winelist was created on {dateFormatterFromString(listing.date_created, "00:00:00").split(" at ")[0] } </div> </div>
                                   </div>
                                 </div>

                                 <div class = "col-sm-12 col-md-12 col-lg-12">
                                   <div class = "winelist-stack-entry-inner">
                                     <div class = "winelist-listing-entry__description" style = {{padding:"0 5px 0 5px", marginBottom: "5px"}}> <div class = "split-with-separator-alignment"> <div class="separator"></div> Status of your winelist is {listing.status} </div> </div>
                                   </div>
                                 </div>


                                 <div class = "col-sm-12 col-md-12 col-lg-12">
                                   <div class = "winelist-stack-entry-inner" style = {{marginBottom: "5px"}}>
                                     <hr/>
                                     <div class = "row" style = {{marginBottom: "5px"}}>
                                       <div class = "cols-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                                         <button class = "btn add_to_event_experience_btn"
                                                 id = {`add-to-event-or-experience-btn-${listing.id}`}
                                                 data-target_hidden_elements   = {`add-to-event-or-experience-section-${listing.id}`}
                                                 data-target_hidden_cancel_btn = {`cancel-add-to-event-or-experience-btn-${listing.id}`}
                                                 data-target_action_btn        = {`add-to-event-or-experience-btn-${listing.id}`}
                                                 onClick={(e) => self.revealAddWinelistToEventOrExperience(e)}> add to event or experience </button>

                                                 <button class = "btn add_to_event_experience_btn hidden "
                                                         id = {`cancel-add-to-event-or-experience-btn-${listing.id}`}
                                                         data-target_hidden_elements   = {`add-to-event-or-experience-section-${listing.id}`}
                                                         data-target_hidden_cancel_btn = {`cancel-add-to-event-or-experience-btn-${listing.id}`}
                                                         data-target_action_btn        = {`add-to-event-or-experience-btn-${listing.id}`}
                                                         data-winelist_id = {listing.id}
                                                         data-action      = "cancel_add_to_event_experience"
                                                         onClick = {(e) => self.cancelAddWinelistToEventOrExperience(e)}

                                                         > cancel </button>
                                       </div>
                                     </div>

                                     <div class = "row addToEventOrExperience hidden"
                                             id = {`add-to-event-or-experience-section-${listing.id}`}  >
                                       <div class = "cols-sm-12 col-md-6 col-lg-6">
                                         <div class = "event_experience_select_wrapper">
                                           {console.log("Events list \n", this.props.winelist.eventsList)}
                                           <select   onChange         = {this.handleSelection}
                                                     id               = {`add-to-event-selection-${listing.id}`}
                                                     name             = "event_Select"
                                                     value            = {this.state.eventSelect}
                                                     data-winelist_id = {listing.id}
                                                     class            = "form-control form-control-sm form-field"
                                                      >
                                             <option value="">Select Event</option>

                                             {  this.props.winelist.eventsListLoaded  ? this.props.winelist.eventsList.map((eventsListData) =>
                                               <option value={eventsListData.id}>{eventsListData.eventTitle}</option>
                                             ) : <option value = {null}> </option>}
                                           </select>
                                         </div>
                                       </div>
                                       <div class = "cols-sm-12 col-md-6 col-lg-6">
                                         <div class = "event_experience_select_wrapper">

                                           <select   onChange         = {this.handleSelection}
                                                     id               = {`add-to-event-experience-${listing.id}`}
                                                     name             = "experience_Select"
                                                     data-winelist_id = {listing.id}
                                                     value            = {this.state.experienceSelect}
                                                     class            = "form-control form-control-sm form-field" >
                                             <option value="">Select Experience</option>
                                             { this.props.winelist.experiencesListLoaded ? this.props.winelist.experiencesList.map((experiencesListData) =>
                                               <option value={experiencesListData.id}>{experiencesListData.experienceTitle}</option>
                                             ) : <option value = {null}> </option> }

                                           </select>
                                         </div>
                                       </div>

                                       <div class = "cols-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                                         <button class = "btn add_to_event_experience_btn"
                                                 id = {`confirm-add-to-event-or-experience-btn-${listing.id}`}
                                                 data-target_hidden_elements   = {`add-to-event-or-experience-section-${listing.id}`}
                                                 data-target_hidden_cancel_btn = {`cancel-add-to-event-or-experience-btn-${listing.id}`}
                                                 data-target_action_btn        = {`add-to-event-or-experience-btn-${listing.id}`}
                                                 data-winelist_id = {listing.id}
                                                 data-action      = "add_to_event_experience"
                                                 onClick = {(e) => self.manageAddWinelistToEventOrExperience(e)}
                                                 > confirm </button>
                                       </div>

                                     </div>


                                   </div>
                                 </div>

                               </div> {/*end card row row*/}

                             </div>

                           </li>
                           ))}
                       </ul>
                     </div>
                   </div>
                 </div>
              </div> {/*End row element*/}


             </div>
            </div>
          </div>










           : <fragment>
            <div class = "my-dashboard-page">
              {!isLoadingAnEvent ?
                [

                  <div>
                    <div class = "dashboard-containeer">
                      <div class = "form dashboard-form"  id = "my-winelist-summary-form">

                      <div class = "row dashboard-row-wrapper">
                        <div class ="col-sm-10 col-md-10 col-lg-10">
                          <span>
                            <h1 class = "my-dashboard__title_text">Manage Winelist</h1>
                            <div class = "my-dashboard__subtitle"> A strapline </div>
                          </span>
                        </div>
                        <div class ="col-sm-2 col-md-2 col-lg-2">

                          <span>
                            <button onClick = {(e) => self.handleCreate(e)}  id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                              <span class="bui-button__text">Update</span>
                            </button>
                          </span>

                        </div>
                      </div> {/*End row element*/}

                      <div class = "row dashboard-row-wrapper">
                        <div class ="col-sm-2 col-md-2 col-lg-2">
                          <div class = "dashboard-row-title"  >Wine List Title</div>
                        </div>
                        <div class ="col-sm-8 col-md-8 col-lg-8">
                          <span>

                            {self.state.wineListData.wineListName}



                                <label class = "dashboard__input__text__label" for = "wineListName">
                                  <input value = {self.state.wineListName}
                                         onChange={this.handleInputChange}
                                         id = "wineListName-editMode-form"
                                         name = "wineListName" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "wineListName"
                                         placeholder = "Wine List title"></input>
                                </label>



                          </span>
                        </div>
                        <div class ="col-sm-2 col-md-2 col-lg-2">
                          <span>
                            <button onClick = {(e) => self.editEventItem(e)} id = "wineListName-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                              <span class="bui-button__text">Edit</span>
                            </button>
                          </span>

                          <span><button onClick = {(e) => self.handleSave(e)} id = "wineListName-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                          <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "wineListName-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                        </div>
                      </div> {/*End row element*/}


                      <div class = "row dashboard-row-wrapper">
                        <div class ="col-sm-2 col-md-2 col-lg-2">
                          <div class = "dashboard-row-title"  > Wine List Description</div>
                        </div>
                        <div class ="col-sm-8 col-md-8 col-lg-8">
                          <span>

                            {self.state.wineListData.wineListdescription}



                                <label class = "dashboard__input__text__label" for = "wineListdescription">
                                  <textarea value = {self.state.wineListdescription}
                                            onChange={this.handleInputChange}
                                            id = "wineListdescription-editMode-form"
                                            name = "wineListdescription" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "wineListdescription"
                                            placeholder = "Describe your wine list"></textarea>
                                </label>


                              <div class = "dashboard-input-information-caption">
                                Please provide a description of the wines on this list.
                              </div>

                          </span>
                        </div>
                        <div class ="col-sm-2 col-md-2 col-lg-2">
                          <span>
                            <button onClick = {(e) => self.editEventItem(e)}  id = "wineListdescription-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                              <span class="bui-button__text">Edit</span>
                            </button>
                          </span>

                          <span><button onClick = {(e) => self.handleSave(e)}            id = "wineListdescription-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                          <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "wineListdescription-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                        </div>
                      </div> {/*End row element*/}


                      <div class = "row dashboard-row-wrapper">
                        <div class ="col-sm-2 col-md-2 col-lg-2">
                          <div class = "dashboard-row-title"  >Wines</div>
                        </div>
                        <div class ="col-sm-8 col-md-8 col-lg-8">
                          <span>

                          <ul class = "my-dashboard-cards">
                             {/*{self.state.wineListData.wines.map((wineToDisplay, index) => (*/}
                             {self.props.winelist.wineList[self.state.wineListToDisplay].wines.map((wineToDisplay, index) => (
                               <li key = {index}>
                                   {/*console.log(self.state.wineListData.id)*/}
                                   <a class = "wineListItem"  style={{ textDecoration: 'none', textAlign: 'left'}} href ={"/wine-profile/" + wineToDisplay.wine.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s{2,}/g," ").split(" ").join("-").toLowerCase() + "?wine-list=" + self.state.wineListData.id + "&wine=" + wineToDisplay.id }>
                                     {wineToDisplay.wine}
                                   </a>
                               </li>
                             ))}
                           </ul>

                            <div class = "dashboard-input-information-caption">
                              Click on a wine to view and edit tasting notes and pricing information. Alternatively add a wine to the list.
                            </div>


                          </span>
                        </div>
                        <div class ="col-sm-2 col-md-2 col-lg-2">
                          <span>
                            <button onClick = {(e) => self.toggleAddWineMenu(e)} id = "wines-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                              <span class="bui-button__text">Add</span>
                            </button>
                          </span>

                        </div>
                      </div> {/*End row element*/}

                      {self.state.displayWineMenu ?
                        <div>
                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-10 col-md-10 col-lg-10">
                              <span>
                                <h1 class = "my-dashboard__title_text">Add Wine Details </h1>
                                <div class = "my-dashboard__subtitle"> The Wine name must be included before you save the wine to the list. </div>
                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">

                              <span>
                                <button id = "submit-wine-to-list__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                                  <span class="bui-button__text">Submit</span>
                                </button>
                              </span>
                              <span>
                                <button onClick={(e) => self.cancelAddWineToList(e)} id = "cancel-submit-wine-to-list__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined" type = "button">
                                  <span class="bui-button__text">Cancel</span>
                                </button>
                              </span>

                            </div>
                          </div> {/*End row element*/}

                          <div class = "row dashboard-row-wrapper">
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                              <div class = "dashboard-row-title"  >Wine</div>
                            </div>
                            <div class ="col-sm-8 col-md-8 col-lg-8">
                              <span>


                                    <label class = "dashboard__input__text__label" for = "wine">
                                      <input value = {self.state.wine}
                                             onChange={(e) => self.addWineDetails(e)}
                                             id   = "wine-editMode-form"
                                             data-target_save_btn = "wine-editMode-save"
                                             name = "wine"
                                             class="dashboard__select__button__style dashboard__input__text__control"
                                             type = "text"
                                             if = "wine"
                                             placeholder = "Wine name"></input>

                                    </label>





                              </span>
                            </div>
                            <div class ="col-sm-2 col-md-2 col-lg-2">
                            <span><button onClick={(e) => self.saveWineDetails(e)}
                                          id = "wine-editMode-save"
                                          class= "bui-button bui-button-tertiary-danger hidden"
                                          type = "button" ><span> Save </span></button></span>
                            </div>
                          </div> {/*End row element*/}


                        </div>

                        : [<div></div>]
                      }


                      </div>
                    </div>
                  </div>




              ]  : [<div></div>]
              }

            </div>


          </fragment> : <fragment>
          </fragment>
    );
  }
}

class WineListsClass_Depricated extends Component{

  constructor(props) {
    super(props);

    this.state = {
      wineList:[],
      isLoading: true,
      isLoadingAnEvent: true,
      isEditing: false,
      wineListData: {},

      // variables for uploading data
      wineListName: null,
      wineListdescription: null,

      // variables for handling post data
      udpateFieldName: '',
      updateFieldValue: null,
      updatePostData  : {},

      // manage a new wine Entry
      displayWineMenu: false,
      updateWineFormFieldName: '',
      updateWineFormFieldValue: null,
      updateWineFormPostData: {},
      isLoadingWineRegionDetails: true,
      wineRegionData: null,
      countryMap    : [],
      regionMap     : [],
      subRegionMap  : [],
      siteMap       : [],
      parcelMap     : [],

      acidity: null,
      alcohol: null,
      appearance: null,
      appelation: null,
      body: null,
      colorIntensity: null,
      country: null,
      drinkingTime: null,
      endToDrink: null,
      flavourIntensity: null,
      length: null,
      mouse: null,
      nose: null,
      noseintensity: null,
      pairWith: null,
      palate: null,
      producer: null,
      quality: null,
      readinessToDrink: null,
      region: null,
      startToDrink: null,
      sweetness: null,
      tanin: null,
      vintage: null,
      wine: null,
      subRegion: null,
      site: null,
      parcel: null,


      // Pagination variables
      currentItemsList: [],
      currentPage: 0,
      listingsPerPage: 3,
      firtListItemIndx: 0,
      lastListItemIndx: 3,
      numberOfListings: 10,
      pageNumbers:[]


    }

    this.fetchWineList = this.fetchWineList.bind(this)
    this.pagination      = this.pagination.bind(this)
    this.manageEvent     = this.manageEvent.bind(this)
    this.handleInputChange   = this.handleInputChange.bind(this)
    this.editEventItem       = this.editEventItem.bind(this)
    this.handleSave          = this.handleSave.bind(this)
    this.cancelEditEventItem = this.cancelEditEventItem.bind(this)
    this.clearFormElements   = this.clearFormElements.bind(this)
    this.toggleAddWineMenu   = this.toggleAddWineMenu.bind(this)
    this.addWineToList       = this.addWineToList.bind(this)
    this.cancelAddWineToList = this.cancelAddWineToList.bind(this)

    // wine form
    this.handleWineFormChange   = this.handleWineFormChange.bind(this)
    this.fetchWineRegionDetails = this.fetchWineRegionDetails.bind(this)
    this.onlyUnique             = this.onlyUnique.bind(this)


  }

  /*export class Experiences extends Component{*/
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    winelist: PropTypes.func.isRequired,
  };

  componentDidMount() {

    this.fetchWineList()
    store.dispatch(getWineList());



  }

  componentDidUpdate(){

    this.fetchWineList()

  }

  pagination(){
    var pageNumbersArray = []
    for(let i = 1; i <= Math.ceil(this.numberOfListings / this.listingsPerPage); i++ )
    {
      pageNumbersArray.push(i)
    }
    this.setState({
      pageNumbers : pageNumbersArray
    })
  }

  fetchWineList() {

    var url = "https://www.therealgrape.com/api/auth/business-profile/my-winelist-listing"
    // var url = "http://127.0.0.1:8000/api/auth/business-profile/my-winelist-listing"


    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => {
        console.log("Fetch winelist data: ", response.data)
        this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
          wineList: response.data,
          isLoading: false,
        })



      })
      .catch((error)   => console.log(error));


  }

  fetchWineRegionDetails() {

    var countryMapData   = []
    var regionMapData    = []
    var subRegionMapData = []
    var siteMapData = []
    var parcelMapData =[]

    //var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-region-details"+"/COUNTRY=France"
    //var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-region-details"+"/COUNTRY=*"
    var url = "https://www.therealgrape.com/api/auth/business-profile/wine-region-details"+"/COUNTRY=*"

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    axios.get(url, config)
      .then((response) => { this.setState({
      /*.then((response) => {console.log(response); this.setState({*/
        wineRegionData: response.data,
        isLoadingWineRegionDetails: false,
        })

      /* create an array of country data*/
      this.state.wineRegionData.map((cntryData) =>
        countryMapData.push({"COUNTRY_CODE": cntryData.COUNTRY_CODE, "COUNTRY": cntryData.COUNTRY})
      )

      /* create an array of region data*/
      this.state.wineRegionData.map((regionData) =>
        regionMapData.push({"REGION_CODE": regionData.REGION_CODE, "REGION": regionData.REGION})
      )

      /*create an array of subregion data*/
      this.state.wineRegionData.map((subRegionData) =>
        subRegionMapData.push({"SUB_REGION_CODE": subRegionData.SUB_REGION_CODE, "SUB_REGION": subRegionData.SUB_REGION})
      )

      /*create an array of site data*/
      this.state.wineRegionData.map((siteData) =>
        siteMapData.push({"SITE_CODE": siteData.SITE_CODE, "SITE": siteData.SITE})
      )

      /*create an array of parcel data*/
      this.state.wineRegionData.map((parcelData) =>
        parcelMapData.push({"PARCEL_CODE": parcelData.PARCEL_CODE, "PARCEL": parcelData.PARCEL})
      )

      const uniqueWineCountries  = getUnique(countryMapData, 'COUNTRY')
      const uniqueWineRegions    = getUnique(regionMapData, 'REGION')
      const uniqueWineSubRegions = getUnique(subRegionMapData, 'SUB_REGION')
      const uniqueWineSites      = getUnique(siteMapData, 'SITE')
      const uniqueWineParcels    = getUnique(parcelMapData, 'PARCEL')

      this.setState({
        countryMap    : uniqueWineCountries,
        regionMap     : uniqueWineRegions,
        subRegionMap  : uniqueWineSubRegions,
        siteMap       : uniqueWineSites,
        parcelMap     : uniqueWineParcels,
      })


      })
      .catch((error)   => console.log(error));




  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  manageEvent(itemToEdit){


    this.setState({
      isEditing: true,
      isLoadingAnEvent: true,
    })


        var url = "https://www.therealgrape.com/api/auth/business-profile/wine-list-details/"+itemToEdit.toString()
        // var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-list-details/"+itemToEdit.toString()



        // GET token from state
        const token = this.props.auth.token;

        // set up the header for the get calls
        const config = {
          headers: {
            'Content-type': 'application/json',
          }
        }

        // // Provide the authorisation token
        if(token) {
          config.headers['Authorization'] = `Token ${token}`
        }

        axios.get(url, config)
          .then((response) => { this.setState({
          /*.then((response) => {console.log(response); this.setState({*/
            wineListData: response.data,
            isLoadingAnEvent: false,
            })})
          .catch((error)   => console.log(error));



  }

  handleInputChange(event){

    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    // handle the create button
    var createBtn     = document.getElementById('create-event__create_button')

    this.setState({
      [name]: value,
      updateFieldName: name,
      updateFieldValue: value,
    })

    var postData   = this.state.updatePostData
    postData[name] = value
    this.setState({
      updatePostData: postData
    })

  }
  editEventItem(event) {

    event.preventDefault()

    const elementId     =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''


    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide form element, save button and camcel button
    formElement.classList.remove("hidden");
    saveBtn.classList.remove("hidden");
    cancelBtn.classList.remove("hidden");

    // hide the edit button
    editBtn.classList.add("hidden");


  }
  handleSave(event) {

    event.preventDefault()

    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-save", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    if (this.state.updateFieldValue != null)
    {

      var field2Update         = this.state.udpateFieldName
      const constUpdateValue   = this.state.updateFieldValue
      const postDataFinal      = this.state.updatePostData

      var postData = {}
      postData[field2Update] = constUpdateValue

      var url = "https://www.therealgrape.com/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"
      // var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"


      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now we need to stringfy the field that we want to provide
      // Request body

      const body = JSON.stringify(postDataFinal);

      // now post the Data
      axios.post(url, body, config)
      .then()

      // reset the state variables for field updates to null
      this.setState({
        udpateFieldName: '',
        updateFieldValue: null,
        editing:false
      })

      // clear anything that is currently in the updatePostData

      this.setState({
          updatePostData : {}
      })

      // return all form data to original state

      this.setState({
        wineListName: null,
        wineListdescription: null,
      })

    }

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");

    // clear the form elements
    this.clearFormElements()

    // refresh form data
    this.manageEvent(this.state.wineListData.id)


  }
  cancelEditEventItem(event) {

    /* handle the cancel button*/
    // start by getting the id "member.title-editMode-cancel"
    var elementId =  event.currentTarget.id;
    var formElementId   = ''
    var saveElementId   = ''
    var cancelElementId = ''

    // remove the cancel from the end of the id
    elementId     = elementId.replace("-cancel", "")

    // find the associated form elements and buttons
    formElementId   = elementId + "-form"
    saveElementId   = elementId + "-save"
    cancelElementId = elementId + "-cancel"

    var editBtn     = document.getElementById(elementId)
    var formElement = document.getElementById(formElementId)
    var saveBtn     = document.getElementById(saveElementId)
    var cancelBtn   = document.getElementById(cancelElementId)

    // unhide the edit button
    editBtn.classList.remove("hidden");

    // hide form element, save button and camcel button
    formElement.classList.add("hidden");
    saveBtn.classList.add("hidden");
    cancelBtn.classList.add("hidden");


    // clear anything that is currently in the updatePostData

    this.setState({
        updatePostData : {}
    })

    // return all form data to original state

    this.setState({

        wineListName: null,
        wineListdescription: null,


    })

    // clear all form elements
    this.clearFormElements()

  }
  clearFormElements() {
    document.getElementById("wineListName-editMode-form").value = ""
  }

  toggleAddWineMenu(e) {
    e.preventDefault()

    this.setState({
        displayWineMenu: true
    })

    // fetch the details of the regions i.e. Country -> Region -> Subregion -> Site ->  Plot  map
    this.fetchWineRegionDetails()

  }
  addWineToList(e) {
    e.preventDefault()
  }

  cancelAddWineToList(e) {
    e.preventDefault()
    this.setState({
        displayWineMenu: false,
        isLoadingWineRegionDetails: false,
    })

    this.setState({
      acidity: null,
      alcohol: null,
      appearance: null,
      appelation: null,
      body: null,
      colorIntensity: null,
      country: null,
      drinkingTime: null,
      endToDrink: null,
      flavourIntensity: null,
      length: null,
      mouse: null,
      nose: null,
      noseintensity: null,
      pairWith: null,
      palate: null,
      producer: null,
      quality: null,
      readinessToDrink: null,
      region: null,
      startToDrink: null,
      sweetness: null,
      tanin: null,
      vintage: null,
      wine: null
    })

    //this.forceUpdate();

  }

  handleWineFormChange(event){

    const target = event.target;
    const value  = target.type === 'checkbox' ? target.checked : target.value;
    const name   = target.name

    this.setState({
      [name]: value,
      updateWineFormFieldName: name,
      updateWineFormFieldValue: value,
    })

    var postData   = this.state.updateWineFormPostData
    postData[name] = value
    this.setState({
      updateWineFormPostData: postData
    })



  }

  handleSaveWineDetails(event) {

    event.preventDefault()



    if (this.state.updateWineFormFieldValue != null)
    {

      var field2Update         = this.state.updateWineFormFieldName
      const constUpdateValue   = this.state.updateWineFormFieldValue
      const postDataFinal      = this.state.updateWineFormPostData
      postDataFinal['addWine'] = true

      var postData = {}
      postData[field2Update] = constUpdateValue



      var url = "https://www.therealgrape.com/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"
      // var url = "http://127.0.0.1:8000/api/auth/business-profile/wine-list-details-update/"+ this.state.wineListData.id.toString()+"/"



      // GET token from state
      const token = this.props.auth.token;

      // set up the header for the get calls
      const config = {
        headers: {
          'Content-type': 'application/json',
        }
      }

      // // Provide the authorisation token
      if(token) {
        config.headers['Authorization'] = `Token ${token}`
      }

      // now we need to stringfy the field that we want to provide
      // Request body

      const body = JSON.stringify(postDataFinal);

      // now post the Data
      axios.post(url, body, config)
      .then(
        store.dispatch(getWineList())
      )
      .catch((error)   => console.log(error));

      // reset the state variables for field updates to null
      this.setState({
        updateWineFormFieldName: '',
        updateWineFormFieldValue: null
      })

      // clear anything that is currently in the       updateWineFormPostData

      this.setState({
        updateWineFormPostData : {}
      })

      // return all form data to original state

      this.setState({
        /*acidity: null,
        alcohol: null,
        appearance: null,
        appelation: null,
        body: null,
        colorIntensity: null,
        country: null,
        drinkingTime: null,
        endToDrink: null,
        flavourIntensity: null,
        length: null,
        mouse: null,
        nose: null,
        noseintensity: null,
        pairWith: null,
        palate: null,
        producer: null,
        quality: null,
        readinessToDrink: null,
        region: null,
        startToDrink: null,
        sweetness: null,
        tanin: null,
        vintage: null,
        wine:null*/
      })

    }

    // refresh form data
    this.manageEvent(this.state.wineListData.id)
    this.forceUpdate();


  }

  render()
  {
    const { isAuthenticated, user } = this.props.auth;
    var             isLoading       = this.state.isLoading
    var      isLoadingAnEvent       = this.state.isLoadingAnEvent
    var             isEditing       = this.state.isEditing
    var                      self   = this

    // variables for pagination
    const indexOfLastListedItem     = this.state.currentPage * this.state.listingsPerPage
    const indexOfFirstListedItem    = indexOfLastListedItem  - this.state.listingsPerPage

    return(
      !isLoading ?
        !isEditing ?
          <fragment>


          <div class = "my-dashboard-page">
           <div class = "my-dashboard-flex-row">
             <div class = "container my-profile-container">
               <div class = "my-dashboard-container">
                 <div class = "my-dashboard-title-wrapper flex-wrapper">  {/*start dashboard element*/}
                     <span>
                       <h1 class = "my-dashboard__title_text"> My Wine Lists </h1>
                       <div class = "my-dashboard__subtitle"> Click on a wine list to make changes</div>
                     </span>
                  </div>


                  <ul class = "my-dashboard-cards">
                    {self.state.wineList.slice(self.state.firtListItemIndx,self.state.lastListItemIndx).map((listing, index) => (
                      <li key={`listing-${index}`} onClick={() => self.manageEvent(listing.id)}>
                        <div>
                          <div class = "my-dashboard-cards__acard">

                            {/*Card Body*/}
                            <div class = "my-dashboard-cards__acard__card_body">

                              <div class ="my-dashboard-cards__acard__title_elements">
                                <h1 class = "my-dashboard-cards__acard__title_elements__title"> {index}. {listing.wineListName} </h1>
                              </div>

                              <div class ="my-dashboard-cards__acard__title_elements">
                                <span class ="my-dashboard-cards__acard__event_status_title">
                                  Status:
                                </span>
                                <div class = "my-dashboard-cards__acard__event_status_status">
                                  {listing.status}
                                </div>




                              </div>

                              <div class ="my-dashboard-cards__acard__title_elements">

                              <span class ="my-dashboard-cards__acard__event_status_title">
                                Created Date:
                              </span>
                              <div class = "my-dashboard-cards__acard__event_status_status">
                                {dateFormatterFromString(listing.date_created, "00:00:00") }
                              </div>




                              </div>

                              <div class ="my-dashboard-cards__acard__title_elements">
                                <span class ="my-dashboard-cards__acard__event_status_title">
                                  Winelist Description:
                                </span>
                                <div class = "my-dashboard-cards__acard__event_status_status">
                                  {listing.wineListdescription}
                                </div>
                              </div>

                              <div class ="my-dashboard-cards__acard__title_elements">
                                <span class ="my-dashboard-cards__acard__event_status_title">
                                  Number of wines
                                </span>
                                <div class = "my-dashboard-cards__acard__event_status_status">
                                  Unknown
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </li>
                      ))}
                  </ul>

                  {/* Render the listings*/}
                  {/*start by getting current listings*/}
                  {/*  <ul className = "list-group mb-4">
                     {self.state.wineList.slice(self.state.firtListItemIndx,self.state.lastListItemIndx).map((listing, index) => (
                       <li key={`listing-${index}`} className = "list-group-item">{listing.wineListName} </li>
                     ))}
                    </ul>*/}

                  <div>
                    {console.log(this.state.wineList)}
                  </div>




               </div>
              </div>
            </div>
          </div>








          </fragment> : <fragment>
            <div class = "my-dashboard-page">
              {!isLoadingAnEvent ?
                [<div class = "my-dashboard-flex-row">
                  <div class = "container my-profile-container">
                    <div class = "my-dashboard-container">
                      <div class = "my-dashboard-title-wrapper flex-wrapper">  {/*start dashboard element*/}
                        <div style={{flex:8}}>
                            <span>
                              <h1 class = "my-dashboard__title_text"> Manage Winelist </h1>
                              <div class = "my-dashboard__subtitle"> A strapline </div>

                            </span>
                          </div>
                          <div style={{flex:1}}>
                            <span>
                              <button onClick = {(e) => self.handleCreate(e)}  id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                                <span class="bui-button__text">Update</span>
                              </button>
                            </span>

                          </div>


                       </div>{/*End header section*/}
                       <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                         <div style={{flex:2}}><span> Wine List Title </span></div>
                         <div style={{flex:6}}>
                           <span>

                             {self.state.wineListData.wineListName}



                                 <label class = "dashboard__input__text__label" for = "wineListName">
                                   <input value = {self.state.wineListName}
                                          onChange={this.handleInputChange}
                                          id = "wineListName-editMode-form"
                                          name = "wineListName" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "wineListName"
                                          placeholder = "Wine List title"></input>
                                 </label>



                           </span></div>
                         <div style={{flex:1}}>
                           <span>
                             <button onClick = {(e) => self.editEventItem(e)} id = "wineListName-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                               <span class="bui-button__text">Edit</span>
                             </button>
                           </span>

                           <span><button onClick = {(e) => self.handleSave(e)} id = "wineListName-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                           <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "wineListName-editMode-cancel" class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                         </div>
                       </div>{/*end dashboard element*/}

                       <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                         <div style={{flex:2}}><span> Wine List Description </span></div>
                         <div style={{flex:6}}>
                           <span>

                             {self.state.wineListData.wineListdescription}



                                 <label class = "dashboard__input__text__label" for = "wineListdescription">
                                   <textarea value = {self.state.wineListdescription}
                                             onChange={this.handleInputChange}
                                             id = "wineListdescription-editMode-form"
                                             name = "wineListdescription" class="dashboard__select__button__style dashboard__input__text__control hidden" type = "text" if = "wineListdescription"
                                             placeholder = "Describe your wine list"></textarea>
                                 </label>


                               <div class = "dashboard-input-information-caption">
                                 Please provide a description of the wines on this list.
                               </div>

                           </span></div>
                         <div style={{flex:1}}>
                           <span>
                             <button onClick = {(e) => self.editEventItem(e)}  id = "wineListdescription-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                               <span class="bui-button__text">Edit</span>
                             </button>
                           </span>

                           <span><button onClick = {(e) => self.handleSave(e)}            id = "wineListdescription-editMode-save" class= "bui-button bui-button-tertiary-danger hidden"  type = "button" ><span> Save </span></button></span>
                           <span><button onClick = {(e) => self.cancelEditEventItem(e)} id = "wineListdescription-editMode-cancel"       class = "bui-button bui-button-tertiary-danger hidden" type = "button" ><span>Cancel</span></button></span>

                         </div>
                       </div>{/*end dashboard element*/}


                       <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                         <div style={{flex:2}}><span> Wines </span></div>
                         <div style={{flex:6}}>
                           <span>

                           <ul class = "my-dashboard-cards">
                              {/*{self.state.wineListData.wines.map((wineToDisplay, index) => (*/}
                              {this.props.winelist.wineList[0].wines.map((wineToDisplay, index) => (
                                <li key = {index}>
                                  {wineToDisplay.wine}
                                </li>
                              ))}
                            </ul>

                             <div class = "dashboard-input-information-caption">
                                Click on a wine to view and edit tasting notes and pricing information. Alternatively add a wine to the list.
                             </div>


                           </span></div>
                         <div style={{flex:1}}>
                           <span>
                             <button onClick = {(e) => self.toggleAddWineMenu(e)} id = "wines-editMode" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary" type = "button">
                               <span class="bui-button__text">Add</span>
                             </button>
                           </span>

                         </div>
                       </div>{/*end dashboard element*/}

                       {/*render the add wine menu*/}
                       {self.state.displayWineMenu ?
                         <div>
                           <div class = "my-dashboard-title-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:8}}>
                                 <span>
                                   <h1 class = "my-dashboard__title_text"> Add Wine Details </h1>
                                   <div class = "my-dashboard__subtitle"> The Wine name, producer and country must be included before you can add the wine to the list.</div>
                                  {console.log(this.props.winelist.wineList[0].wines)}
                                 </span>
                               </div>
                               <div style={{flex:1}}>
                                 <span>
                                   <button id = "submit-wine-to-list__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                                     <span class="bui-button__text">Submit</span>
                                   </button>
                                 </span>
                                 <span>
                                   <button onClick={(e) => self.cancelAddWineToList(e)} id = "cancel-submit-wine-to-list__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined" type = "button">
                                     <span class="bui-button__text">Cancel</span>
                                   </button>
                                 </span>
                               </div>
                           </div>

                           <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:2}}><span> Wine </span></div>
                             <div style={{flex:6}}>
                               <span>


                                     <label class = "dashboard__input__text__label" for = "wine">
                                       <input value = {self.state.wine}
                                              onChange={this.handleWineFormChange}
                                              id = "wine-editMode-form"
                                              name = "wine" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "wine"
                                              placeholder = "Wine name"></input>
                                     </label>



                               </span></div>
                             <div style={{flex:1}}>


                               <span><button onClick={(e) => self.handleSaveWineDetails(e)}
                                             id = "wine-editMode-save"
                                             class= "bui-button bui-button-tertiary-danger"
                                             type = "button" ><span> Save </span></button></span>


                             </div>
                           </div>{/*end dashboard element*/}

                           <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:2}}><span> Producer </span></div>
                             <div style={{flex:6}}>
                               <span>


                                     <label class = "dashboard__input__text__label" for = "producer">
                                       <input value = {self.state.producer}
                                              onChange={this.handleWineFormChange}
                                              id = "producer-editMode-form"
                                              name = "producer" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "producer"
                                              placeholder = "Producer"></input>
                                     </label>



                               </span></div>
                             <div style={{flex:1}}>


                               <span><button onClick={(e) => self.handleSaveWineDetails(e)}
                                             id = "producer-editMode-save"
                                             class= "bui-button bui-button-tertiary-danger"
                                             type = "button" ><span> Save </span></button></span>


                             </div>
                           </div>{/*end dashboard element*/}

                           <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:2}}><span> Country </span></div>
                             <div style={{flex:6}}>
                               <span>

                                    {/*console.log(self.state.wineRegionData)*/}
                                    {/*self.state.wineRegionData ? console.log(self.state.wineRegionData) : console.log("")*/}
                                    {/*console.log(self.state.countryMap)*/}
                                    {/*this.setState({
                                      countryMap    : uniqueWineCountries,
                                      regionMap     : uniqueWineRegions,
                                      subRegionMap  : uniqueWineSubRegions,
                                      siteMap       : uniqueWineSites,
                                      parcelMap     : uniqueWineParcels,
                                    })

                                    country: null,
                                    region: null,
                                    subRegion: null,
                                    site: null,
                                    parcel: null,*/}


                                     <label class = "dashboard__input__text__label" for = "country">
                                       <input value = {self.state.country}
                                              onChange={this.handleWineFormChange}
                                              id = "country-editMode-form"
                                              name = "country" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "country"
                                              placeholder = "Country"></input>
                                     </label>

                                     <div class = "dashboard__filter-bar">
                                      <ul class = "my-dashboard-cards">
                                        {self.state.wineRegionData ? self.state.countryMap.map((regionInfo) =>
                                          <li key ={regionInfo.COUNTRY_CODE}>
                                            <label class = "filter-bar-label">
                                              {/*<input type="radio"/>*/}
                                                {regionInfo.COUNTRY}
                                            </label>
                                          </li>

                                        ) : <></>}
                                      </ul>
                                    </div>




                               </span></div>
                             <div style={{flex:1}}>


                               <span><button onClick={(e) => self.handleSaveWineDetails(e)}
                                             id = "country-editMode-save"
                                             class= "bui-button bui-button-tertiary-danger"
                                             type = "button" ><span> Save </span></button></span>


                             </div>
                           </div>{/*end dashboard element*/}

                           <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:2}}><span> Region </span></div>
                             <div style={{flex:6}}>
                               <span>

                                    {/*console.log(self.state.wineRegionData)*/}
                                    {/*self.state.wineRegionData ? console.log(self.state.wineRegionData) : console.log("")*/}
                                    {/*console.log(self.state.countryMap)*/}
                                    {/*this.setState({
                                      countryMap    : uniqueWineCountries,
                                      regionMap     : uniqueWineRegions,
                                      subRegionMap  : uniqueWineSubRegions,
                                      siteMap       : uniqueWineSites,
                                      parcelMap     : uniqueWineParcels,
                                    })

                                    country: null,
                                    region: null,
                                    subRegion: null,
                                    site: null,
                                    parcel: null,*/}


                                     <label class = "dashboard__input__text__label" for = "region">
                                       <input value = {self.state.region}
                                              onChange={this.handleWineFormChange}
                                              id = "region-editMode-form"
                                              name = "region" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "region"
                                              placeholder = "Region"></input>
                                     </label>

                                     <div class = "dashboard__filter-bar">
                                      <ul class = "my-dashboard-cards">
                                        {self.state.wineRegionData ? self.state.regionMap.map((regionInfo) =>
                                          <li key ={regionInfo.REGION_CODE}>
                                            <label class = "filter-bar-label">
                                              {/*<input type="radio"/>*/}
                                                {regionInfo.REGION}
                                            </label>
                                          </li>

                                        ) : <></>}
                                      </ul>
                                    </div>




                               </span></div>
                             <div style={{flex:1}}>


                               <span><button onClick={(e) => self.handleSaveWineDetails(e)}
                                             id = "region-editMode-save"
                                             class= "bui-button bui-button-tertiary-danger"
                                             type = "button" ><span> Save </span></button></span>


                             </div>
                           </div>{/*end dashboard element*/}

                           <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:2}}><span> Sub-region </span></div>
                             <div style={{flex:6}}>
                               <span>

                                    {/*console.log(self.state.wineRegionData)*/}
                                    {/*self.state.wineRegionData ? console.log(self.state.wineRegionData) : console.log("")*/}
                                    {/*console.log(self.state.countryMap)*/}
                                    {/*this.setState({
                                      countryMap    : uniqueWineCountries,
                                      regionMap     : uniqueWineRegions,
                                      subRegionMap  : uniqueWineSubRegions,
                                      siteMap       : uniqueWineSites,
                                      parcelMap     : uniqueWineParcels,
                                    })

                                    country: null,
                                    region: null,
                                    subRegion: null,
                                    site: null,
                                    parcel: null,*/}


                                     <label class = "dashboard__input__text__label" for = "subRegion">
                                       <input value = {self.state.subRegion}
                                              onChange={this.handleWineFormChange}
                                              id = "subRegion-editMode-form"
                                              name = "subRegion" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "subRegion"
                                              placeholder = "Sub-region"></input>
                                     </label>

                                     <div class = "dashboard__filter-bar">
                                      <ul class = "my-dashboard-cards">
                                        {self.state.wineRegionData ? self.state.subRegionMap.map((regionInfo) =>
                                          <li key ={regionInfo.SUB_REGION_CODE}>
                                            <label class = "filter-bar-label">
                                              {/*<input type="radio"/>*/}
                                                {regionInfo.SUB_REGION}
                                            </label>
                                          </li>

                                        ) : <></>}
                                      </ul>
                                    </div>




                               </span></div>
                             <div style={{flex:1}}>


                               <span><button onClick={(e) => self.handleSaveWineDetails(e)}
                                             id = "subRegion-editMode-save"
                                             class= "bui-button bui-button-tertiary-danger"
                                             type = "button" ><span> Save </span></button></span>


                             </div>
                           </div>{/*end dashboard element*/}

                           <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:2}}><span> Site </span></div>
                             <div style={{flex:6}}>
                               <span>

                                    {/*console.log(self.state.wineRegionData)*/}
                                    {/*self.state.wineRegionData ? console.log(self.state.wineRegionData) : console.log("")*/}
                                    {/*console.log(self.state.countryMap)*/}
                                    {/*this.setState({
                                      countryMap    : uniqueWineCountries,
                                      regionMap     : uniqueWineRegions,
                                      subRegionMap  : uniqueWineSubRegions,
                                      siteMap       : uniqueWineSites,
                                      parcelMap     : uniqueWineParcels,
                                    })

                                    country: null,
                                    region: null,
                                    subRegion: null,
                                    site: null,
                                    parcel: null,*/}


                                     <label class = "dashboard__input__text__label" for = "site">
                                       <input value = {self.state.subRegion}
                                              onChange={this.handleWineFormChange}
                                              id = "site-editMode-form"
                                              name = "site" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "site"
                                              placeholder = "Site"></input>
                                     </label>

                                     <div class = "dashboard__filter-bar">
                                      <ul class = "my-dashboard-cards">
                                        {self.state.wineRegionData ? self.state.siteMap.map((regionInfo) =>
                                          <li key ={regionInfo.SITE_CODE}>
                                            <label class = "filter-bar-label">
                                              {/*<input type="radio"/>*/}
                                                {regionInfo.SITE}
                                            </label>
                                          </li>

                                        ) : <></>}
                                      </ul>
                                    </div>




                               </span></div>
                             <div style={{flex:1}}>


                               <span><button onClick={(e) => self.handleSaveWineDetails(e)}
                                             id = "site-editMode-save"
                                             class= "bui-button bui-button-tertiary-danger"
                                             type = "button" ><span> Save </span></button></span>


                             </div>
                           </div>{/*end dashboard element*/}

                           <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                             <div style={{flex:2}}><span> Parcel </span></div>
                             <div style={{flex:6}}>
                               <span>

                                    {/*console.log(self.state.wineRegionData)*/}
                                    {/*self.state.wineRegionData ? console.log(self.state.wineRegionData) : console.log("")*/}
                                    {/*console.log(self.state.countryMap)*/}
                                    {/*this.setState({
                                      countryMap    : uniqueWineCountries,
                                      regionMap     : uniqueWineRegions,
                                      subRegionMap  : uniqueWineSubRegions,
                                      siteMap       : uniqueWineSites,
                                      parcelMap     : uniqueWineParcels,
                                    })

                                    country: null,
                                    region: null,
                                    subRegion: null,
                                    site: null,
                                    parcel: null,*/}


                                     <label class = "dashboard__input__text__label" for = "parcel">
                                       <input value = {self.state.parcel}
                                              onChange={this.handleWineFormChange}
                                              id = "parcel-editMode-form"
                                              name = "parcel" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "parcel"
                                              placeholder = "Parcel"></input>
                                     </label>

                                     <div class = "dashboard__filter-bar">
                                      <ul class = "my-dashboard-cards">
                                        {self.state.wineRegionData ? self.state.parcelMap.map((regionInfo) =>
                                          <li key ={regionInfo.REGION_CODE}>
                                            <label class = "filter-bar-label">
                                              {/*<input type="radio"/>*/}
                                                {regionInfo.PARCEL}
                                            </label>
                                          </li>

                                        ) : <></>}
                                      </ul>
                                    </div>




                               </span></div>
                             <div style={{flex:1}}>


                               <span><button onClick={(e) => self.handleSaveWineDetails(e)}
                                             id = "parcel-editMode-save"
                                             class= "bui-button bui-button-tertiary-danger"
                                             type = "button" ><span> Save </span></button></span>


                             </div>
                           </div>{/*end dashboard element*/}

                         </div>




                        : [<div></div>]
                       }

                    </div>
                  </div>
                </div>]  : [<div></div>]
              }

            </div>


          </fragment> : <fragment>
          </fragment>
    );
  }
}

class CreateWineListUnconnected extends Component {

  constructor(props) {
    super(props);
      this.state = {
        wineListName: null,
        wineListdescription: null
      }

      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleCreate      = this.handleCreate.bind(this)
      this.clearFormElements = this.clearFormElements.bind(this)

  }

  static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

  handleInputChange(event){
   const target = event.target;
   const value  = target.type === 'checkbox' ? target.checked : target.value;
   const name   = target.name

   // handle the create button
   var createBtn     = document.getElementById('create-event__create_button')

   this.setState({
     [name]: value
   })

   // if an event name is provided then unhide buttons
   if (name === "wineListName")
   {

     createBtn.classList.remove("hidden");
   }


  }

   handleCreate(event) {

     event.preventDefault()

     // handle the create button
     var createBtn     = document.getElementById('create-event__create_button')

     // capture the post data
     var postData = {}
     postData['wineListName']    = this.state.wineListName
     postData['wineListdescription'] = this.state.wineListdescription


     var url = "https://www.therealgrape.com/api/auth/business-profile/create-wine-list"
     // var url = "http://127.0.0.1:8000/api/auth/business-profile/create-wine-list"

     // GET token from state
     const token = this.props.auth.token;


     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     // now we need to stringfy the field that we want to provide
     // Request body

     const body = JSON.stringify(postData);

     // now post the Data
     axios.post(url, body, config)
     .then()

     this.setState({
       wineListName: null,
       wineListdescription: null
     })

     this.clearFormElements()

     // hide the create event button
     createBtn.classList.add("hidden");

   }

   clearFormElements() {
     document.getElementById("create-wine-list__wineListName").value = null
     document.getElementById("create-wine-list__wineListdescription").value = null

   }

  render()
  {
    var     self   = this

    return(

      <div>
        <div class = "dashboard-containeer">
         <div class = "form dashboard-form"  id = "my-create-experience-form">

           <div class = "row dashboard-row-wrapper">
             <div class ="col-sm-10 col-md-10 col-lg-10">
               <span>
                 <h1 class = "my-dashboard__title_text"> Create Wine List  </h1>
                 <div class = "my-dashboard__subtitle"> Create a new wine list</div>
               </span>
             </div>

             <div class ="col-sm-2 col-md-2 col-lg-2">

               <span>
                 <button onClick = {(e) => self.handleCreate(e)} id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                   <span class="bui-button__text">Create</span>
                 </button>
               </span>

             </div>

           </div> {/*End row element*/}

           <div class = "row dashboard-row-wrapper">
             <div class ="col-sm-2 col-md-2 col-lg-2">
               <div class = "dashboard-row-title"  >Wine List Name</div>
             </div>
             <div class ="col-sm-8 col-md-8 col-lg-8">
               <span>

                     <label class = "dashboard__input__text__label" for = "wineListName">
                       <input value={this.state.wineListName}  onChange={this.handleInputChange}  id = "create-wine-list__wineListName"
                       name = "wineListName" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "wineListName"
                       placeholder = "Wine List Name"></input>
                     </label>

               </span>
             </div>
             <div class ="col-sm-2 col-md-2 col-lg-2">
               Button
             </div>
           </div> {/*End row element*/}

           <div class = "row dashboard-row-wrapper">
             <div class ="col-sm-2 col-md-2 col-lg-2">
               <div class = "dashboard-row-title"  >Wine List Description </div>
             </div>
             <div class ="col-sm-8 col-md-8 col-lg-8">
               <span>

                     <label class = "dashboard__input__text__label" for = "wineListdescription">
                       <textarea value={this.state.wineListdescription}  onChange={this.handleInputChange}  id = "create-wine-list__wineListdescription"
                       name = "wineListdescription" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "wineListdescription"
                       placeholder = "Wine List Description"></textarea>
                     </label>

               </span>
             </div>
             <div class ="col-sm-2 col-md-2 col-lg-2">

             </div>
           </div> {/*End row element*/}



         </div>
        </div>
      </div>



    )
  }

}
class CreateWineListUnconnectedDepricated extends Component {

  constructor(props) {
    super(props);
      this.state = {
        wineListName: null,
        wineListdescription: null
      }

      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleCreate      = this.handleCreate.bind(this)
      this.clearFormElements = this.clearFormElements.bind(this)

  }

  static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

  handleInputChange(event){
   const target = event.target;
   const value  = target.type === 'checkbox' ? target.checked : target.value;
   const name   = target.name

   // handle the create button
   var createBtn     = document.getElementById('create-event__create_button')

   this.setState({
     [name]: value
   })

   // if an event name is provided then unhide buttons
   if (name === "wineListName")
   {

     createBtn.classList.remove("hidden");
   }


  }

   handleCreate(event) {

     event.preventDefault()

     // handle the create button
     var createBtn     = document.getElementById('create-event__create_button')

     // capture the post data
     var postData = {}
     postData['wineListName']    = this.state.wineListName
     postData['wineListdescription'] = this.state.wineListdescription


     var url = "https://www.therealgrape.com/api/auth/business-profile/create-wine-list"
     // var url = "http://127.0.0.1:8000/api/auth/business-profile/create-wine-list"

     // GET token from state
     const token = this.props.auth.token;


     // set up the header for the get calls
     const config = {
       headers: {
         'Content-type': 'application/json',
       }
     }

     // // Provide the authorisation token
     if(token) {
       config.headers['Authorization'] = `Token ${token}`
     }

     // now we need to stringfy the field that we want to provide
     // Request body

     const body = JSON.stringify(postData);

     // now post the Data
     axios.post(url, body, config)
     .then()

     this.setState({
       wineListName: null,
       wineListdescription: null
     })

     this.clearFormElements()

     // hide the create event button
     createBtn.classList.add("hidden");

   }

   clearFormElements() {
     document.getElementById("create-wine-list__wineListName").value = null
     document.getElementById("create-wine-list__wineListdescription").value = null

   }

  render()
  {
    var     self   = this

    return(
      <fragment>

        <div class = "my-dashboard-page">
         <div class = "my-dashboard-flex-row">
           <div class = "container my-profile-container">
             <div class = "my-dashboard-container">

               <div class = "my-dashboard-title-wrapper flex-wrapper">  {/*start dashboard element*/}
                <div style={{flex:8}}>
                   <span>
                     <h1 class = "my-dashboard__title_text"> Create Wine List </h1>
                     <div class = "my-dashboard__subtitle"> Create a new wine list</div>

                   </span>
                  </div>
                  <div style={{flex:1}}>
                    <span>
                      <button onClick = {(e) => self.handleCreate(e)} id = "create-event__create_button" target ="_blank" aria-expanded="false" aria-controls = "" aria-describedby = "" aria-disabled = "false" class = "bui-button bui-button-tertiary-outlined hidden" type = "button">
                        <span class="bui-button__text">Create</span>
                      </button>
                    </span>

                  </div>

               </div>

               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Wine List Name </span></div>
                 <div style={{flex:6}}>
                   <span>

                         <label class = "dashboard__input__text__label" for = "wineListName">
                           <input value={this.state.wineListName}  onChange={this.handleInputChange}  id = "create-wine-list__wineListName"
                           name = "wineListName" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "wineListName"
                           placeholder = "Wine List Name"></input>
                         </label>

                   </span></div>
                 <div style={{flex:1}}>


                 </div>
               </div>{/*end dashboard element*/}

               <div class = "my-dashboard-element-wrapper flex-wrapper">  {/*start dashboard element*/}
                 <div style={{flex:2}}><span> Wine List Name </span></div>
                 <div style={{flex:6}}>
                   <span>

                         <label class = "dashboard__input__text__label" for = "wineListdescription">
                           <textarea value={this.state.wineListdescription}  onChange={this.handleInputChange}  id = "create-wine-list__wineListdescription"
                           name = "wineListdescription" class="dashboard__select__button__style dashboard__input__text__control" type = "text" if = "wineListdescription"
                           placeholder = "Wine List Description"></textarea>
                         </label>

                   </span></div>
                 <div style={{flex:1}}>


                 </div>
               </div>{/*end dashboard element*/}








             </div>
            </div>
          </div>
        </div>

      </fragment>
    )
  }

}


/*const mapWineAndAuthStateToProps = (state) => ({
  auth: state.auth,

});*/

class WineProfileClass extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    winelist: PropTypes.func.isRequired,
    /*wineDetails: PropTypes.func.isRequired,
    wineDetailsLoaded: PropTypes.func.isRequired,*/


  };

  constructor(props) {
    super(props);

    this.state = {
      wineQuery: null,
      sweetnessSacle : {0: "Bone Dry", 1 : "Dry", 2:'Fruity', 3:'Off - dry',
                        4: "Semi-sweet", 5 : "Very-sweet", 6 : 'Luscious'},
      acidityScale: {0: 'Low',  1: 'Fresh', 2: 'Medium' , 3: 'Zesty' , 4: 'High'},
      tanninScale: {0: 'Low',  1: 'Rounded', 2: 'Medium' , 3: 'High' , 4: 'Grippy'},
      alcoholScale: {0: 'Low',  1: 'Medium' , 2: 'High' , 3: 'Very High'},
      bodyScale: {0: 'Light',  1: 'Medium' , 2: 'Full' },
      mousseScale: {0: 'Delicate',  1: 'Creamy' , 2: 'Aggressive' },
      flavourItensityScale: {0: 'Light',  1: 'Medium', 2: 'Strong' , 3: 'Pronounced' , 4: 'Pervasive'},
      lengthScale: {0: 'Short',  1: 'Medium', 2: 'Generous' , 3: 'Long' , 4: 'Lingering'},
      colourItensityScale: {0: 'Pale',  1: 'Medium', 2: 'Deep' },
      noseItensityScale: {0: 'Light',  1: 'Medium', 2: 'Strong' , 3: 'Pronounced' , 4: 'Pervasive'},

      wineInfo : {
        'wine' : null,
        'country' : null,
        'region' : null,
        'appelation' : null,
        'producer' : null,
        'vintage' : null,
        'pairWith' : null,
        'appearance' : null,
        'nose' : null,
        'palate' : null,
        'readinessToDrink' : null,
        'startToDrink' : null,
        'endToDrink' : null,
		    'colorIntensity' : null,
				'noseintensity' : null,
				'sweetness' : null ,
				'acidity' : null,
				'tanin' : null ,
				'alcohol' : null ,
				'length' : null ,
				'body' : null ,
				'flavourIntensity' : null ,
				'quality' : null ,
				'mouse' : null ,
				'drinkingTime' : null ,
				'subRegion' : null ,
				'site' : null ,
				'parcel' : null ,
				'colour' : null  ,
				'wineType' : null ,
				'designation' : null,
				'producerTitle' : null ,
				'description' : null ,
				'grapeOrBlend' : null ,
				'alcoholPercentage' : null,
				'vegan' : null ,
				'containsSulphites' : null,
				'titratableAcidity' : null,
				'dosage' : null,
				'finingAgents' : null,
				'awards' : null,
				'vegetarian' : null,
				'viticulture' : null ,
				'vinification' : null ,
				'malolacticFermentation' : null,
				'periodOnLees' : null ,
				'disgorgementYear' : null,
				'minServingTempurature' : null,
				'maxServingTempurature' : null,
				'wineQualityPerception' : null,
				'timestamp' : null,
				'carbonicMaceration' : null,
				'semiCarbonicMaceration' : null,
				'organic' : null,
				'biodynamic' : null,
				'natural' : null,
				'sustainabilityInPractice' : null,
				'aged' : null,
				'filtration': null
      },

      wineImg : null ,
      estateImg : null ,

      countryMapFiltered                : [],
      regionMapFiltered                 : [],
      subRegionMapFiltered              : [],
      siteMapFiltered                   : [],
      parcelMapFiltered                 : [],
      wineRegionDesignationsMapFiltered : [],
      wineClassificationsMapFiltered    : [],
      wineTypesMapFiltered          : [],
      wineColoursMapFiltered        : [],
      grapesMapFiltered             : [],
      finingAgentsMapFiltered       : [],
      foodTypesMapFiltered: [],
      foodNamesMapFiltered: [],

      // sliders
      minServingTempurature         : null,
      wineQualityPerception         : null,
      maxServingTempurature         : null,
      sweetness                     : null,
      acidity                       : null,
      tanin                         : null,
      alcohol                       : null,
      body                          : null,
      mouse                         : null,
      flavourIntensity              : null,
      length                        : null,

      // set action
      dataAction                    : "update",

    }

    this.showSliderAndValue             = this.showSliderAndValue.bind(this)
    this.captureCheckboxAndToggle       = this.captureCheckboxAndToggle.bind(this)
    this.updateWineFields               = this.updateWineFields.bind(this)
    this.handleUpdateDetails            = this.handleUpdateDetails.bind(this)
    this.updateImage                    = this.updateImage.bind(this)
    this.filterArrayDataFromElement     = this.filterArrayDataFromElement.bind(this)
    this.filterArrayDataFromElementBlur = this.filterArrayDataFromElementBlur.bind(this)
    this.updateWineFieldsFromSearch     = this.updateWineFieldsFromSearch.bind(this)
    this.unhideFormElements             = this.unhideFormElements.bind(this)
    this.cancelSectionEdits             = this.cancelSectionEdits.bind(this)
    this.setButtonAction                = this.setButtonAction.bind(this)



  }

  async componentDidMount() {

    var fetchWineRegionReferenceDataQuery = "/COUNTRY=*"
    var fetchWineReferenceDataQuery            = "/="
    var fetchFoodPairingsReferenceDataQuery = "/FOOD_TYPE=*"
    await store.dispatch(getWineDetail( this.props.history.location.search));
    await store.dispatch(fetchWineRegionReferenceData(fetchWineRegionReferenceDataQuery))
    await store.dispatch(fetchWineReferenceData(fetchWineReferenceDataQuery))
    await store.dispatch(fetchFoodPairingsReferenceData(fetchFoodPairingsReferenceDataQuery))


    this.setState({
      wineQuery: this.props.history.location.search,

    })

    this.setState({
      countryMapFiltered                : this.props.winelist.wineRegionReferenceData.countryMapData,
      regionMapFiltered                 : this.props.winelist.wineRegionReferenceData.regionMapData,
      subRegionMapFiltered              : this.props.winelist.wineRegionReferenceData.subRegionMapData,
      siteMapFiltered                   : this.props.winelist.wineRegionReferenceData.siteMapData,
      parcelMapFiltered                 : this.props.winelist.wineRegionReferenceData.parcelMapData,
      wineRegionDesignationsMapFiltered : this.props.winelist.wineRegionReferenceData.wineRegionDesignationsData,
      wineClassificationsMapFiltered    : this.props.winelist.wineRegionReferenceData.wineClassificationsData,

      wineTypesMapFiltered          : this.props.winelist.wineReferenceData.wineTypesMapData,
      wineColoursMapFiltered        : this.props.winelist.wineReferenceData.wineColoursMapData,
      grapesMapFiltered             : this.props.winelist.wineReferenceData.grapesMapData,
      finingAgentsMapFiltered       : this.props.winelist.wineReferenceData.finingAgentsMapData,

    })

      // initialise the sliders
    this.setState({
      minServingTempurature:    this.props.winelist.wineDetails.minServingTempurature,
      maxServingTempurature:    this.props.winelist.wineDetails.maxServingTempurature,
      wineQualityPerception:    this.props.winelist.wineDetails.wineQualityPerception,

      sweetness                     : this.props.winelist.wineDetails.sweetness,
      acidity                       : this.props.winelist.wineDetails.acidity,
      tanin                         : this.props.winelist.wineDetails.tanin,
      alcohol                       : this.props.winelist.wineDetails.alcohol,
      body                          : this.props.winelist.wineDetails.body,
      mouse                         : this.props.winelist.wineDetails.mouse,
      flavourIntensity              : this.props.winelist.wineDetails.flavourIntensity,
      length                        : this.props.winelist.wineDetails.length,

    })

    this.setState({
      foodTypesMapFiltered: this.props.winelist.foodPairingsReferenceData.foodTypesMapData,
      foodNamesMapFiltered: this.props.winelist.foodPairingsReferenceData.foodNamesMapData,

    })


    //if (!this.props.winelist.wineRegionReferenceDataLoaded)

  }

  componentDidUpdate(){

  }



  async updateWineFieldsFromSearch(e) {

    e.preventDefault()


    const elementId     = e.currentTarget.id;
    var  clickedElement = document.getElementById(elementId)

    var name              = clickedElement.dataset.name
    var wineDetailsValue  = this.props.winelist.wineDetails[name]
    var selection         = clickedElement.textContent;
    var action            = clickedElement.dataset.action
    var targetInputBox    = clickedElement.dataset.targetinputbox

    var  targetElement  = document.getElementById(targetInputBox)
    targetElement.value = selection

    console.log("Current value of", name, " is ", wineDetailsValue )

    if (action == "add-another"){
      selection = wineDetailsValue + "|" + selection
    }
    /* add the data to a dictionary that will be passed to the backend*/
    this.setState({
      wineInfo : { ... this.state.wineInfo, [name] : selection}
    })

    console.log("name: ", name , "selection: ", selection, "action: " , action)
    // clean up by closing the suggestion box
    var targetSuggestBox = targetElement.dataset.suggestbox
    var  suggestBox      = document.getElementById(targetSuggestBox)
    suggestBox.classList.remove("active")

    // go the backend and update wine region reference data according to the binary_selection

    var filterField             = targetElement.dataset.filterfield
    var fetchWineRegionReferenceDataQuery = "/" + filterField + "=" + selection
    //await store.dispatch(fetchWineRegionReferenceData(fetchWineRegionReferenceDataQuery))

  }

  setButtonAction(e) {

    e.preventDefault()

    const elementId    = e.currentTarget.id;
    var clickedElement = document.getElementById(elementId)
    var action         = clickedElement.dataset.action
    var name           = clickedElement.dataset.name

    this.setState({
      //dataAction : "add-another"
      dataAction : action
    })

    if (action == "clear"){

      this.setState({
        wineInfo : { ... this.state.wineInfo, [name] : ""}
      })

    }

  }

  cancelSectionEdits(e) {

    e.preventDefault()

    const elementId       = e.currentTarget.id;
    var  clickedElement   = document.getElementById(elementId)
    var  elementsToReveal = clickedElement.dataset.target_edit_button_id
    var  elementsToHide   = clickedElement.dataset.target_hidden_elements
    var  elementsToHideList = document.getElementsByClassName(elementsToHide)
    var  elementToReveal  = document.getElementById(elementsToReveal)

    // variables for clearing form elements
    var targetUpdateValues = clickedElement.dataset.target_update_values
    var targetUpdates       = document.getElementsByClassName(targetUpdateValues + '-select')
    var targetClearElements = document.getElementsByClassName(targetUpdateValues + '-clear')

    // find the fields that were targetted for an update
    var selectedFields = []
    for (var i = 0; i < targetUpdates.length; i++ ){
      /*console.log(targetUpdates[i].name)
        selectedFields.push(targetUpdates[i].name)*/
      // select the rangefieldfor reset
      var rangeFieldForReset = ""
      var rangeOutputElement = null


      // only add unique values
      if (selectedFields.includes(targetUpdates[i].dataset.name)) {
        // do nothing
      }
      else {
        console.log(targetUpdates[i].dataset.name)
        selectedFields.push(targetUpdates[i].dataset.name)
      }

    }


    // console.log(elementsToHide)
    /* clear form field elements*/
    for (var i = 0; i < targetClearElements.length; i++ ){

      /* Reset the values of the input fields once they have been updated*/
      var  resetElement   = document.getElementsByClassName(targetClearElements[i].id + "-class")
      resetElement[0].value = null

      // handle checkbox types
      if (resetElement[0].type === 'checkbox') {
        resetElement[0].checked = false

      }

      console.log("This element is of type: ", resetElement[0].type)

      if (resetElement[0].type === 'range') {
        console.log("This is a range element")
        console.log("Reseting: ", resetElement[0].dataset.range_value_output_field)
        rangeFieldForReset = resetElement[0].dataset.range_value_output_field
        rangeOutputElement = document.getElementById(rangeFieldForReset)
        rangeOutputElement.textContent = "Slide for level"

      }

    }

    // clear wineInfo state variable elements
    var wineInfoLocal = {... this.state.wineInfo }
    for (var i = 0; i < selectedFields.length; i++) {
      var fieldToSetToNull = selectedFields[i]

      /*this.setState({
        wineInfo : { ... this.state.wineInfo, [selectedFields[i]] : null}
      })*/


      wineInfoLocal[fieldToSetToNull] = null

    }

    this.setState({
      wineInfo: wineInfoLocal
    })



    // hide form elemnents
    for (var i = 0; i < elementsToHideList.length; i++ ){
      elementsToHideList[i].classList.add("hidden")
    }

    // reveal edit button
    elementToReveal.classList.remove("hidden")

    /*
      TODO: Clear Form Elements
    */


  }

  unhideFormElements(e) {

    e.preventDefault()
    const elementId       = e.currentTarget.id;
    var  clickedElement   = document.getElementById(elementId)
    var  elementsToReveal = clickedElement.dataset.target_hidden_elements
    var elementsToDisplay = document.getElementsByClassName(elementsToReveal) // "wine-key-facts"

    for (var i = 0; i < elementsToDisplay.length; i++ ){
      elementsToDisplay[i].classList.remove("hidden")
    }

    clickedElement.classList.add("hidden")

  }

  filterArrayDataFromElementBlur(e) {

    e.preventDefault()
    var  suggestBox            = document.getElementById('autocom-box-country-search')
    suggestBox.classList.remove("active")

  }

  filterArrayDataFromElement(e) {

    const elementId             = e.currentTarget.id;
    var  clickedElement         = document.getElementById(elementId)
    const targetFieldName       = clickedElement.name
    var objectToFilter          = clickedElement.dataset.objecttofilter
    var filteredObject          = clickedElement.dataset.filteredobject
    var filterField             = clickedElement.dataset.filterfield
    var targetSuggestBox        = clickedElement.dataset.suggestbox
    var dataGroup               = clickedElement.dataset.datagroup
    var filteredData = []

    if(dataGroup == "wine-origin")
    {
      var data = this.props.winelist.wineRegionReferenceData[objectToFilter]
    }

    if (dataGroup == "wine-characteristics")
    {
      var data = this.props.winelist.wineReferenceData[objectToFilter]
    }
    if (dataGroup == "wine-food-parings")
    {
      var data = this.props.winelist.foodPairingsReferenceData[objectToFilter]
    }







    console.log("objectToFilter: " + objectToFilter)
    console.log("filteredObject: " + filteredObject)
    console.log("filterField: " + filterField)
    /*console.log("Data to Filter: " + data)*/

    //console.log("Clicked entry: " + clickedElement.name + " clicke entry value: " + clickedElement.value)
    //console.log("Filtered Data: ", data)
    // run the filter - we could do this in the backend
    for (var i = 0; i < data.length; i++ ){

      if (data[i][filterField] == null) {

      }
      else{


        var value   = clickedElement.value.toLowerCase()
        var filterObject = data[i][filterField].toLowerCase()

        if (filterObject.includes(value)) {
          // could also use startsWith
          filteredData.push(data[i])
          console.log("Pusing data: ", data[i])

        }
      }
    }


    // allow the user to enter a country that is not currently in the country lists
    if (filteredData.length == 0 )
    {
      filteredData.push({COUNTRY: clickedElement.value, COUNTRY_CODE: "ZZZ"} )
    }


    //save filtered data
    this.setState({
      [filteredObject] : filteredData
    })

    // get all required Elements
    /*var  searchWrapper         = document.getElementById('search-input-element')
    var  inputBox              = document.getElementById('search-country-field')*/
    //var  suggestBox            = document.getElementById('autocom-box-country-search')
    var  suggestBox            = document.getElementById(targetSuggestBox)


    // now we want to show the suggestions
    // function showSuggestions

    if(filteredData.length > 0)
    {
      suggestBox.classList.add("active")
    }
    else
    {
      suggestBox.classList.remove("active")
    }

    if (clickedElement.value == "")
    {
      suggestBox.classList.remove("active")
    }





  }
  updateImage(e) {

      e.preventDefault()
      console.log(e.target.files[0])

      const elementId      = e.currentTarget.id;
      var  clickedElement  = document.getElementById(elementId)

      var action           = clickedElement.dataset.action

      console.log('Field: ', clickedElement.name, ' action: ', action, ' data: ', clickedElement.value )

      this.setState({[clickedElement.name] : e.target.files[0]})
      /* add the data to a dictionary that will be passed to the backend*/
      /*this.setState({
        wineInfo : { ... this.state.wineInfo, [clickedElement.name] : e.target.files[0]}
      })*/


  }
  updateWineFields(e) {

    e.preventDefault()

    const elementId      = e.currentTarget.id;
    var  clickedElement  = document.getElementById(elementId)
    var wineDetailsValue = this.props.winelist.wineDetails[clickedElement.name]
    var action           = clickedElement.dataset.action
    var dataGroup        = clickedElement.dataset.datagroup
    var selection        = null

    // handle floating point numbers
    if (dataGroup == "wine-characteristics-float") {


        var inputStr = clickedElement.value
        var inputStrCharArray = inputStr.split('')

        var inputStrReconstuctued = []
        for (var i = 0; i < inputStrCharArray.length-1; i++ ){
              //filteredData.push(data[i])
              inputStrReconstuctued.push(inputStrCharArray[i])
        }



        // check if the last char is is an int
        let regexPattern      = /^-?[0-9]+$/;
        let result      = regexPattern.test(inputStrCharArray[inputStrCharArray.length-1]);


        if(result)
        {
          inputStrReconstuctued.push(inputStrCharArray[inputStrCharArray.length-1])
          //clickedElement.value = ''
        }

        if(inputStrCharArray[inputStrCharArray.length-1] == '.')
        {
          inputStrReconstuctued.push(inputStrCharArray[inputStrCharArray.length-1])
          //clickedElement.value = ''
        }

        var fieldInput = inputStrReconstuctued.join('')
        clickedElement.value = fieldInput

    }

    // handle floating point numbers
    if (dataGroup == "wine-characteristics-integer") {


        var inputStr = clickedElement.value
        var inputStrCharArray = inputStr.split('')

        var inputStrReconstuctued = []
        for (var i = 0; i < inputStrCharArray.length-1; i++ ){
              //filteredData.push(data[i])
              inputStrReconstuctued.push(inputStrCharArray[i])
        }



        // check if the last char is is an int
        let regexPattern      = /^-?[0-9]+$/;
        let result      = regexPattern.test(inputStrCharArray[inputStrCharArray.length-1]);


        if(result)
        {
          inputStrReconstuctued.push(inputStrCharArray[inputStrCharArray.length-1])
          //clickedElement.value = ''
        }

        var fieldInput = inputStrReconstuctued.join('')
        clickedElement.value = fieldInput

    }


    console.log('Field: ', clickedElement.name, ' action: ', action, ' data: ', clickedElement.value )

    selection = clickedElement.value

    if (action == "add-another"){
      selection = wineDetailsValue + "|" + clickedElement.value
    }

    /* add the data to a dictionary that will be passed to the backend*/
    this.setState({
      wineInfo : { ... this.state.wineInfo, [clickedElement.name] : selection}
    })

  }

  handleUpdateDetails(e) {

    e.preventDefault()
    var clickedElement     = document.getElementById(e.currentTarget.id)
    var targetUpdateValues = clickedElement.dataset.target_update_values

    // select the objects you would  you would like to update from
    var targetUpdates       = document.getElementsByClassName(targetUpdateValues + '-select')
    var targetClearElements = document.getElementsByClassName(targetUpdateValues + '-clear')

    // selec the elements to hide post update
    var  elementsToHide   = clickedElement.dataset.target_hidden_elements
    var  elementsToHideList = document.getElementsByClassName(elementsToHide)

    // select the edit button to elementToRevea
    var  editBtnToReveal  = clickedElement.dataset.target_edit_button_id
    var  revealEditBtn     = document.getElementById(editBtnToReveal)

    // select the field names for the  targeted updates
    var selectedFields = []
    for (var i = 0; i < targetUpdates.length; i++ ){
      /*console.log(targetUpdates[i].name)
        selectedFields.push(targetUpdates[i].name)*/
      // select the rangefieldfor reset
      var rangeFieldForReset = ""
      var rangeOutputElement = null


      // only add unique values
      if (selectedFields.includes(targetUpdates[i].dataset.name)) {
        // do nothing
      }
      else {
        console.log(targetUpdates[i].dataset.name)
        selectedFields.push(targetUpdates[i].dataset.name)
      }

    }

    console.log('clickedElement: ', clickedElement)
    console.log('targetUpdateValues: ', targetUpdateValues)
    console.log('targetUpdates: ', targetUpdates)
    console.log('selectedFields: ', selectedFields)

    var query = this.props.history.location.search
    var fetchWineRegionReferenceDataQuery = "/COUNTRY=*"

     var url = "https://www.therealgrape.com/api/wine-profile/wine-details-update-with-image/search"  + query
    //var url = "http://127.0.0.1:8000/api/wine-profile/wine-details-update-with-image/search"  + query

    // GET token from state
    const token = this.props.auth.token;

    // set up the header for the get calls
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }

    // // Provide the authorisation token
    if(token) {
      config.headers['Authorization'] = `Token ${token}`
    }

    // now we need to stringfy the field that we want to provide
    // Request body

    //const body = JSON.stringify({'OrderId': this.state.orderData.OrderId,
    //                    'attendeeDetails' : this.state.attendeeInfo});

    const body = new FormData()
    if (this.state.wineImg !== null)
    {
        body.append('wineImg', this.state.wineImg, this.state.wineImg.name)
    }

    for ( var key in selectedFields){
    //for ( var key in this.state.wineInfo ) {

      if (this.state.wineInfo[selectedFields[key]] !== null)
      {
        if (typeof this.state.wineInfo[selectedFields[key]] !== 'undefined')
          {
            console.log('This is the key: ', selectedFields[key])
            console.log('This is the value: ', this.state.wineInfo[selectedFields[key]])
            body.append(selectedFields[key], this.state.wineInfo[selectedFields[key]])
            //body.append(key, this.state.wineInfo[key])

          }
      }

    }

    console.log(Array.from(body))

    // now post the Data


    axios.post(url, body, config)
    .then((response) => {
      store.dispatch(getWineDetail( query));
      store.dispatch(fetchWineRegionReferenceData(fetchWineRegionReferenceDataQuery))
    })
    .then((data) => {

    })

    // now clear the fields and make sure confirm details closes
    this.setState({
      dataAction: "update",

    })


    for (var i = 0; i < targetClearElements.length; i++ ){

      /* Reset the values of the input fields once they have been updated*/
      var  resetElement   = document.getElementsByClassName(targetClearElements[i].id + "-class")
      resetElement[0].value = null

      // handle checkbox types
      if (resetElement[0].type === 'checkbox') {
        resetElement[0].checked = false

      }

      console.log("This element is of type: ", resetElement[0].type)

      if (resetElement[0].type === 'range') {
        console.log("This is a range element")
        console.log("Reseting: ", resetElement[0].dataset.range_value_output_field)
        rangeFieldForReset = resetElement[0].dataset.range_value_output_field
        rangeOutputElement = document.getElementById(rangeFieldForReset)
        rangeOutputElement.textContent = "Slide for level"

      }

    }

    // clear updated elements
    // clear wineInfo state variable elements
    var wineInfoLocal = {... this.state.wineInfo }
    for (var i = 0; i < selectedFields.length; i++) {
      var fieldToSetToNull = selectedFields[i]

      /*this.setState({
        wineInfo : { ... this.state.wineInfo, [selectedFields[i]] : null}
      })*/


      wineInfoLocal[fieldToSetToNull] = null

    }

    this.setState({
      wineInfo: wineInfoLocal
    })

    /*
    this.setState({
      wineInfo : { ... this.state.wineInfo, [selectedFields] : null}
    })*/


    // hide the form elements and reveal the edit button
    for (var i = 0; i < elementsToHideList.length; i++ ){
      elementsToHideList[i].classList.add("hidden")
    }

    // reveal the edit button
    revealEditBtn.classList.remove("hidden")




  }



  captureCheckboxAndToggle(e) {

    const elementId       = e.currentTarget.id;
    var   myCheckox       = document.getElementById(elementId)
    //const targetName      = myCheckox.name
    const targetValue     = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    var toggleTarget      = myCheckox.dataset.toggle_target
    var toggle_value      = myCheckox.dataset.toggle_value
    var toggleElement     = document.getElementById(toggleTarget)

    if (targetValue) {
        toggleElement.checked = false
    }

    console.log("Togglebox: targetName: ", myCheckox.name, " toggle value: ", toggle_value)

    /* add the data to a dictionary that will be passed to the backend*/
    this.setState({
      wineInfo : { ... this.state.wineInfo, [myCheckox.name] : toggle_value}
    })


  }

  showSliderAndValue(e) {

      const elementId       = e.currentTarget.id;
      var mySlider          = document.getElementById(elementId)
      const targetName      = mySlider.name
      const targetValue     = mySlider.value
      var outputField       = mySlider.dataset.range_value_output_field
      var outputElement     = document.getElementById(outputField)

      mySlider.textContent  = targetValue

      /*var slideValue = document.querySelector("." + outputElement.className);*/

      /*if (outputField == 'sweetness-range-output') {
          outputElement.textContent = this.state.sweetnessSacle[targetValue];
      }
      else {
        outputElement.textContent = targetValue;
      }*/

      this.setState({
        [mySlider.name] : mySlider.value, // update the display slider
        wineInfo : { ... this.state.wineInfo, [mySlider.name] : mySlider.value} // update the backend
      })

      switch(outputField.toString())
      {
        case "sweetness-range-output":
          {
            outputElement.textContent = this.state.sweetnessSacle[targetValue];
            break;
          }
        case "acidity-perception-range-output":
            {
              outputElement.textContent = this.state.acidityScale[targetValue];
              break;
            }
        case "tannin-perception-range-output":
              {
                outputElement.textContent = this.state.tanninScale[targetValue];
                break;
              }
        case "alcohol-perception-range-output":
                {
                  outputElement.textContent = this.state.alcoholScale[targetValue];
                  break;
                }
        case "body-perception-range-output":
            {
              outputElement.textContent = this.state.bodyScale[targetValue];
              break;
            }
        case "mousse-perception-range-output":
        {
              outputElement.textContent = this.state.mousseScale[targetValue];
              break;
        }

        case "flavour-itensity-range-output":
        {
              outputElement.textContent = this.state.flavourItensityScale[targetValue];
              break;
        }

        case "flavour-length-itensity-range-output":
        {
              outputElement.textContent = this.state.lengthScale[targetValue];
              break;
        }

        case "colour-itensity-range-output":
        {
              outputElement.textContent = this.state.colourItensityScale[targetValue];
              break;
        }

        case "nose-itensity-range-output":
        {
              outputElement.textContent = this.state.noseItensityScale[targetValue];
              break;
        }



        default:
          outputElement.textContent = targetValue;
          break;
      }

  }


  render()
  {
      const { isAuthenticated, user }   = this.props.auth;
      var wineDetailsLoaded             = this.props.winelist.wineDetailsLoaded
      var wineRegionReferenceDataLoaded = this.props.winelist.wineRegionReferenceDataLoaded
      var wineReferenceDataLoaded       = this.props.winelist.wineReferenceDataLoaded
      var self = this
      return(
        wineDetailsLoaded && wineRegionReferenceDataLoaded && wineReferenceDataLoaded ? [
        <div>
          <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
            <div class = "app-content-full container">
              <div class = "someSpacing">
              </div>
              <br/>
              <div class = "main-page-content">
                <div class="wine_profile__page__strapline">
                Wine Profile: {this.props.winelist.wineDetails.wine.toLowerCase()}
                </div>



                <div class = "wine_profile__page__intro-copy-paragraph">
                  We invite you to fill in as much of the wine profile as possible. This information will be available to members who have registered to attend your event or experience. Only the wine name and producer will be displayed on the <span style = {{fontWeight:"500 !important"}}> Wine List </span> section of the event or experience listing.
                </div>
              </div>

              {/*start wine intro*/}
              <div class = "wine_profile_origin-info-wrapper" >
                <div class = "wine_profile_origin-info-container-two">
                  <div class = "row">
                    <div class = "col-sm-12 col-md-12">
                      <div class = "main-page-content">
                        <div class="wine_profile__row__strapline">
                          <div class = "row">
                            <div class = "col-sm-12 col-md-10 col-lg-10">
                              <div class = "section-title-wrapper">
                                <span style = {{textTransform:"none"}}>Key Facts </span>
                              </div>
                            </div>
                            <div class ="col-sm-12 col-md-2 col-lg-2">

                              <div class = "section-button-wrapper">
                                <button id = 'edit-wine-key-facts-btn'
                                        class = "button button-primary confirm-update-button"
                                        onClick = {(e) => this.unhideFormElements(e)}
                                        data-target_hidden_elements = "wine-key-facts"
                                        style = {{margin:"4px 4px 4px 4px"}}>

                                  <div style = {{fontSize:"12px"}}>Edit</div>
                                </button>



                                  <button id = 'cancel-wine-key-facts-btn'
                                          class = "wine-key-facts button button-primary cancel-update-button hidden"
                                          onClick = {(e) => this.cancelSectionEdits(e)}
                                          data-target_hidden_elements = "wine-key-facts"
                                          data-target_edit_button_id  = "edit-wine-key-facts-btn"
                                          data-target_update_values = "wine-key-facts-value"
                                          style = {{margin:"4px 4px 4px 4px"}}>

                                    <div style = {{fontSize:"12px"}}>Cancel</div>
                                  </button>
                                </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class = "col-sm-12 col-md-4">
                      <div class = "wine_profile_Img_wrapper">
                        <img class = "wine_profile_Img_Centered" src = {this.props.winelist.wineDetails.wineImg} />
                      </div>
                      <div class = "row">
                        <div class = "col-sm-12">
                          <input type = "file"
                                  class             = "wine-wineImg-input-class wine-key-facts wine-key-facts-value-select wine-key-facts-value-clear hidden"
                                  id                = "wine-wineImg-input"
                                  data-hidden_group = "wine-key-facts"
                                  name              = "wineImg"
                                  data-name         = "wineImg"
                                  onChange          = {(e) => this.updateImage(e)}
                                  data-action       = "update"/>
                        </div>
                      </div>
                    </div>

                    <div class = "col-sm-12 offset-md-1 col-md-7">
                      <div class = "row">

                        <div class = "col-sm-12 col-md-12 col-lg-12" style = {{marginBottom:"5px"}}>

                          <div class = "wine_profile-name-wrapper" style = {{alignItmes:"left"}}>
                            <div class = "wine_profile-inpage-details-title" style = {{textAlign:"left"}}>About the wine </div>
                          </div>

                            {this.props.winelist.wineDetails.description === null ? <></> : <p class = "wine_profile__page__intro-copy-paragraph">  {this.props.winelist.wineDetails.description.toString()} </p>}



                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {/*this.props.winelist.wineDetails.timestamp.substring(0, 10)*/}
                              {/*class = 'form-control'*/}
                              <textarea  class       = "wine-description-input-class wine-key-facts wine-key-facts-value-select wine-key-facts-value-clear wine_profile_textarea_input hidden"
                                         id          = "wine-description-input"
                                         data-hidden_group    = "wine-key-facts"
                                         name        = "description"
                                         data-name   = "description"
                                         onInput     = {(e) => this.updateWineFields(e)}
                                         data-action = "update"
                                         wrap        = "hard"
                                         placeholder ="Enter a description of the wine and estate ..."/>
                            </div>
                          </div>

                        </div>

                        <div class = "col-sm-12 col-md-12 col-lg-12"  style = {{marginBottom:"5px"}}>

                          <div class = "wine_profile-name-wrapper" style = {{alignItmes:"left"}}>
                            <div class = "wine_profile-inpage-details-title" style = {{textAlign:"left"}}>Viticulture </div>
                          </div>

                          {this.props.winelist.wineDetails.viticulture === null ? <></> : <p class = "wine_profile__page__intro-copy-paragraph">  {this.props.winelist.wineDetails.viticulture.toString()} </p>}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <textarea  class = "wine-viticulture-input-class wine-key-facts wine-key-facts-value-select wine-key-facts-value-clear wine_profile_textarea_input hidden"
                                      id          = "wine-viticulture-input"
                                      data-hidden_group = "wine-key-facts"
                                      name        = "viticulture"
                                      data-name   = "viticulture"
                                      onInput     = {(e) => this.updateWineFields(e)}
                                      data-action = "update"
                                      wrap = "hard"
                                      placeholder ="Highlight the viticultural practices ..."/>
                            </div>
                          </div>

                        </div>

                        <div class = "col-sm-12 col-md-12 col-lg-12"  style = {{marginBottom:"5px"}}>

                          <div class = "wine_profile-name-wrapper" style = {{alignItmes:"left"}}>
                            <div class = "wine_profile-inpage-details-title" style = {{textAlign:"left"}}> Vinification </div>
                          </div>

                          {this.props.winelist.wineDetails.vinification === null ? <></> : <p class = "wine_profile__page__intro-copy-paragraph">  {this.props.winelist.wineDetails.vinification.toString()} </p>}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <textarea  class = "wine-vinification-input-class wine-key-facts wine-key-facts-value-select wine-key-facts-value-clear wine_profile_textarea_input hidden"
                                      id          = "wine-vinification-input"
                                      data-hidden_group = "wine-key-facts"
                                      name        = "vinification"
                                      data-name   = "vinification"
                                      onInput     = {(e) => this.updateWineFields(e)}
                                      data-action = "update"
                                      wrap = "hard"
                                      placeholder ="Highlight the practices in the winery ..."/>
                            </div>
                          </div>

                        </div>

                        <div class = "col-sm-12 col-md-12 col-lg-12"  style = {{marginBottom:"5px"}}>

                          <div class = "wine_profile-name-wrapper" style = {{alignItmes:"left"}}>
                            <div class = "wine_profile-inpage-details-title" style = {{textAlign:"left"}}> Awards </div>
                          </div>

                          {this.props.winelist.wineDetails.awards === null ? <></> : <div>
                          <p class = "wine_profile__page__intro-copy-paragraph">  {this.props.winelist.wineDetails.awards.split("|").map((award, index) =>
                            <span style={{ marginRight: '10px' }}> <div class = "split-with-separator-alignment"> <div class="separator"></div> {award} </div> </span>
                          )} </p> </div>}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12" style = {{display:"flex", justifyContent: "center", marginLeft:"2px", marginRight:"2px"}}>

                                <input  class = "wine-awards-input-class wine-key-facts wine-key-facts-value-select wine-key-facts-value-clear wine_profile_box_input hidden"
                                  id          = "wine-awards-input"
                                  data-hidden_group = "wine-key-facts"
                                  name        = "awards"
                                  data-name   = "awards"
                                  onInput     = {(e) => this.updateWineFields(e)}
                                  data-action = {this.state.dataAction}
                                        placeholder ="Enter award and year ..."/>

                                  {this.props.winelist.wineDetails.awards != "" && typeof this.props.winelist.wineDetails.awards === 'string' ?
                                     <div style = {{display:"flex"}}>
                                      {/*"button button-primary cancel-update-button"*/}
                                        <button class       = "wine-key-facts wine-key-facts-value-select bui-button hidden"
                                                data-action = "add-another"
                                                id          = 'awards-search-add-another-btn'
                                                data-name   = "awards"
                                                onClick     = {(e) => this.setButtonAction(e)} > ADD </button>
                                      </div> : <div></div>}


                                </div>

                          </div>

                          {this.props.winelist.wineDetails.awards != "" && typeof this.props.winelist.wineDetails.awards === 'string' ?
                            <div class ="row" style = {{marginTop:"5px"}}>
                              <div class = "col-sm-12 offset-md-4 col-md-4" >

                                <button class       = "wine-key-facts wine-key-facts-value-select button button-primary cancel-update-button hidden"
                                        data-action = "clear"
                                        id          = 'awards-search-clear-grapes-btn'
                                        data-name   = "awards"
                                        onClick     = {(e) => this.setButtonAction(e)}
                                         > Clear</button>
                              </div>
                              <div class = "col-sm-12 col-md-12 col-md-12">
                                <p class = "wine-key-facts wine_profile__page__intro-copy-paragraph hidden"> click on confirm details to effect changes</p>
                              </div>
                            </div> : <div></div>}



                        </div>




                      </div>

                    </div> {/*end*/}

                    <div class = "col-sm-12 offset-md-4 col-md-4">
                      <button id = 'confirm-attendee-details-btn'
                              data-hidden_group = "wine-key-facts"
                              data-target_update_values = "wine-key-facts-value"
                              data-target_hidden_elements = "wine-key-facts"
                              data-target_edit_button_id  = "edit-wine-key-facts-btn"
                              onClick={(e) => this.handleUpdateDetails(e)}
                              class = "wine-key-facts button button-primary confirm-update-button hidden"
                              style = {{margin:"4px 4px 4px 4px"}}>


                        Confirm Details
                        </button>
                    </div>
                  </div>
                </div> {/*end wine profile info*/}
              </div>

              {/*wine region info*/}
              <div class = "wine_profile_origin-info-wrapper" >
                <div class = "wine_profile_origin-info-container" style = {{backgroundColor: "#fcfcfc"}}>
                  <div class = "main-page-content">
                    <div class="wine_profile__row__strapline">
                      <div class = "row">
                        <div class = "col-sm-12 col-md-10 col-lg-10">
                          <div class = "section-title-wrapper">
                            <span style = {{textTransform:"none"}}>Where the wine is from </span>
                          </div>
                        </div>
                        <div class ="col-sm-12 col-md-2 col-lg-2">
                          <div class = "section-button-wrapper">

                              <button id = 'edit-where-the-wine-is-from-btn'
                                      class = "button button-primary confirm-update-button"
                                      onClick = {(e) => this.unhideFormElements(e)}
                                      data-target_hidden_elements = "where-the-wine-is-from"
                                      style = {{margin:"4px 4px 4px 4px"}}>

                                <div style = {{fontSize:"12px"}}>Edit Section</div>
                                </button>

                                <button id = 'cancel-where-the-wine-is-from-btn'
                                        class = "where-the-wine-is-from button button-primary cancel-update-button hidden"
                                        onClick = {(e) => this.cancelSectionEdits(e)}
                                        data-target_hidden_elements = "where-the-wine-is-from"
                                        data-target_edit_button_id  = "edit-where-the-wine-is-from-btn"
                                        data-target_update_values = "where-the-wine-is-from-value"
                                        style = {{margin:"4px 4px 4px 4px"}}>

                                  <div style = {{fontSize:"12px"}}>Cancel</div>
                                </button>


                            </div>

                        </div>


                      </div>
                    </div>
                  </div>

                  <div class = "wine_profile-info-wrapper">
                    <div class = "wine_profile-info row" >


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >

                          <div class = "wine_profile-inpage-elements-wrapper">
                            Producer
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-producer-input-class where-the-wine-is-from where-the-wine-is-from-value-select where-the-wine-is-from-value-clear wine_profile_box_input hidden"
                                      id          = "wine-producer-input"
                                      name        = "producer"
                                      data-name   = "producer"
                                      onInput     = {(e) => this.updateWineFields(e)}
                                      data-action = "update"
                                      data-hidden_group = "where-the-wine-is-from"
                                      placeholder ="Producer"/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.producer === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.producer.toString()} </p>}
                            </div>
                          </div>

                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Title
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-producerTitle-input-class where-the-wine-is-from where-the-wine-is-from-value-select where-the-wine-is-from-value-clear wine_profile_box_input hidden"
                                      id          = "wine-producerTitle-input"
                                      name        = "producerTitle"
                                      data-name   = "producerTitle"
                                      onInput     = {(e) => this.updateWineFields(e)}
                                      data-action = "update"
                                      data-hidden_group = "where-the-wine-is-from"
                                      placeholder ="Title"/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                            {this.props.winelist.wineDetails.producerTitle === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.producerTitle.toString()} </p>}
                            </div>
                          </div>


                        </div>


                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Country
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "where-the-wine-is-from wrapper_search_box hidden" data-hidden_group = "where-the-wine-is-from">
                                {/*<div class = "search-input" id = "search-input-element">*/}
                                <div class = "search-input"
                                      id = "country-input-element"
                                      >
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-country-field-class where-the-wine-is-from-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-country-field"
                                    data-objectToFilter = "countryMapData"
                                    data-filteredObject = "countryMapFiltered"
                                    data-filterField    = "COUNTRY"
                                    data-suggestBox     = "autocom-box-country-search"
                                    data-datagroup      = "wine-origin"

                                    placeholder         = "Country"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container ">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = " autocom-box "
                                         id    = 'autocom-box-country-search'
                                         >
                                      { this.state.countryMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li
                                          class = "where-the-wine-is-from-value-select"
                                          key = {wineRefData.COUNTRY_CODE}
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-country-input" + "-" + wineRefData.COUNTRY_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-country-field"

                                          data-name   = "country"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.COUNTRY}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}



                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.country === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.country.toString()} </p>}
                            </div>
                          </div>





                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                             Region
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "where-the-wine-is-from wrapper_search_box hidden" data-hidden_group = "where-the-wine-is-from">
                                <div class = "search-input"
                                     id = "region-input-element"
                                     >
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-region-field-class where-the-wine-is-from-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-region-field"
                                    data-objectToFilter = "regionMapData"
                                    data-filteredObject = "regionMapFiltered"
                                    data-filterField    = "REGION"
                                    data-suggestBox     = "autocom-box-region-search"
                                    data-datagroup      = "wine-origin"
                                    placeholder         = "REGION"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-region-search' >
                                      { this.state.regionMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.REGION_CODE}
                                          class = "where-the-wine-is-from-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-region-input" + "-" + wineRefData.REGION_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-region-field"
                                          data-name   = "region"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.REGION}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}


                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.region} </p>
                            </div>
                          </div>



                        </div>

                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              Appellation/Sub-region
                            </div>


                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <div class = "where-the-wine-is-from wrapper_search_box hidden" data-hidden_group = "where-the-wine-is-from">
                                  <div class = "search-input" id = "appelation-input-element">
                                    <input
                                      /*onClick = {(e) => this.searchSuggestions(e)}*/
                                      class = "search-appelation-field-class where-the-wine-is-from-value-clear"
                                      onChange={(e) => self.filterArrayDataFromElement(e)}
                                      /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                      type = "text"
                                      id = "search-appelation-field"
                                      data-objectToFilter = "subRegionMapData"
                                      data-filteredObject = "subRegionMapFiltered"
                                      data-filterField    = "SUB_REGION"
                                      data-suggestBox     = "autocom-box-appelation-search"
                                      data-datagroup      = "wine-origin"
                                      placeholder         = "SUB_REGION"/>

                                      <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                        <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                      </div>

                                      <div class = "autocom-box"
                                           id    = 'autocom-box-appelation-search' >
                                        { this.state.subRegionMapFiltered.slice(0, 5).map((wineRefData) =>
                                          <li key = {wineRefData.SUB_REGION_CODE}
                                            class = "where-the-wine-is-from-value-select"
                                            onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                            id          = {"wine-appelation-input"+ "-" + wineRefData.SUB_REGION_CODE}
                                            data-action = "update"
                                            data-targetInputBox = "search-appelation-field"
                                            data-name   = "appelation"
                                            >
                                            <label class = "filter-bar-label">
                                              {/*<input type="radio"/>*/}
                                                {wineRefData.SUB_REGION}
                                            </label>
                                          </li>
                                        ) }
                                      </div>
                                  </div>
                                </div>
                              </div> {/*end column*/}
                            </div> {/*end row*/}




                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                {this.props.winelist.wineDetails.appelation === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.appelation.toString()} </p>}
                              </div>
                            </div>

                        </div>



                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Appellation designation
                          </div>





                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "where-the-wine-is-from wrapper_search_box hidden" data-hidden_group = "where-the-wine-is-from">
                                <div class = "search-input" id = "designation-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-designation-field-class where-the-wine-is-from-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-designation-field"
                                    data-objectToFilter = "wineRegionDesignationsData"
                                    data-filteredObject = "wineRegionDesignationsMapFiltered"
                                    data-filterField    = "DESIGNATION"
                                    data-suggestBox     = "autocom-box-designation-search"
                                    data-datagroup      = "wine-origin"
                                    placeholder         = "DESIGNATION"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-designation-search' >
                                      { this.state.wineRegionDesignationsMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.DESIGNATION_CODE}
                                          class = "where-the-wine-is-from-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-designation-input"+ "-" + wineRefData.DESIGNATION_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-designation-field"
                                          data-name   = "designation"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.DESIGNATION}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}


                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.designation === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.designation.toString()} </p>}
                            </div>
                          </div>

                        </div>



                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Site
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "where-the-wine-is-from wrapper_search_box hidden" data-hidden_group = "where-the-wine-is-from">
                                <div class = "search-input" id = "site-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-site-field-class where-the-wine-is-from-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-site-field"
                                    data-objectToFilter = "siteMapData"
                                    data-filteredObject = "siteMapFiltered"
                                    data-filterField    = "SITE"
                                    data-suggestBox     = "autocom-box-site-search"
                                    data-datagroup      = "wine-origin"
                                    placeholder         = "SITE"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-site-search' >
                                      { this.state.siteMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.SITE_CODE}
                                          class = "where-the-wine-is-from-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-site-input"+ "-" + wineRefData.SITE_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-site-field"
                                          data-name   = "site"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.SITE}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.site === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.site.toString()} </p>}
                            </div>
                          </div>




                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Parcel
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "where-the-wine-is-from wrapper_search_box hidden" data-hidden_group = "where-the-wine-is-from" >
                                <div class = "search-input" id = "parcel-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-parcel-field-class where-the-wine-is-from-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-parcel-field"
                                    data-objectToFilter = "parcelMapData"
                                    data-filteredObject = "parcelMapFiltered"
                                    data-filterField    = "PARCEL"
                                    data-suggestBox     = "autocom-box-parcel-search"
                                    data-datagroup      = "wine-origin"
                                    placeholder         = "PARCEL"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-parcel-search' >
                                      { this.state.parcelMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.PARCEL_CODE}
                                          class = "where-the-wine-is-from-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-parcel-input"+ "-" + wineRefData.PARCEL_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-parcel-field"
                                          data-name   = "parcel"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.PARCEL}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}


                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.parcel === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.parcel.toString()} </p>}
                            </div>
                          </div>

                        </div> {/* End wine profil fields container*/}
                      </div>

                      <div class = "col-sm-12 offset-md-4 col-md-4">
                        <button id = 'confirm-where-the-wine-is-from-btn'
                                data-hidden_group = "wine-key-facts"
                                data-target_update_values = "where-the-wine-is-from-value"
                                data-target_hidden_elements = "where-the-wine-is-from"
                                data-target_edit_button_id  = "edit-where-the-wine-is-from-btn"
                                onClick={(e) => this.handleUpdateDetails(e)}
                                class = "where-the-wine-is-from button button-primary confirm-update-button hidden"
                                style = {{margin:"4px 4px 4px 4px"}}>

                          Confirm Details
                          </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              {/*key characteristics*/}
              <div class = "wine_profile_origin-info-wrapper" >
                <div class = "wine_profile_origin-info-container-two">
                  <div class = "main-page-content">
                    <div class="wine_profile__row__strapline">
                      <div class = "row">


                        <div class = "col-sm-12 col-md-10 col-lg-10">
                          <span style = {{textTransform:"none"}}> Key Characteristics </span>
                        </div>
                        <div class ="col-sm-12 col-md-2 col-lg-2">

                          <button id = 'edit-key-wine-characteristics-btn'
                                  class = "button button-primary confirm-update-button"
                                  onClick = {(e) => this.unhideFormElements(e)}
                                  data-target_hidden_elements = "key-wine-characteristics"
                                  style = {{margin:"4px 4px 4px 4px"}}>

                            <div style = {{fontSize:"12px"}}>Edit Section</div>
                            </button>

                            <button id = 'cancel-key-wine-characteristics-btn'
                                    class = "key-wine-characteristics button button-primary cancel-update-button hidden"
                                    onClick = {(e) => this.cancelSectionEdits(e)}
                                    data-target_hidden_elements = "key-wine-characteristics"
                                    data-target_update_values = "key-wine-characteristics-value"
                                    data-target_edit_button_id  = "edit-key-wine-characteristics-btn"
                                    style = {{margin:"4px 4px 4px 4px"}}>

                              <div style = {{fontSize:"12px"}}>Cancel</div>
                              </button>

                        </div>
                    </div>
                  </div>
                </div>

                  <div class = "wine_profile-info-wrapper">
                    <div class = "wine_profile-info row" >


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >

                          <div class = "wine_profile-inpage-elements-wrapper">
                            Wine Type
                          </div>





                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "key-wine-characteristics wrapper_search_box hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <div class = "search-input" id = "wineType-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-wineType-field-class key-wine-characteristics-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-wineType-field"
                                    data-objectToFilter = "wineTypesMapData"
                                    data-filteredObject = "wineTypesMapFiltered"
                                    data-filterField    = "TYPE"
                                    data-suggestBox     = "autocom-box-wineType-search"
                                    data-datagroup      = "wine-characteristics"
                                    placeholder         = "WINE TYPE"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-wineType-search' >
                                      { this.state.wineTypesMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.TYPE_CODE}
                                          class = "key-wine-characteristics-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-wineType-input"+ "-" + wineRefData.TYPE_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-wineType-field"
                                          data-name   = "wineType"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.TYPE}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.wineType === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.wineType.toString()} </p>}
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Colour
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "key-wine-characteristics wrapper_search_box hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <div class = "search-input" id = "colour-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-colour-field-class key-wine-characteristics-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-colour-field"
                                    data-objectToFilter = "wineColoursMapData"
                                    data-filteredObject = "wineColoursMapFiltered"
                                    data-filterField    = "COLOUR"
                                    data-suggestBox     = "autocom-box-colour-search"
                                    data-datagroup      = "wine-characteristics"
                                    placeholder         = "COLOUR"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-colour-search' >
                                      { this.state.wineColoursMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.COLOUR_CODE}
                                          class = "key-wine-characteristics-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-colour-input"+ "-" + wineRefData.COLOUR_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-colour-field"
                                          data-name   = "colour"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.COLOUR}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.colour === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.colour.toString()} </p>}
                            </div>
                          </div>


                        </div>


                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            <div style = {{display:"flex", alignItems: "center", justifyContent: "space-between"}}>
                              Grape/Blend
                              {this.props.winelist.wineDetails.grapeOrBlend != "" && typeof this.props.winelist.wineDetails.grapeOrBlend === 'string' ?
                                <div>
                                  {/*"button button-primary cancel-update-button"*/}
                                  <button class       = "key-wine-characteristics bui-button hidden"
                                          data-action = "add-another"
                                          id          = 'grapeOrBlend-search-add-another-btn'
                                          data-name   = "grapeOrBlend"
                                          onClick     = {(e) => this.setButtonAction(e)} > ADD </button>
                                 </div> : <div></div>}

                                 {this.props.winelist.wineDetails.grapeOrBlend != "" && typeof this.props.winelist.wineDetails.grapeOrBlend === 'string' ?


                                       <button class       = "key-wine-characteristics  bui-button  hidden"
                                               data-action = "clear"
                                               id          = 'grapeOrBlend-search-clear-grapes-btn'
                                               data-name   = "grapeOrBlend"
                                               style       = {{font:"red"}}
                                               onClick     = {(e) => this.setButtonAction(e)} > CLEAR </button>

                                    : <div></div>}


                              </div>

                          </div>



                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12" style = {{display:"flex", justifyContent: "center", marginLeft:"2px", marginRight:"2px"}}>
                              <div class = "key-wine-characteristics wrapper_search_box hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <div class = "search-input" id = "grapeOrBlend-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-grapeOrBlend-field-class key-wine-characteristics-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-grapeOrBlend-field"
                                    data-objectToFilter = "grapesMapData"
                                    data-filteredObject = "grapesMapFiltered"
                                    data-filterField    = "GRAPE"
                                    data-suggestBox     = "autocom-box-grapeOrBlend-search"
                                    data-datagroup      = "wine-characteristics"
                                    placeholder         = "ADD GRAPE"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-grapeOrBlend-search' >
                                      { this.state.grapesMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.GRAPE_CODE}
                                          class = "key-wine-characteristics-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-grapeOrBlend-input"+ "-" + wineRefData.GRAPE_CODE}
                                          data-action = {this.state.dataAction}
                                          data-targetInputBox = "search-grapeOrBlend-field"
                                          data-name   = "grapeOrBlend"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.GRAPE}
                                          </label>
                                        </li>
                                      ) }
                                    </div>


                                </div>
                              </div>
                              {/*Add Button Original Location*/}


                            </div>

                            <div class = "row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                {this.props.winelist.wineDetails.grapeOrBlend != "" && typeof this.props.winelist.wineDetails.grapeOrBlend === 'string' ?
                                  <p class = "key-wine-characteristics wine_profile__page__intro-copy-paragraph hidden"> click on confirm details to effect changes</p>
                                  /*<div class ="row" style = {{marginTop:"5px"}}>
                                    <div class = "col-sm-12 offset-md-3 col-md-6 offset-lg-3 col-lg-6">
                                      <button class       = "key-wine-characteristics button button-primary cancel-update-button hidden"
                                              data-action = "clear"
                                              id          = 'grapeOrBlend-search-clear-grapes-btn'
                                              data-name   = "grapeOrBlend"
                                              onClick     = {(e) => this.setButtonAction(e)} > Clear </button>
                                    </div>
                                    <div class = "col-sm-12 col-md-12 col-md-12">
                                    </div>
                                  </div> */: <div></div>}


                                </div>
                              </div>{/*end column*/}
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                {this.props.winelist.wineDetails.grapeOrBlend === null ? <></> :  <div>
                                <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.grapeOrBlend.split("|").map((grapeOrBlend, index) =>
                                  <span style={{ marginRight: '10px' }}> <div class = "split-with-separator-alignment"> <div class="separator"></div> {grapeOrBlend} </div> </span>
                                )} </p> </div>}
                              </div>
                            </div>
                          </div> {/*end wine profile fields container*/}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">

                            </div>





                        </div>{/*end floating row*/}

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Wine Classification
                          </div>



                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "key-wine-characteristics wrapper_search_box hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <div class = "search-input" id = "classification-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-classification-field-class key-wine-characteristics-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-classification-field"
                                    data-objectToFilter = "wineClassificationsData"
                                    data-filteredObject = "wineClassificationsMapFiltered"
                                    data-filterField    = "CLASSIFICATION"
                                    data-suggestBox     = "autocom-box-classification-search"
                                    data-datagroup      = "wine-origin"
                                    placeholder         = "WINE CLASSIFICATION"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-classification-search' >
                                      { this.state.wineClassificationsMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.CLASSIFICATION_CODE}
                                          class = "key-wine-characteristics-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-classification-input"+ "-" + wineRefData.CLASSIFICATION_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-classification-field"
                                          data-name   = "classification"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.CLASSIFICATION}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.classification} </p>
                            </div>
                          </div>

                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            ABV (%)
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-alcoholPercentage-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                      id             = "wine-alcoholPercentage-input"
                                      name           = "alcoholPercentage"
                                      data-name      = "alcoholPercentage"
                                      onInput        = {(e) => this.updateWineFields(e)}
                                      data-action    = "update"
                                      data-datagroup = "wine-characteristics-float"
                                      data-target_hidden_elements = "key-wine-characteristics"
                                      maxlength="5"
                                      placeholder    = "ABV e.g 14.5"/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.alcoholPercentage} % </p>
                            </div>
                          </div>



                        </div>

                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              Vintage
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <input  class = "wine-vintage-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                        id             = "wine-vintage-input"
                                        name           = "vintage"
                                        data-name      = "vintage"
                                        onInput        = {(e) => this.updateWineFields(e)}
                                        data-action    = "update"
                                        data-datagroup = "wine-characteristics-integer"
                                        data-target_hidden_elements = "key-wine-characteristics"
                                        maxlength="4"
                                        placeholder ="YYYY"/>
                              </div>
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.vintage} </p>
                              </div>
                            </div>


                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              year of disgorgement
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <input  class = "wine-disgorgementYear-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                        id             = "wine-disgorgementYear-input"
                                        name           = "disgorgementYear"
                                        data-name      = "disgorgementYear"
                                        onInput        = {(e) => this.updateWineFields(e)}
                                        data-action    = "update"
                                        data-datagroup = "wine-characteristics-integer"
                                        data-target_hidden_elements = "key-wine-characteristics"
                                        maxlength="4"
                                        placeholder ="YYYY"/>
                              </div>
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.disgorgementYear} </p>
                              </div>
                            </div>


                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              Aged
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <input  class = "wine-aged-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                        id             = "wine-aged-input"
                                        name           = "aged"
                                        data-name      = "aged"
                                        onInput        = {(e) => this.updateWineFields(e)}
                                        data-action    = "update"
                                        data-datagroup = "wine-characteristics-float"
                                        data-target_hidden_elements = "key-wine-characteristics"
                                        maxlength="5"
                                        placeholder =""/>
                              </div>
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.aged} years</p>
                              </div>
                            </div>


                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              Time on lees (years)
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <input  class = "wine-periodOnLees-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                        id             = "wine-periodOnLees-input"
                                        name           = "periodOnLees"
                                        data-name      = "periodOnLees"
                                        onInput        = {(e) => this.updateWineFields(e)}
                                        data-action    = "update"
                                        data-datagroup = "wine-characteristics-float"
                                        data-target_hidden_elements = "key-wine-characteristics"
                                        maxlength="5"
                                        placeholder =""/>
                              </div>
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.periodOnLees} years</p>
                              </div>
                            </div>


                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Drinking Window (years)
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-drinkingTime-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                      id             = "wine-drinkingTime-input"
                                      name           = "drinkingTime"
                                      data-name      = "drinkingTime"
                                      onInput        = {(e) => this.updateWineFields(e)}
                                      data-action    = "update"
                                      data-datagroup = "wine-characteristics-integer"
                                      data-target_hidden_elements = "key-wine-characteristics"
                                      maxlength="2"
                                      placeholder =""/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.drinkingTime} years </p>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Drink After
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-startToDrink-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                            id             = "wine-startToDrink-input"
                                            name           = "startToDrink"
                                            data-name      = "startToDrink"
                                            onInput        = {(e) => this.updateWineFields(e)}
                                            data-action    = "update"
                                            data-datagroup = "wine-characteristics-integer"
                                            data-target_hidden_elements = "key-wine-characteristics"
                                            maxlength="4"
                                      placeholder ="YYYY"/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.startToDrink} </p>
                            </div>
                          </div>

                        </div>
                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Drink Before
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-endToDrink-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                      id             = "wine-endToDrink-input"
                                      name           = "endToDrink"
                                      data-name      = "endToDrink"
                                      onInput        = {(e) => this.updateWineFields(e)}
                                      data-action    = "update"
                                      data-datagroup = "wine-characteristics-integer"
                                      data-target_hidden_elements = "key-wine-characteristics"
                                      maxlength="4"
                                      placeholder ="YYYY"/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.endToDrink} </p>
                            </div>
                          </div>



                        </div>
                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            dosage/sugar
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-dosage-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                            id             = "wine-dosage-input"
                                            name           = "dosage"
                                            data-name      = "dosage"
                                            onInput        = {(e) => this.updateWineFields(e)}
                                            data-action    = "update"
                                            data-datagroup = "wine-characteristics-float"
                                            data-target_hidden_elements = "key-wine-characteristics"
                                            maxlength="5"
                                            placeholder ="e.g. 3.0"/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.dosage} g/litre</p>
                            </div>
                          </div>


                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Titratable Acidity
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "wine-titratableAcidity-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                            id             = "wine-titratableAcidity-input"
                                            name           = "titratableAcidity"
                                            data-name      = "titratableAcidity"
                                            onInput        = {(e) => this.updateWineFields(e)}
                                            data-action    = "update"
                                            data-datagroup = "wine-characteristics-float"
                                            data-target_hidden_elements = "key-wine-characteristics"
                                            maxlength="5"
                                            placeholder ="e.g. 6.4"/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.titratableAcidity} g/litre</p>
                            </div>
                          </div>



                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Carbonic Maceration
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = " checkbox-wrapper" >
                                    <label class ="checkbox_label">


                                      <input class = "carbonic-maceration-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        name = "carbonicMaceration"
                                        data-name = "carbonicMaceration"
                                        type = "checkbox"
                                        id = "carbonic-maceration-true"
                                        data-toggle_target = "carbonic-maceration-false"
                                        data-toggleGroup   = "carbonic-maceration"
                                        data-toggle_value  = "True"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "carbonic-maceration-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "carbonicMaceration"
                                        id = "carbonic-maceration-false"
                                        data-toggleGroup   = "carbonic-maceration"
                                        data-toggle_target = "carbonic-maceration-true"
                                        data-toggle_value  = "False"
                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.carbonicMaceration === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.carbonicMaceration.toString()} </p>}

                            </div>
                          </div>


                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Semi-carbonic Maceration
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "semi-carbonic-maceration-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "semiCarbonicMaceration"
                                        data-name   = "semiCarbonicMaceration"
                                        id = "semi-carbonic-maceration-true"
                                        data-toggle_target = "semi-carbonic-maceration-false"
                                        data-toggleGroup   = "semi-carbonic-maceration"
                                        data-toggle_value  = "True"

                                         />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "semi-carbonic-maceration-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "semiCarbonicMaceration"
                                        id = "semi-carbonic-maceration-false"
                                        data-toggle_target = "semi-carbonic-maceration-true"
                                        data-toggleGroup   = "semi-carbonic-maceration"
                                        data-toggle_value  = "False"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.semiCarbonicMaceration === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.semiCarbonicMaceration.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Malolactic Fermentation
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "malolactic-fermentation-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "malolacticFermentation"
                                        data-name  = "malolacticFermentation"
                                        id = "malolactic-fermentation-true"
                                        data-toggle_target = "malolactic-fermentation-false"
                                        data-toggleGroup   = "malolactic-fermentation"
                                        data-toggle_value  = "True"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "malolactic-fermentation-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "malolacticFermentation"
                                        id = "malolactic-fermentation-false"
                                        data-toggle_target = "malolactic-fermentation-true"
                                        data-toggleGroup   = "malolactic-fermentation"
                                        data-toggle_value  = "False"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.malolacticFermentation === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.malolacticFermentation.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>



                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Sustainable Practices
                          </div>


                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "sustainable-practices-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "sustainabilityInPractice"
                                        data-name   = "sustainabilityInPractice"
                                        id = "sustainable-practices-true"
                                        data-toggle_target = "sustainable-practices-false"
                                        data-toggleGroup   = "sustainable-practices"
                                        data-toggle_value  = "True"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "sustainable-practices-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "sustainabilityInPractice"
                                        id = "sustainable-practices-false"
                                        data-toggle_target = "sustainable-practices-true"
                                        data-toggleGroup   = "sustainable-practices"
                                        data-toggle_value  = "False"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.sustainabilityInPractice === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.sustainabilityInPractice.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>


                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Organic
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "organic-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "organic"
                                        data-name   = "organic"
                                        id = "organic-true"
                                        data-toggle_target = "organic-false"
                                        data-toggleGroup   = "organic"
                                        data-toggle_value  = "True"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> Certified</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "organic-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "organic"
                                        id = "organic-false"
                                        data-toggle_target = "organic-true"
                                        data-toggleGroup   = "organic"
                                        data-toggle_value  = "False"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.organic === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.organic.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Biodynamic
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "biodynamic-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "biodynamic"
                                        data-name   = "biodynamic"
                                        id = "biodynamic-true"
                                        data-toggle_target = "biodynamic-false"
                                        data-toggleGroup   = "biodynamic"
                                        data-toggle_value  = "True"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> Certified</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "biodynamic-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "biodynamic"
                                        id = "biodynamic-false"
                                        data-toggle_target = "biodynamic-true"
                                        data-toggleGroup   = "biodynamic"
                                        data-toggle_value  = "False"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.biodynamic === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.biodynamic.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Natural
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "natural-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "natural"
                                        data-name = "natural"
                                        id = "natural-true"
                                        data-toggle_target = "natural-false"
                                        data-toggleGroup   = "natural"
                                        data-toggle_value  = "True"
                                        />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "natural-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "natural"
                                        id = "natural-false"
                                        data-toggle_target = "natural-true"
                                        data-toggleGroup   = "natural"
                                        data-toggle_value  = "False"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.natural === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.natural.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>


                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Vegetarian
                          </div>


                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "vegetarian-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "vegetarian"
                                        data-name = "vegetarian"
                                        id = "vegetarian-true"
                                        data-toggle_target = "vegetarian-false"
                                        data-toggleGroup   = "vegetarian"
                                        data-toggle_value  = "True"
                                        />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "vegetarian-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "vegetarian"
                                        id = "vegetarian-false"
                                        data-toggle_target = "vegetarian-true"
                                        data-toggleGroup   = "vegetarian"
                                        data-toggle_value  = "False"
                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.vegetarian === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.vegetarian.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>

                      {/*<div class = "col-sm-6 col-md-6 offset-lg-3 col-lg-3">*/}
                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Vegan
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "vegan-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "vegan"
                                        data-name   = "vegan"
                                        id = "vegan-true"
                                        data-toggle_target = "vegan-false"
                                        data-toggleGroup   = "vegan"
                                        data-toggle_value  = "True"
                                        />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "vegan-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "vegan"
                                        id = "vegan-false"
                                        data-toggle_target = "vegan-true"
                                        data-toggleGroup   = "vegan"
                                        data-toggle_value  = "False"

                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.vegan === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.vegan.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Contains Sulphites
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <ul class = "key-wine-characteristics binary_selection_input_buttons hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "contains-sulphites-true-class wine_profile_checkbox_input key-wine-characteristics-value-select key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "containsSulphites"
                                        data-name   = "containsSulphites"
                                        id = "contains-sulphites-true"
                                        data-toggle_target = "contains-sulphites-false"
                                        data-toggleGroup   = "contains-sulphites"
                                        data-toggle_value  = "True"
                                        />

                                      <span style = {{textTransform:"uppercase"}}> Yes</span>

                                    </label>
                                  </div>
                                </li>
                                <li class = "binary_selection_input_buttons_value">
                                  <div class = "checkbox-wrapper">
                                    <label class ="checkbox_label">


                                      <input class = "contains-sulphites-false-class wine_profile_checkbox_input key-wine-characteristics-value-clear"
                                        onInput = {(e) => this.captureCheckboxAndToggle(e)}
                                        type = "checkbox"
                                        name = "containsSulphites"
                                        id = "contains-sulphites-false"
                                        data-toggle_target = "contains-sulphites-true"
                                        data-toggleGroup   = "contains-sulphites"
                                        data-toggle_value  = "False"
                                        />

                                      <span style = {{textTransform:"uppercase"}}> No</span>

                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.containsSulphites === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.containsSulphites.toString()} </p>}

                            </div>
                          </div>

                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6  col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Fining Agents
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "key-wine-characteristics wrapper_search_box hidden" data-target_hidden_elements = "key-wine-characteristics">
                                <div class = "search-input" id = "finingAgents-input-element">
                                  <input
                                    /*onClick = {(e) => this.searchSuggestions(e)}*/
                                    class = "search-finingAgents-field-class key-wine-characteristics-value-clear"
                                    onChange={(e) => self.filterArrayDataFromElement(e)}
                                    /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                    type = "text"
                                    id = "search-finingAgents-field"
                                    data-objectToFilter = "finingAgentsMapData"
                                    data-filteredObject = "finingAgentsMapFiltered"
                                    data-filterField    = "FINING_AGENT"
                                    data-suggestBox     = "autocom-box-finingAgents-search"
                                    data-datagroup      = "wine-characteristics"
                                    placeholder         = "FINING AGENTS"/>

                                    <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                      <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                    </div>

                                    <div class = "autocom-box"
                                         id    = 'autocom-box-finingAgents-search' >
                                      { this.state.finingAgentsMapFiltered.slice(0, 5).map((wineRefData) =>
                                        <li key = {wineRefData.FINING_AGENT_CODE}
                                          class = "key-wine-characteristics-value-select"
                                          onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                          id          = {"wine-finingAgents-input"+ "-" + wineRefData.FINING_AGENT_CODE}
                                          data-action = "update"
                                          data-targetInputBox = "search-finingAgents-field"
                                          data-name   = "finingAgents"
                                          >
                                          <label class = "filter-bar-label">
                                            {/*<input type="radio"/>*/}
                                              {wineRefData.FINING_AGENT}
                                          </label>
                                        </li>
                                      ) }
                                    </div>
                                </div>
                              </div>
                            </div> {/*end column*/}
                          </div> {/*end row*/}

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.finingAgents === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.finingAgents.toString()} </p>}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              Filtration
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <input  class = "wine-filtration-input-class key-wine-characteristics key-wine-characteristics-value-select key-wine-characteristics-value-clear wine_profile_box_input hidden"
                                        id          = "wine-filtration-input"
                                        name        = "filtration"
                                        data-name   = "filtration"
                                        onInput     = {(e) => this.updateWineFields(e)}
                                        data-action = "update"
                                        data-target_hidden_elements = "key-wine-characteristics"
                                        placeholder = "FILTRATIION"/>
                              </div>
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                {this.props.winelist.wineDetails.filtration === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.filtration.toString()} </p>}
                              </div>
                            </div>


                        </div>
                      </div>

                      <div class = "col-sm-12 offset-md-4 col-md-4">
                        <button id = 'confirm-key-wine-characteristics-btn'
                                data-hidden_group = "key-wine-characteristics"
                                data-target_update_values = "key-wine-characteristics-value"
                                data-target_hidden_elements = "key-wine-characteristics"
                                data-target_edit_button_id  = "edit-key-wine-characteristics-btn"
                                onClick={(e) => this.handleUpdateDetails(e)}
                                class = "key-wine-characteristics button button-primary confirm-update-button hidden"
                                style = {{margin:"4px 4px 4px 4px"}}>

                          Confirm Details
                          </button>
                      </div>



                    </div>
                  </div>
                </div>
              </div>

              {/*Site and smell*/}
              <div class = "row">
                <div class = "wine_profile_origin-info-wrapper" >
                  <div class = "wine_profile_origin-info-container-two">
                    <div class = "main-page-content">
                      <div class="wine_profile__row__strapline">
                        <div class = "row">
                          <div class = "col-sm-12 col-md-10 col-lg-10">
                            {/*<div class = "section-title-wrapper">*/}
                            <div class = "col-sm-12 col-md-10 col-lg-10">
                              <span style = {{textTransform:"none"}}> Appearance and Nose </span>
                            </div>
                          </div>
                        <div class ="col-sm-12 col-md-2 col-lg-2">
                          <div class = "section-button-wrapper">
                            <button id = 'edit-appearance-and-nose-btn'
                                    class = "button button-primary confirm-update-button"
                                    onClick = {(e) => this.unhideFormElements(e)}
                                    data-target_hidden_elements = "appearance-and-nose"
                                    style = {{margin:"4px 4px 4px 4px"}}>

                              <div style = {{fontSize:"12px"}}>Edit Section</div>
                              </button>

                              <button id = 'cancel-appearance-and-nose-btn'
                                      class = "appearance-and-nose button button-primary cancel-update-button hidden"
                                      onClick = {(e) => this.cancelSectionEdits(e)}
                                      data-target_hidden_elements = "appearance-and-nose"
                                      data-target_edit_button_id  = "edit-appearance-and-nose-btn"
                                      data-target_update_values = "appearance-and-nose-value"
                                      style = {{margin:"4px 4px 4px 4px"}}>

                                <div style = {{fontSize:"12px"}}>Cancel</div>
                              </button>
                            </div>

                          </div>
                      </div>{/*end row*/}
                    </div>
                  </div>
                </div>

                </div>
                <div class = "col-sm-12 col-md-6 col-lg-6">

                  {/*Appearance*/}
                  <div class = "wine_profile_origin-info-wrapper" >
                    <div class = "wine_profile_origin-info-container" style = {{backgroundColor: "#fcfcfc"}}>


                    <div class = "main-page-content">
                      <div class="wine_profile__row__strapline">
                        {/*<div class = "wine_profile-inpage-elements-wrapper">*/}
                        <div class = "section-title-wrapper">

                          <span style = {{textTransform:"none"}}>Appearance </span>
                        </div>
                      </div>
                    </div>



                      <div class = "wine_profile-info-wrapper">
                        <div class = "wine_profile-info row" >


                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            {/*<div class = "wine_profile_origin-info-wrapper" >*/}
                            <div class = "wine_profile_fields_container" >

                              <div class = "wine_profile-inpage-elements-wrapper">
                                Appearance
                              </div>

                              <div class ="row">
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  <textarea  class = "wine-appearance-input-class appearance-and-nose appearance-and-nose-value-select appearance-and-nose-value-clear wine_profile_textarea_input hidden"
                                          id          = "wine-appearance-input"
                                          data-hidden_group    = "appearance-and-nose"
                                          name        = "appearance"
                                          data-name   = "appearance"
                                          onInput     = {(e) => this.updateWineFields(e)}
                                          data-action = "update"
                                          wrap = "hard"
                                          placeholder ="Describe the colour and clarity of the wine"/>
                                </div>
                              </div>

                              <div class ="row">
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  {this.props.winelist.wineDetails.appearance === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.appearance.toString()} </p>}
                                </div>
                              </div>

                            </div>

                          </div>

                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "wine_profile_fields_container" >
                              <div class = "wine_profile-inpage-elements-wrapper" >
                                Colour Intensity
                              </div>



                              <div class = "appearance-and-nose range hidden">

                                <div class = "appearance-and-nose rangeField hidden"  data-hidden_group = "appearance-and-nose">
                                  <div class = "rangeField-value_left"></div>

                                  <input onInput = {(e) => this.showSliderAndValue(e)}
                                          id = "colour-itensity-range-slider"
                                          name        = "colorIntensity"
                                          data-name   = "colorIntensity"
                                          value  = {this.state.colorIntensity}
                                          data-range_value_output_field = "colour-itensity-range-output"
                                          class = "colour-itensity-range-slider-class rangeField-Input appearance-and-nose-value-select appearance-and-nose-value-clear"
                                          type = "range"
                                          min = "0"
                                          max = "2"
                                          steps = "1"/>
                                  <div  class = "rangeField-value_right"></div>
                                </div>
                              </div>

                              <div id  = "colour-itensity-range-output" class = "appearance-and-nose output-area hidden"  data-hidden_group = "appearance-and-nose">
                                Slide for level
                              </div>

                              <div class ="row">
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  {this.props.winelist.wineDetails.colorIntensity === null ? <></> : <p class = "cart-form-text-element">  {this.state.colourItensityScale[this.props.winelist.wineDetails.colorIntensity]} </p>}
                                </div>
                              </div>

                            </div> {/*end wine profile fields containter*/}




                          </div>



                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class = "col-sm-12 col-md-6 col-lg-6">

                  {/*Nose*/}
                  <div class = "wine_profile_origin-info-wrapper" >
                    <div class = "wine_profile_origin-info-container-two">
                      <div class = "main-page-content">
                        <div class="wine_profile__row__strapline">
                          {/*<div class = "wine_profile-inpage-elements-wrapper">*/}
                          <div class = "section-title-wrapper">

                            <span style = {{textTransform:"none"}}> Nose </span>
                          </div>
                        </div>
                      </div>


                      <div class = "wine_profile-info-wrapper">
                        <div class = "wine_profile-info row" >


                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "wine_profile_fields_container" >

                              <div class = "wine_profile-inpage-elements-wrapper">
                                Nose
                              </div>

                              <div class ="row">
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  <textarea  class = "wine-nose-input-class appearance-and-nose appearance-and-nose-value-select appearance-and-nose-value-clear wine_profile_textarea_input hidden"
                                          id          = "wine-nose-input"
                                          data-hidden_group = "appearance-and-nose"
                                          name        = "nose"
                                          data-name   = "nose"
                                          onInput     = {(e) => this.updateWineFields(e)}
                                          data-action = "update"
                                          wrap = "hard"
                                          placeholder ="Highlight the aromas"/>
                                </div>
                              </div>

                              <div class ="row">
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  {this.props.winelist.wineDetails.nose === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.nose.toString()} </p>}
                                </div>
                              </div>


                            </div>

                          </div>

                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "wine_profile_fields_container" >
                              <div class = "wine_profile-inpage-elements-wrapper" >
                                Nose Intensity
                              </div>



                              <div class = "appearance-and-nose range hidden">

                                <div class = "appearance-and-nose rangeField hidden">
                                  <div class = "rangeField-value_left"></div>

                                  <input onInput = {(e) => this.showSliderAndValue(e)}
                                        id = "nose-intensity-range-slider"
                                        name   = "noseintensity"
                                        data-name = "noseintensity"
                                        value  = {this.state.noseintensity}
                                        data-range_value_output_field = "nose-itensity-range-output"
                                        class = "nose-intensity-range-slider-class rangeField-Input appearance-and-nose-value-select appearance-and-nose-value-clear"
                                        type = "range"
                                        min = "0"
                                        max = "4"
                                        steps = "1"/>
                                  <div  class = "rangeField-value_right"></div>
                                </div>
                              </div>

                              <div id  = "nose-itensity-range-output" class = "appearance-and-nose output-area hidden" data-hidden_group = "appearance-and-nose">
                                Slide for level
                              </div>

                              <div class ="row">
                                <div class = "col-sm-12 col-md-12 col-lg-12">
                                  {this.props.winelist.wineDetails.noseintensity === null ? <></> : <p class = "cart-form-text-element">  {this.state.noseItensityScale[this.props.winelist.wineDetails.noseintensity]} </p>}
                                </div>
                              </div>

                            </div> {/* end wine_profile_fields_container*/}




                          </div>



                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div class = "col-sm-12 offset-md-4 col-md-4">
                  <button id = 'confirm-appearance-and-nose-btn'
                          data-hidden_group = "appearance-and-nose"
                          data-target_update_values = "appearance-and-nose-value"
                          data-target_hidden_elements = "appearance-and-nose"
                          data-target_edit_button_id  = "edit-appearance-and-nose-btn"
                          onClick={(e) => this.handleUpdateDetails(e)}
                          class = "appearance-and-nose button button-primary confirm-update-button hidden"
                          style = {{margin:"4px 4px 4px 4px"}}>

                    Confirm Details
                    </button>
                </div>

              </div>

              {/*What does the wine taste like*/}
              <div class = "wine_profile_origin-info-wrapper" >
                <div class = "wine_profile_origin-info-container-two">
                  <div class = "main-page-content">
                    <div class="wine_profile__row__strapline">
                      <div class = "row">
                        <div class = "col-sm-12 col-md-10 col-lg-10">
                          <div class = "col-sm-12 col-md-10 col-lg-10">
                            <span style = {{textTransform:"none"}}> What does the wine taste like </span>
                          </div>
                        </div>
                        <div class ="col-sm-12 col-md-2 col-lg-2">
                          <div class = "section-button-wrapper">
                            <button id = 'edit-what-does-the-wine-taste-like-btn'
                                    class = "button button-primary confirm-update-button"
                                    onClick = {(e) => this.unhideFormElements(e)}
                                    data-target_hidden_elements = "what-does-the-wine-taste-like"
                                    style = {{margin:"4px 4px 4px 4px"}}>

                              <div style = {{fontSize:"12px"}}>Edit Section</div>
                              </button>

                              <button id = 'cancel-what-does-the-wine-taste-like-btn'
                                      class = "what-does-the-wine-taste-like button button-primary cancel-update-button hidden"
                                      onClick = {(e) => this.cancelSectionEdits(e)}
                                      data-target_hidden_elements = "what-does-the-wine-taste-like"
                                      data-target_edit_button_id  = "edit-what-does-the-wine-taste-like-btn"
                                      data-target_update_values = "what-does-the-wine-taste-like-value"
                                      style = {{margin:"4px 4px 4px 4px"}}>

                                <div style = {{fontSize:"12px"}}>Cancel</div>
                              </button>
                            </div>

                        </div>


                      </div>
                    </div>
                  </div>

                  <div class = "wine_profile-info-wrapper">
                    <div class = "wine_profile-info row" >


                    <div class = "col-sm-12 offset-md-3 col-md-6 offset-lg-3 col-lg-6">
                      <div class = "wine_profile_fields_container" >

                        <div class = "wine_profile-inpage-elements-wrapper">
                          Palate
                        </div>

                        <div class ="row">
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            {this.props.winelist.wineDetails.palate === null ? <></> : <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.palate.toString()} </p>}
                          </div>
                        </div>


                      </div>

                    </div>

                    {/*blank column*/}
                    <div class = "col-sm-0  col-md-3 col-lg-3">
                    </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >

                          <div class = "wine_profile-inpage-elements-wrapper">
                            Sweetness
                          </div>




                          <div class = "what-does-the-wine-taste-like range hidden">

                            <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              <div class = "rangeField-value_left"></div>

                              <input onInput = {(e) => this.showSliderAndValue(e)}
                                      id = "sweetness-range-slider"
                                      name        = "sweetness"
                                      data-name   = "sweetness"
                                      value  = {this.state.sweetness}
                                      data-range_value_output_field = "sweetness-range-output"
                                      class = "sweetness-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                      type = "range" min = "0"
                                      max = "6"
                                      steps = "1"/>
                              <div  class = "rangeField-value_right"></div>
                            </div>
                          </div>

                          <div id  = "sweetness-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                            Slide for level
                          </div>
                        </div>

                        <div class ="row">
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            {this.props.winelist.wineDetails.sweetness === null ? <></> : <p class = "cart-form-text-element">  {this.state.sweetnessSacle[this.props.winelist.wineDetails.sweetness]} </p>}
                          </div>
                        </div>



                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Acidity Perception
                          </div>



                          <div class = "what-does-the-wine-taste-like range hidden">

                            <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              <div class = "rangeField-value_left"></div>

                              <input onInput = {(e) => this.showSliderAndValue(e)}
                                      id = "acidity-perception-range-slider"
                                      name        = "acidity"
                                      data-name   = "acidity"
                                      value  = {this.state.acidity}
                                      data-range_value_output_field = "acidity-perception-range-output"
                                      class = "acidity-perception-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                      type = "range"
                                      min = "0"
                                      max = "4"
                                      steps = "1"/>
                              <div  class = "rangeField-value_right"></div>
                            </div>
                          </div>

                          <div id  = "acidity-perception-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                            Slide for level
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.acidity === null ? <></> : <p class = "cart-form-text-element">  {this.state.acidityScale[this.props.winelist.wineDetails.acidity]} </p>}
                            </div>
                          </div>
                        </div>





                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Tannin
                          </div>



                          <div class = "what-does-the-wine-taste-like range hidden">

                            <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              <div class = "rangeField-value_left"></div>

                              <input onInput = {(e) => this.showSliderAndValue(e)}
                                    id = "tannin-perception-range-slider"
                                    name        = "tanin"
                                    data-name   = "tanin"
                                    value  = {this.state.tanin}
                                    data-range_value_output_field = "tannin-perception-range-output"
                                    class = "tannin-perception-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                    type = "range"
                                    min = "0"
                                    max = "4"
                                    steps = "1"/>
                              <div  class = "rangeField-value_right"></div>
                            </div>
                          </div>

                          <div id  = "tannin-perception-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                            Slide for level
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.tanin === null ? <></> : <p class = "cart-form-text-element">  {this.state.tanninScale[this.props.winelist.wineDetails.tanin]} </p>}
                            </div>
                          </div>
                        </div>



                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                             Alcohol perception
                          </div>




                          <div class = "what-does-the-wine-taste-like range hidden">

                            <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              <div class = "rangeField-value_left"></div>

                              <input onInput = {(e) => this.showSliderAndValue(e)}
                                      id = "alcohol-perception-range-slider"
                                      name        = "alcohol"
                                      data-name   = "alcohol"
                                      value  = {this.state.alcohol}
                                      data-range_value_output_field = "alcohol-perception-range-output"
                                      class = "alcohol-perception-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                      type = "range"
                                      min = "0"
                                      max = "3"
                                      steps = "1"/>
                              <div  class = "rangeField-value_right"></div>
                            </div>
                          </div>



                          <div id  = "alcohol-perception-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                            Slide for level
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.alcohol === null ? <></> : <p class = "cart-form-text-element">  {this.state.alcoholScale[this.props.winelist.wineDetails.alcohol]} </p>}
                            </div>
                          </div>

                        </div>



                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              Body
                            </div>



                            <div class = "what-does-the-wine-taste-like range hidden">

                              <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                                <div class = "rangeField-value_left"></div>

                                <input onInput = {(e) => this.showSliderAndValue(e)}
                                       id = "body-perception-range-slider"
                                       name        = "body"
                                       data-name   = "body"
                                       value  = {this.state.body}
                                       data-range_value_output_field = "body-perception-range-output"
                                       class = "body-perception-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                       type = "range"
                                       min = "0"
                                       max = "2"
                                       steps = "1"/>
                                <div  class = "rangeField-value_right"></div>
                              </div>
                            </div>

                            <div id  = "body-perception-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              Slide for level
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                {this.props.winelist.wineDetails.body === null ? <></> : <p class = "cart-form-text-element">  {this.state.bodyScale[this.props.winelist.wineDetails.body]} </p>}
                              </div>
                            </div>

                        </div>



                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Mousse
                          </div>


                          <div class = "what-does-the-wine-taste-like range hidden">

                            <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              <div class = "rangeField-value_left"></div>

                              <input onInput = {(e) => this.showSliderAndValue(e)}
                                    id = "mousse-perception-range-slider"
                                    name        = "mouse"
                                    data-name   = "mouse"
                                    value  = {this.state.mouse}
                                    data-range_value_output_field = "mousse-perception-range-output"
                                    class = "mousse-perception-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                    type = "range"
                                    min = "0"
                                    max = "2"
                                    steps = "1"/>
                              <div  class = "rangeField-value_right"></div>
                            </div>
                          </div>

                          <div id  = "mousse-perception-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                            Slide for level
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.mouse === null ? <></> : <p class = "cart-form-text-element">  {this.state.mousseScale[this.props.winelist.wineDetails.mouse]} </p>}
                            </div>
                          </div>

                        </div>





                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Flavour Intensity
                          </div>



                          <div class = "what-does-the-wine-taste-like range hidden">

                            <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              <div class = "rangeField-value_left"></div>

                              <input onInput = {(e) => this.showSliderAndValue(e)}
                                     id = "flavour-itensity-range-slider"
                                     name        = "flavourIntensity"
                                     data-name   = "flavourIntensity"
                                     value  = {this.state.flavourIntensity}
                                     data-range_value_output_field = "flavour-itensity-range-output"
                                     class = "flavour-itensity-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                     type = "range"
                                     min = "0"
                                     max = "4"
                                     steps = "1"/>
                              <div  class = "rangeField-value_right"></div>
                            </div>
                          </div>


                          <div id  = "flavour-itensity-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                            Slide for level
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.flavourIntensity === null ? <></> : <p class = "cart-form-text-element">  {this.state.flavourItensityScale[this.props.winelist.wineDetails.flavourIntensity]} </p>}
                            </div>
                          </div>

                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Length
                          </div>


                          <div class = "what-does-the-wine-taste-like range hidden">

                            <div class = "what-does-the-wine-taste-like rangeField hidden" data-hidden_group = "what-does-the-wine-taste-like">
                              <div class = "rangeField-value_left"></div>

                              <input onInput = {(e) => this.showSliderAndValue(e)}
                                     id = "flavour-length-range-slider"
                                     name        = "length"
                                     data-name   = "length"
                                     value  = {this.state.length}
                                     data-range_value_output_field = "flavour-length-itensity-range-output"
                                     class = "flavour-length-range-slider-class rangeField-Input what-does-the-wine-taste-like-value-select what-does-the-wine-taste-like-value-clear"
                                     type = "range"
                                     min = "0"
                                     max = "4"
                                     steps = "1"/>
                              <div  class = "rangeField-value_right"></div>
                            </div>
                          </div>

                          <div id  = "flavour-length-itensity-range-output" class = "what-does-the-wine-taste-like output-area hidden" data-hidden_group = "what-does-the-wine-taste-like">
                            Slide for level
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              {this.props.winelist.wineDetails.length === null ? <></> : <p class = "cart-form-text-element">  {this.state.lengthScale[this.props.winelist.wineDetails.length]} </p>}
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class = "col-sm-12 offset-md-4 col-md-4">
                        <button id = 'confirm-what-does-the-wine-taste-like-btn'
                                data-hidden_group = "what-does-the-wine-taste-like"
                                data-target_update_values = "what-does-the-wine-taste-like-value"
                                data-target_hidden_elements = "what-does-the-wine-taste-like"
                                data-target_edit_button_id  = "edit-what-does-the-wine-taste-like-btn"
                                onClick={(e) => this.handleUpdateDetails(e)}
                                class = "what-does-the-wine-taste-like button button-primary confirm-update-button hidden"
                                style = {{margin:"4px 4px 4px 4px"}}>

                          Confirm Details
                          </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              {/*how to drink this wine*/}
              <div class = "wine_profile_origin-info-wrapper" >
                <div class = "wine_profile_origin-info-container" style = {{backgroundColor: "#fcfcfc"}}>
                  <div class = "main-page-content">
                    <div class="wine_profile__row__strapline">
                      <div class = "row">
                        <div class = "col-sm-12 col-md-10 col-lg-10">
                          <div class = "section-title-wrapper">
                            <span style = {{textTransform:"none"}}>How does the wine drink </span>
                          </div>

                        </div>
                        <div class ="col-sm-12 col-md-2 col-lg-2">
                          <div class = "section-button-wrapper">
                            <button id = 'edit-how-does-the-wine-drink-btn'
                                    class = "button button-primary confirm-update-button"
                                    onClick = {(e) => this.unhideFormElements(e)}
                                    data-target_hidden_elements = "how-does-the-wine-drink"
                                    style = {{margin:"4px 4px 4px 4px"}}>

                              <div style = {{fontSize:"12px"}}>Edit Section</div>
                              </button>

                              <button id = 'cancel-how-does-the-wine-drink-from-btn'
                                      class = "how-does-the-wine-drink button button-primary cancel-update-button hidden"
                                      onClick = {(e) => this.cancelSectionEdits(e)}
                                      data-target_hidden_elements = "how-does-the-wine-drink"
                                      data-target_edit_button_id  = "edit-how-does-the-wine-drink-btn"
                                      data-target_update_values = "how-does-the-wine-drink-value"
                                      style = {{margin:"4px 4px 4px 4px"}}>

                                <div style = {{fontSize:"12px"}}>Cancel</div>
                              </button>
                            </div>

                        </div>


                      </div>
                    </div>
                  </div>

                  <div class = "wine_profile-info-wrapper">
                    <div class = "wine_profile-info row" >

                    <div class = "col-sm-6 col-md-4 col-lg-4">
                      <div class = "wine_profile_fields_container" >

                        <div class = "wine_profile-inpage-elements-wrapper">
                            Food Pairings
                        </div>

                        <div class ="row">
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "how-does-the-wine-drink wrapper_search_box hidden" data-hidden_group = "how-does-the-wine-drink">
                              <div class = "search-input" id = "foodPairingGroup-input-element">
                                <input
                                  /*onClick = {(e) => this.searchSuggestions(e)}*/
                                  class = "search-foodPairingGroup-field-class how-does-the-wine-drink-value-clear"
                                  onChange={(e) => self.filterArrayDataFromElement(e)}
                                  /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                  type = "text"
                                  id = "search-foodPairingGroup-field"
                                  data-objectToFilter = "foodTypesMapData"
                                  data-filteredObject = "foodTypesMapFiltered"
                                  data-filterField    = "FOOD_TYPE"
                                  data-suggestBox     = "autocom-box-foodPairingGroup-search"
                                  data-datagroup      = "wine-food-parings"
                                  placeholder         = "FOOD GROUP"/>

                                  <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                    <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                  </div>

                                  <div class = "autocom-box"
                                       id    = 'autocom-box-foodPairingGroup-search' >
                                    { this.state.foodTypesMapFiltered.slice(0, 5).map((wineRefData) =>
                                      <li key = {wineRefData.FOOD_TYPE_CODE}
                                        class   = "how-does-the-wine-drink-value-select"
                                        onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                        id          = {"wine-foodPairingGroup-input"+ "-" + wineRefData.FOOD_TYPE_CODE}
                                        data-action = "update"
                                        data-targetInputBox = "search-foodPairingGroup-field"
                                        data-name   = "foodPairingGroup"
                                        >
                                        <label class = "filter-bar-label">
                                          {/*<input type="radio"/>*/}
                                            {wineRefData.FOOD_TYPE}
                                        </label>
                                      </li>
                                    ) }
                                  </div>
                              </div>
                            </div>
                          </div> {/*end column*/}
                        </div> {/*end row*/}

                        <p class = "cart-form-text-element"> {this.props.winelist.wineDetails.foodPairingGroup} </p>

                        <div class ="row">
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "how-does-the-wine-drink wrapper_search_box hidden" data-hidden_group = "how-does-the-wine-drink">
                              <div class = "search-input" id = "pairWith-input-element">
                                <input
                                  /*onClick = {(e) => this.searchSuggestions(e)}*/
                                  class = "search-pairWith-field-class how-does-the-wine-drink-value-clear"
                                  onChange={(e) => self.filterArrayDataFromElement(e)}
                                  /*onBlur = {(e) => self.filterArrayDataFromElementBlur(e)}*/
                                  type = "text"
                                  id = "search-pairWith-field"
                                  data-objectToFilter = "foodNamesMapData"
                                  data-filteredObject = "foodNamesMapFiltered"
                                  data-filterField    = "FOOD_NAME"
                                  data-suggestBox     = "autocom-box-pairWith-search"
                                  data-datagroup      = "wine-food-parings"
                                  placeholder         = "FOOD"/>

                                  <div aria-hidden="true" class="search-global-typeahead__search-icon-container">
                                    <div class = "search-input_icon"><i class="fas fa-search"></i></div>
                                  </div>

                                  <div class = "autocom-box"
                                       id    = 'autocom-box-pairWith-search' >
                                    { this.state.foodNamesMapFiltered.slice(0, 5).map((wineRefData) =>
                                      <li key = {wineRefData.FOOD_CODE}
                                        class = "how-does-the-wine-drink-value-select"
                                        onClick = {(e) => this.updateWineFieldsFromSearch(e)}
                                        id          = {"wine-pairWith-input"+ "-" + wineRefData.FOOD_CODE}
                                        data-action = "update"
                                        data-targetInputBox = "search-pairWith-field"
                                        data-name   = "pairWith"
                                        >
                                        <label class = "filter-bar-label">
                                          {/*<input type="radio"/>*/}
                                            {wineRefData.FOOD_NAME}
                                        </label>
                                      </li>
                                    ) }
                                  </div>
                              </div>
                            </div>
                          </div> {/*end column*/}
                        </div> {/*end row*/}

                        <p class = "cart-form-text-element"> {this.props.winelist.wineDetails.pairWith} </p>
                      </div>

                    </div>

                    <div class = "col-sm-6 col-md-4 col-lg-4">
                      <div class = "wine_profile_fields_container" >

                        <div class = "wine_profile-inpage-elements-wrapper">
                          Minimum Serving Temperature
                        </div>



                        <div class = "row">
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "how-does-the-wine-drink range hidden">

                              <div class = "how-does-the-wine-drink rangeField hidden" data-hidden_group = "how-does-the-wine-drink">
                                <div class = "rangeField-value_left"></div>

                                  <input
                                    onInput = {(e) => this.showSliderAndValue(e)}
                                    id     = "min-service-range-slider"
                                    name      = "minServingTempurature"
                                    data-name = "minServingTempurature"
                                    value  = {this.state.minServingTempurature}
                                    data-range_value_output_field = "min-service-range-output"
                                    class   = "min-service-range-slider-class rangeField-Input how-does-the-wine-drink-value-select how-does-the-wine-drink-value-clear "
                                    type    = "range" min = "6"
                                    max     = "15"
                                    steps  = "1"/>

                              </div>
                            </div>

                            <div id  = "min-service-range-output" class = "how-does-the-wine-drink output-area hidden">
                              Slide for level
                            </div>

                            <p class = "cart-form-text-element"> {this.props.winelist.wineDetails.minServingTempurature} </p>

                          </div>
                        </div>
                      </div>

                    </div>

                    <div class = "col-sm-6 col-md-4 col-lg-4">
                      <div class = "wine_profile_fields_container" >

                        <div class = "wine_profile-inpage-elements-wrapper">
                          Max Serving Temperature
                        </div>



                        <div class = "row">
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <div class = "how-does-the-wine-drink range hidden">


                              <div class = "how-does-the-wine-drink rangeField hidden" data-hidden_group = "how-does-the-wine-drink">
                                <div class = "rangeField-value_left"></div>

                                <input onInput = {(e) => this.showSliderAndValue(e)}
                                                  id = "max-service-range-slider"
                                                  data-range_value_output_field = "max-service-range-output"
                                                  name      = "maxServingTempurature"
                                                  data-name = "maxServingTempurature"
                                                  value  = {this.state.maxServingTempurature}
                                                  class = "max-service-range-slider-class rangeField-Input how-does-the-wine-drink-value-select how-does-the-wine-drink-value-clear"
                                                  type = "range"
                                                  min = "9"

                                                  max = "18"
                                                  steps = "1"/>

                              </div>
                            </div>

                            <div id  = "max-service-range-output" class = "how-does-the-wine-drink output-area hidden">
                              Slide for level
                            </div>

                            <p class = "cart-form-text-element"> {this.props.winelist.wineDetails.maxServingTempurature} </p>

                          </div>
                        </div>
                      </div>

                    </div>

                    <div class = "col-sm-6 col-md-4 col-lg-4">
                      <div class = "wine_profile_fields_container" >
                        <div class = "wine_profile-inpage-elements-wrapper" >
                          Prime time to drink
                        </div>

                        <div class ="row">
                          <div class = "col-sm-12 col-md-12 col-lg-12">
                            <textarea  class = "wine-readinessToDrink-input-class how-does-the-wine-drink how-does-the-wine-drink-value-select how-does-the-wine-drink-value-clear wine_profile_textarea_input hidden"
                                    id          = "wine-readinessToDrink-input"
                                    name        = "readinessToDrink"
                                    data-name   = "readinessToDrink"
                                    onInput     = {(e) => this.updateWineFields(e)}
                                    data-action = "update"
                                    data-hidden_group = "how-does-the-wine-drink"
                                    wrap = "hard"
                                    placeholder ="Will the wine benefit from ageing or should it be drunk young?"/>
                          </div>
                        </div>

                        <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.readinessToDrink} </p>
                      </div>


                    </div>






                      <div class = "col-sm-12 col-md-4  col-lg-4">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Quality Perception
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <textarea  class = "wine-wineQualityPerception-input-class how-does-the-wine-drink how-does-the-wine-drink-value-select how-does-the-wine-drink-value-clear wine_profile_textarea_input hidden"
                                      id          = "wine-wineQualityPerception-input"
                                      name        = "wineQualityPerception"
                                      data-name   = "wineQualityPerception"
                                      onInput     = {(e) => this.updateWineFields(e)}
                                      data-action = "update"
                                      data-hidden_group = "how-does-the-wine-drink"
                                      wrap = "hard"
                                      placeholder ="How would you describe the quality of this wine?"/>
                            </div>
                          </div>

                          <p class = "cart-form-text-element"> {this.props.winelist.wineDetails.wineQualityPerception} </p>
                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-4 col-lg-4">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            How you rate this wine
                          </div>



                          <div class = "row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <div class = "how-does-the-wine-drink range hidden">
                                {/*<div class = "sliderValue">
                                  <span class = "sliderValueText">100</span>
                                </div>*/}
                                {/*div class = field*/}

                                <div class = "how-does-the-wine-drink rangeField hidden" data-hidden_group = "how-does-the-wine-drink">
                                  <div class = "rangeField-value_left">0</div>
                                  {/*<input type = "range" min = "0" max = "100" value = "100" steps = "1"/>*/}
                                  <input onInput = {(e) => this.showSliderAndValue(e)}
                                          id = "quality-range-slider"
                                          name   = "quality"
                                          data-name   = "quality"
                                          value  = {this.state.quality}
                                          data-range_value_output_field = "quality-range-output"
                                          class = "quality-range-slider-class rangeField-Input how-does-the-wine-drink-value-select how-does-the-wine-drink-value-clear"
                                          type = "range" min = "0"
                                          max = "100"
                                          steps = "1"/>

                                </div>
                              </div>

                              <div id  = "quality-range-output" class = "how-does-the-wine-drink output-area hidden">
                                Slide for level
                              </div>

                              <p class = "cart-form-text-element">   {this.props.winelist.wineDetails.quality}  </p>

                              {/*<script>
                                sliderValue_Text
                                const slideValue = document.querySelector("span");
                                const inputSlider = document.querySelector("span");
                                inputSlider.oninput = (() => {
                                  let value = inputSlider.value;
                                  slideValue.textContent = value;
                                  slideValue.style.left = (value) + "%";
                                  slideValue.classList.add("show")
                                });
                                inputSlider.onblur = (() => {
                                  slideValue.classList.add("show")
                              })
                              </script>*/}

                            </div>
                          </div>
                        </div>


                      </div>


                      <div class = "col-sm-12 offset-md-4 col-md-4">
                        <button id = 'confirm-how-does-the-wine-drink-btn'
                                data-hidden_group = "how-does-the-wine-drink"
                                data-target_update_values = "how-does-the-wine-drink-value"
                                data-target_hidden_elements = "how-does-the-wine-drink"
                                data-target_edit_button_id  = "edit-how-does-the-wine-drink-btn"
                                onClick={(e) => this.handleUpdateDetails(e)}
                                class = "how-does-the-wine-drink button button-primary confirm-update-button hidden"
                                style = {{margin:"4px 4px 4px 4px"}}>

                          Confirm Details
                          </button>
                      </div>


                    </div>
                  </div>
                </div>
              </div>





              {/*wine region info*/}
              <div class = "wine_profile_origin-info-wrapper" >
                {/*<div class = "wine_profile_origin-info-container"style = {{backgroundColor: "#f7f3f0"}}>*/}
                <div class = "wine_profile_origin-info-container"style = {{backgroundColor: "#dbf3fd"}}>

                  <div class = "main-page-content">
                    <div class="wine_profile__row__strapline">
                      <div class = "row">
                        <div class = "col-sm-12 col-md-10 col-lg-10">
                          <div class = "section-title-wrapper">
                            <span style = {{textTransform:"none"}}>Price {this.props.winelist.wineDetails.timestamp.substring(0, 10)} </span>
                          </div>

                        </div>
                        <div class ="col-sm-12 col-md-2 col-lg-2">
                          <div class = "section-button-wrapper">
                            <button id = 'edit-price-info-btn'
                                    class = "button button-primary confirm-update-button"
                                    onClick = {(e) => this.unhideFormElements(e)}
                                    data-target_hidden_elements = "price-info"
                                    style = {{margin:"4px 4px 4px 4px"}}>

                              <div style = {{fontSize:"12px"}}>Edit Section</div>
                              </button>

                              <button id = 'cancel-price-info-btn'
                                      class = "price-info button button-primary cancel-update-button hidden"
                                      onClick = {(e) => this.cancelSectionEdits(e)}
                                      data-target_hidden_elements = "price-info"
                                      data-target_edit_button_id  = "edit-price-info-btn"
                                      style = {{margin:"4px 4px 4px 4px"}}>

                                <div style = {{fontSize:"12px"}}>Cancel</div>
                              </button>
                            </div>

                        </div>


                      </div> {/*end row*/}
                    </div>
                  </div>

                  <div class = "wine_profile-info-wrapper">
                    <div class = "wine_profile-info row" >


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >

                          <div class = "wine_profile-inpage-elements-wrapper">
                            Currency
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                            <select  class = "price-info wine_profile_select_box_input hidden">
                              <option value="GBP">£</option>
                              <option value="USD">$</option>
                            </select>

                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element"> {this.props.winelist.wineDetails.currency} </p>
                            </div>
                          </div>


                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Glass 50ml Price
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "price-info wine_profile_box_input hidden"
                                      type="text"
                                      maxlength="8"
                                      placeholder =""/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.glass50mlPrice} </p>
                            </div>
                          </div>


                        </div>


                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Glass 125ml Price
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = " price-info wine_profile_box_input hidden "
                                      type="text"
                                      maxlength="8"
                                      placeholder =""/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.glass125mlPrice} </p>
                            </div>
                          </div>

                        </div>

                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                             Glass 250ml Price
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "price-info wine_profile_box_input hidden"
                                      type="text"
                                      maxlength="8"
                                      placeholder =""/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.glass250mlPrice} </p>
                            </div>
                          </div>



                        </div>

                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">

                        <div class = "wine_profile_fields_container" >
                            <div class = "wine_profile-inpage-elements-wrapper" >
                              Bottle 375ml Price
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <input  class = "price-info wine_profile_box_input hidden"
                                        type="text"
                                        maxlength="8"
                                        placeholder =""/>
                              </div>
                            </div>

                            <div class ="row">
                              <div class = "col-sm-12 col-md-12 col-lg-12">
                                <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.bottle375mlPrice} </p>
                              </div>
                            </div>

                        </div>



                      </div>

                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            Bottle 750ml Price
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "price-info wine_profile_box_input hidden"
                                      type="text"
                                      maxlength="8"
                                      placeholder =""/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.bottle750mlPrice} </p>
                            </div>
                          </div>

                        </div>



                      </div>


                      <div class = "col-sm-6 col-md-6 col-lg-3">
                        <div class = "wine_profile_fields_container" >
                          <div class = "wine_profile-inpage-elements-wrapper" >
                            magnumPrice
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <input  class = "price-info wine_profile_box_input hidden"
                                      type="text"
                                      maxlength="8"
                                      placeholder =""/>
                            </div>
                          </div>

                          <div class ="row">
                            <div class = "col-sm-12 col-md-12 col-lg-12">
                              <p class = "cart-form-text-element">  {this.props.winelist.wineDetails.magnumPrice} </p>
                            </div>
                          </div>



                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div> ] :
        [<div></div>]

    )

  }
}

class WineProfilesClass extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,

  };

  constructor(props) {
    super(props);

    this.state = {}


  }

  componentDidMount() {


  }

  componentDidUpdate(){
  }


  render()
  {
      const { isAuthenticated, user } = this.props.auth;
      var self = this
      return(

        <div>
          <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
            <div class = "app-content-full container">

            </div>
          </div>
        </div>

    )

  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  winelist: state.winelist,
  wineDetails: state.wineDetails,
  wineDetailsLoaded: state.wineDetailsLoaded,
  wineRegionReferenceData: state.wineRegionReferenceData,
  wineRegionReferenceDataLoaded: state.wineRegionReferenceDataLoaded,
  wineReferenceData: state.wineReferenceData,
  wineReferenceDataLoaded: state.wineReferenceDataLoaded,
  foodPairingsReferenceData: state.foodPairingswineReferenceData,
  foodPairingsReferenceDataLoaded: state.foodPairingsReferenceDataLoaded,
  experiencesList: state.experiencesList,
  experiencesListLoaded: state.experiencesListLoaded,
  eventsList: state.eventsList,
  eventsListLoaded: state.eventsListLoaded,

});


const  CreateWineListConnected       = connect(mapStateToProps, { logout })(CreateWineListUnconnected);
export {CreateWineListConnected as CreateWineList}

const  connectedWineListsClass  = connect(mapStateToProps)(WineListsClass);
export {connectedWineListsClass as WineLists}

const  connectedWineProfileClass = connect(mapStateToProps)(WineProfileClass);
export {connectedWineProfileClass as WineProfile }

const  connectedWineProfilesClass = connect(mapStateToProps)(WineProfilesClass);
export {connectedWineProfilesClass as WineProfiles }
