import React, {useState, useEffect, useLayoutEffect} from 'react';
import ReactGA from 'react-ga'
import {createBrowserHistory} from 'history'

import  { Component } from 'react';
import '../App.css';
import '../Styles/MyDashboard.css'
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import "../Actions/Auth.js"

import axios from 'axios'

import {loadUser, logout} from '../Actions/Auth.js'
import {SideNavigation, SideNavigationClass } from "./SideNavigation.js"

import {titleList,
       genderList,
       countryList,
       internationalCallingCodeList,
       findElementInTitleArray,
       findElementInGenderArray,
       findElementInCountryListArray,
       findElementInCountryCodeListArray,
       PlaceHolderImage} from "../Forms/FormComponents.js"

import {MyProfile, BusinessProfile} from "./Profile.js"
import {ExperiencesList, CreateExperiences, MyExperiences} from "./Experiences.js"
import {EventsList, CreateEvent, MyEvents} from "./Events.js"
import {WineLists, CreateWineList, WineProfile, WineProfiles} from "./WineLists.js"




// use the router for navigating to other webpages
import {
  BrowserRouter as Router,
  HashRouter, // try using the HashRouter
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from 'react-router-dom'

// initialise react-ga module
const trackingId ="UA-187471492-1"
ReactGA.initialize(trackingId)

// initialise useHistory
const history = createBrowserHistory();

class MyDashboardClass extends Component{
/*export class Experiences extends Component{*/
componentDidMount() {

  ReactGA.pageview(window.location.pathname + window.location.search);
}

static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};


  render()
  {
    const { isAuthenticated, user, group, membership } = this.props.auth;

    return(
      <div>


      <div class = "all-page">
        <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "app-content-full container">

            <div class = "main-page-content">
              <div class="strapline">
                Welcome{isAuthenticated ? `, ${user.first_name} ${user.last_name}, ` : ``} to your dashboard

              </div>

              <h4 class ="intro-copy">
              Hello {isAuthenticated ? ` ${user.username},` : `my friend`} it is nice to see you. {isAuthenticated ? `You are part of the group  ${membership},` : ``}
              </h4>


                {/*isAuthenticated ? console.log("User groups", user.groups)  : console.log()*/}
                {/*isAuthenticated ? user.groups.map((group) => console.log(group.name))
                   : console.log()*/}

                {/*isAuthenticated ?  group.map((myGroup) => myGroup.name === "members" ? console.log("I am definitely a member")  :  console.log("I am not a member") ) : console.log()*/}


                {/*------------------------------ Here is where we split the page to a navbar and main content ------------------------------*/}

                <div class = "container-fluid">
                  <Router history={history}>
                  <Switch>


                  <div class = "dashboard-contents-container" >

                      <SideNavigationClass/>

                      {/*render the page below*/}

                      {/*render member profile links*/}
                      <Route path='/Mydashboard/MyProfile'     component = {MyProfile} />
                      <Route path='/Mydashboard/MyEvents'      component = {MyEvents} />
                      <Route path='/Mydashboard/MyExperiences' component = {MyExperiences} />

                      {/*render business profile links*/}
                      <Route path='/Mydashboard/business-profile'   component = {BusinessProfile} />
                      <Route path='/Mydashboard/create-event'       component = {CreateEvent} />
                      <Route path='/Mydashboard/create-experiences' component = {CreateExperiences} />
                      <Route path='/Mydashboard/create-wine-list' component = {CreateWineList} />
                      <Route path='/Mydashboard/events'        component = {EventsList} />
                      <Route path='/Mydashboard/experiences'   component = {ExperiencesList} />
                      <Route path="/Mydashboard/wine-lists"         component = {WineLists} />
                      {/*<Route exact path="/Mydashboard/wine-lists/wine-profile/:slug"  component = {WineProfile} />*/}
                      {/*<Route exact path  = "http://127.0.0.1:3000/wine-profile"  component = {WineProfiles} />
                      <Route exact path  = "http://127.0.0.1:3000/wine-profile/:slug"  component = {WineProfile} />*/}

                  </div>




                      {/*render the side navigation below*/}

                  <div class = "row  hidden">
                    <div class = "col-sm-0 col-md-4 col-lg-4">
                      {<SideNavigationClass/>}
                    </div>


                    <div class = "col-sm-12 col-md-8 col-lg-8">

                        {/*render the page below*/}

                        {/*render member profile links*/}
                        <Route path='/Mydashboard/MyProfile'     component = {MyProfile} />
                        <Route path='/Mydashboard/MyEvents'      component = {MyEvents} />
                        <Route path='/Mydashboard/MyExperiences' component = {MyExperiences} />

                        {/*render business profile links*/}
                        <Route path='/Mydashboard/business-profile'   component = {BusinessProfile} />
                        <Route path='/Mydashboard/create-event'       component = {CreateEvent} />
                        <Route path='/Mydashboard/create-experiences' component = {CreateExperiences} />
                        <Route path='/Mydashboard/create-wine-list' component = {CreateWineList} />
                        <Route path='/Mydashboard/events'        component = {EventsList} />
                        <Route path='/Mydashboard/experiences'   component = {ExperiencesList} />
                        <Route path="/Mydashboard/wine-lists"         component = {WineLists} />
                        {/*<Route exact path="/Mydashboard/wine-lists/wine-profile/:slug"  component = {WineProfile} />*/}
                        {/*<Route exact path  = "http://127.0.0.1:3000/wine-profile"  component = {WineProfiles} />
                        <Route exact path  = "http://127.0.0.1:3000/wine-profile/:slug"  component = {WineProfile} />*/}


                    </div>
                  </div>

                  </Switch>
                  </Router>
                </div>

            </div> {/* end main page content*/}
          </div> {/* end app-conent-full container*/}
        </div> {/* end app-page-content*/}
      </div> {/*end all page*/}



      </div>
    );
  }
}

class MyDashboardClassDepricated extends Component{
/*export class Experiences extends Component{*/
componentDidMount() {

  ReactGA.pageview(window.location.pathname + window.location.search);
}

static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};


  render()
  {
    const { isAuthenticated, user, group, membership } = this.props.auth;

    return(
      <div>


      <div class = "all-page">
        <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "app-content-full container">

            <div class = "main-page-content">
              <div class="strapline">
                Welcome{isAuthenticated ? `, ${user.first_name} ${user.last_name}, ` : ``} to your dashboard

              </div>

              <h4 class ="intro-copy">
              Hello {isAuthenticated ? ` ${user.username},` : `my friend`} it is nice to see you. {isAuthenticated ? `You are part of the group  ${membership},` : ``}
              </h4>


                {/*isAuthenticated ? console.log("User groups", user.groups)  : console.log()*/}
                {/*isAuthenticated ? user.groups.map((group) => console.log(group.name))
                   : console.log()*/}

                {/*isAuthenticated ?  group.map((myGroup) => myGroup.name === "members" ? console.log("I am definitely a member")  :  console.log("I am not a member") ) : console.log()*/}


                {/*------------------------------ Here is where we split the page to a navbar and main content ------------------------------*/}

                <div class = "container-fluid">
                  <Router history={history}>
                  <Switch>



                      {/*render the side navigation below*/}

                  <div class = "my-dashboard-flex-row">

                      <SideNavigationClass/>



                  <div class = "my-dashboard-flex-grow">
                      {/*render the page below*/}

                      {/*render member profile links*/}
                      <Route path='/Mydashboard/MyProfile'     component = {MyProfile} />
                      <Route path='/Mydashboard/MyEvents'      component = {MyEvents} />
                      <Route path='/Mydashboard/MyExperiences' component = {MyExperiences} />

                      {/*render business profile links*/}
                      <Route path='/Mydashboard/business-profile'   component = {BusinessProfile} />
                      <Route path='/Mydashboard/create-event'       component = {CreateEvent} />
                      <Route path='/Mydashboard/create-experiences' component = {CreateExperiences} />
                      <Route path='/Mydashboard/create-wine-list' component = {CreateWineList} />
                      <Route path='/Mydashboard/events'        component = {EventsList} />
                      <Route path='/Mydashboard/experiences'   component = {ExperiencesList} />
                      <Route path="/Mydashboard/wine-lists"         component = {WineLists} />
                      {/*<Route exact path="/Mydashboard/wine-lists/wine-profile/:slug"  component = {WineProfile} />*/}
                      {/*<Route exact path  = "http://127.0.0.1:3000/wine-profile"  component = {WineProfiles} />
                      <Route exact path  = "http://127.0.0.1:3000/wine-profile/:slug"  component = {WineProfile} />*/}


                    </div>

                  </div>
                  </Switch>
                  </Router>
                </div>

            </div> {/* end main page content*/}
          </div> {/* end app-conent-full container*/}
        </div> {/* end app-page-content*/}
      </div> {/*end all page*/}



      </div>
    );
  }
}

class MyDashboardClassOriginal extends Component{
/*export class Experiences extends Component{*/
static propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};


  render()
  {
    const { isAuthenticated, user, group, membership } = this.props.auth;

    return(
      <div>


      <div class = "all-page">
        <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "app-content-full container">

            <div class = "main-page-content">
              <div class="strapline">
                Welcome{isAuthenticated ? `, ${user.first_name} ${user.last_name}, ` : ``} to your dashboard

              </div>

              <h4 class ="intro-copy">
              Hello {isAuthenticated ? ` ${user.username},` : `my friend`} it is nice to see you. {isAuthenticated ? `You are part of the group  ${membership},` : ``}
              </h4>


                {/*isAuthenticated ? console.log("User groups", user.groups)  : console.log()*/}
                {/*isAuthenticated ? user.groups.map((group) => console.log(group.name))
                   : console.log()*/}

                {/*isAuthenticated ?  group.map((myGroup) => myGroup.name === "members" ? console.log("I am definitely a member")  :  console.log("I am not a member") ) : console.log()*/}


                {/*------------------------------ Here is where we split the page to a navbar and main content ------------------------------*/}

                <div class = "container-fluid">
                  <Router>
                  <Switch>
                  <div class = "row row-offcanvas row-offcanvas-left">

                    <div class = "col-xs-6 col-sm-3 col-md-3 nav-sidebar-offcanvas" id = "sidebar" role = "navigation">
                      {/*render the side navigation below*/}
                      <SideNavigation/>
                    </div> {/* end side navigation container*/}

                    <div class = "col-6 col-sm-6 col-md-9 "> {/*col-lg-4*/}
                      {/*render the page below*/}

                      {/*render member profile links*/}
                      <Route path='/Mydashboard/MyProfile'     component = {MyProfile} />
                      <Route path='/Mydashboard/MyEvents'      component = {MyEvents} />
                      <Route path='/Mydashboard/MyExperiences' component = {MyExperiences} />

                      {/*render business profile links*/}
                      <Route path='/Mydashboard/BusinessProfile'   component = {BusinessProfile} />
                      <Route path='/Mydashboard/CreateEvent'       component = {CreateEvent} />
                      <Route path='/Mydashboard/CreateExperiences' component = {CreateExperiences} />
                      <Route path='/Mydashboard/PastEvents'        component = {PastEvents} />
                      <Route path='/Mydashboard/PastExperiences'   component = {PastExperiences} />

                    </div>

                  </div>
                  </Switch>
                  </Router>
                </div>

            </div> {/* end main page content*/}
          </div> {/* end app-conent-full container*/}
        </div> {/* end app-page-content*/}
      </div> {/*end all page*/}



      </div>
    );
  }
}











function PastEvents() {
  return(
    <div>
      <div class = "all-page">
        <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "app-content-full container">
            <div class="strapline"> Welcome to your past events</div>
          </div>
        </div>
      </div>
    </div>
  )
}
function PastExperiences() {
  return(
    <div>
      <div class = "all-page">
        <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "app-content-full container">
            <div class="strapline"> Welcome to your past experiences</div>
          </div>
        </div>
      </div>
    </div>
  )
}





const mapStateToProps = (state) => ({
  auth: state.auth,
});

const  connectedMyDashboardClass  = connect(mapStateToProps)(MyDashboardClass);
export {connectedMyDashboardClass as MyDashboard}




//const MyProfile         = connect(mapStateToProps)(MyProfileUnconnected);
