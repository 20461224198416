import React, {useState, useEffect, useLayoutEffect} from 'react';
import '../Styles/Authentication.css'
import '../App.css'

import {Link} from "react-router-dom"


export function Register(){

  const [activeRequestItem, setActiveRequestItem] = useState({
    "username": "",
    "email": "",
    "password": "",
    "password2": "",
    "first_name": "",
    "last_name": "",
    "registrationType": ""

  })

  // get some cookies with ajax
  // we can be more efficient with the cookie calls and management
  // documentation at
  // https://docs.djangoproject.com/en/3.1/ref/csrf/
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }


  function handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // capture the state of the form when we log a change
    setActiveRequestItem({
      ...activeRequestItem,
      [e.target.name]: value
    })



  }

  function handleSubmit(e){

    e.preventDefault();

    // var url = "http://127.0.0.1:8000/api/register-user-create/"
    // the url for the create request we use the web endpoints
    // var url = "https://real-grape-site-us.herokuapp.com/api/register-user-create/"
    var url = "https://www.therealgrape.com/api/register-user-create/"

    // get a cookie that we will pass in our headers
    const csrftoken = getCookie('csrftoken');

    // here would be the place to do some validation in the browser

    //now let us create the POST request
    fetch(url, {
      method:'POST',
      headers:{
              'Content-type': 'application/json',
              'X-CSRFToken': csrftoken
            },
            credentials: 'include',
      body:JSON.stringify(activeRequestItem)
    }).then((response) => {
      // clear the web form
      setActiveRequestItem( {
        "username": "",
        "email": "",
        "password": "",
        "password2": "",
        "first_name": "",
        "last_name": "",
        "registrationType": ""
        })



        }).catch(function(error){
          console.log('ERROR MSG: ', error)
          console.error('Caught error', error)


    })
  }


  return(
    <fragment>
    <div class = "all-page">
      <div class="app-page-content" itemprop = "mainContentOfPage" role="main">

      <div class = "authentication__page__shell">
        <div class = "authentication__page__content">
          <div class = "authentication__panel__container" role = "group">
              <div class = "authentication__panel">
                <div class = "panel__header">
                  <div class = "pannel__header__leftbox"/>
                  <div class = "pannel__header__title"> Register Account</div>
                  <div class = "pannel__header__rightbox"/>
                </div> {/* End Authentication Form Header*/}

                <div class = "registration__panel__containter">

                  <form method = "POST" action = "">
                  {/*% csrf_token %*/}

                    <div class = "input-group mb-3">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-user"></i></span>
                      </div>
                      {/*{form.username}*/}
                      <input  onChange = {handleFormChange} value = {activeRequestItem.username} type = "text" name="username" placeholder = "Username" class = "form-control"/>
                    </div>


                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-user"></i></span>
                      </div>
                      {/*{form.first_name}*/}
                      <input  onChange = {handleFormChange} value = {activeRequestItem.first_name} type = "text" name="first_name" placeholder = "First name" class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-user"></i></span>
                      </div>
                      {/*{form.last_name}*/}
                      <input  onChange = {handleFormChange} value = {activeRequestItem.last_name} type = "text" name="last_name" placeholder = "Last name" class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-envelope-square"></i></span>
                      </div>
                      {/*{form.email}*/}
                      <input onChange = {handleFormChange} value = {activeRequestItem.email} type = "email" name="email" placeholder = "Enter email" class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                      </div>
                      {/*{form.password1}*/}
                      <input onChange = {handleFormChange} value = {activeRequestItem.password} type = "password" name = "password" placeholder = "Enter Password" class = "form-control"/>
                    </div>

                    <div class = "input-group mb-2">
                      <div class = "input-group-append">
                        <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                      </div>
                      {/*{form.password2}*/}
                      <input onChange = {handleFormChange} value = {activeRequestItem.password2} type = "password" name = "password2" placeholder = "Re-enter password" class = "form-control"/>
                    </div>


                    <div class = "input-group mb-2">
                      <select onChange = {handleFormChange} value = {activeRequestItem.registrationType} name = "registrationType"  class = "input-group-append form-control">
                        <option value="">Are you a member of professional?</option>
                        {clientType.map((clientTypeSelect) =>
                          <option value = {clientTypeSelect.optionValue}>{clientTypeSelect.source} </option>
                        )}
                      </select>
                      {/*{form.usertype}*/}
                    </div>

                    <div class="d-flex justify-content-center mt-3 login_container">
                    <input class="btn login_btn" type="submit"  onClick={(e) =>
                      handleSubmit(e)}/>
                    </div>

                  </form>

                  <div class = "mt-4">
                    <div class = "d-flex justify-content-center links">
                      <p> Already have an account?
                      {/*<a href = "{% url 'login' %}" class = "ml-2">Login</a>*/}
                      {/*<a href = "/login" class = "ml-2">Login</a>*/}
                      <Link to = "/login" class = "login__register__link"> Login </Link> </p>
                    </div>
                  </div>


                </div> {/* End registration__panel container*/}

              </div> {/* End Authentication Pannel*/}
          </div>
        </div>
      </div>


      <div class = "authentication__page__bottom"/>


        </div> {/*app-page-content*/}
      </div> {/*"all-page"*/}
    </fragment>
  )

}

export function RegisterDepricated(){

  const [activeRequestItem, setActiveRequestItem] = useState({
    "username": "",
    "email": "",
    "password": "",
    "password2": ""
  })

  // get some cookies with ajax
  // we can be more efficient with the cookie calls and management
  // documentation at
  // https://docs.djangoproject.com/en/3.1/ref/csrf/
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }


  function handleFormChange(e) {

    // ensure that we habdle check boxes as well
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // capture the state of the form when we log a change
    setActiveRequestItem({
      ...activeRequestItem,
      [e.target.name]: value
    })



  }

  function handleSubmit(e){

    e.preventDefault();

    // var url = "http://127.0.0.1:8000/api/register-user-create/"
    // the url for the create request we use the web endpoints
    var url = "https://real-grape-site-us.herokuapp.com/api/register-user-create/"

    // get a cookie that we will pass in our headers
    const csrftoken = getCookie('csrftoken');

    // here would be the place to do some validation in the browser

    //now let us create the POST request
    fetch(url, {
      method:'POST',
      headers:{
              'Content-type': 'application/json',
              'X-CSRFToken': csrftoken
            },
            credentials: 'include',
      body:JSON.stringify(activeRequestItem)
    }).then((response) => {
      // clear the web form
      setActiveRequestItem( {
        "username": "",
        "email": "",
        "password": "",
        "password2": ""
        })



        }).catch(function(error){
          console.log('ERROR MSG: ', error)
          console.error('Caught error', error)


    })
  }


  return(
    <fragment>
    <div class = "all-page">
      <div class="app-page-content" itemprop = "mainContentOfPage" role="main">
          <div class = "conatainer h-100">
            <div class = "d-flex justify-content-center h-100">
              <div class = "user_card">

                <div class = "d-flex justify-content-center">
                  <h3 id = "form-title"> REGISTER ACCOUNT </h3>
                </div>

                <div class = "d-flex justify-content-center form_container">
                  <form method = "POST" action = "">
                  {/*% csrf_token %*/}

                  <div class = "input-group mb-3">
                    <div class = "input-group-append">
                      <span class = "input-group-text"><i class = "fas fa-user"></i></span>
                    </div>
                    {/*{form.username}*/}
                    <input  onChange = {handleFormChange} value = {activeRequestItem.username} type = "text" name="username" placeholder = "Username..." class = "form-control"/>
                  </div>

                  <div class = "input-group mb-2">
                    <div class = "input-group-append">
                      <span class = "input-group-text"><i class = "fas fa-envelope-square"></i></span>
                    </div>
                    {/*{form.email}*/}
                    <input onChange = {handleFormChange} value = {activeRequestItem.email} type = "email" name="email" placeholder = "Email..." class = "form-control"/>
                  </div>

                  <div class = "input-group mb-2">
                    <div class = "input-group-append">
                      <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                    </div>
                    {/*{form.password1}*/}
                    <input onChange = {handleFormChange} value = {activeRequestItem.password} type = "password" name = "password" placeholder = "Input Password" class = "form-control"/>
                  </div>

                  <div class = "input-group mb-2">
                    <div class = "input-group-append">
                      <span class = "input-group-text"><i class = "fas fa-key"></i></span>
                    </div>
                    {/*{form.password2}*/}
                    <input onChange = {handleFormChange} value = {activeRequestItem.password2} type = "password" name = "password2" placeholder = "Verify Password" class = "form-control"/>
                  </div>

                  <div class = "input-group mb-2">
                    <select class = "input-group-append">
                      {clientType.map((clientTypeSelect) =>
                        <option value = {clientTypeSelect.optionValue}>{clientTypeSelect.source} </option>
                      )}
                    </select>
                    {/*{form.usertype}*/}
                  </div>

                  <div class="d-flex justify-content-center mt-3 login_container">
    				 			<input class="btn login_btn" type="submit"  onClick={(e) =>
                    handleSubmit(e)}/>
    				   		</div>

                  </form>
                </div>

                {/*{form.errors}*/}
                <div class = "mt-4">
                  <div class = "d-flex justify-content-center links">
                    Already have an account?
                    {/*<a href = "{% url 'login' %}" class = "ml-2">Login</a>*/}
                    {/*<a href = "/login" class = "ml-2">Login</a>*/}
                    <Link to = "/login"> Login </Link>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div> {/*app-page-content*/}
      </div> {/*"all-page"*/}
    </fragment>
  )

}

export const clientType = [
  {optionValue: "mbr", source: "Member"},
  {optionValue: "prf", source: "Professional"}

]
