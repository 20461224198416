import React from 'react';
import '../Styles/PrivacyPolicy.css'
import '../App.css';

export function PrivacyPolicy() {
  return(


          <div class = "all-page">
            <div class="app-page-content">
              <div class = "app-content-full container">
                <div class = "someSpacing">
                </div>
                <br/>
                <div class = "privacy-strapline">
                  <u>Privacy Policy Statement - The Real Grape 2021</u>
                </div>
                <p class = "policy-copy">
                  The Real Grape is committed to respecting and protecting your privacy. This policy sets out how The Real Grape collects and uses your personal data that you provide via this site, www.therealgrape.com. This note further sets about your privacy rights and how certain laws may apply to you.
                </p>
                <div class = "policy-headings">
                  Security
                </div>
                <p class = "policy-copy">
                  The Real Grape is committed to ensuring your information is secured. Suitable physical and electronic management procedures are in place to safeguard and secure information collected on therealgrape.com.
                </p>
                <div class = "policy-headings">
                  What personal data The Real Grape Collects
                </div>

                <p class = "policy-copy">
                  <i>Personal</i> data means any data that can be associated with you as an individual, either by direct or indirect means. The Real Grape collects relevant personal data depending on how you use The Real Grape’s services and interact with the site.
                <br/>
                <br/>
                  Personal data that can be collected includes:
                  <ul>
                    <li> <i>Identity information</i> such as your name, organisation name and position</li>
                    <li><i>Contact Information</i> such as your email address, telephone number and address</li>
                    <li><i>Usage data </i> such as services you interact with on the site and your location </li>
                    <li><i>Login-information </i> such as user name and password </li>
                    <li><i>Transaction information </i>including booking history and other information associated with your payments </li>
                    <li><i>Technical Data</i> such as device used for accessing the site, IP, browser type and version, time zone and location </li>
                    <li><i>Marketing and communications data</i> including communications we may receive from you and your preferences in receiving communications from The Real Grape</li>
                  </ul>
                    No financial or credit information is stored on therealgrape.com. Credit card details are processed by The Real Grape’s third party provider, Stripe. You can find details of their privacy policy by following this <a class = "stripe-link" href=  "https://stripe.com/gb/privacy">link</a>.
                    <br/>
                    <br/>
                    Where The Real Grape fails to collect personal data by law, or under terms of conditions of the services you wish to engage with The Real Grape (such as to provide goods or services) then The Real Grape may not be able to fulfil the contract you are trying to enter. In this case, The Real Grape may have to cancel the product or service you have and will notify you if this is the case at the time.
                  </p>

                <div class = "policy-headings">
                  How The Real Grape uses your personal data
                </div>
                <p class = "policy-copy">
                  The Real Grape will use your personal data where the law allows mostly commonly in the following situations:
                  <ul>
                    <li>To perform the contract that your entered into with The Real Grape </li>
                    <li>Where necessary for legitimate and reasonable business interests, either The Real Grape’s and yours, and fundamental rights do not override those interests </li>
                    <li>In compliance of legal and regulatory obligations</li>
                  </ul>
                    Generally The Real Grape does not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing and communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting The Real Grape.
                    <br/>
                    <br/>
                    The Real Grape uses your personal data for the following purposes:
                    <ul>
                    	<li>To register you as a new member or your organisations as a service provider on therealgrape.com</li>
                    	<li>Processing your requests and orders including (a) fulfilment of requests (b) management of payments and (c) collection and recovery of money owed to The Real Grape</li>
                    	<li>Management of your relationship with The Real Grape including notifications of changes to terms or policy and asking you to leave a review or take a survey</li>
                    	<li>Enabling you to enter a prize draw, competition or survey</li>
                    	<li>Administration and protection of therealgrape.com including troubleshooting, data analysis, testing, system maintenance support, reporting and hosting of data</li>
                    	<li>Delivering relevant website content and advertisements to you</li>
                    	<li>Measuring and understanding the effectiveness of advertising served by therealgrape.com</li>
                    	<li>Using data analytics to improve therealgrape.com, products, services, customer relationships and experiences</li>
                    	<li>Make suggestions and recommendations to you about goods and services that may be of interest</li>
                    </ul>
                </p>

                <div class = "policy-headings">
                  How you can exercise your privacy rights under GDPR
                </div>
                <p class = "policy-copy">
                  The Real Grape retains records of your transactions and order history for a reasonable period up to 5 years.
                  <br/>
                  <br/>
                  You have the right to require The Real Grape:
                  <ul>
                  	<li>to rectify the personal data held about you on therealgrape.com where the data is incorrect</li>
                  	<li>restrict processing of your personal information in certain circumstances</li>
                  	<li>provide you details on your personal data held at therealgrape.com</li>
                  	<li>to delete personal data held about you in certain circumstances</li>
                  </ul>
                  Furthermore you can withdraw your consent to The Real Grape using your data for marketing purposes at any time.
                  <br/>
                  <br/>
                  It is within your rights to lodge a complaint with the Information Commissioner’s Office (ICO). The ICO is the UK supervisory authority for data protection issues (www.ico.org.uk). Prior to lodging a complaint please engage The Real Grape in the first instance so The Real Grape can endeavour to deal with your concerns directly.
                  <br/>
                  <br/>
                  To exercise any of the above rights please email The Real Grape at wine@therealgrape.com.
                </p>

                <div class = "policy-headings">
                   About the Real Grape
                </div>

                <p class = "policy-copy">
                  The Real Grape is a trading name of <i>I.Q. Pro limited</i>. I.Q. Pro limited is a limited company incorporated in England and Wales with the company number with the company number 1017510. I.Q. Pro limited is the data controller of any data that you provide to The Real Grape and is registered with the UK Information Commissioners Office under number ZA834419.
                  <br/>
                  <br/>
                  The Real Grape is a member of the Travel Trust Association with member number <i>Q6257</i>. The Travel Trust Association is the only travel network in the UK to guarantee <b>100% financial protection</b> to the consumer, ensuring total consumer confidence.
                </p>



              </div>
            </div>

          </div>



  )

}
