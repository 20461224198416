import React from 'react';
import  { Component } from 'react';

import '../Styles/SideNavigation.css'
import {Link} from "react-router-dom"

import {connect} from "react-redux";
import PropTypes from 'prop-types';
import "../Actions/Auth.js"



export function SideNavigation() {return(

      <div class = "Side__Navbar " id = "side-navigation">
        <div class = "side__navigation__menu_flex">

          {/* render the below dashboard for members*/}
          <div class = "navigation__links__member">
            <ul class = "nav_links">
              {/*<li class="active"><a href="#">My Profile</a></li>*/}
              <li class = "active">


                  <Link class = "nav_links_content" to ="/Mydashboard/MyProfile" ><span><i class="fas fa-user-circle icon"></i></span> <span class =  "link__text">  My Profile </span> </Link>


              </li>
              <li class = "nav_links_content"><Link class = "nav_links_content" to ="/Mydashboard/MyEvents"  > <span><i class="fas fa-calendar-alt"></i></span>  My Events </Link></li>
              <li class = "nav_links_content"><Link  to ="/Mydashboard/MyExperiences"  >  <i class="fas fa-glass-cheers"></i> My Experiences </Link></li>
            </ul>
          </div>

          {/* render the below dashboard for professionals*/}
          <div class = "navigation__links__professional">
            <ul class = "nav_links">
              <li class = "nav_links_content active"> <Link to ="/Mydashboard/BusinessProfile" >  <i class="fas fa-user-tie"></i> Business Profile </Link> </li>
              <li class = "nav_links_content"><Link  to ="/Mydashboard/MyEvents"  >  <i class="far fa-calendar-alt"></i> Create Event </Link></li>
              <li class = "nav_links_content"><Link  to ="/Mydashboard/CreateEvent"  > <i class="fas fa-wine-glass-alt"></i> Create Experience </Link></li>
              <li class = "nav_links_content"><Link  to ="/Mydashboard/PastEvents"  > <i class="fas fa-calendar-alt"></i>  Past Events </Link></li>
              <li class = "nav_links_content"><Link  to ="/Mydashboard/PastExperiences"  > <i class="fas fa-glass-cheers"></i> Past Experiences </Link></li>
            </ul>
          </div>

        </div>
      </div>)
}

class SideNavigationClassUnconnected extends Component{

  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
    }

    this.showSideBar = this.showSideBar.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  showSideBar(e) {

    e.preventDefault()

    var sidebarState = !this.state.sidebar;
    this.setState({
      sidebar: sidebarState
    })


  }


      render()
      {

        const { isAuthenticated, membership } = this.props.auth;

        return(
          <div>
            {this.state.sidebar ? <div class = "nav_links_content active menu-bars" onClick = {(e) => this.showSideBar(e)}>
                          <Link to ="#" >  <i class="fas fa-bars" style = {{color:"black"}}> </i>  </Link>
                        </div> : <div></div>}


            <div class = {!this.state.sidebar ? 'sidebar-nav-menu active' : 'sidebar-nav-menu'}>
              <div class = "Side__Navbar " id = "side-navigation">
                <div class = "side__navigation__menu_flex">

                  {/* render the below dashboard for members*/}
                {
                  membership === "members"  ?
                    <div class = "navigation__links__member">
                        <ul class = "nav_links" style = {{padding:"0px 0px 0px 0px"}}>
                          {/*<li class="active"><a href="#">My Profile</a></li>*/}
                          <li class = "nav_links_content active" onClick = {(e) => this.showSideBar(e)}> <Link to ="#" >  <i class="far fa-window-close" ></i> Close menu </Link> </li>
                          <li class = "active" onClick = {(e) => this.showSideBar(e)}>


                              <Link class = "nav_links_content" to ="/Mydashboard/MyProfile" ><span><i class="fas fa-user-circle icon"></i></span> <span class =  "link__text">  My Profile </span> </Link>


                          </li>
                          <li class = "nav_links_content" onClick = {(e) => this.showSideBar(e)}><Link class = "nav_links_content" to ="/Mydashboard/MyEvents"  > <span><i class="fas fa-calendar-alt"></i></span>  My Events </Link></li>
                          <li class = "nav_links_content" onClick = {(e) => this.showSideBar(e)}><Link  to ="/Mydashboard/MyExperiences"  >  <i class="fas fa-glass-cheers"></i> My Experiences </Link></li>
                        </ul>
                      </div> : <></>
                  }

                  {/* render the below dashboard for professionals*/}


                    {
                      membership === "professionals"   ?
                        <div class = "navigation__links__professional">
                          <ul class = "nav_links" style = {{padding:"0px 0px 0px 0px"}}>
                            <li class = "nav_links_content active" onClick = {(e) => this.showSideBar(e)}> <Link to ="#" >  <i class="far fa-window-close" ></i></Link> </li>
                            <li class = "nav_links_content active" onClick = {(e) => this.showSideBar(e)}> <Link to ="/Mydashboard/business-profile" >  <i class="fas fa-user-tie"></i> View business profile </Link> </li>
                            <li class = "nav_links_content"        onClick = {(e) => this.showSideBar(e)}><Link  to ="/Mydashboard/create-event"  >  <i class="far fa-calendar-alt"></i> Create an event </Link></li>
                            <li class = "nav_links_content"        onClick = {(e) => this.showSideBar(e)}><Link  to ="/Mydashboard/create-experiences"  > <i class="fas fa-wine-glass-alt"></i> Create an experience </Link></li>
                            <li class = "nav_links_content"        onClick = {(e) => this.showSideBar(e)}><Link  to ="/Mydashboard/create-wine-list"  > <i class="fas fa-wine-glass-alt"></i> Create wine list </Link></li>
                            <li class = "nav_links_content"        onClick = {(e) => this.showSideBar(e)}><Link  to ="/Mydashboard/events"  > <i class="fas fa-calendar-alt"></i>  Manage my events </Link></li>
                            <li class = "nav_links_content"        onClick = {(e) => this.showSideBar(e)}><Link  to ="/Mydashboard/experiences"  > <i class="fas fa-glass-cheers"></i> Manage my experiences </Link></li>
                            <li class = "nav_links_content"        onClick = {(e) => this.showSideBar(e)}><Link  to ="/Mydashboard/wine-lists"  > <i class="fas fa-glass-cheers"></i> Manage my wine lists </Link></li>
                          </ul>
                        </div> :
                        <></>
                   }

                </div>
              </div>
            </div>
          </div>

      )
      }
}

class SideNavigationClassUnconnectedDepricated extends Component{

  static propTypes = {
    auth: PropTypes.object.isRequired,
  }


      render()
      {

        const { isAuthenticated, membership } = this.props.auth;

        return(
          <div class = "Side__Navbar " id = "side-navigation">
          <div class = "side__navigation__menu_flex">

            {/* render the below dashboard for members*/}
          {
          membership === "members"  ?
            <div class = "navigation__links__member">
                <ul class = "nav_links">
                  {/*<li class="active"><a href="#">My Profile</a></li>*/}
                  <li class = "active">


                      <Link class = "nav_links_content" to ="/Mydashboard/MyProfile" ><span><i class="fas fa-user-circle icon"></i></span> <span class =  "link__text">  My Profile </span> </Link>


                  </li>
                  <li class = "nav_links_content"><Link class = "nav_links_content" to ="/Mydashboard/MyEvents"  > <span><i class="fas fa-calendar-alt"></i></span>  My Events </Link></li>
                  <li class = "nav_links_content"><Link  to ="/Mydashboard/MyExperiences"  >  <i class="fas fa-glass-cheers"></i> My Experiences </Link></li>
                </ul>
              </div> : <></>
          }

            {/* render the below dashboard for professionals*/}


            {
              membership === "professionals"   ?
                <div class = "navigation__links__professional">
                  <ul class = "nav_links">
                    <li class = "nav_links_content active"> <Link to ="/Mydashboard/business-profile" >  <i class="fas fa-user-tie"></i> View business profile </Link> </li>
                    <li class = "nav_links_content"><Link  to ="/Mydashboard/create-event"  >  <i class="far fa-calendar-alt"></i> Create an event </Link></li>
                    <li class = "nav_links_content"><Link  to ="/Mydashboard/create-experiences"  > <i class="fas fa-wine-glass-alt"></i> Create an experience </Link></li>
                    <li class = "nav_links_content"><Link  to ="/Mydashboard/create-wine-list"  > <i class="fas fa-wine-glass-alt"></i> Create wine list </Link></li>
                    <li class = "nav_links_content"><Link  to ="/Mydashboard/events"  > <i class="fas fa-calendar-alt"></i>  Manage my events </Link></li>
                    <li class = "nav_links_content"><Link  to ="/Mydashboard/experiences"  > <i class="fas fa-glass-cheers"></i> Manage my experiences </Link></li>
                    <li class = "nav_links_content"><Link  to ="/Mydashboard/wine-lists"  > <i class="fas fa-glass-cheers"></i> Manage my wine lists </Link></li>
                  </ul>
                </div> :
                <></>
           }

          </div>
        </div>)
      }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export const SideNavigationClass  = connect(mapStateToProps)(SideNavigationClassUnconnected);
